let languageDict = {
  ja: {
    //サインイン画面作成画面
    "Sign in to your account": "アカウントにサインイン",
    Username: "社員番号",
    "Username *": "社員番号",
    //'Username': "メールアドレス",
    //'Username *': "メールアドレス",
    Password: "パスワード",
    "Password *": "パスワード",
    Change: "変更",
    Skip: "スキップ",
    Verify: "検証",
    "Enter code": "検証コード",
    "Enter your username": "ユーザ名を入力してください",
    "Enter your Username": "ユーザ名を入力してください",
    "Enter your password": "パスワードを入力してください",
    "Forgot your password?": "パスワードを忘れた場合",
    "Forget your password?": "パスワードを忘れた場合",
    "Forgot your password? ": "パスワードを忘れた場合",
    "Forget your password? ": "パスワードを忘れた場合",
    "Reset password": "パスワードのリセット",
    "No account? ": "アカウントがない場合",
    "Create account": "アカウント作成",
    "No account?": "アカウントがない場合",
    "Sign In": "サインイン",
    "User does not exist": "ユーザーが存在しません。",
    "Incorrect username or password.":
      "ユーザー名またはパスワードが間違っています。",
    "Password reset required for the user":
      "パスワードのリセットを行ってください",
    //アカウント作成画面
    "Create a new account": "新しいアカウントを作成",
    Email: "Eメール",
    "Have an account? ": "アカウントをお持ちの場合",
    "Sign in": "サインイン",
    "Create Account": "アカウント作成",
    "Password did not conform with policy: Password must have uppercase characters":
      "パスワードがポリシーに適合していませんでした。英大文字が必要です",
    "Password did not conform with policy: Password must have symbol characters":
      "パスワードがポリシーに適合していませんでした。パスワードには記号文字が必要です。",
    "User already exists": "ユーザが既に存在します",
    //パスワード変更画面
    "Enter New Password": "新しいパスワードを入力",
    "Enter new password": "新しいパスワードを入力",
    "enter new password": "新しいパスワードを入力",
    "New Password *": "新しいパスワード",
    "Reset your password": "パスワードをリセット",
    "Send Code": "コードの送信",
    Code: "リセットコード",
    Submit: "送信",
    "New Password": "新しいパスワード",
    //'New Password *':'新しいパスワード',
    "Change Password": "パスワードの変更",
    "New password": "新しいパスワード",
    "Enter your new password": "新しいパスワードを入力してください",
    "Invalid code provided, please request a code again.":
      "無効な確認コードです。コードの再送を行ってください",
    //確認画面
    "Confirm Sign Up": "サインアップ確認",
    "Confirmation Code * ": "確認コード",
    "Confirmation Code": "確認コード",
    "Lost your code? ": "コードをお忘れですか？",
    "Resend Code": "コードの再送",
    "Back to Sign In": "サインイン",
    Confirm: "登録",
    "Invalid verification code provided, please try again.":
      "無効な確認コードです",
    //
    "User does not exist.": "ユーザーが存在しません",
    //'Incorrect username or password.': 'ユーザー名またはパスワードが違います',
    "User is not confirmed.": "ユーザーは検証されていません",
    //'User already exists': 'ユーザーは既に存在します',
    //'Invalid verification code provided, please try again.': '指定された確認コードが無効です。もう一度お試しください',
    "Invalid password format": "パスワードのフォーマットが不正です",
    "Account recovery requires verified contact information":
      "アカウントの復元には確認済みの連絡先情報が必要です",
    "Invalid phone number format":
      "不正な電話番号フォーマットです。 電話番号は次のフォーマットで入力してください: +12345678900",
    "An account with the given email already exists.":
      "そのメールアドレスは既に存在します",
    "Username cannot be empty": "ユーザー名は必須です",
    "Password attempts exceeded": "パスワード試行回数が超過しました",
    "Attempt limit exceeded, please try after some time.":
      "試行制限を超過しました。しばらくしてからもう一度お試しください",
    "Username/client id combination not found.": "ユーザーが存在しません",
    "CUSTOM_AUTH is not enabled for the client.": "パスワードは必須です", // 本来の意味とは異なるが、パスワード未入力時に発生するのでこの訳としている
    "Password does not conform to policy: Password not long enough":
      "パスワードは8文字以上を入力してください (8文字以上の大文字小文字を含む英数字)",
    "Password does not conform to policy: Password must have uppercase characters":
      "パスワードには大文字を含めてください (8文字以上の大文字小文字を含む英数字)",
    "Password does not conform to policy: Password must have lowercase characters":
      "パスワードには小文字を含めてください (8文字以上の大文字小文字を含む英数字)",
    "Password does not conform to policy: Password must have numeric characters":
      "パスワードには数字を含めてください (8文字以上の大文字小文字を含む英数字)",
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
      "パスワードは8文字以上、大文字小文字を含む英数字を指定してください", // 適宜修正。。
    //"2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\S]+.*[\S]+$": 'パスワードは8文字以上、大文字小文字を含む英数字を指定してください', // 適宜修正。本来の意味とは異なるがこれで明示している。
  },
};

export default languageDict;
