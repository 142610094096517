<template>
  <div class="body">
    <p align="center" class="title">組織図(参照)</p>
    <v-row>
      <v-col align="right" class="button">
        <v-btn @click.once="back">戻る</v-btn>
      </v-col>
    </v-row>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-form ref="form2" v-model="valid">
        <v-row class="ml-2 mr-1 mt-2">
          <v-col cols="0">
            <v-select
              label="事業所名"
              v-model="keyword8124"
              background-color="white"
              id="FID8124"
              class="searchForm selectBox8124"
              no-data-text="データが見つかりません"
              :items="selectBoxFID8124"
              outlined
              clearable
              hide-details="auto"
            >
            </v-select> </v-col
          ><v-col cols="0">
            <v-text-field
              label="事業所コード"
              v-model="keyword8125"
              background-color="white"
              id="FID8125"
              class="searchForm"
              outlined
              clearable
              hide-details="auto"
            >
            </v-text-field> </v-col
          ><v-col cols="0"
            ><v-btn x-large @click="search()" color="primary white--text"
              >検索</v-btn
            ></v-col
          >
        </v-row>
        <v-row class="ml-2 mr-1"> </v-row>
      </v-form>
      <v-form ref="form" v-model="valid">
        <v-row class="ml-2 mr-1 mt-2">
          <v-col cols="">
            <v-data-table
              :headers="headers"
              :items="reports"
              :item-key="reports.ID"
              :page="
                $store.state.pageNum == undefined ? 1 : $store.state.pageNum
              "
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              :header-props="{
                'sort-by-text': '並び順',
              }"
              :footer-props="{
                'items-per-page-text': '行/ページ:',
              }"
              no-data-text="データが存在しません"
              :items-per-page="displayResults"
              @update:page="updatePageNum"
              @update:sort-by="updateSortBy"
              @update:sort-desc="updateSortDesc"
              :custom-sort="customSort"
            >
              <template v-slot:no-results>
                <span>データが存在しません</span>
              </template>

              <!-- ---->
              <template v-slot:item="{ item, index }">
                <template
                  v-if="
                    typeof item.Recodes.FID7986.subReports != 'undefined' &&
                    item.Recodes.FID7986.subReports != null &&
                    item.Recodes.FID7986.subReports.length > 0
                  "
                >
                  <template
                    v-for="(sub0, i0) in item.Recodes.FID7986.subReports"
                  >
                    <template
                      v-if="
                        typeof sub0.Recodes.FID7987.subReports != 'undefined' &&
                        sub0.Recodes.FID7987.subReports != null &&
                        sub0.Recodes.FID7987.subReports.length > 0
                      "
                    >
                      <template
                        v-for="(sub1, i1) in sub0.Recodes.FID7987.subReports"
                      >
                        <template
                          v-if="
                            typeof sub1.Recodes.FID7988.subReports !=
                              'undefined' &&
                            sub1.Recodes.FID7988.subReports != null &&
                            sub1.Recodes.FID7988.subReports.length > 0
                          "
                        >
                          <template
                            v-for="(sub2, i2) in sub1.Recodes.FID7988
                              .subReports"
                          >
                            <tr
                              :class="index % 2 == 0 ? 'trWhite' : 'trBlue'"
                              :key="
                                'sub-' + index + '-' + i0 + '-' + i1 + '-' + i2
                              "
                            >
                              <td
                                v-if="
                                  typeof item.Recodes.FID7977 != 'undefined' &&
                                  i2 == 0 &&
                                  i1 == 0 &&
                                  i0 == 0
                                "
                              >
                                <v-text-field
                                  label="事業所名"
                                  v-model="item.Recodes.FID7977.value"
                                  :disabled="
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    allDisabled ||
                                    item.DeleteFlag
                                  "
                                  :rules="[rules.required]"
                                  id="FID7977"
                                  class="textfield7977 mt-2 mb-1"
                                  outlined
                                  hide-details="auto"
                                >
                                </v-text-field>
                              </td>
                              <td v-else></td>
                              <td
                                v-if="
                                  typeof item.Recodes.FID7978 != 'undefined' &&
                                  i2 == 0 &&
                                  i1 == 0 &&
                                  i0 == 0
                                "
                              >
                                <v-text-field
                                  label="事業所コード"
                                  v-model="item.Recodes.FID7978.value"
                                  :disabled="
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    allDisabled ||
                                    item.DeleteFlag
                                  "
                                  :rules="[rules.required]"
                                  id="FID7978"
                                  class="textfield7978 mt-2 mb-1"
                                  outlined
                                  hide-details="auto"
                                >
                                </v-text-field>
                              </td>
                              <td v-else></td>
                              <td v-if="i2 == 0 && i1 == 0 && i0 == 0">
                                <v-btn
                                  :color="
                                    allDisabled || item.DeleteFlag
                                      ? 'green white--text'
                                      : 'red white--text'
                                  "
                                  dark
                                  class="ma-1"
                                  @click="delIndex(item)"
                                  >{{
                                    allDisabled || item.DeleteFlag
                                      ? "復元"
                                      : "削除"
                                  }}</v-btn
                                >
                              </td>
                              <td v-else></td>
                              <td
                                v-if="
                                  typeof sub0.Recodes.FID7980 != 'undefined' &&
                                  i2 == 0 &&
                                  i1 == 0
                                "
                              >
                                <v-text-field
                                  label="部署名"
                                  v-model="sub0.Recodes.FID7980.value"
                                  :disabled="
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    sub0.DeleteFlag ||
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    sub0.DeleteFlag ||
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    sub0.DeleteFlag
                                  "
                                  id="FID7980"
                                  class="textfield7980 mt-2 mb-1"
                                  outlined
                                  hide-details="auto"
                                >
                                </v-text-field>
                              </td>
                              <td v-else></td>
                              <td
                                v-if="
                                  typeof sub0.Recodes.FID7981 != 'undefined' &&
                                  i2 == 0 &&
                                  i1 == 0
                                "
                              >
                                <v-text-field
                                  label="部署コード"
                                  v-model="sub0.Recodes.FID7981.value"
                                  :disabled="
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    sub0.DeleteFlag ||
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    sub0.DeleteFlag ||
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    sub0.DeleteFlag
                                  "
                                  :rules="[rules.required]"
                                  id="FID7981"
                                  class="textfield7981 mt-2 mb-1"
                                  outlined
                                  hide-details="auto"
                                >
                                </v-text-field>
                              </td>
                              <td v-else></td>
                              <td
                                v-if="
                                  i0 ==
                                    item.Recodes.FID7986.subReports.length -
                                      1 &&
                                  i1 ==
                                    sub0.Recodes.FID7987.subReports.length -
                                      1 &&
                                  sub0.Recodes.FID7987.subReports.length < 20 &&
                                  i2 ==
                                    sub1.Recodes.FID7988.subReports.length -
                                      1 &&
                                  sub1.Recodes.FID7988.subReports.length < 20
                                "
                              >
                                <v-btn
                                  color="primary"
                                  dark
                                  class="ma-2"
                                  @click="
                                    addSubReport(
                                      item,
                                      'FID7986',
                                      'subReport7986'
                                    )
                                  "
                                  :disabled="
                                    item.Recodes.FID7986.subReports.length >= 20
                                  "
                                  >追加</v-btn
                                >
                              </td>
                              <td v-else></td>
                              <td v-if="i2 == 0 && i1 == 0">
                                <v-btn
                                  :color="
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    sub0.DeleteFlag
                                      ? 'green white--text'
                                      : 'red white--text'
                                  "
                                  dark
                                  class="ma-1"
                                  @click="delIndex(sub0)"
                                  >{{
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    sub0.DeleteFlag
                                      ? "復元"
                                      : "削除"
                                  }}</v-btn
                                >
                              </td>
                              <td v-else></td>
                              <td
                                v-if="
                                  typeof sub1.Recodes.FID7982 != 'undefined' &&
                                  i2 == 0
                                "
                              >
                                <v-text-field
                                  label="部門名"
                                  v-model="sub1.Recodes.FID7982.value"
                                  :disabled="
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    sub0.DeleteFlag ||
                                    sub1.DeleteFlag ||
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    sub0.DeleteFlag ||
                                    sub1.DeleteFlag
                                  "
                                  id="FID7982"
                                  class="textfield7982 mt-2 mb-1"
                                  outlined
                                  hide-details="auto"
                                >
                                </v-text-field>
                              </td>
                              <td v-else></td>
                              <td
                                v-if="
                                  typeof sub1.Recodes.FID7983 != 'undefined' &&
                                  i2 == 0
                                "
                              >
                                <v-text-field
                                  label="部署コード"
                                  v-model="sub1.Recodes.FID7983.value"
                                  :disabled="
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    sub0.DeleteFlag ||
                                    sub1.DeleteFlag ||
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    sub0.DeleteFlag ||
                                    sub1.DeleteFlag
                                  "
                                  :rules="[rules.required]"
                                  id="FID7983"
                                  class="textfield7983 mt-2 mb-1"
                                  outlined
                                  hide-details="auto"
                                >
                                </v-text-field>
                              </td>
                              <td v-else></td>
                              <td
                                v-if="
                                  i0 ==
                                    item.Recodes.FID7986.subReports.length -
                                      1 &&
                                  i1 ==
                                    sub0.Recodes.FID7987.subReports.length -
                                      1 &&
                                  sub0.Recodes.FID7987.subReports.length < 20 &&
                                  i2 ==
                                    sub1.Recodes.FID7988.subReports.length -
                                      1 &&
                                  sub1.Recodes.FID7988.subReports.length < 20
                                "
                              >
                                <v-btn
                                  color="primary"
                                  dark
                                  class="ma-2"
                                  @click="
                                    addSubReport(
                                      sub0,
                                      'FID7987',
                                      'subReport7987'
                                    )
                                  "
                                  :disabled="
                                    sub0.Recodes.FID7987.subReports.length >= 20
                                  "
                                  >追加</v-btn
                                >
                              </td>
                              <td v-else></td>
                              <td v-if="i2 == 0">
                                <v-btn
                                  :color="
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    sub0.DeleteFlag ||
                                    sub1.DeleteFlag
                                      ? 'green white--text'
                                      : 'red white--text'
                                  "
                                  dark
                                  class="ma-1"
                                  @click="delIndex(sub1)"
                                  >{{
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    sub0.DeleteFlag ||
                                    sub1.DeleteFlag
                                      ? "復元"
                                      : "削除"
                                  }}</v-btn
                                >
                              </td>
                              <td v-else></td>
                              <td
                                v-if="
                                  typeof sub2.Recodes.FID7984 != 'undefined'
                                "
                              >
                                <v-text-field
                                  label="部署名"
                                  v-model="sub2.Recodes.FID7984.value"
                                  :disabled="
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    sub0.DeleteFlag ||
                                    sub1.DeleteFlag ||
                                    sub2.DeleteFlag
                                  "
                                  :rules="[rules.required]"
                                  id="FID7984"
                                  class="textfield7984 mt-2 mb-1"
                                  outlined
                                  hide-details="auto"
                                >
                                </v-text-field>
                              </td>
                              <td v-else></td>
                              <td
                                v-if="
                                  typeof sub2.Recodes.FID7985 != 'undefined'
                                "
                              >
                                <v-text-field
                                  label="部署コード"
                                  v-model="sub2.Recodes.FID7985.value"
                                  :disabled="
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    sub0.DeleteFlag ||
                                    sub1.DeleteFlag ||
                                    sub2.DeleteFlag
                                  "
                                  :rules="[rules.required]"
                                  id="FID7985"
                                  class="textfield7985 mt-2 mb-1"
                                  outlined
                                  hide-details="auto"
                                >
                                </v-text-field>
                              </td>
                              <td v-else></td>
                              <td
                                v-if="
                                  i2 ==
                                  sub1.Recodes.FID7988.subReports.length - 1
                                "
                              >
                                <v-btn
                                  color="primary"
                                  dark
                                  class="ma-2"
                                  @click="
                                    addSubReport(
                                      sub1,
                                      'FID7988',
                                      'subReport7988'
                                    )
                                  "
                                  :disabled="
                                    sub1.Recodes.FID7988.subReports.length >= 20
                                  "
                                  >追加</v-btn
                                >
                              </td>
                              <td v-else></td>
                              <td>
                                <v-btn
                                  :color="
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    sub0.DeleteFlag ||
                                    sub1.DeleteFlag ||
                                    sub2.DeleteFlag
                                      ? 'green white--text'
                                      : 'red white--text'
                                  "
                                  dark
                                  class="ma-1"
                                  @click="delIndex(sub2)"
                                  >{{
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    sub0.DeleteFlag ||
                                    sub1.DeleteFlag ||
                                    sub2.DeleteFlag
                                      ? "復元"
                                      : "削除"
                                  }}</v-btn
                                >
                              </td>
                            </tr>
                          </template></template
                        >
                        <template v-else
                          ><tr
                            :class="index % 2 == 0 ? 'trWhite' : 'trBlue'"
                            :key="'sub-' + index + '-' + i0 + '-' + i1"
                          >
                            <td
                              v-if="
                                typeof item.Recodes.FID7977 != 'undefined' &&
                                i1 == 0 &&
                                i0 == 0
                              "
                            >
                              <v-text-field
                                label="事業所名"
                                v-model="item.Recodes.FID7977.value"
                                :disabled="
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  allDisabled ||
                                  item.DeleteFlag
                                "
                                :rules="[rules.required]"
                                id="FID7977"
                                class="textfield7977 mt-2 mb-1"
                                outlined
                                hide-details="auto"
                              >
                              </v-text-field>
                            </td>
                            <td v-else></td>
                            <td
                              v-if="
                                typeof item.Recodes.FID7978 != 'undefined' &&
                                i1 == 0 &&
                                i0 == 0
                              "
                            >
                              <v-text-field
                                label="事業所コード"
                                v-model="item.Recodes.FID7978.value"
                                :disabled="
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  allDisabled ||
                                  item.DeleteFlag
                                "
                                :rules="[rules.required]"
                                id="FID7978"
                                class="textfield7978 mt-2 mb-1"
                                outlined
                                hide-details="auto"
                              >
                              </v-text-field>
                            </td>
                            <td v-else></td>
                            <td v-if="i1 == 0 && i0 == 0">
                              <v-btn
                                :color="
                                  allDisabled || item.DeleteFlag
                                    ? 'green white--text'
                                    : 'red white--text'
                                "
                                dark
                                class="ma-1"
                                @click="delIndex(item)"
                                >{{
                                  allDisabled || item.DeleteFlag
                                    ? "復元"
                                    : "削除"
                                }}</v-btn
                              >
                            </td>
                            <td v-else></td>
                            <td
                              v-if="
                                typeof sub0.Recodes.FID7980 != 'undefined' &&
                                i1 == 0
                              "
                            >
                              <v-text-field
                                label="部署名"
                                v-model="sub0.Recodes.FID7980.value"
                                :disabled="
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag ||
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag
                                "
                                id="FID7980"
                                class="textfield7980 mt-2 mb-1"
                                outlined
                                hide-details="auto"
                              >
                              </v-text-field>
                            </td>
                            <td v-else></td>
                            <td
                              v-if="
                                typeof sub0.Recodes.FID7981 != 'undefined' &&
                                i1 == 0
                              "
                            >
                              <v-text-field
                                label="部署コード"
                                v-model="sub0.Recodes.FID7981.value"
                                :disabled="
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag ||
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag
                                "
                                :rules="[rules.required]"
                                id="FID7981"
                                class="textfield7981 mt-2 mb-1"
                                outlined
                                hide-details="auto"
                              >
                              </v-text-field>
                            </td>
                            <td v-else></td>
                            <td
                              v-if="
                                i0 ==
                                  item.Recodes.FID7986.subReports.length - 1 &&
                                i1 ==
                                  sub0.Recodes.FID7987.subReports.length - 1 &&
                                sub0.Recodes.FID7987.subReports.length < 20
                              "
                            >
                              <v-btn
                                color="primary"
                                dark
                                class="ma-2"
                                @click="
                                  addSubReport(item, 'FID7986', 'subReport7986')
                                "
                                :disabled="
                                  item.Recodes.FID7986.subReports.length >= 20
                                "
                                >追加</v-btn
                              >
                            </td>
                            <td v-else></td>
                            <td v-if="i1 == 0">
                              <v-btn
                                :color="
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag
                                    ? 'green white--text'
                                    : 'red white--text'
                                "
                                dark
                                class="ma-1"
                                @click="delIndex(sub0)"
                                >{{
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag
                                    ? "復元"
                                    : "削除"
                                }}</v-btn
                              >
                            </td>
                            <td v-else></td>
                            <td
                              v-if="typeof sub1.Recodes.FID7982 != 'undefined'"
                            >
                              <v-text-field
                                label="部門名"
                                v-model="sub1.Recodes.FID7982.value"
                                :disabled="
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag ||
                                  sub1.DeleteFlag
                                "
                                id="FID7982"
                                class="textfield7982 mt-2 mb-1"
                                outlined
                                hide-details="auto"
                              >
                              </v-text-field>
                            </td>
                            <td v-else></td>
                            <td
                              v-if="typeof sub1.Recodes.FID7983 != 'undefined'"
                            >
                              <v-text-field
                                label="部署コード"
                                v-model="sub1.Recodes.FID7983.value"
                                :disabled="
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag ||
                                  sub1.DeleteFlag
                                "
                                :rules="[rules.required]"
                                id="FID7983"
                                class="textfield7983 mt-2 mb-1"
                                outlined
                                hide-details="auto"
                              >
                              </v-text-field>
                            </td>
                            <td v-else></td>
                            <td
                              v-if="
                                i1 == sub0.Recodes.FID7987.subReports.length - 1
                              "
                            >
                              <v-btn
                                color="primary"
                                dark
                                class="ma-2"
                                @click="
                                  addSubReport(sub0, 'FID7987', 'subReport7987')
                                "
                                :disabled="
                                  sub0.Recodes.FID7987.subReports.length >= 20
                                "
                                >追加</v-btn
                              >
                            </td>
                            <td v-else></td>
                            <td>
                              <v-btn
                                :color="
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag ||
                                  sub1.DeleteFlag
                                    ? 'green white--text'
                                    : 'red white--text'
                                "
                                dark
                                class="ma-1"
                                @click="delIndex(sub1)"
                                >{{
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag ||
                                  sub1.DeleteFlag
                                    ? "復元"
                                    : "削除"
                                }}</v-btn
                              >
                            </td>
                            <td></td>
                            <td></td>
                            <td>
                              <v-btn
                                color="primary"
                                dark
                                class="ma-2"
                                @click="
                                  addSubReport(sub1, 'FID7988', 'subReport7988')
                                "
                                >追加</v-btn
                              >
                            </td>
                            <td></td>
                          </tr>
                        </template> </template
                    ></template>
                    <template v-else
                      ><tr
                        :class="index % 2 == 0 ? 'trWhite' : 'trBlue'"
                        :key="'sub-' + index + '-' + i0"
                      >
                        <td
                          v-if="
                            typeof item.Recodes.FID7977 != 'undefined' &&
                            i0 == 0
                          "
                        >
                          <v-text-field
                            label="事業所名"
                            v-model="item.Recodes.FID7977.value"
                            :disabled="
                              allDisabled ||
                              item.DeleteFlag ||
                              allDisabled ||
                              item.DeleteFlag
                            "
                            :rules="[rules.required]"
                            id="FID7977"
                            class="textfield7977 mt-2 mb-1"
                            outlined
                            hide-details="auto"
                          >
                          </v-text-field>
                        </td>
                        <td v-else></td>
                        <td
                          v-if="
                            typeof item.Recodes.FID7978 != 'undefined' &&
                            i0 == 0
                          "
                        >
                          <v-text-field
                            label="事業所コード"
                            v-model="item.Recodes.FID7978.value"
                            :disabled="
                              allDisabled ||
                              item.DeleteFlag ||
                              allDisabled ||
                              item.DeleteFlag
                            "
                            :rules="[rules.required]"
                            id="FID7978"
                            class="textfield7978 mt-2 mb-1"
                            outlined
                            hide-details="auto"
                          >
                          </v-text-field>
                        </td>
                        <td v-else></td>
                        <td v-if="i0 == 0">
                          <v-btn
                            :color="
                              allDisabled || item.DeleteFlag
                                ? 'green white--text'
                                : 'red white--text'
                            "
                            dark
                            class="ma-1"
                            @click="delIndex(item)"
                            >{{
                              allDisabled || item.DeleteFlag ? "復元" : "削除"
                            }}</v-btn
                          >
                        </td>
                        <td v-else></td>
                        <td v-if="typeof sub0.Recodes.FID7980 != 'undefined'">
                          <v-text-field
                            label="部署名"
                            v-model="sub0.Recodes.FID7980.value"
                            :disabled="
                              allDisabled || item.DeleteFlag || sub0.DeleteFlag
                            "
                            id="FID7980"
                            class="textfield7980 mt-2 mb-1"
                            outlined
                            hide-details="auto"
                          >
                          </v-text-field>
                        </td>
                        <td v-else></td>
                        <td v-if="typeof sub0.Recodes.FID7981 != 'undefined'">
                          <v-text-field
                            label="部署コード"
                            v-model="sub0.Recodes.FID7981.value"
                            :disabled="
                              allDisabled || item.DeleteFlag || sub0.DeleteFlag
                            "
                            :rules="[rules.required]"
                            id="FID7981"
                            class="textfield7981 mt-2 mb-1"
                            outlined
                            hide-details="auto"
                          >
                          </v-text-field>
                        </td>
                        <td v-else></td>
                        <td
                          v-if="
                            i0 == item.Recodes.FID7986.subReports.length - 1
                          "
                        >
                          <v-btn
                            color="primary"
                            dark
                            class="ma-2"
                            @click="
                              addSubReport(item, 'FID7986', 'subReport7986')
                            "
                            :disabled="
                              item.Recodes.FID7986.subReports.length >= 20
                            "
                            >追加</v-btn
                          >
                        </td>
                        <td v-else></td>
                        <td>
                          <v-btn
                            :color="
                              allDisabled || item.DeleteFlag || sub0.DeleteFlag
                                ? 'green white--text'
                                : 'red white--text'
                            "
                            dark
                            class="ma-1"
                            @click="delIndex(sub0)"
                            >{{
                              allDisabled || item.DeleteFlag || sub0.DeleteFlag
                                ? "復元"
                                : "削除"
                            }}</v-btn
                          >
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          <v-btn
                            color="primary"
                            dark
                            class="ma-2"
                            @click="
                              addSubReport(sub0, 'FID7987', 'subReport7987')
                            "
                            >追加</v-btn
                          >
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    </template> </template
                ></template>
                <template v-else>
                  <tr :class="index % 2 == 0 ? 'trWhite' : 'trBlue'">
                    <td v-if="typeof item.Recodes.FID7977 != 'undefined'">
                      <v-text-field
                        label="事業所名"
                        v-model="item.Recodes.FID7977.value"
                        :disabled="allDisabled || item.DeleteFlag"
                        :rules="[rules.required]"
                        id="FID7977"
                        class="textfield7977 mt-2 mb-1"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field>
                    </td>
                    <td v-else></td>
                    <td v-if="typeof item.Recodes.FID7978 != 'undefined'">
                      <v-text-field
                        label="事業所コード"
                        v-model="item.Recodes.FID7978.value"
                        :disabled="allDisabled || item.DeleteFlag"
                        :rules="[rules.required]"
                        id="FID7978"
                        class="textfield7978 mt-2 mb-1"
                        outlined
                        hide-details="auto"
                      >
                      </v-text-field>
                    </td>
                    <td v-else></td>
                    <td>
                      <v-btn
                        :color="
                          allDisabled || item.DeleteFlag
                            ? 'green white--text'
                            : 'red white--text'
                        "
                        dark
                        class="ma-1"
                        @click="delIndex(item)"
                        >{{
                          allDisabled || item.DeleteFlag ? "復元" : "削除"
                        }}</v-btn
                      >
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <v-btn
                        color="primary"
                        dark
                        class="ma-2"
                        @click="addSubReport(item, 'FID7986', 'subReport7986')"
                        >追加</v-btn
                      >
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </template>
              </template>
              <template v-slot:footer.prepend>
                <v-btn color="primary" dark class="ma-2" @click="addReport"
                  >追加</v-btn
                ></template
              >
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col> </v-col>
          <v-col align="right" cols="1">
            <v-btn @click="send" color="primary">送信</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");

export default {
  name: "ReportIndex",
  components: {},
  data() {
    return {
      valid: true,
      initEscape: false,
      isLoading: false,
      fullPage: false,
      displayResults: -1, //5,10,15のいずれか
      height: 100,
      width: 250,
      path: "",
      sortBy: null,
      sortDesc: null,
      //
      PageID: 1151,
      reports: [],
      rules: {
        requiredArray: (value) => (value.length > 0 ? true : "必須項目です"),
        fullWidth: (value) => {
          return /^[^ -~｡-ﾟ]*$/.test(value) || "全角のみ入力可能です";
        },
        fileSize: (value) => {
          return (
            value == null ||
            value.size < 15000000 ||
            "ファイルサイズを15MB未満にしてください"
          );
        },
        required: (value) => !!value || "必須項目です",
      },
      buttonShow: true,
      data: {
        PageID: 1151,
        ReportID: null,
        Recodes: {
          FID7977: { value: "", formType: 1, disabled: false },
          FID7978: { value: "", formType: 1, disabled: false },
          FID7986: {
            value: "PID1152",
            formType: 17,
            disabled: false,
            subReports: [],
          },
        },
      },

      selectBoxFID8124: [],
      keyword8124: "経営企画本部",
      keyword8125: "",

      allDisabled: false,
      subReport7986: {
        PageID: 1152,
        ReportID: null,
        Recodes: {
          FID7980: { value: "", formType: 1, disabled: false },
          FID7981: { value: "", formType: 1, disabled: false },
          FID7987: {
            value: "PID1153",
            formType: 17,
            disabled: false,
            subReports: [],
          },
        },
        DeleteFlag: false,
      },
      subReport7987: {
        PageID: 1153,
        ReportID: null,
        Recodes: {
          FID7982: { value: "", formType: 1, disabled: false },
          FID7983: { value: "", formType: 1, disabled: false },
          FID7988: {
            value: "PID1154",
            formType: 17,
            disabled: false,
            subReports: [],
          },
        },
        DeleteFlag: false,
      },
      subReport7988: {
        PageID: 1154,
        ReportID: null,
        Recodes: {
          FID7984: { value: "", formType: 1, disabled: false },
          FID7985: { value: "", formType: 1, disabled: false },
        },
        DeleteFlag: false,
      },
      ruleMap: {
        FID7977: ["required"],
        FID7978: ["required"],
        FID7981: ["required"],
        FID7983: ["required"],
        FID7984: ["required"],
        FID7985: ["required"],
      },
      //
    };
  },
  async created() {
    await this.getInit();
  },
  methods: {
    async getInit() {
      //タイトルをデフォルトに戻す
      if (typeof process.env.VUE_APP_BASE_TITLE != "undefined") {
        document.title = process.env.VUE_APP_BASE_TITLE;
      }
      //初期処理
      this.isLoading = true;
      this.getKeyWord(this.$store.state.keyWord);
      this.path = this.$route.path;
      //データ取得
      if (JSON.stringify(this.$store.state.reports) != "{}") {
        this.reports = this.$store.state.reports;
      } else {
        let profile;
        [, profile] = await Promise.all([this.search(), api.GetRole()]);
        store.commit("setRole", profile.Role);
        //要素がない場合の回避処理
        this.reports.map((report, index) => {
          for (const [key, Recode] of Object.entries(this.data.Recodes)) {
            if (typeof report.Recodes[key] == "undefined") {
              this.reports[index].Recodes[key] = JSON.parse(
                JSON.stringify(Recode)
              );
            }
          }
        });
      }
      if (this.$store.state.tableSort.sortBy != null) {
        this.sortBy = this.$store.state.tableSort.sortBy;
        this.sortDesc = this.$store.state.tableSort.sortDesc;
      }
      //データ取得処理

      //init処理

      this.reports = this.reports.reverse();
      this.selectBoxFID8124 = await api.FormDetail(7977);

      this.isLoading = false;
    },
    addReport() {
      this.reports.push(JSON.parse(JSON.stringify(this.data)));
    },
    addSubReport(item, FID, subReport) {
      this.isLoading = true;
      if (typeof item.Recodes[FID] == "undefined") {
        item.Recodes[FID] = {
          value: "",
          formType: 17,
          disabled: false,
          subReports: [],
        };
      }
      if (item.Recodes[FID].subReports == null) {
        item.Recodes[FID].subReports = [];
      }
      item.Recodes[FID].subReports.push(
        JSON.parse(JSON.stringify(this[subReport]))
      );
      this.isLoading = false;
    },
    delSubReport(item, FID, subReport) {
      this.isLoading = true;
      if (typeof item.Recodes[FID] == "undefined") {
        item.Recodes[FID] = {
          value: "",
          formType: 17,
          disabled: false,
          subReports: [],
        };
      }
      if (item.Recodes[FID].subReports == null) {
        item.Recodes[FID].subReports = [];
      }
      item.Recodes[FID].subReports.push(
        JSON.parse(JSON.stringify(this[subReport]))
      );
      this.isLoading = false;
    },
    getKeyWord(data) {
      if (data.PageID == this.PageID) {
        data.search.forEach((data) => {
          if (data.form_id == 7977) {
            this.keyword8124 = data.value;
          }
          if (data.form_id == 7978) {
            this.keyword8125 = data.value;
          }
        });
      }
    },
    getSearchWord() {
      let data = {
        PageID: this.PageID,
        orderBy: "",
        search: [],
      };

      if (this.keyword8124 != "") {
        data.search.push({ form_id: 7977, value: this.keyword8124 });
      }
      if (this.keyword8125 != "") {
        data.search.push({ form_id: 7978, value: this.keyword8125 });
      }

      return data;
    },
    async search() {
      if (this.initEscape == false) {
        this.initEscape = true;
        this.isLoading = false;
        await this.$nextTick();
      }
      const ck = await this.checkValidation2();
      if (!ck && !this.isLoading) {
        return;
      }
      //初期処理
      this.isLoading = true;
      //データ追加
      let data = await this.getSearchWord();
      //api
      const reports = await api.ReportSearch(data);

      if (reports != null) {
        this.reports = reports;
        store.commit("setreports", reports);
      }
      store.commit("setkeyWord", data);
      this.isLoading = false;
    },

    back() {
      store.commit("clearkeyWord");
      store.commit("clearreports");
      store.commit("clearPageNum");
      store.commit("clearTableSort");
      //
      if (this.$route.path == this.path) {
        history.back();
      } else {
        alert("読み込みエラー:ホームに戻ります");
        this.$router.push("/", () => {});
      }
      //history.back();
    },
    //reports用のチェック
    checkValidation: async function () {
      const checkReport = function (rules, ruleMap, report) {
        let flag = true;
        //通常のデータ内で実行するとfor分岐が地獄を見るので無し
        for (const [formID, Recode] of Object.entries(report.Recodes)) {
          if (typeof ruleMap[formID] != "undefined" && !report.DeleteFlag) {
            let ruleArr = ruleMap[formID];
            ruleArr.map((key) => {
              if (key != "") {
                const r = rules[key];
                let result = r(Recode.value);
                if (result != true) {
                  flag = false;
                }
              }
            });
          } else if (
            !report.DeleteFlag &&
            Recode.formType == 17 &&
            typeof Recode.subReports != "undefined" &&
            Recode.subReports != null
          ) {
            let subFlag = true;
            Recode.subReports.map((sub) => {
              const res = checkReport(rules, ruleMap, sub);
              if (subFlag) {
                subFlag = res;
              }
            });
            if (!subFlag) {
              flag = false;
            }
          }
        }
        return flag;
      };
      //デフォルト機能による検索は行うが判定は別
      let check = true;
      await this.$refs.form.validate();
      this.reports.map((report) => {
        let flag = checkReport(this.rules, this.ruleMap, report);
        if (check) {
          check = flag;
        }
      });
      return check;
    },
    //検索用のバリデーションチェック
    checkValidation2: async function () {
      let form = this.$refs.form2;
      if (typeof form == "undefined") {
        return false;
      }
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }
      return check;
    },
    setReportID: function (report, pageIDs, index, delReports) {
      report.PageID = pageIDs[index]; //ページIDの追加
      //サブレポートを含む場合の再帰処理
      for (const [FID, Recode] of Object.entries(report.Recodes)) {
        if (Recode.formType == 17) {
          let subReports = [];
          if (Recode.subReports == null) {
            Recode.subReports = [];
          }
          if (typeof pageIDs[index + 1] != "undefined") {
            Recode.subReports.map((sub, i) => {
              const res = this.setReportID(
                report.Recodes[FID].subReports[i],
                pageIDs,
                index + 1,
                delReports
              );
              if (res != null && !report.DeleteFlag) {
                subReports.push(res);
              } else if (res != null && report.DeleteFlag) {
                delReports.push(res);
              }
            });
          } else {
            Recode.subReports = [];
          }
          report.Recodes[FID].subReports = subReports;
        }
      }
      //削除処理
      if (!report.DeleteFlag) {
        return report;
      } else if (report.ReportID != null) {
        delReports.push(report);
      }
      return null;
    },
    async send() {
      //バリデーションチェック
      const isCorrectValue = await this.checkValidation();
      if (isCorrectValue == false) return;
      this.isLoading = true;
      //
      let reports = [];
      let delReports = [];
      let pageIDs = [1151, 1152, 1153, 1154];
      //
      for (let i = 0; i < this.reports.length; i++) {
        const repo = this.setReportID(this.reports[i], pageIDs, 0, delReports);
        if (repo != null) {
          reports.push(repo);
        }
      }
      const result = await api.RegReports(reports);
      if (
        result == undefined ||
        (result.result != "success" && result.result != "Success")
      ) {
        alert("データが登録できませんでした。");
      } else {
        alert("登録完了");
      }
      if (delReports.length > 0) {
        try {
          await api.DelReports(delReports);
        } catch (e) {
          alert("削除失敗");
        }
      }
      this.isLoading = false;
      history.back();
    },
    getValue(v) {
      if (typeof v == "undefined") {
        return "";
      }
      return v.value;
    },
    downloadCSV(csv, filename) {
      //CSV出力部分
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },
    updatePageNum(value) {
      store.commit("setPageNum", value);
    },
    updateSortBy(value) {
      store.commit("setTableSortBy", value);
    },
    updateSortDesc(value) {
      store.commit("setTableSortDesc", value);
    },
    customSort(items, index, isDesc) {
      //デフォルト
      if (index.length == 0 || items.length == 0) {
        return items;
      }
      const regex = /[^0-9]/g;
      const order = isDesc[0] ? 1 : -1;
      const pos = parseInt(index[0].replace(regex, ""));
      items.sort((a, b) => {
        //アイコンソートは特殊処理
        if (index == "target") {
          return 0;
        }
        //
        return this.sort(a, b, order, pos);
      });
      //
      return items;
    },
    sort(a, b, order, pos) {
      if (
        typeof a.Recodes["FID" + pos] == "undefined" ||
        typeof b.Recodes["FID" + pos] == "undefined"
      )
        return -1;
      if (a.Recodes["FID" + pos].value == b.Recodes["FID" + pos].value)
        return 0;
      if (a.Recodes["FID" + pos].value === "") return 1;
      if (b.Recodes["FID" + pos].value === "") return -1;
      if (
        !isNaN(a.Recodes["FID" + pos].value) &&
        !isNaN(b.Recodes["FID" + pos].value)
      ) {
        return Number(a.Recodes["FID" + pos].value) ==
          Number(b.Recodes["FID" + pos].value)
          ? 0
          : Number(a.Recodes["FID" + pos].value) >
            Number(b.Recodes["FID" + pos].value)
          ? -1 * order
          : order;
      }
      return (
        a.Recodes["FID" + pos].value.localeCompare(
          b.Recodes["FID" + pos].value,
          "jp"
        ) *
        -1 *
        order
      );
    },
    dateSort(dateArr) {
      const date1 = new Date(dateArr[0].replace(/-/g, "/"));
      const date2 = new Date(dateArr[1].replace(/-/g, "/"));
      if (date2 - date1 > 0) {
        return dateArr;
      } else {
        return [dateArr[1], dateArr[0]];
      }
    },
    delIndex(item) {
      this.isLoading = true;
      item.DeleteFlag = !item.DeleteFlag;
      this.isLoading = false;
    },
  },
  watch: {},
  computed: {
    headers() {
      return [
        {
          text: "事業所名",
          value: "Recodes.FID7977.value",
          align: "start",
          sortable: true,
          width: "200px",
        },
        {
          text: "事業所コード",
          value: "Recodes.FID7978.value",
          sortable: true,
          width: "160px",
        },
        { text: "削除", value: "delSubReport0", sortable: false },
        {
          text: "部署名",
          value: "Recodes.FID7980.value",
          sortable: true,
          width: "200px",
        },
        {
          text: "部署コード",
          value: "Recodes.FID7981.value",
          sortable: true,
          width: "160px",
        },
        { text: "追加", value: "addSubReport7986", sortable: false },
        { text: "削除", value: "delSubReport7986", sortable: false },
        {
          text: "部門名",
          value: "Recodes.FID7982.value",
          sortable: true,
          width: "200px",
        },
        {
          text: "部署コード",
          value: "Recodes.FID7983.value",
          sortable: true,
          width: "160px",
        },
        { text: "追加", value: "addSubReport7987", sortable: false },
        { text: "削除", value: "delSubReport7987", sortable: false },
        {
          text: "店舗名",
          value: "Recodes.FID7984.value",
          sortable: true,
          width: "250px",
        },
        {
          text: "部署コード",
          value: "Recodes.FID7985.value",
          sortable: true,
          width: "160px",
        },
        { text: "追加", value: "addSubReport7988", sortable: false },
        { text: "削除", value: "delSubReport7988", sortable: false },
      ];
    },
  },
};
</script>
