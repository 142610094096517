<template>
  <v-card :height="resizeHeight" :width="resizeWidth">
    <v-card-actions>
      <v-container>
        <div class="text-center">
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col>
                <v-text-field
                  v-model="returnData.value"
                  disabled
                  :label="title"
                  :rules="rules"
                  style="color: red"
                  type="text"
                  outlined
                  class="custom-label-color text-h5"
                  background-color="rgb(224, 224, 224)"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-btn
                :height="buttonHeihgt"
                :width="buttonWidth"
                :color="color"
                class="mr-1 mb-1 white--text"
                :style="style"
                @click="addNumber(1)"
              >
                1
              </v-btn>
              <v-btn
                :height="buttonHeihgt"
                :width="buttonWidth"
                :color="color"
                class="mr-1 mb-1 white--text"
                :style="style"
                @click="addNumber(2)"
              >
                2
              </v-btn>
              <v-btn
                :height="buttonHeihgt"
                :width="buttonWidth"
                :color="color"
                class="mb-1 white--text"
                :style="style"
                @click="addNumber(3)"
              >
                3
              </v-btn>
            </v-row>

            <v-row justify="center">
              <v-btn
                :height="buttonHeihgt"
                :width="buttonWidth"
                :color="color"
                class="mr-1 mb-1 white--text"
                :style="style"
                @click="addNumber(4)"
              >
                4
              </v-btn>
              <v-btn
                :height="buttonHeihgt"
                :width="buttonWidth"
                :color="color"
                class="mr-1 mb-1 white--text"
                :style="style"
                @click="addNumber(5)"
              >
                5
              </v-btn>
              <v-btn
                :height="buttonHeihgt"
                :width="buttonWidth"
                :color="color"
                class="mb-1 white--text"
                :style="style"
                @click="addNumber(6)"
              >
                6
              </v-btn>
            </v-row>

            <v-row justify="center">
              <v-btn
                :height="buttonHeihgt"
                :width="buttonWidth"
                :color="color"
                class="mr-1 mb-1 white--text"
                :style="style"
                @click="addNumber(7)"
              >
                7
              </v-btn>
              <v-btn
                :height="buttonHeihgt"
                :width="buttonWidth"
                :color="color"
                class="mr-1 mb-1 white--text"
                :style="style"
                @click="addNumber(8)"
              >
                8
              </v-btn>
              <v-btn
                :height="buttonHeihgt"
                :width="buttonWidth"
                :color="color"
                class="mb-1 white--text"
                :style="style"
                @click="addNumber(9)"
              >
                9
              </v-btn>
            </v-row>

            <v-row justify="center">
              <v-btn
                :height="buttonHeihgt"
                :width="buttonWidth"
                :color="color"
                class="mr-1 mb-1 white--text"
                :style="style"
                @click="addNumber(0)"
              >
                0
              </v-btn>
              <v-btn
                :disabled="naturalNumber"
                :height="buttonHeihgt"
                :width="buttonWidth"
                :color="color"
                class="mr-1 mb-1 white--text"
                :style="style"
                @click="addDot"
              >
                .
              </v-btn>
              <v-btn
                v-if="AC"
                :height="buttonHeihgt"
                :width="buttonWidth"
                :color="color"
                class="mb-1 white--text"
                :style="style"
                @click="allClear"
              >
                AC
              </v-btn>
              <v-btn
                v-else-if="minusFlag"
                :height="buttonHeihgt"
                :width="buttonWidth"
                :color="color"
                class="mb-1 white--text"
                :style="style"
                @click="addMinus()"
              >
                -
              </v-btn>
              <v-btn
                v-else
                :height="buttonHeihgt"
                :width="buttonWidth"
                color="white"
                disabled
                depressed
                class="mb-1 white--text"
                style="
                  background-color: white !important;
                  color: white !important;
                "
              >
              </v-btn>
            </v-row>
            <v-row justify="center">
              <v-btn
                :height="buttonHeihgt"
                :width="buttonWidth"
                :color="color"
                class="mr-1 mb-1 white--text"
                :style="style"
                @click="returnData.value = returnData.value.slice(0, -1)"
              >
                DEL
              </v-btn>
              <v-btn
                :height="buttonHeihgt"
                :width="buttonWidth"
                :color="color"
                class="mr-1 mb-1 white--text"
                :style="style"
                @click="submit"
              >
                OK
              </v-btn>
            </v-row>
          </v-form>
        </div>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    value: String,
    title: String,
    rules: Array,
    type: { default: "default", type: String },
    AC: { default: false, type: Boolean },
    minusFlag: { default: false, type: Boolean },
    naturalNumber: { default: false, type: Boolean },
  },
  data() {
    return {
      returnData: {
        value: this.value,
      },
      color: "blue-grey",
      style: "border-radius: 0px;font-size:20px",
      //
      valid: true,
    };
  },
  mounted() {
    this.init();
    document.addEventListener("keydown", this.onKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
  computed: {
    resizeHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "400px";
        case "sm":
          return "500px";
        case "md":
          return "620px";
        case "lg":
          return "500px";
        case "xl":
          return "500px";
        default:
          return "400px";
      }
    },
    resizeWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "270px";
        case "sm":
          return "500px";
        case "md":
          return "600px";
        case "lg":
          return "500px";
        case "xl":
          return "500px";
        default:
          return "500px";
      }
    },
    buttonHeihgt() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "44px";
        case "sm":
          return "60px";
        case "md":
          return "90px";
        case "lg":
          return "60px";
        case "xl":
          return "60px";
        default:
          return "44px";
      }
    },
    buttonWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "70px";
        case "sm":
          return "120px";
        case "md":
          return "150px";
        case "lg":
          return "120px";
        case "xl":
          return "120px";
        default:
          return "70px";
      }
    },
  },
  methods: {
    onKeyDown(event) {
      //let v = this.returnData.value;
      switch (event.keyCode) {
        case 48:
        case 96:
          this.addNumber(0);
          break;
        case 49:
        case 97:
          this.addNumber(1);
          break;
        case 50:
        case 98:
          this.addNumber(2);
          break;
        case 51:
        case 99:
          this.addNumber(3);
          break;
        case 52:
        case 100:
          this.addNumber(4);
          break;
        case 53:
        case 101:
          this.addNumber(5);
          break;
        case 54:
        case 102:
          this.addNumber(6);
          break;
        case 55:
        case 103:
          this.addNumber(7);
          break;
        case 56:
        case 104:
          this.addNumber(8);
          break;
        case 57:
        case 105:
          this.addNumber(9);
          break;
        case 190:
        case 110:
          this.addDot();
          break;
        case 189:
          if (this.minusFlag) {
            this.addMinus();
          }
          break;
        case 46:
        case 8:
          this.returnData.value = this.returnData.value.slice(0, -1);
          break;
        case 13:
          this.submit();
          break;
        default:
          console.log("athoerkey", typeof event.keyCode, event.keyCode);
      }
    },
    addNumber(number) {
      if (isNaN(number)) {
        return;
      }
      //
      switch (this.type) {
        case "calculator":
          if (this.returnData.value == "0") {
            this.returnData.value = String(number);
          } else {
            this.returnData.value += String(number);
          }
          break;
        case "default":
        default:
          this.returnData.value += String(number);
      }
    },
    addMinus() {
      if (this.returnData.value.includes("-")) return;
      if (this.returnData.value != "") return;

      this.returnData.value += "-";
    },
    addDot() {
      switch (this.returnData.value) {
        case "":
          this.returnData.value = "0.";
          break;
        default:
          this.returnData.value += ".";
      }
    },
    allClear(cl) {
      switch (this.type) {
        case "calculator":
          if (typeof cl == "string") {
            this.returnData.value = String(cl);
          } else {
            this.returnData.value = "0";
          }
          break;
        case "default":
        default:
          this.returnData.value = "";
      }
    },
    init() {
      if (this.value == null) {
        this.returnData.value = "";
      } else {
        this.returnData.value = this.value;
      }
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.$emit("ok", this.returnData.value);
      }
    },
  },
};
</script>

<style>
.custom-label-color input {
  color: black !important;
}
</style>
