<template>
  <div class="body">
    <p align="center" class="title">社有車一覧</p>
    <v-row>
      <v-col align="left" class="button"
        ><v-btn @click="create">新規作成</v-btn></v-col
      >

      <v-col align="right" class="button">
        <v-btn
          @click="makeCSVDownload"
          class="mr-5"
          color="orange white--text"
          :disabled="isLoading"
        >
          CSV出力
        </v-btn>
        <v-btn @click.once="back">戻る</v-btn>
      </v-col>
    </v-row>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-form ref="form" v-model="valid">
        <v-row class="ml-2 mr-1">
          <v-col cols="4">
            <v-select
              label="車両番号"
              v-model="keyword8051"
              background-color="white"
              id="FID8051"
              class="searchForm selectBox8051"
              no-data-text="データが見つかりません"
              :items="selectBoxFID8051"
              item-text="label"
              item-value="value"
              outlined
              clearable
              hide-details="auto"
            >
            </v-select> </v-col
          ><v-col cols="6" sm="6" md="3" lg="3">
            <v-select
              label="ステータス"
              v-model="keyword9254"
              :rules="[rules.required]"
              background-color="white"
              id="FID9254"
              class="searchForm"
              :items="selectBox9254"
              outlined
              readonly
              disabled
              hide-details="auto"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row class="ml-2 mr-1">
          <v-col cols="6" sm="6" md="2" lg="2">
            <v-text-field
              label="事業本部"
              :rules="[]"
              v-model="keyword1"
              outlined
              no-data-text="データが見つかりません"
              clearable
              hide-details="auto"
              disabled
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="6" md="2" lg="2">
            <v-text-field
              label="部署名"
              :rules="[rules.required]"
              v-model="keyword2"
              outlined
              no-data-text="データが見つかりません"
              disabled
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="6" md="3" lg="3">
            <v-autocomplete
              label="グループ"
              :rules="[]"
              v-model="keyword3"
              :items="selectBox3"
              id="FID8322"
              class="selectBox8322"
              outlined
              no-data-text="データが見つかりません"
              clearable
              item-text="label"
              item-value="value"
              hide-details="auto"
              @change="setSelectBox4"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="6" sm="6" md="3" lg="3">
            <v-autocomplete
              label="店舗"
              v-model="keyword4"
              :items="selectBox4"
              id="FID8322"
              class="selectBox8322"
              outlined
              no-data-text="データが見つかりません"
              clearable
              item-text="label"
              item-value="value"
              hide-details="auto"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="0">
            <v-btn x-large @click="search()" color="primary white--text">
              検索
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="ml-2 mr-1">
          <v-col cols="">
            <v-data-table
              class="reportIndex"
              :headers="headers"
              :items="reports"
              :item-key="reports.ID"
              :page="
                $store.state.pageNum == undefined ? 1 : $store.state.pageNum
              "
              @update:page="updatePageNum"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              @click:row="move"
              :header-props="{
                'sort-by-text': '並び順',
              }"
              :footer-props="{
                'items-per-page-text': '行/ページ:',
              }"
              no-data-text="データが存在しません"
              :items-per-page="displayResults"
              @update:sort-by="updateSortBy"
              @update:sort-desc="updateSortDesc"
              :custom-sort="customSort"
            >
              <template v-slot:item.link8008="{ item }">
                <v-btn @click.stop="link8008(item)" color="blue white--text"
                  >車両利用申請</v-btn
                >
              </template>
              <template v-slot:item.Recodes.FID8322.value="{ item }">
                {{ $store.state.organization[item.Recodes.FID8322.value] }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
import { getCompanyCar } from "./specialMethod";
const eneosCar = RepositoryFactory.get("eneosCar");
import { companyCar } from "./csvExport/companyCar";

export default {
  name: "ReportIndex",
  components: {},
  data() {
    return {
      valid: true,
      initEscape: false,
      isLoading: false,
      fullPage: false,
      displayResults: 15, //5,10,15のいずれか
      height: 100,
      width: 250,
      path: "",
      sortBy: null,
      sortDesc: null,
      //
      PageID: 1158,
      reports: [],
      rules: {
        required: (value) => !!value || "必須項目です",
        requiredArray: (value) => (value.length > 0 ? true : "必須項目です"),
        fullWidth: (value) => {
          return /^[^ -~｡-ﾟ]*$/.test(value) || "全角のみ入力可能です";
        },
      },
      keyword8335: "",
      selectBoxFID8051: [],
      keyword8051: "",
      selectBox9254: ["利用可"],
      keyword9254: "利用可",
      //
      //部署検索
      keyword1: "",
      keyword2: "",
      keyword1Code: "",
      keyword2Code: "",
      keyword3: "",
      keyword4: "",
      selectBox3: [],
      selectBox4: [],
      ALLDepartment: "",
    };
  },
  async created() {
    await this.getInit();
  },
  methods: {
    async getInit() {
      //タイトルをデフォルトに戻す
      if (typeof process.env.VUE_APP_BASE_TITLE != "undefined") {
        document.title = process.env.VUE_APP_BASE_TITLE;
      }
      //初期処理
      this.isLoading = true;
      await this.initKeyword();
      this.getKeyWord(this.$store.state.keyWord);
      this.path = this.$route.path;
      //データ取得
      if (JSON.stringify(this.$store.state.reports) != "{}") {
        this.reports = this.$store.state.reports;
      } else {
        let profile;
        [, profile] = await Promise.all([this.search(), api.GetRole()]);
        store.commit("setRole", profile.Role);
      }
      if (this.$store.state.tableSort.sortBy != null) {
        this.sortBy = this.$store.state.tableSort.sortBy;
        this.sortDesc = this.$store.state.tableSort.sortDesc;
      }
      console.log("store", Object.keys(this.$store.state.organization).length);
      if (Object.keys(this.$store.state.organization).length < 2) {
        await store.commit("setDepartmentAll");
      }
      this.reports = this.reports.reverse();
      this.selectBoxFID8051 = await getCompanyCar();

      this.isLoading = false;
    },
    link8008(item) {
      this.isLoading = true;
      this.$router.push({ path: "/applicationform/index" });
      this.isLoading = false;
      localStorage.setItem("kakanaiValueFID8007", item.Recodes.FID8007.value);
      localStorage.setItem("kakanaiValueFID9572", item.Recodes.FID8322.value);
      localStorage.setItem("kakanaiValueFID8014", item.Recodes.FID8613.value);
      store.commit("clearreports");
    },
    getKeyWord(data) {
      if (data.PageID == this.PageID) {
        data.search.forEach((data) => {
          if (data.form_id == 8007) {
            this.keyword8051 = data.value;
          }
        });
      }
    },
    getSearchWord() {
      let data = {
        PageID: this.PageID,
        orderBy: "",
        search: [],
      };
      //部署情報の検索
      if (this.keyword4 != "" && this.keyword4 != null) {
        data.search.push({
          form_id: 8322,
          value: this.keyword4,
          option: "match",
        });
      } else if (this.keyword3 != "" && this.keyword3 != null) {
        data.search.push({
          form_id: 8322,
          value: this.keyword3,
          option: "match",
        });
      } else {
        data.search.push({
          form_id: 8322,
          value: this.ALLDepartment,
          option: "commaValuesAnyMatch",
        });
      }
      //
      if (this.keyword8051 != "") {
        data.search.push({ form_id: 8007, value: this.keyword8051 });
      }
      if (this.keyword9254 == "利用可") {
        data.search.push({
          form_id: 8505,
          value: "新規申請 更新",
          option: "anyMatch",
        });
      }

      return data;
    },
    async search() {
      if (this.initEscape == false) {
        this.initEscape = true;
        this.isLoading = false;
        await this.$nextTick();
      }
      const ck = await this.checkValidation();
      if (!ck && !this.isLoading) {
        return;
      }
      //初期処理
      this.isLoading = true;
      //データ追加
      let data = await this.getSearchWord();
      //api
      const reports = await api.ReportSearch(data);
      //SearchTag
      if (reports != null) {
        this.reports = reports;
        store.commit("setreports", reports);
      }
      store.commit("setkeyWord", data);
      this.isLoading = false;
    },
    async initKeyword() {
      if (Object.keys(this.$store.state.organization).length < 2) {
        await store.commit("setDepartmentAll");
      }
      //
      this.keyword1 =
        store.state.organization[store.state.params.FID7767.value];
      this.keyword1Code = store.state.params.FID7767.value;
      let response = await eneosCar.getOrganizationChart();
      this.keyword2 = response.label;
      this.ALLDepartment += response.value + ",";
      this.selectBox3 = response.group;
      //リストの作成
      for (let i = 0; i < response.group.length; i++) {
        this.ALLDepartment += response.group[i].value + ",";
        //
        if (response.group[i].shop != null) {
          for (let j = 0; j < response.group[i].shop.length; j++) {
            this.ALLDepartment += response.group[i].shop[j].value + ",";
          }
        }
      }
      //末尾のカンマを削除
      this.ALLDepartment = this.ALLDepartment.slice(0, -1);
    },
    setSelectBox4() {
      //対象を探す
      if (this.keyword3 == null) {
        this.selectBox4 = [];
        return;
      }
      for (let i = 0; i < this.selectBox3.length; i++) {
        if (this.selectBox3[i].value == this.keyword3) {
          this.selectBox4 = this.selectBox3[i].shop;
          this.keyword4 = "";
        }
      }
    },
    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }

      return check;
    },
    create() {
      this.isLoading = true;
      this.$router.push({ path: "/companycar/create" });

      this.isLoading = false;
    },
    move(data) {
      this.isLoading = true;
      this.$router.push({ path: "/companycar/create/" + data.ReportID });

      this.isLoading = false;
    },
    back() {
      store.commit("clearkeyWord");
      store.commit("clearreports");
      store.commit("clearPageNum");
      store.commit("clearTableSort");
      store.commit("clearkeyWord");

      //
      if (this.$route.path == this.path) {
        history.back();
      } else {
        alert("読み込みエラー:ホームに戻ります");
        this.$router.push("/", () => {});
      }
      //history.back();
    },
    getValue(v) {
      if (typeof v == "undefined") {
        return "";
      }
      return v.value;
    },
    downloadCSV(csv, filename) {
      //CSV出力部分
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },
    updatePageNum(value) {
      store.commit("setPageNum", value);
    },
    updateSortBy(value) {
      store.commit("setTableSortBy", value);
    },
    updateSortDesc(value) {
      store.commit("setTableSortDesc", value);
    },
    customSort(items, index, isDesc) {
      //デフォルト
      if (index.length == 0 || items.length == 0) {
        return items;
      }
      const regex = /[^0-9]/g;
      const order = isDesc[0] ? 1 : -1;
      const pos = parseInt(index[0].replace(regex, ""));
      items.sort((a, b) => {
        //アイコンソートは特殊処理
        if (index == "target") {
          // //アイコンの特殊ソート処理は実装途中
          return 0;
        }
        //
        return this.sort(a, b, order, pos);
      });
      //
      return items;
    },
    sort(a, b, order, pos) {
      if (
        typeof a.Recodes["FID" + pos] == "undefined" ||
        typeof b.Recodes["FID" + pos] == "undefined"
      )
        return -1;
      if (a.Recodes["FID" + pos].value == b.Recodes["FID" + pos].value)
        return 0;
      if (a.Recodes["FID" + pos].value === "") return 1;
      if (b.Recodes["FID" + pos].value === "") return -1;
      if (
        !isNaN(a.Recodes["FID" + pos].value) &&
        !isNaN(b.Recodes["FID" + pos].value)
      ) {
        return Number(a.Recodes["FID" + pos].value) ==
          Number(b.Recodes["FID" + pos].value)
          ? 0
          : Number(a.Recodes["FID" + pos].value) >
            Number(b.Recodes["FID" + pos].value)
          ? -1 * order
          : order;
      }
      return (
        a.Recodes["FID" + pos].value.localeCompare(
          b.Recodes["FID" + pos].value,
          "jp"
        ) *
        -1 *
        order
      );
    },
    dateSort(dateArr) {
      const date1 = new Date(dateArr[0].replace(/-/g, "/"));
      const date2 = new Date(dateArr[1].replace(/-/g, "/"));
      if (date2 - date1 > 0) {
        return dateArr;
      } else {
        return [dateArr[1], dateArr[0]];
      }
    },
    async makeCSVDownload() {
      let name = "社有車一覧";
      if (this.keyword8051 != null && this.keyword8051 != "") {
        name += "[" + this.keyword8051 + "]";
      }
      await store.commit("setDepartmentAll");
      companyCar(this.reports, name);
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "登録事由",
          value: "Recodes.FID8101.value",
          align: "start",
          sortable: true,
        },
        {
          text: "自動車番号または車両番号",
          value: "Recodes.FID8007.value",
          align: "start",
          sortable: true,
        },
        { text: "部署", value: "Recodes.FID8322.value", sortable: true },
        { text: "最終更新日", value: "Recodes.FID8100.value", sortable: true },
        { text: "使用区分", value: "Recodes.FID8108.value", sortable: true },
        { text: "種別・用途", value: "Recodes.FID8109.value", sortable: true },
        {
          text: "初期日・異動日",
          value: "Recodes.FID8271.value",
          sortable: true,
        },
        { text: "車両利用申請", value: "link8008", sortable: false },
      ];
    },
  },
};
</script>
