<template>
  <v-card :height="resizeHeight" :width="resizeWidth">
    <v-card-actions>
      <v-container class="ma-3">
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="4" lg="3" md="3" class="d-flex align-center mx-auto">
              <p class="text-h6 font-weight-bold mb-0">年号</p>
            </v-col>
            <v-col cols="8" lg="9" md="9">
              <v-radio-group
                row
                v-model="japaneseYear"
                :rules="[rules.japaneseYear]"
              >
                <v-radio
                  color="blue-grey darken-2"
                  label="令和"
                  value="令和"
                ></v-radio>
                <v-radio
                  color="blue-grey darken-2"
                  label="平成"
                  value="平成"
                ></v-radio>
                <v-radio
                  color="blue-grey darken-2"
                  label="昭和"
                  value="昭和"
                ></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" lg="3" md="3">
              <p class="text-h6 font-weight-bold mt-1">年月</p>
            </v-col>
            <v-col cols="4" lg="2" md="2">
              <v-text-field
                type="number"
                v-model="year"
                outlined
                hide-spin-buttons
                dense
                :rules="[rules.yearCheck]"
                hide-details="true"
              />
            </v-col>
            <v-col cols="1" lg="1" md="1">
              <p class="text-h6 mt-1 pa-0">年</p>
            </v-col>
            <v-col cols="4" lg="2" md="2">
              <v-text-field
                type="number"
                v-model="month"
                outlined
                hide-spin-buttons
                dense
                :rules="[rules.monthCheck]"
                hide-details="true"
              />
            </v-col>
            <v-col cols="1" lg="1" md="1">
              <p class="text-h6 mt-1">月</p>
            </v-col>
            <v-col cols="4" lg="2" md="2" v-if="!YMonly">
              <v-text-field
                type="number"
                v-model="day"
                outlined
                hide-spin-buttons
                dense
                :rules="[]"
                hide-details="true"
              />
            </v-col>
            <v-col cols="1" lg="1" md="1" v-if="!YMonly">
              <p class="text-h6 mt-1">日</p>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn
              :height="buttonHeihgt"
              :width="buttonWidth"
              color="grey lighten-3"
              class="mr-1 mb-1 red--text"
              style="font-size: 20px; border: 1px solid #333 !important"
              @click="today"
            >
              本日
            </v-btn>
            <v-btn
              :height="buttonHeihgt"
              :width="buttonWidth"
              color="primary"
              class="mr-1 mb-1 white--text"
              style="font-size: 20px; border: 1px solid #333 !important"
              @click="submit"
            >
              確定
            </v-btn>
          </v-row>
          <v-row justify="center" class="mb-1">
            <v-col v-if="message1 != ''" cols="12" lg="4" md="4"
              >{{ message1 }}<br
            /></v-col>
            <v-col v-if="message2 != ''" cols="12" lg="4" md="4"
              >{{ message2 }}<br
            /></v-col>
            <v-col v-if="message3 != ''" cols="12" lg="4" md="4"
              >{{ message3 }}<br
            /></v-col>
          </v-row>
        </v-form>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    label: { default: "和暦", type: String },
    value: String,
    title: String,
    type: { default: "default", type: String },
    AC: { default: true, type: Boolean },
    YMonly: { default: false, type: Boolean },
  },
  data() {
    return {
      japaneseYear: "",
      year: "",
      month: "",
      day: "",
      //
      color: "blue-grey",
      style: "border-radius: 0px;font-size:20px",
      //
      valid: true,
      //
      message1: "",
      message2: "",
      message3: "",
      rules: {
        japaneseYear: (value) => {
          if (value == "") {
            this.message1 = "年号を選択";
            return "年号を選択";
          } else {
            this.message1 = "";
          }
          return true;
        },
        yearCheck: (value) => {
          if (this.japaneseYear == "") {
            this.message2 = "年を入力";
            return "年を入力";
          }
          if (this.japaneseYear == "令和") {
            this.message2 = "";
          } else if (this.japaneseYear == "平成") {
            if (Number(value) > 31) {
              this.message2 = "平成は31年まで";
              return "平成は31年まで";
            }
          } else if (this.japaneseYear == "昭和") {
            if (Number(value) > 64) {
              this.message2 = "昭和は64年まで";
              return "昭和は64年まで";
            }
          }
          this.message2 = "";
          return true;
        },
        monthCheck: (value) => {
          if (value == "") {
            this.message3 = "月を入力";
            return "月を入力";
          } else {
            this.message3 = "";
          }
          return true;
        },
      },
    };
  },
  mounted() {
    this.init();
    document.addEventListener("keydown", this.onKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
  computed: {
    resizeHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "500px";
        case "sm":
          return "500px";
        case "md":
          return "620px";
        case "lg":
          return "350px";
        case "xl":
          return "350px";
        default:
          return "350px";
      }
    },
    resizeWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "270px";
        case "sm":
          return "500px";
        case "md":
          return "600px";
        case "lg":
          return "500px";
        case "xl":
          return "500px";
        default:
          return "500px";
      }
    },
    buttonHeihgt() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "44px";
        case "sm":
          return "60px";
        case "md":
          return "90px";
        case "lg":
          return "60px";
        case "xl":
          return "60px";
        default:
          return "44px";
      }
    },
    buttonWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "70px";
        case "sm":
          return "120px";
        case "md":
          return "150px";
        case "lg":
          return "120px";
        case "xl":
          return "120px";
        default:
          return "70px";
      }
    },
    radioButtonWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "70px";
        case "sm":
          return "120px";
        case "md":
          return "150px";
        case "lg":
          return "120px";
        case "xl":
          return "120px";
        default:
          return "70px";
      }
    },
    btnFlag() {
      let num;
      let flag = false;
      switch (this.status) {
        case "year":
          num = Number(this.year);
          if (this.year == "") {
            break;
          }
          if (this.year == "令和" && (num < 1 || 12 < num)) {
            flag = true;
          } else if (this.year == "平成" && (num < 1 || 31 < num)) {
            flag = true;
          } else if (this.year == "昭和" && (num < 1 || 64 < num)) {
            flag = true;
          }
          break;
        case "month":
          if (this.month == "") {
            break;
          }
          num = Number(this.month);
          if (num < 1 || 12 < num) {
            flag = true;
          }
          break;
        case "day":
          if (this.day == "") {
            break;
          }
          num = Number(this.day);
          if (num < 1 || 31 < num) {
            flag = true;
          }
          break;
      }
      return flag;
    },
  },

  methods: {
    init() {
      console.log("this.value", this.value);
      if (this.value == null || this.value == "") {
        this.japaneseYear = "令和";
        return;
      } else if (this.YMonly) {
        //正規表現処理
        const exp = /(昭和|平成|令和|大正)(\d+)年(\d+)月/;
        const match = this.value.match(exp);
        if (match) {
          this.japaneseYear = match[1];
          this.year = match[2];
          this.month = match[3];
        }
      } else {
        //正規表現処理
        const exp = /(昭和|平成|令和|大正)(\d+)年(\d+)月(\d+)日/;
        const match = this.value.match(exp);
        if (match) {
          this.japaneseYear = match[1];
          this.year = match[2];
          this.month = match[3];
          this.day = match[4];
        }
      }
    },
    onKeyDown(event) {
      //let v = this.returnData.value;
      switch (event.keyCode) {
        case 13:
          this.submit();
          break;
        default:
          console.log("athoerkey", typeof event.keyCode, event.keyCode);
      }
    },
    reset() {
      this.japaneseYear = "";
      this.year = "";
      this.month = "";
      this.day = "";
    },
    today() {
      var today = new Date();
      this.japaneseYear = "令和";
      this.year = String(today.getFullYear() - 2018);
      this.month = String(today.getMonth() + 1);
      if (!this.YMonly) {
        this.day = String(today.getDate());
      }
      this.submit();
    },
    submit() {
      if (this.$refs.form.validate()) {
        let value =
          this.japaneseYear + "" + this.year + "年" + this.month + "月";
        if (!this.YMonly) {
          value += this.day + "日";
        }
        console.log("submit", this.YMonly, value);
        this.$emit("ok", value);
      }
    },
  },
};
</script>

<style></style>
