<template>
  <v-card :height="resizeHeight" :width="resizeWidth">
    <v-card-actions>
      <v-container fluid>
        <v-row justify="center" align-content="center" class="mb-2">
          <v-subheader class="main-title">車両番号入力</v-subheader>
        </v-row>
        <v-form ref="form" v-model="valid" class="ma-0 pa-0">
          <v-row class="ma-0 pa-0">
            <v-col cols="8" lg="4">
              <v-subheader
                :class="
                  'mb-4 settings ' + $vuetify.breakpoint.name == 'lg'
                    ? 'text-h7'
                    : 'text-h8'
                "
              >
                地名区分
              </v-subheader>
            </v-col>
            <v-col cols="4" lg="6" class="pa-0">
              <v-tooltip top color="black">
                <template v-slot:activator="{ on, attrs }">
                  <v-combobox
                    label="地名"
                    v-model="place"
                    type="text"
                    outlined
                    background-color="gray"
                    :items="placeBox"
                    no-data-text="該当なし"
                    :rules="[number_rules.input_rules]"
                    hide-details="true"
                    v-bind="attrs"
                    v-on="on"
                  >
                  </v-combobox>
                </template>
                <span>選択肢に無い地区も入力できます</span>
              </v-tooltip>
            </v-col>
            <v-tooltip
              v-if="$vuetify.breakpoint.name == 'lg'"
              top
              color="black"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mb-8 ml-2" v-bind="attrs" v-on="on"
                  >mdi-help
                </v-icon>
              </template>
              <span>選択肢に無い地区も入力できます</span>
            </v-tooltip>
          </v-row>
          <v-row class="ma-0">
            <v-col cols="8" lg="4">
              <v-subheader
                :class="
                  'mb-4 settings ' + $vuetify.breakpoint.name == 'lg'
                    ? 'text-h7'
                    : 'text-h8'
                "
              >
                分類番号
              </v-subheader>
            </v-col>
            <v-col cols="4" lg="6" class="pa-0">
              <v-text-field
                label="分類番号"
                v-model="Class"
                type="text"
                outlined
                background-color="gray"
                :persistent-hint="false"
                :rules="[number_rules.input_rules, number_rules.hankakuEiSuu]"
                hide-details="true"
              >
              </v-text-field>
            </v-col>
            <v-tooltip
              v-if="$vuetify.breakpoint.name == 'lg'"
              top
              color="black"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mb-8 ml-2" v-bind="attrs" v-on="on"
                  >mdi-help
                </v-icon>
              </template>
              <span>半角入力です</span>
            </v-tooltip>
          </v-row>
          <v-row
            class="ma-0"
            v-if="
              windowsType == 'xl' || windowsType == 'lg' || windowsType == 'md'
            "
          >
            <v-col cols="6" lg="4">
              <v-subheader
                :class="
                  'mb-4 settings ' + $vuetify.breakpoint.name == 'lg'
                    ? 'text-h7'
                    : 'text-h8'
                "
              >
                車両番号
              </v-subheader>
            </v-col>
            <v-col cols="6" lg="8">
              <v-row>
                <v-col cols="0" lg="3" class="ma-0 pa-0">
                  <v-text-field
                    v-model="char"
                    :label="char == '' ? 'あ' : 'ひらがな'"
                    type="text"
                    outlined
                    background-color="gray"
                    :rules="[number_rules.input_rules, number_rules.hiragana]"
                    hide-details="true"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="5" lg="5" class="pa-0 ml-6">
                  <v-text-field
                    :label="designNum1 == '' ? '1234' : '4桁'"
                    v-model="designNum1"
                    type="number"
                    outlined
                    background-color="gray"
                    :rules="[number_rules.input_rules, number_rules.number]"
                  >
                  </v-text-field>
                </v-col>
                <!-- <v-col :cols="hyphenCols" class="pa-0 text-center">
                  <span class="divider">―</span>
                </v-col>
                <v-col cols="5" lg="3" class="pa-0">
                  <v-text-field
                    :label="designNum2 == '' ? '34' : '2桁'"
                    v-model="designNum2"
                    type="number"
                    outlined
                    background-color="gray"
                    :rules="[number_rules.input_rules, number_rules.number]"
                  >
                  </v-text-field>
                </v-col> -->
              </v-row>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col cols="8">
              <v-subheader
                :class="
                  'mb-4 settings ' + $vuetify.breakpoint.name == 'lg'
                    ? 'text-h7'
                    : 'text-h8'
                "
              >
                車体番号
              </v-subheader>
            </v-col>
            <v-col cols="4" class="ma-0 pa-0">
              <v-text-field
                v-model="char"
                label="ひらがな"
                type="text"
                outlined
                background-color="gray"
                :rules="[number_rules.input_rules, number_rules.hiragana]"
                hide-details="true"
              >
              </v-text-field>
            </v-col>
            <v-col cols="5" class="pa-0 ml-2">
              <v-text-field
                label="12"
                v-model="designNum1"
                type="number"
                outlined
                background-color="gray"
                :rules="[number_rules.input_rules, number_rules.number]"
                hide-details="true"
              >
              </v-text-field>
            </v-col>
            <v-col cols="5" class="ml-6 pa-0">
              <v-text-field
                label="34"
                v-model="designNum2"
                type="number"
                outlined
                background-color="gray"
                :rules="[number_rules.input_rules, number_rules.number]"
                hide-details="true"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-btn
              :height="buttonHeihgt"
              :width="buttonWidth"
              color="blue-grey lighten-5"
              class="mb-1 mt-3 red--text settings"
              :style="cancelStyle"
              @click="sub2"
            >
              リセット
            </v-btn>
            <v-btn
              :height="buttonHeihgt"
              :width="buttonWidth"
              :color="color"
              class="mb-1 mt-3 ml-7 white--text settings"
              :style="submitStyle"
              @click="submit"
            >
              確定
            </v-btn>
          </v-row>
          <v-row
            v-if="error_message"
            class="error_message ma-0"
            justify="center"
          >
            {{ error_message }}
          </v-row>
        </v-form>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    value: String,
    title: String,
    rules: Array,
    type: { default: "default", type: String },
    AC: { default: true, type: Boolean },
  },
  data() {
    return {
      returnData: {
        value: this.value,
      },
      placeBox: [
        "札幌",
        "函館",
        "室蘭",
        "帯広",
        "釧路",
        "北見",
        "旭川",
        "宮城",
        "仙台",
        "福島",
        "会津",
        "いわき",
        "岩手",
        "青森",
        "八戸",
        "新潟",
        "長岡",
        "長野",
        "松本",
        "諏訪",
        "山形",
        "庄内",
        "秋田",
        "品川",
        "足立",
        "練馬",
        "多摩",
        "八王子",
        "横浜",
        "川崎",
        "相模",
        "湘南",
        "大宮",
        "熊谷",
        "春日部",
        "所沢",
        "川越",
        "群馬",
        "高崎",
        "千葉",
        "成田",
        "習志野",
        "袖ヶ浦",
        "野田",
        "柏",
        "水戸",
        "土浦",
        "つくば",
        "宇都宮",
        "那須",
        "とちぎ",
        "山梨",
        "名古屋",
        "尾張小牧",
        "一宮",
        "三河",
        "岡崎",
        "豊田",
        "豊橋",
        "三重",
        "鈴鹿",
        "静岡",
        "沼津",
        "伊豆",
        "浜松",
        "岐阜",
        "飛騨",
        "福井",
        "石川",
        "金沢",
        "富山",
        "大阪",
        "なにわ",
        "和泉",
        "堺",
        "京都",
        "奈良",
        "滋賀",
        "和歌山",
        "神戸",
        "姫路",
        "姫路",
        "広島",
        "福山",
        "鳥取",
        "島根",
        "岡山",
        "倉敷",
        "山口",
        "下関",
        "香川",
        "徳島",
        "愛媛",
        "高知",
        "福岡",
        "北九州",
        "筑豊",
        "久留米",
        "長崎",
        "佐世保",
        "大分",
        "佐賀",
        "熊本",
        "宮崎",
        "鹿児島",
        "沖縄",
      ],
      //
      select: "",
      //
      place: "",
      Class: "",
      char: "",
      designNum1: "",
      designNum2: "",
      error_message: "",
      //
      color: "primary",
      del_color: "red white--text",
      //
      submitStyle: "font-size:20px",
      cancelStyle: "font-size:20px; border #CCCCCC;",

      //
      labelStyle: "font-size: 20px; letter-spacing: 10px",
      //
      valid: true,
      number_rules: {
        input_rules: (v) => !!v || "値が入力されていません。",
        class_number: (int) => {
          if (int)
            return (
              /^\d{1,3}$/.test(int) || "数値のみかつ3文字以下のみ入力可能です"
            );
          return true;
        },
        number: (int) => {
          if (int)
            return (
              /^\d{1,4}$/.test(int) || "数値のみかつ4文字以下のみ入力可能です"
            );
          return true;
        },
        hiragana: (value) => {
          if (value)
            return (
              /^[\u3040-\u309F]{1}$/.test(value) ||
              "ひらがなかつ1文字のみ入力可能です。"
            );
          return true;
        },
        hankakuEiSuu: (value) => {
          if (value) {
            return (
              /^[A-Za-z0-9]*$/.test(value) ||
              "半角英数字のみ入力可能です"
            );
          }
          return true;
        },
      },
    };
  },
  mounted() {
    this.init();
    document.addEventListener("keydown", this.onKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
  computed: {
    windowsType() {
      return this.$vuetify.breakpoint.name;
    },
    resizeHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "500px"; // 元450px
        case "sm":
          return "500px"; // 元400px
        case "md":
          return "500px"; // 元420px
        case "lg":
          return "500px"; // 元400px
        case "xl":
          return "400px";
        default:
          return "100px";
      }
    },
    resizeWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "270px";
        case "sm":
          return "500px";
        case "md":
          return "600px";
        case "lg":
          return "700px"; // 元1000px
        case "xl":
          return "500px";
        default:
          return "500px";
      }
    },
    textCols() {
      if (this.$vuetify.breakpoint.name == "xs") {
        return "12";
      }
      return "3";
    },
    inputCols() {
      if (this.$vuetify.breakpoint.name == "xs") {
        return "auto";
      }
      return "5";
    },
    hyphenCols() {
      if (this.$vuetify.breakpoint.name == "xs") {
        return "auto";
      }
      return "1";
    },
    buttonHeihgt() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "44px";
        case "sm":
          return "60px";
        case "md":
          return "90px";
        case "lg":
          return "60px";
        case "xl":
          return "60px";
        default:
          return "44px";
      }
    },
    buttonWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "100px";
        case "sm":
          return "120px";
        case "md":
          return "150px";
        case "lg":
          return "120px";
        case "xl":
          return "120px";
        default:
          return "70px";
      }
    },
    btnFlag() {
      let num;
      let flag = false;
      switch (this.status) {
        case "year":
          num = Number(this.year);
          if (this.year == "") {
            break;
          }
          if (this.year == "令和" && (num < 1 || 12 < num)) {
            flag = true;
          } else if (this.year == "平成" && (num < 1 || 31 < num)) {
            flag = true;
          } else if (this.year == "昭和" && (num < 1 || 64 < num)) {
            flag = true;
          }
          break;
        case "month":
          if (this.month == "") {
            break;
          }
          num = Number(this.month);
          if (num < 1 || 12 < num) {
            flag = true;
          }
          break;
        case "day":
          if (this.day == "") {
            break;
          }
          num = Number(this.day);
          if (num < 1 || 31 < num) {
            flag = true;
          }
          break;
      }
      return flag;
    },
  },
  methods: {
    init() {
      if (this.value == null) {
        this.returnData.value = "";
      } else {
        this.select = "place";
      }
      if (this.returnData.value) {
        const number_data = this.returnData.value.split(" ");
        // 地名
        if (
          number_data[0] &&
          /^[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAF]{1,4}$/.test(
            number_data[0]
          )
        )
          this.place = number_data[0];
        // 分類番号
        if (number_data[1] && /^\d{1,3}$/.test(Number(number_data[1])))
          this.Class = Number(number_data[1]);
        // ひらがな
        if (number_data[2] && /^[\u3040-\u309F]{1}$/.test(number_data[2]))
          this.char = number_data[2];
        // ナンバー
        if (number_data[3] && /[-]/.test(number_data[3])) {
          // ナンバー前半
          if (Number(number_data[3].split("-")[0]))
            this.designNum1 = Number(number_data[3].split("-")[0]);
          // ナンバー後半
          if (Number(number_data[3].split("-")[1]))
            this.designNum2 = Number(number_data[3].split("-")[1]);
        }
      }
    },
    onKeyDown(event) {
      //let v = this.returnData.value;
      switch (event.keyCode) {
        case 13:
          this.submit();
          break;
        default:
          console.log("athoerkey", typeof event.keyCode, event.keyCode);
      }
    },
    addNumber(number) {
      if (isNaN(number)) {
        return;
      }
      //
      switch (this.status) {
        case "year":
          this.year += String(number);
          break;
        case "month":
          this.month += String(number);
          break;
        case "day":
          this.day += String(number);
          break;
      }
    },
    clear() {
      switch (this.status) {
        case "year":
          if (this.year != "") {
            this.year = this.year.slice(0, -1);
          }
          break;
        case "month":
          this.month = this.month.slice(0, -1);
          // if (this.month == "") {
          //   this.status = "year";
          // }
          break;
        case "day":
          this.day = this.day.slice(0, -1);
          // if (this.date == "") {
          //   this.status = "month";
          // }
          break;
      }
    },
    reset() {
      this.status = "year";
      this.japaneseYear = "";
      this.year = "";
      this.month = "";
      this.day = "";
    },
    setYear(value) {
      this.japaneseYear = value;
      this.status = "year";
      this.year = "";
    },
    setMonth(value) {
      this.month = value;
      if (this.status == "month") {
        this.status = "day";
        this.day = "";
      }
    },
    setDay(value) {
      this.day = value;
      this.submit();
    },
    setDate() {
      switch (this.status) {
        case "year":
          this.status = "month";
          this.month = "";
          break;
        case "month":
          this.status = "day";
          this.day = "";
          break;
        case "day":
          this.status = "";
          this.submit();
          break;
      }
    },
    today() {
      var today = new Date();
      this.japaneseYear = "令和";
      this.year = today.getFullYear() - 2018;
      this.month = today.getMonth() + 1;
      this.day = today.getDate();
      this.submit();
    },
    submit() {
      // // 各項目が入力されているかどうか
      // this.error_message = "";
      // if (!this.place) this.error_message = "地名が入力されていません。";
      // if (!this.Class)
      //   this.error_message = this.error_message
      //     ? this.error_message + "\n" + "分類番号が入力されていません。"
      //     : "分類番号が入力されていません。";
      // if (!this.char)
      //   this.error_message = this.error_message
      //     ? this.error_message + "\n" + "ひらがなが入力されていません。"
      //     : "ひらがなが入力されていません。";
      // if (!this.designNum1)
      //   this.error_message = this.error_message
      //     ? this.error_message + "\n" + "ナンバーが入力されていません。"
      //     : "ナンバーが入力されていません。";
      // if (!this.designNum2)
      //   this.error_message = this.error_message
      //     ? this.error_message + "\n" + "ナンバーが入力されていません。"
      //     : "ナンバーが入力されていません。";
      // if (this.error_message) return;
      if (this.$refs.form.validate()) {
        this.$emit(
          "ok",
          this.place +
            " " +
            this.Class +
            " " +
            this.char +
            " " +
            this.designNum1 +
            this.designNum2
        );
      }
    },
    sub2() {
      this.place = "";
      this.char = "";
      this.Class = "";
      this.designNum1 = "";
      this.designNum2 = "";
      this.$refs.form.reset();
    },
  },
};
</script>

<style>
.settings {
  font-size: 20px;
  font-weight: bolder;
}
.custom-label-color input {
  color: black !important;
}
.main-title {
  color: orangered;
  font-size: 30px;
  font-weight: bolder;
  font-family: "MS PGothic";
}
.divider {
  display: block;
  margin-top: 12px;
  font-size: 24px;
}
.error_message {
  white-space: pre-wrap;
  color: red;
}
</style>
