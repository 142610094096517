<template>
  <v-card class="subPage" color="white">
    <div class="ma-1">
      <p>組織図3</p>
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form">
          <v-row>
            <v-col cols="0">
              <v-text-field
                label="部門名"
                v-model="data.Recodes.FID7982.value"
                background-color="white"
                id="FID7982"
                class="textfield7982"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="0">
              <v-text-field
                label="部署コード"
                v-model="data.Recodes.FID7983.value"
                :rules="[rules.required]"
                background-color="white"
                id="FID7983"
                class="textfield7983"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="
              typeof data.Recodes.FID7988.subReports != 'undefined' &&
              data.Recodes.FID7988.subReports.length > 0
            "
          >
            <v-col
              cols="12"
              v-for="(report, i) in data.Recodes.FID7988.subReports"
              :key="`[7988-${i}`"
            >
              <carapprovalSub3
                label=""
                background-color="white"
                :id="'FID7988-' + i"
                class="subPage7988"
                ref="subPage7988"
                :Report="report"
                :subindex="i"
                :daleteReports.sync="data.DeleteSubReports"
                v-on:clickSubmit="saveSubPage"
                :myInit="subInit"
                FID="FID7988"
                hide-details="auto"
              >
              </carapprovalSub3>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="left">
              <v-btn @click="subPageDel('FID7988')" color="red" class="ma-2"
                >削除</v-btn
              >
              <v-btn @click="subPageAdd('FID7988')" color="primary" class="ma-2"
                >追加</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-card>
</template>

<script>
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");

import carapprovalSub3 from "../views/carapprovalSub3";

export default {
  name: "Home",
  components: {
    carapprovalSub3,
  },
  props: {
    //値
    Report: Object,
    subindex: Number,
    parentData: {
      type: Object,
      default: () => {},
      required: false,
    },
    myInit: Boolean,
    FID: String,
  },
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      data: {
        PageID: 1153,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID7982: { value: "", formType: 1, disabled: false },
          FID7983: { value: "", formType: 1, disabled: false },
          FID7988: {
            value: "PID1154",
            formType: 17,
            disabled: false,
            subReports: [],
          },
        },
        DeleteSubReports: [],
      },

      rules: {
        required: (value) => !!value || "必須項目です",
      },
      copyFlag: false,
      noBack: false,
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {},
  watch: {
    $route() {
      this.getInit();
    },
    data: {
      handler: async function (data) {
        //

        //
        this.$emit("clickSubmit", this.FID, this.subindex, data);
      },
      deep: true,
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        //データが存在する場合
        if (this.$route.query.copyFlag) {
          this.reportCopy();
        } else if (this.Report.ReportID != null || this.myInit) {
          //サブレポートの値代入
          this.data.ReportID = this.Report.ReportID;
          this.data.Recodes = api.OptionCopy(
            this.data.Recodes,
            this.Report.Recodes
          );
        } else {
          //初期値設定
        }
        //データ取得処理

        //init処理

        //初回データ反映
        this.$emit("clickSubmit", this.FID, this.subindex, this.data);
        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
      }
    },
    async send() {
      const result = this.escapeValidation
        ? true
        : await this.checkValidation();
      if (result) {
        console.log("SendPage1153");
      }
    },
    subPageAdd(FID, index) {
      this.isLoading = true;
      if (
        typeof this.data.Recodes[FID] == "undefined" ||
        typeof this.data.Recodes[FID].subReports == "undefined"
      ) {
        index = 0;
        this.data.Recodes[FID].subReports = [];
      }
      if (typeof index == "undefined") {
        index = this.data.Recodes[FID].subReports.length;
      }
      let max = 20;
      if (this.data.Recodes[FID].subReports.length < max) {
        this.data.Recodes[FID].subReports.splice(index, 0, {});
      }
      this.isLoading = false;
    },
    subPageDel(FID, index) {
      //this.subInit = false;
      let min = 0;
      if (typeof index == "undefined") {
        index = this.data.Recodes[FID].subReports.length - 1;
      }
      if (this.data.Recodes[FID].subReports.length > min) {
        this.data.DeleteSubReports = this.data.DeleteSubReports.concat(
          this.data.Recodes[FID].subReports.splice(index, 1)
        );
      }
      //this.subInit = true;
    },
    saveSubPage(FID, subindex, reports) {
      this.data.Recodes[FID].subReports.splice(subindex, 1, reports);
      if (reports.DeleteSubReports.length == 1) {
        this.data.DeleteSubReports = this.data.DeleteSubReports.concat(
          reports.DeleteSubReports.pop()
        );
      }
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (this.escapeValidation) {
        return true;
      }
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }
      return check;
    },
    async del() {
      await api.DelReport(this.data);
      alert("削除完了");
      this.back();
    },
  },
};
</script>
<style></style>
