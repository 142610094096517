<template>
  <v-card class="ma-2 mt-4">
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <v-container v-else class="login-form">
      <v-form ref="form" v-model="valid">
        <v-row class="ma-0">
          <v-text-field
            v-model="username"
            label="社員番号"
            key="username"
            required
            outlined
            background-color="white"
            :hint="hint"
            persistent-placeholder
            persistent-hint
            :disabled="usernameDisabled"
          ></v-text-field>
        </v-row>
        <v-row class="ma-0">
          <v-text-field
            v-model="password"
            :label="
              typeof authState == 'undefined' ||
              authState == '' ||
              authState == 'challenge'
                ? 'パスワード'
                : '新しいパスワードを入力'
            "
            key="password"
            :rules="[rules.required, rules.password,rules.isConditionMet,rules.isLengthValid]"
            outlined
            background-color="white"
            :hint="hint"
            persistent-placeholder
            persistent-hint
            :type="passwordShow ? 'text' : 'password'"
            :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="passwordShow = !passwordShow"
            :disabled="passwordDisabled"
          ></v-text-field>
        </v-row>
        <v-row>
          <div
            :label="authState === 'newPassword' ? 'パスワード条件' : ''"
            key="passwordText"
            :rules="[rules.required, rules.password, rules.isConditionMet, rules.isLengthValid]"
            outlined
            background-color="white"
            >
            <template v-if="authState === 'newPassword'">
              パスワード条件<br>
              <span :style="{ color: isConditionMet ? 'black' : 'red' }">
                ■「数字」「英大文字」「英小文字」「記号」全て含む
              </span><br>
              <span :style="{ color: isLengthValid ? 'black' : 'red' }">
                ■ 12文字以上
              </span><br>
            </template>
          </div>
        </v-row>
        <v-row class="ma-0" v-if="authState == 'mail_register'">
          <v-text-field
            v-model="email"
            label="メールアドレスを登録"
            key="email"
            :rules="[rules.required, rules.mail]"
            outlined
            background-color="white"
            :hint="hint"
            persistent-placeholder
            persistent-hint
            :disabled="emailDisabled"
          ></v-text-field>
        </v-row>
        <v-row class="ma-0">
          <v-col align="left" cols="6" sm="6" md="6" lg="6">
            <v-btn
              v-if="!usernameDisabled"
              @click="forgotPassword"
              large
              class="text-caption"
              color="white--text pink lighten-4"
              >パスワードを忘れた場合
            </v-btn>
          </v-col>
          <v-col align="right" cols="6" sm="6" md="6" lg="6">
            <v-btn
              v-if="!usernameDisabled"
              @click="signin"
              large
              color="primary"
              >ログイン</v-btn
            >
            <v-btn
              v-if="authState == 'mail_register'"
              @click="register"
              large
              color="primary"
              >登録
            </v-btn>
            <v-btn
              v-if="authState == 'newPassword'"
              @click="newPassword"
              large
              color="primary"
              >パスワード設定
            </v-btn>
          </v-col>
        </v-row>
        <!-- 検証コード入力  -->
        <div
          v-if="
            authState === 'challenge' ||
            authState == 'email_verified' ||
            authState == 'forgotPassword' ||
            authState == 'first_verify_email' ||
            authState == 'codeResend'
          "
        >
          <v-row class="ma-0">
            <v-text-field
              v-model="verficationCode"
              label="検証コード"
              key="verificationCode"
              required
              outlined
              background-color="white"
              :hint="hint"
              persistent-placeholder
              persistent-hint
              :disabled="verficationCodeDisabled"
              :rules="[rules.required, rules.smallNum]"
            ></v-text-field>
          </v-row>
          <v-row class="ma-0">
            <v-col align="left" v-if="false">
              <v-btn @click="resend" large color="white--text green">
                コード再送
              </v-btn>
            </v-col>
            <v-col align="right">
              <v-btn
                v-if="authState == 'forgotPassword'"
                @click="passwordReset"
                large
                color="primary"
                >リセット
              </v-btn>
              <v-btn
                v-else-if="authState == 'first_verify_email'"
                @click="firstVerifyMail"
                large
                color="primary"
                >初回検証
              </v-btn>
              <v-btn v-else @click="verify" large color="primary">検証</v-btn>
            </v-col>
          </v-row>
        </div>
        <v-row>
          <v-col>
            <v-btn
              @click="viewStatus = !viewStatus"
              :class="viewStatus ? '' : 'white--text white'"
              depressed
            >
              login status
            </v-btn>
          </v-col>
          <v-col>
            <div :class="viewStatus ? 'black--text' : 'white--text'">
              {{ authState }}
            </div>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-container v-if="authState === 'signedin'">
      <v-btn v-if="false" class="mr-4" @click="signout">Sign Out</v-btn>
    </v-container>
  </v-card>
</template>

<script>
//import store from "../../store/vuex.js";
import store from "../../store/vuex.js";
import { Auth } from "aws-amplify";

export default {
  name: "SampleApp",
  data() {
    return {
      isLoading: false,
      passwordShow: false,
      //
      user: undefined,
      authState: "",
      username: "",
      password: "",
      verficationCode: "",
      email: "",
      hint: "",
      //
      viewStatus: false,
      //
      valid: false,
      isConditionMet: false,
      isLengthValid: false,
      rules: {
        required: (value) => !!value || "必須項目です",
        mail: (v) => /.+@.+\..+/.test(v) || "メールアドレスの形式が不正です",
        password: (password) => {
          const digitPattern = /\d/;
          const upperCasePattern = /[A-Z]/;
          const lowerCasePattern = /[a-z]/;
          const symbolPattern = /[\W_]/; // \Wは非単語文字（記号など）、_はアンダースコアを表す
          // 各パターンに一致するかをカウント
          let count = 0;
          if (digitPattern.test(password)) count++;
          if (upperCasePattern.test(password)) count++;
          if (lowerCasePattern.test(password)) count++;
          if (symbolPattern.test(password)) count++;
          if (count < 4) {
            return "数字・英大文字・英小文字・記号を全て使用してください";
          }
          if (password.length < 12) {
            return "パスワードの長さが12文字以下です";
          }
          if (password.includes("＠")) {
            return "全角「＠」が入っています";
          }
        return true
        },
        isConditionMet: (password) => {
          const digitPattern = /\d/;
          const upperCasePattern = /[A-Z]/;
          const lowerCasePattern = /[a-z]/;
          const symbolPattern = /[\W_]/; // \Wは非単語文字（記号など）、_はアンダースコアを表す
          // 各パターンに一致するかをカウント
          let count = 0;
          if (digitPattern.test(password)) count++;
          if (upperCasePattern.test(password)) count++;
          if (lowerCasePattern.test(password)) count++;
          if (symbolPattern.test(password)) count++;
          if (count < 3) {
            this.isConditionMet = false
          } else if (count >= 4) {
            this.isConditionMet = true
          }
        return true
        },
        isLengthValid: (password) => {
          if (password.length < 12) {
            this.isLengthValid = false
          } else if (password.length >= 12) {
            this.isLengthValid = true
          }
        return true
        },
        smallNum: (value) => {
          return /^(\d*.?\d+)*$/.test(value) || "数値のみ入力可能です。";
        },
      },
    };
  },
  computed: {
    usernameDisabled() {
      if (
        this.authState == "" ||
        this.authState == null ||
        this.authState == "signIn_failure"
      ) {
        return false;
      }
      return true;
    },
    passwordDisabled() {
      if (
        this.authState == "" ||
        this.authState == null ||
        this.authState == "newPassword" ||
        this.authState == "signIn_failure" ||
        this.authState == "forgotPassword"
      ) {
        return false;
      }
      return true;
    },
    verficationCodeDisabled() {
      if (
        this.authState == "challenge" ||
        this.authState == "email_verified" ||
        this.authState == "forgotPassword" ||
        this.authState == "first_verify_email" ||
        this.authState == "codeResend"
      ) {
        return false;
      }
      return true;
    },
    emailDisabled() {
      if (
        this.authState == "mail_register" ||
        this.authState == "email_verified"
      ) {
        return false;
      }
      return true;
    },
  },
  async mounted() {
    document.addEventListener("keydown", this.onKeyDown);
    if (this.$store.state.auth) {
      console.log("mounted ログイン済み");
      await this.$router.push("/");
    }
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
  methods: {
    onKeyDown(event) {
      if (event.keyCode == 13 && this.authState == "") {
        this.signin();
      }
    },
    async signin() {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        return;
      }
      this.isLoading = true;
      //通常ログイン
      if (
        this.authState == "" ||
        this.authState == null ||
        this.authState == "signIn_failure"
      ) {
        let [user, hint, state] = await this.$router.signIn(
          this.username,
          this.password.trim()
        );
        this.hint = hint;
        this.authState = state;
        this.user = user;
        //
        if (this.authState == "signedin") {
          this.$router.push("/");
          return;
        }
      } else if (this.authState == "newPassword") {
        const state = await this.$router.firstResetPassword(this.password);
        this.authState = state;
        //this.$router.push("/");
      } else {
        this.hint = "メールアドレスかパスワードが異なります";
      }
      this.isLoading = false;
    },
    async verify() {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        return;
      }

      let res, hint;
      this.isLoading = true;
      //
      if (this.authState == "email_verified") {
        //(モバイル)メールアドレスの検証
        console.log("(モバイル)メールアドレスの検証");
        [res, hint] = await this.$router.verifyMail(this.verficationCode);
        this.authState = res;
        this.hint = hint;
        this.verficationCode = "";
        alert("メールの検証が完了しました");
        //このセッション上は、メールアドレスが未検証の情報が残っているので、
        //サインアウトしてリロードする
        await store.commit("logOut");
        await Auth.signOut();
        location.href = process.env.BASE_URL + "/login";

        this.isLoading = false;
        return;
      }
      if (this.authState == "codeResend") {
        //検証コード再送時のログイン
        console.log("検証コード再送時のログイン");
        [res, hint] = await this.$router.verify(
          this.username,
          this.verficationCode,
          this.authState
        );
      } else {
        //(モバイル)検証コードによるログイン
        [res, hint] = await this.$router.verify(
          this.username,
          this.verficationCode,
          this.authState
        );
        console.log("(モバイル)検証コードによるログイン", res);
      }
      //
      this.authState = res;
      this.hint = hint;
      if (this.authState == "signedin") {
        this.$router.push({ path: "/" });
      } else {
        console.log("login verify", this.authState);
      }

      this.isLoading = false;
    },
    //メールアドレスの登録
    async register() {
      this.isLoading = true;
      const [res, hint] = await this.$router.registerMail(
        this.email,
        this.username
      );
      if (res) {
        this.authState = "email_verified";
      }
      this.isLoading = false;
      this.hint = hint;
    },
    //初期パスワードの設定
    async newPassword() {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        return;
      }
      //
      const res = await this.$router.firstResetPassword(this.password);
      if (res) {
        alert("パスワードの初期設定完了");
        this.authState = "";
      } else {
        console.log("newPassword");
        this.hint = "パスワードリセットに失敗しました";
      }
    },
    //サインアウト
    async signout() {
      try {
        await Auth.signOut();
        this.authState = "";
      } catch (err) {
        console.log(err);
      }
    },
    //検証コードの再送
    async resend() {
      this.isLoading = true;
      if (this.authState == "email_verified") {
        //未認証:検証コードの再送
        this.hint = "送信中";
        const res = await this.$router.resend2(this.username, this.password);
        this.hint = "再送しました";
        console.log("未認証-再送:2", res);
      } else if (this.authState == "challenge") {
        //認証済:検証コードの再送
        //〇こちらコード再送はできているが、再ログインがなぜかうまくいかない
        //前のセッションが残っている
        this.hint = "送信中";
        //await this.$router.resend2(this.username, this.password);
        this.authState = "codeResend";
        this.hint = "再送しました";
        console.log("認証済-再送:3");
      } else if (this.authState == "first_verify_email") {
        //初回メール紐づけ時の再送
        const res = await this.$router.resend1(this.username);
        //this.authState = "first_verify_email ";
        this.hint = "再送済";
        console.log("認証済-再送:4", res);
      } else {
        console.log("パスワードのリセット:", this.authState);
      }
      this.isLoading = false;
    },
    //パスワードの忘れた時
    async forgotPassword() {
      try {
        if (this.username == "") {
          this.hint = "従業員番号が空です";
          return;
        }
        this.hint = "";
        //
        await Auth.forgotPassword(this.username);
        this.authState = "forgotPassword";
      } catch (err) {
        console.log(err);
      }
    },
    //パスワードのリセット(通常)
    async passwordReset() {
      try {
        const [res, message] = await this.$router.resetPassword(
          this.username,
          this.verficationCode,
          this.password
        );
        //this.authState = res;
        console.log("passwordReset", res, message);
        this.authState = "";
        alert("パスワードのリセット成功");
      } catch (err) {
        alert("パスワードリセット失敗:code 1023");
        console.log(err);
        this.authState = "forgotPassword";
      }
    },
    //初回メールアドレスの検証
    async firstVerifyMail() {
      try {
        const res = await this.$router.firstVerifyMail(this.verficationCode);
        if (res == true) {
          alert("メールアドレスの登録が完了しました");
          this.hint = "";
          this.authState = "";
        } else {
          console.log("初回メールアドレスの検証失敗");
          this.hint = "検証コードが異なります";
        }
      } catch (err) {
        console.log("初回メールアドレスの検証", err);
      }
    },
  },
};
</script>
