<template>
  <v-card class="subPage" color="white">
    <div class="ma-1">
      <p>承認記録</p>
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form">
          <v-row>
            <v-col cols="0">
              <v-autocomplete
                label="ステータス"
                v-model="data.Recodes.FID8496.value"
                :disabled="data.Recodes.FID8496.disabled"
                :rules="[rules.required, rules.containBox8496]"
                background-color="white"
                id="FID8496"
                class="selectBox8496"
                outlined
                no-data-text="データが見つかりません"
                clearable
                :items="selectBoxFID8496"
                hide-details="auto"
                :hint="hint8496"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="0">
              <v-btn
                label="棄却"
                :disabled="data.Recodes.FID8567.disabled || inputRuleFID8567()"
                background-color="white"
                id="FID8567"
                class="button8567"
                @click="onBtn8567"
                color="red white--text"
                block
                style="font-size: 20px; letter-spacing: 10px"
                height="50"
                hide-details="auto"
              >
                棄却</v-btn
              >
            </v-col>
            <v-col cols="0">
              <v-btn
                label="承認"
                :disabled="data.Recodes.FID8568.disabled || inputRuleFID8568()"
                background-color="white"
                id="FID8568"
                class="button8568"
                @click="onBtn8568"
                color="indigo white--text"
                block
                style="font-size: 20px; letter-spacing: 10px"
                height="50"
                hide-details="auto"
              >
                承認</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="0">
              <v-text-field
                label="備考欄"
                v-model="data.Recodes.FID8497.value"
                background-color="white"
                id="FID8497"
                class="textfield8497"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-text-field
                label="承認部署"
                :value="
                  typeof $store.state.organization[
                    data.Recodes.FID8541.value
                  ] == 'undefined'
                    ? '部署コード:' + data.Recodes.FID8541.value
                    : $store.state.organization[data.Recodes.FID8541.value]
                "
                disabled
                background-color="white"
                id="FID8541"
                outlined
                hide-details="auto"
              >
              </v-text-field>
              <v-autocomplete
                v-if="false"
                label="承認部署"
                v-model="data.Recodes.FID8541.value"
                :disabled="data.Recodes.FID8541.disabled"
                background-color="white"
                id="FID8541"
                class="selectBox8541"
                outlined
                no-data-text="データが見つかりません"
                :items="selectBoxFID8541"
                item-text="label"
                item-value="value"
                hide-details="auto"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-text-field
                label="承認役職"
                v-model="data.Recodes.FID8542.value"
                background-color="white"
                id="FID8542"
                class="textfield8542"
                outlined
                readonly
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="承認ルート"
                v-model="data.Recodes.FID8562.value"
                :disabled="data.Recodes.FID8562.disabled"
                background-color="white"
                id="FID8562"
                class="textfield8562"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="
              typeof data.Recodes.FID8491.subReports != 'undefined' &&
              data.Recodes.FID8491.subReports.length > 0
            "
          >
            <v-col
              cols="12"
              v-for="(report, i) in data.Recodes.FID8491.subReports"
              :key="`[8491-${i}`"
            >
              <approvalHistory2
                label=""
                background-color="white"
                :id="'FID8491-' + i"
                class="subPage8491"
                ref="subPage8491"
                :Report="report"
                :subindex="i"
                :daleteReports.sync="data.DeleteSubReports"
                v-on:clickSubmit="saveSubPage"
                :myInit="subInit"
                FID="FID8491"
                hide-details="auto"
              >
              </approvalHistory2>
            </v-col>
          </v-row>
          <v-row> </v-row>
        </v-form>
      </div>
    </div>
  </v-card>
</template>

<script>
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");

import { MyCarApprovalCheck } from "./specialMethod";
import { collBtn8567No1 } from "./specialMethod";
import { collBtn8568No1 } from "./specialMethod";
import { getDepartmentAll } from "./specialMethod";
import approvalHistory2 from "../views/approvalHistory2";

export default {
  name: "Home",
  components: {
    approvalHistory2,
  },
  props: {
    //値
    Report: Object,
    subindex: Number,
    parentData: {
      type: Object,
      default: () => {},
      required: false,
    },
    myInit: Boolean,
    FID: String,
    mainRecodes: { default: () => {} },
  },
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      data: {
        PageID: 1214,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID8496: { value: "申請前", formType: 2, disabled: true },
          FID8567: { value: "", formType: 16, disabled: false },
          FID8568: { value: "", formType: 16, disabled: false },
          FID8497: { value: "", formType: 1, disabled: false },
          FID8541: { value: "", formType: 2, disabled: true },
          FID8542: { value: "", formType: 1, disabled: false },
          FID8562: { value: "", formType: 1, disabled: true },
          FID8491: {
            value: "PID1213",
            formType: 17,
            disabled: false,
            subReports: [{}],
          },
          FID8502: { value: "", formType: 20, disabled: false },
        },
        DeleteSubReports: [],
      },
      selectBoxFID8496: [
        "申請前",
        "申請中(1)",
        "申請中(2)",
        "申請中(3)",
        "申請中(4)",
        "承認済",
        "棄却",
        "差し戻し",
      ],

      rules: {
        required: (value) => !!value || "必須項目です",
        containBox8496: (value) => {
          if (typeof this.selectBoxFID8496 == "undefined") {
            return true;
          }
          return this.selectBoxFID8496.includes(value) ||
            value == "" ||
            value == null
            ? true
            : "選択肢にない項目が入力されています";
        },
      },
      copyFlag: false,
      noBack: false,
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {
    hint8496: function () {
      return "申請前→申請中(1~4)→承認済";
    },
    showTemplate8502() {
      return true;
    },
  },
  watch: {
    $route() {
      this.getInit();
    },
    data: {
      handler: async function (data) {
        //
        if (MyCarApprovalCheck(data)) {
          this.data.Recodes.FID8567.disabled = false;
        } else {
          this.data.Recodes.FID8567.disabled = true;
        }
        if (MyCarApprovalCheck(data)) {
          this.data.Recodes.FID8568.disabled = false;
        } else {
          this.data.Recodes.FID8568.disabled = true;
        }

        //
        this.$emit("clickSubmit", this.FID, this.subindex, data);
      },
      deep: true,
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        //データが存在する場合
        if (this.$route.query.copyFlag) {
          this.reportCopy();
        } else if (this.Report.ReportID != null || this.myInit) {
          //サブレポートの値代入
          this.data.ReportID = this.Report.ReportID;
          this.data.Recodes = api.OptionCopy(
            this.data.Recodes,
            this.Report.Recodes
          );
        } else {
          //初期値設定
        }
        //データ取得処理
        [this.selectBoxFID8541] = await Promise.all([
          getDepartmentAll(this.data),
        ]);
        this.selectBoxFID8541.push({
          label: "システムによる追加",
          value: "システムによる追加",
        });
        //init処理
        this.initSubPage8491();

        //初回データ反映
        this.$emit("clickSubmit", this.FID, this.subindex, this.data);
        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
      }
    },
    async onBtn8567() {
      let check = await this.checkValidation();
      if (!check) {
        return;
      }
      //処理を記載
      const date = this.getDateTime();
      this.data.Recodes.FID8567.value = date; //tag:日付+時間
      this.data.Recodes.FID8491.subReports[
        this.data.Recodes.FID8491.subReports.length - 1
      ].Recodes.FID8488.value = this.data.Recodes.FID8567.value;
      const val = this.data.Recodes.FID8567.value.split(" ");
      this.data.Recodes.FID8491.subReports[
        this.data.Recodes.FID8491.subReports.length - 1
      ].Recodes.FID8488.date = val[0];
      this.data.Recodes.FID8491.subReports[
        this.data.Recodes.FID8491.subReports.length - 1
      ].Recodes.FID8488.time = val[1];
      await collBtn8567No1(this.data, this.mainRecodes); //特殊関数の呼び出し
      this.subPageAdd("FID8491");
      await this.save8502(); //送信処理
    },
    inputRuleFID8567() {
      let data = this.data;
      if (MyCarApprovalCheck(data)) {
        return false;
      }
      return true;
    },
    getDateTime() {
      let date = new Date();
      let years = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let days = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let seconds = date.getSeconds().toString().padStart(2, "0");
      return (
        years +
        "-" +
        month +
        "-" +
        days +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    async onBtn8568() {
      let check = await this.checkValidation();
      if (!check) {
        return;
      }
      //処理を記載
      const date = this.getDateTime();
      this.data.Recodes.FID8568.value = date; //tag:日付+時間
      this.data.Recodes.FID8491.subReports[
        this.data.Recodes.FID8491.subReports.length - 1
      ].Recodes.FID8488.value = this.data.Recodes.FID8568.value;
      const val = this.data.Recodes.FID8568.value.split(" ");
      this.data.Recodes.FID8491.subReports[
        this.data.Recodes.FID8491.subReports.length - 1
      ].Recodes.FID8488.date = val[0];
      this.data.Recodes.FID8491.subReports[
        this.data.Recodes.FID8491.subReports.length - 1
      ].Recodes.FID8488.time = val[1];
      await collBtn8568No1(this.data, this.mainRecodes); //特殊関数の呼び出し
      this.subPageAdd("FID8491");
      await this.save8502(); //送信処理
    },
    inputRuleFID8568() {
      let data = this.data;
      if (MyCarApprovalCheck(data)) {
        return false;
      }
      return true;
    },
    subPageAdd(FID, index) {
      this.isLoading = true;
      if (
        typeof this.data.Recodes[FID] == "undefined" ||
        typeof this.data.Recodes[FID].subReports == "undefined"
      ) {
        index = 0;
        this.data.Recodes[FID].subReports = [];
      }
      if (typeof index == "undefined") {
        index = this.data.Recodes[FID].subReports.length;
      }
      let max = 4;
      if (this.data.Recodes[FID].subReports.length < max) {
        this.data.Recodes[FID].subReports.splice(index, 0, {});
      }
      this.isLoading = false;
    },
    subPageDel(FID, index) {
      //this.subInit = false;
      let min = 1;
      if (typeof index == "undefined") {
        index = this.data.Recodes[FID].subReports.length - 1;
      }
      if (this.data.Recodes[FID].subReports.length > min) {
        this.data.DeleteSubReports = this.data.DeleteSubReports.concat(
          this.data.Recodes[FID].subReports.splice(index, 1)
        );
      }
      //this.subInit = true;
    },
    saveSubPage(FID, subindex, reports) {
      this.data.Recodes[FID].subReports.splice(subindex, 1, reports);
      if (reports.DeleteSubReports.length == 1) {
        this.data.DeleteSubReports = this.data.DeleteSubReports.concat(
          reports.DeleteSubReports.pop()
        );
      }
    },
    async send() {
      const result = this.escapeValidation
        ? true
        : await this.checkValidation();
      if (result) {
        await this.$emit("send", this.data, this.subindex);
        console.log("SendPage1214");
      }
    },
    //承認処理
    async save8502() {
      this.data.Recodes.FID8502.value = this.getDateTime();
      //
      await this.send();
    },
    initSubPage8491() {
      for (let i = this.data.Recodes.FID8491.subReports.length; i < 1; i++) {
        this.data.Recodes.FID8491.subReports.push({});
      }
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (this.escapeValidation) {
        return true;
      }
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }
      return check;
    },
    async del() {
      await api.DelReport(this.data);
      alert("削除完了");
      this.back();
    },
  },
};
</script>
<style></style>
