<template>
  <div class="body">
    <p align="center" class="title">マイカー承認フロー</p>
    <v-row>
      <v-col align="right" class="button">
        <v-btn @click.once="back">戻る</v-btn>
      </v-col>
    </v-row>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-form ref="form2" v-model="valid">
        <v-row class="ml-2 mr-1" align="center">
          <v-col cols="2"></v-col>
          <v-col cols="10">
            設定方法<br />
            1.申請者の部署・役職に応じて、承認フローの判定を行います<br />
            2.優先順位の順に部署と役職が該当するかチェックしルート判定をします<br />
            3.ルートが見つからない場合は、申請時にエラーとなります<br />
            4.ルートが見つかった場合は、ルートに沿って該当の権限を持つユーザが承認します<br />
            5.ルートが見つかった場合は、ルートに沿って該当の権限を持つユーザが承認します<br />
            6.下記部署・店舗を指定した場合は、部署名の末尾が同じであれば同じと判定されます<br />
            「業務グループ・販売グループ・販売1グループ・サポートグループ・特約店グループ・特約店1グループ」<br />
            例：「指定：業務グループ 判定：○○事業部業務グループ」<br />
            7.最後に「承認ルートチェック」から全ユーザがいずれかの承認ルートにマッチするかチェックを行ってください。
          </v-col>
        </v-row>
        <v-row class="ml-2 mr-1 mt-2">
          <v-col align="right">
            <v-btn color="deep-purple white--text" @click.once="moveCheck" large
              >承認ルートチェック</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
      <v-form ref="form" v-model="valid">
        <v-row class="ml-2 mr-1 mt-2">
          <v-col cols="">
            <v-data-table
              ref="dataTable"
              :headers="headers"
              :items="reports"
              :item-key="reports.ID"
              :page="
                $store.state.pageNum == undefined ? 1 : $store.state.pageNum
              "
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              :header-props="{
                'sort-by-text': '並び順',
              }"
              :footer-props="{
                'items-per-page-text': '行/ページ:',
              }"
              no-data-text="データが存在しません"
              :items-per-page="displayResults"
              @update:page="updatePageNum"
              @update:sort-by="updateSortBy"
              @update:sort-desc="updateSortDesc"
              :custom-sort="customSort"
            >
              <template v-slot:no-results>
                <span>データが存在しません</span>
              </template>

              <!-- ---->
              <template v-slot:item="{ item, index }">
                <template
                  v-if="
                    typeof item.Recodes.FID8549.subReports != 'undefined' &&
                    item.Recodes.FID8549.subReports != null &&
                    item.Recodes.FID8549.subReports.length > 0
                  "
                >
                  <template
                    v-for="(sub0, i0) in item.Recodes.FID8549.subReports"
                  >
                    <template
                      v-if="
                        typeof sub0.Recodes.FID8552.subReports != 'undefined' &&
                        sub0.Recodes.FID8552.subReports != null &&
                        sub0.Recodes.FID8552.subReports.length > 0
                      "
                    >
                      <template
                        v-for="(sub1, i1) in sub0.Recodes.FID8552.subReports"
                      >
                        <tr
                          :class="index % 2 == 0 ? 'trWhite' : 'trBlue'"
                          :key="'sub-' + index + '-' + i0 + '-' + i1"
                        >
                          <td
                            v-if="
                              typeof item.Recodes.FID10496 != 'undefined' &&
                              i1 == 0 &&
                              i0 == 0
                            "
                          >
                            <v-dialog
                              v-model="item.Recodes.FID10496.modal"
                              :max-width="resizeWidth"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <div v-bind="attrs">
                                  <!-- 優先順位 -->
                                  <v-text-field
                                    :label="String(item.ReportID)"
                                    v-model="item.Recodes.FID10496.value"
                                    :disabled="
                                      allDisabled ||
                                      item.DeleteFlag ||
                                      allDisabled ||
                                      item.DeleteFlag ||
                                      allDisabled ||
                                      item.DeleteFlag
                                    "
                                    :rules="[rules.required, rules.smallNum]"
                                    id="FID10496"
                                    class="textfield10496"
                                    append-icon="mdi-calculator"
                                    v-bind="attrs"
                                    v-on="on"
                                    readonly
                                    hide-details="auto"
                                  >
                                  </v-text-field></div></template
                              ><input-Number
                                v-if="item.Recodes.FID10496.modal"
                                title="優先順位"
                                :value="item.Recodes.FID10496.value"
                                :rules="[rules.required, rules.smallNum]"
                                v-on:ok="
                                  (item.Recodes.FID10496.modal = false),
                                    $set(item.Recodes.FID10496, 'value', $event)
                                "
                                type="calculator"
                              ></input-Number>
                            </v-dialog>
                          </td>
                          <td v-else></td>
                          <td class="mr-0 pr-0 ml-0 pl-0 pa-0">
                            <v-btn
                              class="ma-0"
                              :disabled="index == reports.length - 1"
                              @click="sortDown(index)"
                              color="green white--text"
                              small
                            >
                              <v-icon> mdi-arrow-down-bold </v-icon>
                            </v-btn>
                          </td>
                          <td
                            v-if="
                              typeof item.Recodes.FID8547 != 'undefined' &&
                              i1 == 0 &&
                              i0 == 0
                            "
                          >
                            <v-autocomplete
                              label="部署・店舗"
                              v-model="item.Recodes.FID8547.value"
                              :disabled="
                                allDisabled ||
                                item.DeleteFlag ||
                                allDisabled ||
                                item.DeleteFlag ||
                                allDisabled ||
                                item.DeleteFlag
                              "
                              :rules="[rules.required]"
                              id="FID8547"
                              class="selectBox8547"
                              no-item-text="データが見つかりません"
                              clearable
                              :items="selectBoxFID8547"
                              item-text="label"
                              item-value="value"
                              hide-details="auto"
                            >
                            </v-autocomplete>
                          </td>
                          <td v-else></td>
                          <td
                            v-if="
                              typeof item.Recodes.FID8548 != 'undefined' &&
                              i1 == 0 &&
                              i0 == 0
                            "
                          >
                            <v-autocomplete
                              label="役職"
                              v-model="item.Recodes.FID8548.value"
                              :disabled="
                                allDisabled ||
                                item.DeleteFlag ||
                                allDisabled ||
                                item.DeleteFlag ||
                                allDisabled ||
                                item.DeleteFlag
                              "
                              :rules="[rules.required]"
                              id="FID8548"
                              class="selectBox8548"
                              no-item-text="データが見つかりません"
                              clearable
                              :items="selectBoxFID8548"
                              hide-details="auto"
                            >
                            </v-autocomplete>
                          </td>
                          <td v-else></td>
                          <td v-if="i1 == 0 && i0 == 0">
                            <v-btn
                              :color="
                                allDisabled || item.DeleteFlag
                                  ? 'green white--text'
                                  : 'red white--text'
                              "
                              dark
                              class="ma-1"
                              @click="delIndex(item)"
                              >{{
                                allDisabled || item.DeleteFlag ? "復元" : "削除"
                              }}</v-btn
                            >
                          </td>
                          <td v-else></td>
                          <td
                            v-if="
                              typeof sub0.Recodes.FID8550 != 'undefined' &&
                              i1 == 0
                            "
                          >
                            <v-autocomplete
                              label="部署・店舗"
                              v-model="sub0.Recodes.FID8550.value"
                              :disabled="
                                allDisabled ||
                                item.DeleteFlag ||
                                sub0.DeleteFlag ||
                                allDisabled ||
                                item.DeleteFlag ||
                                sub0.DeleteFlag
                              "
                              :rules="[rules.required]"
                              id="FID8550"
                              class="selectBox8550"
                              no-sub0-text="データが見つかりません"
                              clearable
                              :items="selectBoxFID8550"
                              hide-details="auto"
                              item-text="label"
                              item-value="value"
                            >
                            </v-autocomplete>
                          </td>
                          <td v-else></td>
                          <td
                            v-if="
                              typeof sub0.Recodes.FID8551 != 'undefined' &&
                              i1 == 0
                            "
                          >
                            <v-autocomplete
                              label="役職"
                              v-model="sub0.Recodes.FID8551.value"
                              :disabled="
                                allDisabled ||
                                item.DeleteFlag ||
                                sub0.DeleteFlag ||
                                allDisabled ||
                                item.DeleteFlag ||
                                sub0.DeleteFlag
                              "
                              :rules="[rules.required]"
                              id="FID8551"
                              class="selectBox8551"
                              no-sub0-text="データが見つかりません"
                              clearable
                              :items="selectBoxFID8551"
                              hide-details="auto"
                            >
                            </v-autocomplete>
                          </td>
                          <td v-else></td>
                          <td
                            v-if="
                              i0 ==
                                item.Recodes.FID8549.subReports.length - 1 &&
                              i1 ==
                                sub0.Recodes.FID8552.subReports.length - 1 &&
                              sub0.Recodes.FID8552.subReports.length < 1 &&
                              false
                            "
                          >
                            <v-btn
                              color="primary"
                              dark
                              class="ma-2"
                              @click="
                                addSubReport(item, 'FID8549', 'subReport8549')
                              "
                              :disabled="
                                item.Recodes.FID8549.subReports.length >= 1
                              "
                              >追加</v-btn
                            >
                          </td>
                          <td v-else></td>
                          <td v-if="i1 == 0">
                            <v-btn
                              :color="
                                allDisabled ||
                                item.DeleteFlag ||
                                sub0.DeleteFlag
                                  ? 'green white--text'
                                  : 'red white--text'
                              "
                              dark
                              class="ma-1"
                              @click="delIndex(sub0)"
                              >{{
                                allDisabled ||
                                item.DeleteFlag ||
                                sub0.DeleteFlag
                                  ? "復元"
                                  : "削除"
                              }}</v-btn
                            >
                          </td>
                          <td v-else></td>
                          <td v-if="typeof sub1.Recodes.FID8553 != 'undefined'">
                            <v-autocomplete
                              label="部署・店舗"
                              v-model="sub1.Recodes.FID8553.value"
                              :disabled="
                                allDisabled ||
                                item.DeleteFlag ||
                                sub0.DeleteFlag ||
                                sub1.DeleteFlag
                              "
                              :rules="[rules.required]"
                              id="FID8553"
                              class="selectBox8553"
                              no-sub1-text="データが見つかりません"
                              clearable
                              :items="selectBoxFID8553"
                              item-text="label"
                              item-value="value"
                              hide-details="auto"
                            >
                            </v-autocomplete>
                          </td>
                          <td v-else></td>
                          <td v-if="typeof sub1.Recodes.FID8554 != 'undefined'">
                            <v-autocomplete
                              label="役職"
                              v-model="sub1.Recodes.FID8554.value"
                              :disabled="
                                allDisabled ||
                                item.DeleteFlag ||
                                sub0.DeleteFlag ||
                                sub1.DeleteFlag
                              "
                              :rules="[rules.required]"
                              id="FID8554"
                              class="selectBox8554"
                              no-sub1-text="データが見つかりません"
                              clearable
                              :items="selectBoxFID8554"
                              hide-details="auto"
                            >
                            </v-autocomplete>
                          </td>
                          <td v-else></td>
                          <td
                            v-if="
                              i1 ==
                                sub0.Recodes.FID8552.subReports.length - 1 &&
                              false
                            "
                          >
                            <v-btn
                              color="primary"
                              dark
                              class="ma-2"
                              @click="
                                addSubReport(sub0, 'FID8552', 'subReport8552')
                              "
                              :disabled="
                                sub0.Recodes.FID8552.subReports.length >= 1
                              "
                              >追加</v-btn
                            >
                          </td>
                          <td v-else></td>
                          <td>
                            <v-btn
                              :color="
                                allDisabled ||
                                item.DeleteFlag ||
                                sub0.DeleteFlag ||
                                sub1.DeleteFlag
                                  ? 'green white--text'
                                  : 'red white--text'
                              "
                              dark
                              class="ma-1"
                              @click="delIndex(sub1)"
                              >{{
                                allDisabled ||
                                item.DeleteFlag ||
                                sub0.DeleteFlag ||
                                sub1.DeleteFlag
                                  ? "復元"
                                  : "削除"
                              }}</v-btn
                            >
                          </td>
                        </tr>
                      </template></template
                    >
                    <template v-else
                      ><tr
                        :class="index % 2 == 0 ? 'trWhite' : 'trBlue'"
                        :key="'sub-' + index + '-' + i0"
                      >
                        <td
                          v-if="
                            typeof item.Recodes.FID10496 != 'undefined' &&
                            i0 == 0
                          "
                        >
                          <v-dialog
                            v-model="item.Recodes.FID10496.modal"
                            :max-width="resizeWidth"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <div v-bind="attrs">
                                <!-- 優先順位 -->
                                <v-text-field
                                  :label="String(item.ReportID)"
                                  v-model="item.Recodes.FID10496.value"
                                  :disabled="
                                    allDisabled ||
                                    item.DeleteFlag ||
                                    allDisabled ||
                                    item.DeleteFlag
                                  "
                                  :rules="[rules.required, rules.smallNum]"
                                  id="FID10496"
                                  class="textfield10496"
                                  append-icon="mdi-calculator"
                                  v-bind="attrs"
                                  v-on="on"
                                  readonly
                                  hide-details="auto"
                                >
                                </v-text-field></div></template
                            ><input-Number
                              v-if="item.Recodes.FID10496.modal"
                              title="優先順位"
                              :value="item.Recodes.FID10496.value"
                              :rules="[rules.required, rules.smallNum]"
                              v-on:ok="
                                (item.Recodes.FID10496.modal = false),
                                  $set(item.Recodes.FID10496, 'value', $event)
                              "
                              type="calculator"
                            ></input-Number>
                          </v-dialog>
                        </td>
                        <td v-else></td>
                        <td class="mr-0 pr-0 ml-0 pl-0 pa-0">
                          <v-btn
                            :disabled="index == reports.length - 1"
                            @click="sortDown(index)"
                            color="green white--text"
                            small
                          >
                            <v-icon> mdi-arrow-down-bold </v-icon>
                          </v-btn>
                        </td>
                        <td
                          v-if="
                            typeof item.Recodes.FID8547 != 'undefined' &&
                            i0 == 0
                          "
                        >
                          <v-autocomplete
                            label="部署・店舗1"
                            v-model="item.Recodes.FID8547.value"
                            :disabled="
                              allDisabled ||
                              item.DeleteFlag ||
                              allDisabled ||
                              item.DeleteFlag
                            "
                            :rules="[rules.required]"
                            id="FID8547"
                            class="selectBox8547"
                            no-item-text="データが見つかりません"
                            clearable
                            :items="selectBoxFID8547"
                            item-text="label"
                            item-value="value"
                            hide-details="auto"
                          >
                          </v-autocomplete>
                        </td>
                        <td v-else></td>
                        <td
                          v-if="
                            typeof item.Recodes.FID8548 != 'undefined' &&
                            i0 == 0
                          "
                        >
                          <v-autocomplete
                            label="役職"
                            v-model="item.Recodes.FID8548.value"
                            :disabled="
                              allDisabled ||
                              item.DeleteFlag ||
                              allDisabled ||
                              item.DeleteFlag
                            "
                            :rules="[rules.required]"
                            id="FID8548"
                            class="selectBox8548"
                            no-item-text="データが見つかりません"
                            clearable
                            :items="selectBoxFID8548"
                            hide-details="auto"
                          >
                          </v-autocomplete>
                        </td>
                        <td v-else></td>
                        <td v-if="i0 == 0">
                          <v-btn
                            :color="
                              allDisabled || item.DeleteFlag
                                ? 'green white--text'
                                : 'red white--text'
                            "
                            dark
                            class="ma-1"
                            @click="delIndex(item)"
                            >{{
                              allDisabled || item.DeleteFlag ? "復元" : "削除"
                            }}</v-btn
                          >
                        </td>
                        <td v-else></td>
                        <td v-if="typeof sub0.Recodes.FID8550 != 'undefined'">
                          <v-autocomplete
                            label="部署・店舗"
                            v-model="sub0.Recodes.FID8550.value"
                            :disabled="
                              allDisabled || item.DeleteFlag || sub0.DeleteFlag
                            "
                            :rules="[rules.required]"
                            id="FID8550"
                            class="selectBox8550"
                            no-sub0-text="データが見つかりません"
                            clearable
                            :items="selectBoxFID8550"
                            item-text="label"
                            item-value="value"
                            hide-details="auto"
                          >
                          </v-autocomplete>
                        </td>
                        <td v-else></td>
                        <td v-if="typeof sub0.Recodes.FID8551 != 'undefined'">
                          <v-autocomplete
                            label="役職"
                            v-model="sub0.Recodes.FID8551.value"
                            :disabled="
                              allDisabled || item.DeleteFlag || sub0.DeleteFlag
                            "
                            :rules="[rules.required]"
                            id="FID8551"
                            class="selectBox8551"
                            no-sub0-text="データが見つかりません"
                            clearable
                            :items="selectBoxFID8551"
                            hide-details="auto"
                          >
                          </v-autocomplete>
                        </td>
                        <td v-else></td>
                        <td
                          v-if="
                            i0 == item.Recodes.FID8549.subReports.length - 1 &&
                            false
                          "
                        >
                          <v-btn
                            color="primary"
                            dark
                            class="ma-2"
                            @click="
                              addSubReport(item, 'FID8549', 'subReport8549')
                            "
                            :disabled="
                              item.Recodes.FID8549.subReports.length >= 1
                            "
                            >追加</v-btn
                          >
                        </td>
                        <td v-else></td>
                        <td>
                          <v-btn
                            :color="
                              allDisabled || item.DeleteFlag || sub0.DeleteFlag
                                ? 'green white--text'
                                : 'red white--text'
                            "
                            dark
                            class="ma-1"
                            @click="delIndex(sub0)"
                            >{{
                              allDisabled || item.DeleteFlag || sub0.DeleteFlag
                                ? "復元"
                                : "削除"
                            }}</v-btn
                          >
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          <v-btn
                            color="primary"
                            dark
                            class="ma-2"
                            @click="
                              addSubReport(sub0, 'FID8552', 'subReport8552')
                            "
                            >追加</v-btn
                          >
                        </td>
                        <td></td>
                      </tr>
                    </template> </template
                ></template>
                <template v-else>
                  <tr :class="index % 2 == 0 ? 'trWhite' : 'trBlue'">
                    <td v-if="typeof item.Recodes.FID10496 != 'undefined'">
                      <v-dialog
                        v-model="item.Recodes.FID10496.modal"
                        :max-width="resizeWidth"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <div v-bind="attrs">
                            <!-- 優先順位 -->
                            <v-text-field
                              :label="String(item.ReportID)"
                              v-model="item.Recodes.FID10496.value"
                              :disabled="allDisabled || item.DeleteFlag"
                              :rules="[rules.required, rules.smallNum]"
                              id="FID10496"
                              class="textfield10496"
                              append-icon="mdi-calculator"
                              v-bind="attrs"
                              v-on="on"
                              readonly
                              hide-details="auto"
                            >
                            </v-text-field></div></template
                        ><input-Number
                          v-if="item.Recodes.FID10496.modal"
                          title="優先順位"
                          :value="item.Recodes.FID10496.value"
                          :rules="[rules.required, rules.smallNum]"
                          v-on:ok="
                            (item.Recodes.FID10496.modal = false),
                              $set(item.Recodes.FID10496, 'value', $event)
                          "
                          type="calculator"
                        ></input-Number>
                      </v-dialog>
                    </td>
                    <td v-else></td>
                    <td class="mr-0 pr-0 ml-0 pl-0 pa-0">
                      <v-btn
                        :disabled="index == reports.length - 1"
                        @click="sortDown(index)"
                        color="green white--text"
                        small
                      >
                        <v-icon> mdi-arrow-down-bold </v-icon>
                      </v-btn>
                    </td>
                    <td v-if="typeof item.Recodes.FID8547 != 'undefined'">
                      <v-autocomplete
                        label="部署・店舗"
                        v-model="item.Recodes.FID8547.value"
                        :disabled="allDisabled || item.DeleteFlag"
                        :rules="[rules.required]"
                        id="FID8547"
                        class="selectBox8547"
                        no-item-text="データが見つかりません"
                        clearable
                        :items="selectBoxFID8547"
                        item-text="label"
                        item-value="value"
                        hide-details="auto"
                      >
                      </v-autocomplete>
                    </td>
                    <td v-else></td>
                    <td v-if="typeof item.Recodes.FID8548 != 'undefined'">
                      <v-autocomplete
                        label="役職"
                        v-model="item.Recodes.FID8548.value"
                        :disabled="allDisabled || item.DeleteFlag"
                        :rules="[rules.required]"
                        id="FID8548"
                        class="selectBox8548"
                        no-item-text="データが見つかりません"
                        clearable
                        :items="selectBoxFID8548"
                        hide-details="auto"
                      >
                      </v-autocomplete>
                    </td>
                    <td v-else></td>
                    <td>
                      <v-btn
                        :color="
                          allDisabled || item.DeleteFlag
                            ? 'green white--text'
                            : 'red white--text'
                        "
                        dark
                        class="ma-1"
                        @click="delIndex(item)"
                        >{{
                          allDisabled || item.DeleteFlag ? "復元" : "削除"
                        }}</v-btn
                      >
                    </td>
                    <td></td>
                    <td></td>
                    <td>
                      <v-btn
                        color="primary"
                        dark
                        class="ma-2"
                        @click="addSubReport(item, 'FID8549', 'subReport8549')"
                        >追加</v-btn
                      >
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                </template>
              </template>
              <!-- eslint-disable-next-line -->
              <template v-slot:footer.prepend>
                <v-btn color="primary" dark class="ma-2" @click="addReport"
                  >追加</v-btn
                ></template
              >
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col> </v-col>
          <v-col align="right" cols="1">
            <v-btn @click="send" color="primary">送信</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              color="light-green white--text"
              fixed
              bottom
              @click="scrollLeft"
              :style="'left:120px;'"
            >
              <v-icon>mdi-arrow-left-thick</v-icon>
            </v-btn>
            <v-btn
              color="light-green white--text"
              fixed
              bottom
              @click="scrollRight"
              :style="'left:200px;'"
            >
              <v-icon>mdi-arrow-right-thick</v-icon>
            </v-btn>
          </v-col>
          <v-col align="right">
            <v-btn @click="send" color="primary" fixed> 送信 </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
const eneosCar = RepositoryFactory.get("eneosCar");
import inputNumber from "../views/inputNumber";

export default {
  name: "ReportIndex",
  components: {
    inputNumber,
  },
  data() {
    return {
      valid: true,
      initEscape: false,
      isLoading: false,
      fullPage: false,
      displayResults: -1, //5,10,15のいずれか
      height: 100,
      width: 250,
      path: "",
      sortBy: null,
      sortDesc: null,
      //
      PageID: 1226,
      reports: [],
      rules: {
        requiredArray: (value) => (value.length > 0 ? true : "必須項目です"),
        fullWidth: (value) => {
          return /^[^ -~｡-ﾟ]*$/.test(value) || "全角のみ入力可能です";
        },
        fileSize: (value) => {
          return (
            value == null ||
            value.size < 15000000 ||
            "ファイルサイズを15MB未満にしてください"
          );
        },
        required: (value) => !!value || "必須項目です",
        smallNum: (value) => {
          return /^(\d*.?\d+)*$/.test(value) || "数値のみ入力可能です。";
        },
        containBox8547: (value) => {
          if (typeof this.selectBoxFID8547 == "undefined") {
            return true;
          }
          for (let i = 0; i < this.selectBoxFID8547.length; i++) {
            if (
              typeof this.selectBoxFID8547[i] == "string" &&
              this.selectBoxFID8547[i] == value
            ) {
              return true;
            }
            if (this.selectBoxFID8547[i].value == value) {
              return true;
            }
          }
          return "選択肢にない項目が入力されています";
        },
        containBox8548: (value) => {
          if (typeof this.selectBoxFID8548 == "undefined") {
            return true;
          }
          for (let i = 0; i < this.selectBoxFID8548.length; i++) {
            if (
              typeof this.selectBoxFID8548[i] == "string" &&
              this.selectBoxFID8548[i] == value
            ) {
              return true;
            }
            if (this.selectBoxFID8548[i].value == value) {
              return true;
            }
          }
          return "選択肢にない項目が入力されています";
        },
        containBox8550: (value) => {
          if (typeof this.selectBoxFID8550 == "undefined") {
            return true;
          }
          for (let i = 0; i < this.selectBoxFID8550.length; i++) {
            if (
              typeof this.selectBoxFID8550[i] == "string" &&
              this.selectBoxFID8550[i] == value
            ) {
              return true;
            }
            if (this.selectBoxFID8550[i].value == value) {
              return true;
            }
          }
          return "選択肢にない項目が入力されています";
        },
        containBox8551: (value) => {
          if (typeof this.selectBoxFID8551 == "undefined") {
            return true;
          }
          return this.selectBoxFID8551.includes(value) ||
            value == "" ||
            value == null
            ? true
            : "選択肢にない項目が入力されています";
        },
        containBox8553: (value) => {
          if (typeof this.selectBoxFID8553 == "undefined") {
            return true;
          }
          for (let i = 0; i < this.selectBoxFID8553.length; i++) {
            if (
              typeof this.selectBoxFID8553[i] == "string" &&
              this.selectBoxFID8553[i] == value
            ) {
              return true;
            }
            if (this.selectBoxFID8553[i].value == value) {
              return true;
            }
          }
          return "選択肢にない項目が入力されています";
        },
        containBox8554: (value) => {
          if (typeof this.selectBoxFID8554 == "undefined") {
            return true;
          }
          for (let i = 0; i < this.selectBoxFID8554.length; i++) {
            if (
              typeof this.selectBoxFID8554[i] == "string" &&
              this.selectBoxFID8554[i] == value
            ) {
              return true;
            }
            if (this.selectBoxFID8554[i].value == value) {
              return true;
            }
          }
          return "選択肢にない項目が入力されています";
        },
      },
      buttonShow: true,
      data: {
        PageID: 1226,
        ReportID: null,
        Recodes: {
          FID10496: { value: "", formType: 1, disabled: false, modal: false },
          FID8547: { value: "", formType: 2, disabled: false },
          FID8548: { value: "", formType: 2, disabled: false },
          FID8549: {
            value: "PID1227",
            formType: 17,
            disabled: false,
            subReports: [],
          },
        },
      },

      allDisabled: false,
      selectBoxFID8547: [
        "事業部",
        "部署",
        "支社",
        "部署(業務グループ)",
        "グループ",
        "店舗",
      ],
      selectBoxFID8548: [
        "スタッフ",
        "マネージャー",
        "グループリーダー",
        "直営事業部長",
      ],
      selectBoxFID8550: [
        "事業部",
        "部署",
        "支社",
        "部署(業務グループ)",
        "グループ",
        "店舗",
      ],
      selectBoxFID8551: [
        "スタッフ",
        "マネージャー",
        "グループリーダー",
        "直営事業部長",
      ],
      subReport8549: {
        PageID: 1227,
        ReportID: null,
        Recodes: {
          FID8550: { value: "", formType: 2, disabled: false },
          FID8551: { value: "", formType: 2, disabled: false },
          FID8552: {
            value: "PID1228",
            formType: 17,
            disabled: false,
            subReports: [],
          },
        },
        DeleteFlag: false,
      },
      selectBoxFID8553: [
        "事業部",
        "部署",
        "支社",
        "部署(業務グループ)",
        "グループ",
        "店舗",
      ],
      selectBoxFID8554: [
        "スタッフ",
        "マネージャー",
        "グループリーダー",
        "直営事業部長",
      ],
      subReport8552: {
        PageID: 1228,
        ReportID: null,
        Recodes: {
          FID8553: { value: "", formType: 2, disabled: false },
          FID8554: { value: "", formType: 2, disabled: false },
        },
        DeleteFlag: false,
      },
      ruleMap: {
        FID10496: ["required", "smallNum"],
        FID8547: ["required", "containBox8547"],
        FID8548: ["required", "containBox8548"],
        FID8550: ["required", "containBox8550"],
        FID8551: ["required", "containBox8551"],
        FID8553: ["required", "containBox8553"],
        FID8554: ["required", "containBox8554"],
      },
    };
  },
  async created() {
    await this.getInit();
  },
  methods: {
    async getInit() {
      //タイトルをデフォルトに戻す
      if (typeof process.env.VUE_APP_BASE_TITLE != "undefined") {
        document.title = process.env.VUE_APP_BASE_TITLE;
      }
      //初期処理
      this.isLoading = true;
      this.getKeyWord(this.$store.state.keyWord);
      this.path = this.$route.path;
      //データ取得
      let profile;
      [, profile, , ,] = await Promise.all([
        this.search(),
        api.GetRole(),
        eneosCar.getDepartmentAll(),
        this.initPositionSelectBox(),
        this.initDepartmentSelectBox(),
      ]);
      store.commit("setRole", profile.Role);
      //要素がない場合の回避処理
      this.reports.map((report, index) => {
        for (const [key, Recode] of Object.entries(this.data.Recodes)) {
          if (typeof report.Recodes[key] == "undefined") {
            this.reports[index].Recodes[key] = JSON.parse(
              JSON.stringify(Recode)
            );
          }
        }
      });
      //
      if (this.$store.state.tableSort.sortBy != null) {
        this.sortBy = this.$store.state.tableSort.sortBy;
        this.sortDesc = this.$store.state.tableSort.sortDesc;
      }
      //init処理

      this.isLoading = false;
    },
    addReport() {
      let data = JSON.parse(JSON.stringify(this.data));
      data.Recodes.FID10496.value = String(this.reports.length + 1);
      this.reports.push(data);
    },
    addSubReport(item, FID, subReport) {
      this.isLoading = true;
      if (typeof item.Recodes[FID] == "undefined") {
        item.Recodes[FID] = {
          value: "",
          formType: 17,
          disabled: false,
          subReports: [],
        };
      }
      if (item.Recodes[FID].subReports == null) {
        item.Recodes[FID].subReports = [];
      }
      item.Recodes[FID].subReports.push(
        JSON.parse(JSON.stringify(this[subReport]))
      );
      this.isLoading = false;
    },
    delSubReport(item, FID, subReport) {
      this.isLoading = true;
      if (typeof item.Recodes[FID] == "undefined") {
        item.Recodes[FID] = {
          value: "",
          formType: 17,
          disabled: false,
          subReports: [],
        };
      }
      if (item.Recodes[FID].subReports == null) {
        item.Recodes[FID].subReports = [];
      }
      item.Recodes[FID].subReports.push(
        JSON.parse(JSON.stringify(this[subReport]))
      );
      this.isLoading = false;
    },
    getKeyWord(data) {
      if (data.PageID == this.PageID) {
        data.search.forEach((data) => {
          console.log(data);
        });
      }
    },
    getSearchWord() {
      let data = {
        PageID: this.PageID,
        orderBy: "",
        search: [],
      };

      return data;
    },
    async search() {
      if (this.initEscape == false) {
        this.initEscape = true;
        this.isLoading = false;
        await this.$nextTick();
      }
      const ck = await this.checkValidation2();
      if (!ck && !this.isLoading) {
        return;
      }
      //初期処理
      this.isLoading = true;
      //データ追加
      let data = await this.getSearchWord();
      //api
      const reports = await api.ReportSearch(data);

      if (reports != null) {
        this.reports = reports;
      }
      //データ取得後のソート処理
      this.sortReports();
      this.isLoading = false;
    },
    async initPositionSelectBox() {
      //役職のセレクトボックスを作成
      const res = await eneosCar.getPositionAll();
      this.selectBoxFID8548 = res;
      this.selectBoxFID8551 = res;
      this.selectBoxFID8554 = res;
    },
    async initDepartmentSelectBox() {
      //部署のセレクトボックスを作成
      const [rankList, departments] = await Promise.all([
        eneosCar.getDepartmentRankList(),
        eneosCar.getDepartmentAll(),
      ]);
      this.selectBoxFID8547 = rankList;
      this.selectBoxFID8550 = rankList;
      this.selectBoxFID8553 = rankList;
      //
      this.selectBoxFID8547.push({
        value: "",
        label: "------------------------以下 個別グループ",
      });
      this.selectBoxFID8550.push({
        value: "",
        label: "------------------------以下 個別グループ",
      });
      this.selectBoxFID8553.push({
        value: "",
        label: "------------------------以下 個別グループ",
      });
      //
      departments.map((data) => {
        let obj = { value: data.value, label: data.label };
        this.selectBoxFID8547.push(obj);
        this.selectBoxFID8550.push(obj);
        this.selectBoxFID8553.push(obj);
      });
    },
    back() {
      store.commit("clearkeyWord");
      store.commit("clearreports");
      store.commit("clearPageNum");
      store.commit("clearTableSort");
      //
      if (this.$route.path == this.path) {
        history.back();
      } else {
        alert("読み込みエラー:ホームに戻ります");
        this.$router.push("/", () => {});
      }
      //history.back();
    },
    //reports用のチェック
    checkValidation: async function () {
      const checkReport = function (rules, ruleMap, report) {
        let flag = true;
        //通常のデータ内で実行するとfor分岐が地獄を見るので無し
        for (const [formID, Recode] of Object.entries(report.Recodes)) {
          if (typeof ruleMap[formID] != "undefined" && !report.DeleteFlag) {
            let ruleArr = ruleMap[formID];
            ruleArr.map((key) => {
              if (key != "") {
                const r = rules[key];
                let result = r(Recode.value);
                if (result != true) {
                  console.log("checkReport:key", Recode.value, key);
                  flag = false;
                }
              }
            });
          } else if (
            !report.DeleteFlag &&
            Recode.formType == 17 &&
            typeof Recode.subReports != "undefined" &&
            Recode.subReports != null
          ) {
            let subFlag = true;
            Recode.subReports.map((sub) => {
              const res = checkReport(rules, ruleMap, sub);
              if (subFlag) {
                subFlag = res;
              }
            });
            if (!subFlag) {
              flag = false;
            }
          }
        }
        return flag;
      };
      //デフォルト機能による検索は行うが判定は別
      let check = true;
      await this.$refs.form.validate();
      this.reports.map((report) => {
        let flag = checkReport(this.rules, this.ruleMap, report);
        if (check) {
          check = flag;
        }
      });
      return check;
    },
    //検索用のバリデーションチェック
    checkValidation2: async function () {
      let form = this.$refs.form2;
      if (typeof form == "undefined") {
        return false;
      }
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }
      return check;
    },
    setReportID: function (report, pageIDs, index, delReports) {
      report.PageID = pageIDs[index]; //ページIDの追加
      //サブレポートを含む場合の再帰処理
      for (const [FID, Recode] of Object.entries(report.Recodes)) {
        if (Recode.formType == 17) {
          let subReports = [];
          if (Recode.subReports == null) {
            Recode.subReports = [];
          }
          if (typeof pageIDs[index + 1] != "undefined") {
            Recode.subReports.map((sub, i) => {
              const res = this.setReportID(
                report.Recodes[FID].subReports[i],
                pageIDs,
                index + 1,
                delReports
              );
              if (res != null && !report.DeleteFlag) {
                subReports.push(res);
              } else if (res != null && report.DeleteFlag) {
                delReports.push(res);
              }
            });
          } else {
            Recode.subReports = [];
          }
          report.Recodes[FID].subReports = subReports;
        }
      }
      //削除処理
      if (!report.DeleteFlag) {
        return report;
      } else if (report.ReportID != null) {
        delReports.push(report);
      }
      return null;
    },
    async send() {
      //バリデーションチェック
      const isCorrectValue = await this.checkValidation();
      if (isCorrectValue == false) {
        console.log("isCorrectValue == false");
        return;
      }
      this.isLoading = true;
      //
      let reports = [];
      let delReports = [];
      let pageIDs = [1226, 1227, 1228];
      //
      for (let i = 0; i < this.reports.length; i++) {
        const repo = this.setReportID(this.reports[i], pageIDs, 0, delReports);
        if (repo != null) {
          reports.push(repo);
        }
      }
      const result = await api.RegReports(reports);
      if (
        result == undefined ||
        (result.result != "success" && result.result != "Success")
      ) {
        alert("データが登録できませんでした。");
      } else {
        alert("登録完了");
      }
      if (delReports.length > 0) {
        try {
          await api.DelReports(delReports);
        } catch (e) {
          alert("削除失敗");
        }
      }
      this.isLoading = true;
      await this.search();
      this.isLoading = false;

      //history.back();
    },
    getValue(v) {
      if (typeof v == "undefined") {
        return "";
      }
      return v.value;
    },
    downloadCSV(csv, filename) {
      //CSV出力部分
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },
    updatePageNum(value) {
      store.commit("setPageNum", value);
    },
    updateSortBy(value) {
      store.commit("setTableSortBy", value);
    },
    updateSortDesc(value) {
      store.commit("setTableSortDesc", value);
    },
    customSort(items, index, isDesc) {
      //デフォルト
      if (index.length == 0 || items.length == 0) {
        return items;
      }
      const regex = /[^0-9]/g;
      const order = isDesc[0] ? 1 : -1;
      const pos = parseInt(index[0].replace(regex, ""));
      items.sort((a, b) => {
        //アイコンソートは特殊処理
        if (index == "target") {
          return 0;
        }
        //
        return this.sort(a, b, order, pos);
      });
      //
      return items;
    },
    sort(a, b, order, pos) {
      if (
        typeof a.Recodes["FID" + pos] == "undefined" ||
        typeof b.Recodes["FID" + pos] == "undefined"
      )
        return -1;
      console.log("sort", a, b, order, pos);
      if (a.Recodes["FID" + pos].value == b.Recodes["FID" + pos].value)
        return 0;
      if (a.Recodes["FID" + pos].value === "") return 1;
      if (b.Recodes["FID" + pos].value === "") return -1;
      if (
        !isNaN(a.Recodes["FID" + pos].value) &&
        !isNaN(b.Recodes["FID" + pos].value)
      ) {
        return Number(a.Recodes["FID" + pos].value) ==
          Number(b.Recodes["FID" + pos].value)
          ? 0
          : Number(a.Recodes["FID" + pos].value) >
            Number(b.Recodes["FID" + pos].value)
          ? -1 * order
          : order;
      }
      return (
        a.Recodes["FID" + pos].value.localeCompare(
          b.Recodes["FID" + pos].value,
          "jp"
        ) *
        -1 *
        order
      );
    },
    dateSort(dateArr) {
      const date1 = new Date(dateArr[0].replace(/-/g, "/"));
      const date2 = new Date(dateArr[1].replace(/-/g, "/"));
      if (date2 - date1 > 0) {
        return dateArr;
      } else {
        return [dateArr[1], dateArr[0]];
      }
    },
    delIndex(item) {
      this.isLoading = true;
      item.DeleteFlag = !item.DeleteFlag;
      this.isLoading = false;
    },
    sortDown(index) {
      let [a1, a2] = [
        this.reports[index].Recodes.FID10496.value,
        this.reports[index + 1].Recodes.FID10496.value,
      ];
      let [originReportID, nextReportID] = [
        this.reports[index].ReportID,
        this.reports[index + 1].ReportID,
      ];
      let tmp = this.reports[index].Recodes;
      this.reports[index].Recodes = this.reports[index + 1].Recodes;
      this.reports[index + 1].Recodes = tmp;
      this.reports[index].Recodes.FID10496.value = a1;
      this.reports[index + 1].Recodes.FID10496.value = a2;
      this.reports[index].ReportID = nextReportID;
      this.reports[index + 1].ReportID = originReportID;
    },
    moveCheck() {
      this.$router.push("/approvalflow/check", () => {});
    },
    scrollLeft() {
      const dataTable = this.$refs.dataTable.$el;
      dataTable.querySelector(".v-data-table__wrapper").scrollLeft = 0;
    },

    scrollRight() {
      const dataTable = this.$refs.dataTable.$el;
      const tableWidth = dataTable.querySelector(
        ".v-data-table__wrapper"
      ).scrollWidth;
      dataTable.querySelector(".v-data-table__wrapper").scrollLeft = tableWidth;
    },
    sortReports() {
      this.reports = this.reports.sort((a, b) =>
        Number(a.Recodes.FID10496.value) > Number(b.Recodes.FID10496.value)
          ? 1
          : Number(b.Recodes.FID10496.value) > Number(a.Recodes.FID10496.value)
          ? -1
          : 0
      );
      //優先順位を変える
      for (let i = 0; i < this.reports.length; i++) {
        if (
          i > 0 &&
          Number(this.reports[i - 1].Recodes.FID10496.value) + 1 !=
            Number(this.reports[i].Recodes.FID10496.value)
        ) {
          this.reports[i].Recodes.FID10496.value = String(
            Number(this.reports[i - 1].Recodes.FID10496.value) + 1
          );
        }
      }
    },
  },
  watch: {},
  computed: {
    headers() {
      return [
        {
          text: "優先順位",
          value: "Recodes.FID10496.value",
          align: "start",
          sortable: false,
          width: "110px",
        },
        {
          text: "変更",
          value: "target",
          sortable: false,
          width: "70px",
          class: "mr-0 pr-0 ml-0 pl-0 pa-0",
        },
        {
          text: "部署・店舗",
          value: "Recodes.FID8547.value",
          sortable: false,
          width: "320px",
        },
        {
          text: "申請者の役職",
          value: "Recodes.FID8548.value",
          sortable: false,
          width: "220px",
        },
        { text: "削除", value: "delSubReport0", sortable: false },
        {
          text: "部署・店舗",
          value: "Recodes.FID8550.value",
          sortable: false,
          width: "320px",
        },
        {
          text: "承認者の役職",
          value: "Recodes.FID8551.value",
          sortable: false,
          width: "220px",
        },
        { text: "追加", value: "addSubReport8549", sortable: false },
        { text: "削除", value: "delSubReport8549", sortable: false },
        {
          text: "部署・店舗",
          value: "Recodes.FID8553.value",
          sortable: false,
          width: "320px",
        },
        {
          text: "承認者の役職",
          value: "Recodes.FID8554.value",
          sortable: false,
          width: "220px",
        },
        { text: "追加", value: "addSubReport8552", sortable: false },
        { text: "削除", value: "delSubReport8552", sortable: false },
      ];
    },

    resizeWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "270px";
        case "sm":
          return "500px";
        case "md":
          return "600px";
        case "lg":
          return "500px";
        case "xl":
          return "500px";
        default:
          return "500px";
      }
    },
  },
};
</script>
