<template>
  <div class="body">
    <v-layout
      align-center
      justify-center
      style="border: 1px solid black; padding: 8px"
    >
      <v-flex xs12>
        <div class="text-center">
          <h1>車両使用に関する誓約書</h1>
          <div class="text-left mb-3 mt-3">
            <h3>&nbsp;&nbsp;株式会社ＥＮＥＯＳフロンティア 殿</h3>
          </div>
          <div class="text-left">
            <p class="pl-1">
              &nbsp;&nbsp;&nbsp;私が上記申請車両を、申請致しました使用用途の&nbsp;&nbsp;通勤車もしくは通勤車および業務使用車&nbsp;&nbsp;として運転することについては、
              会社の指示・命令に従い、車両管理要領を遵守することを誓約致します。<br />
              万一、私の責に帰すべき事由により、事故が発生した場合は、私の責任において解決し、一切会社にはご迷惑をお掛け致しません。
            </p>
          </div>
          <div class="text-right">
            <p class="pl-3 ml-3">以上&nbsp;&nbsp;&nbsp;&nbsp;</p>
          </div>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
export default {
  name: "oath",
  components: {},
  data() {
    return {};
  },
  async created() {},
  methods: {},
  computed: {},
};
</script>
