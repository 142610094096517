<template>
  <div class="body">
    <v-row v-if="!homeflag">
      <v-col align="right" class="button"
        ><v-btn @click="back" :disabled="backflag">戻る</v-btn></v-col
      >
    </v-row>
    <p align="center" class="title">メニュー</p>
    <v-row
      justify="center"
      v-for="links2 in links"
      :key="links2.text"
      class="home mb-2"
      align-content="center"
    >
      <div v-for="link in links2" :key="link.text" class="home">
        <v-btn
          @click="move(link.to)"
          v-if="link.rule"
          :height="height"
          :width="width"
          class="white--text"
          :color="link.color"
          >{{ link.text }}<br />{{ link.text2 }}</v-btn
        >
      </div>
    </v-row>
    <!-- 申請へモーダルを表示 -->
    <v-dialog v-model="moveReportModal" max-width="400" persistent>
      <v-card>
        <v-card-title class="text-h5">
          未提出の運転日報を開きますか?
        </v-card-title>
        <v-card-actions>
          {{ countdown + 1 }}秒後に閉じます
          <v-spacer></v-spacer>
          <v-btn
            class="mr-3"
            color="green darken-1 white--text"
            @click="moveCancel"
            large
          >
            いいえ
          </v-btn>
          <v-btn color="red darken-1 white--text" @click="moveReport" large>
            はい
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
import {
  getIPAuth,
  myCarApprovalLink,
  limitUserAdd,
  carInspectionCheck,
  compCarAdderCheck,
  settingLink,
  myCarDocumentCheck,
} from "./specialMethod";

// 画面デザインや色などはCSSで調整
export default {
  name: "Home",
  components: {},
  data: () => ({
    links: [],
    Role: 0,
    homeflag: false,
    height: 100,
    width: 250,
    path: "",
    backflag: false,
    moveReportModal: false,
    countdown: 1,
    intervalId: null,
    reportID: null,
  }),
  height: function () {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 100;
      case "sm":
        return 100;
      case "md":
        return 100;
      case "lg":
        return 100;
      case "xl":
        return 100;
    }
    return 100;
  },
  width: function () {
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return 250;
      case "sm":
        return 250;
      case "md":
        return 250;
      case "lg":
        return 250;
      case "xl":
        return 250;
    }
    return 250;
  },
  display: function () {
    const displayheight = this.$vuetify.breakpoint.height;
    switch (this.$vuetify.breakpoint.name) {
      case "xs":
        return this.role != 1 && displayheight > 400
          ? "height: " + 480 + "px;"
          : null;
      case "sm":
        return this.role != 1 && displayheight > 400
          ? "height: " + 480 + "px;"
          : null;
    }
    return null;
  },
  async mounted() {
    if (!this.$store.state.auth) {
      this.$router.push("/login");
    }
    this.path = this.$route.path;
    if (this.$route.path == "/") {
      this.homeflag = true;
      const result = await api.EmployeeDetail();
      store.commit("setParams", result.Recodes);
      const IPAuth = await getIPAuth();
      store.commit("setIPauth", IPAuth);
    }
    store.commit("clearreports");
    const arrayLength = 2 + 1;
    this.links = Array.from({ length: arrayLength }, () => []);
    const result = await api.GetRole();
    this.Role = result.Role;
    store.commit("setRole", result.Role);
    this.links[0].push({
      to: "/mycar/index",
      text: "マイカー通勤の許可申請はこちら",
      level: 0,
      color: "indigo white--text",
      rule: false,
    });

    /*
    if (this.Role == 1 && true) {
      this.links[2].push({
        to: "/setting",
        text: "設定",
        level: 0,
        color: "blue-grey white--text",
        rule: false,
      });
      for (let i = 0; i < this.links.length; i++) {
        this.links[i].forEach((l, index) => {
          if (l.level == 0 || l.level == this.Role) {
            this.links[i][index].rule = true;
          }
        });
        this.links[i] = this.links[i].filter((l) => {
          return l.rule;
        });
      }
      return;
    }
    */
    if (await myCarApprovalLink()) {
      this.links[0].push({
        to: "/mycar/approval",
        text: "マイカー承認",
        level: 0,
        color: "blue",
        rule: false,
      });
    }
    if ((await myCarDocumentCheck()) && store.state.IPauth) {
      this.links[0].push({
        to: "/mycar/documentCheckIndex",
        text: "マイカー添付書類確認",
        level: 0,
        color: "cyan",
        rule: false,
      });
    }
    if (await limitUserAdd()) {
      this.links[0].push({
        to: "/index/tempUser",
        text: "新規従業員の一覧",
        level: 0,
        color: "grey",
        rule: false,
      });
    }
    this.links[1].push({
      to: "/car/index",
      text: "社有車利用はこちらから",
      level: 0,
      color: "light-green white--text",
      rule: false,
    });
    if (await carInspectionCheck()) {
      this.links[1].push({
        to: "/car/inspection",
        text: "社有車利用の確認",
        level: 0,
        color: "amber",
        rule: false,
      });
    }
    if (await compCarAdderCheck()) {
      this.links[1].push({
        to: "/companycar/index",
        text: "社有車管理",
        text2: "(更新・増減)",
        level: 0,
        color: "orange white--text",
        rule: false,
      });
    }
    if (await settingLink()) {
      this.links[2].push({
        to: "/setting",
        text: "設定",
        level: 0,
        color: "blue-grey white--text",
        rule: false,
      });
    }

    for (let i = 0; i < this.links.length; i++) {
      this.links[i].forEach((l, index) => {
        if (l.level == 0 || l.level == this.Role) {
          this.links[i][index].rule = true;
        }
      });
      this.links[i] = this.links[i].filter((l) => {
        return l.rule;
      });
    }
    await this.checkApplicationForm();
  },
  methods: {
    move(link) {
      this.$router.push(link, () => {});
    },
    back() {
      if (this.$route.path == this.path) {
        history.back();
        this.backflag = true;
      } else {
        alert("読み込みエラー:ホームに戻ります");
        this.$router.push("/", () => {});
      }
      //history.back();
    },
    async checkApplicationForm() {
      let flag = this.$store.state.carApprovalMove;
      //レポートを検索し存在するか

      if (flag) {
        let data = {
          PageID: 1160,
          orderBy: "",
          search: [],
        };
        //ステータス
        data.search.push({
          form_id: 8021,
          value: "業務前,利用中,業務後",
          option: "commaValuesAnyMatch",
        });
        //運転者
        data.search.push({
          form_id: 8016,
          value: store.state.params.FID0.name,
          option: "match",
        });
        const reports = await api.ReportSearch(data);
        if (reports.length > 0) {
          this.reportID = reports[reports.length - 1].ReportID;
          this.openModal();
        }
      }
      //
    },
    openModal() {
      this.moveReportModal = true;
      this.intervalId = setInterval(this.updateCountdown, 1000);
    },
    updateCountdown() {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.moveReportModal = false; //モーダルを閉じる
        store.commit("setCarApprovalMove", false); //再表示をしない形
        clearInterval(this.intervalId); //
      }
    },

    moveCancel() {
      this.moveReportModal = false;
      store.commit("setCarApprovalMove", false);
    },
    moveReport() {
      store.commit("setCarApprovalMove", false);
      this.$router.push("/applicationform/create/" + this.reportID, () => {});
    },
  },
};
</script>
<style>
.selectbox {
  margin: 4px;
}
</style>
<style>
.home {
  margin: 8px;
}
</style>
