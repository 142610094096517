<template>
  <v-card>
    <v-card-title>新しいWebサイトが存在します。</v-card-title>
    <v-card-text>
      <v-container>
        <v-form ref="form">
          <v-row align-content="center">
            <v-btn depressed color="error" @click="close" class="ma-2">
              キャンセル
            </v-btn>
            <v-btn depressed color="primary" @click="reload" class="ma-2">
              更新
            </v-btn>
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "login",
  data() {
    return {};
  },
  methods: {
    close() {
      this.$emit("click-Submit");
    },
    reload() {
      location.reload(true);
    },
  },
};
</script>
