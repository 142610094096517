import axios from "axios";
import store from "../store/vuex";

//const baseDomain = "http://localhost:8002";
//const baseDomain = "https://api.develop.kakanai.com";
const baseDomain = process.env.VUE_APP_SEISEI_URL;

const baseURL = `${baseDomain}`;
const Repository = axios.create({
  baseURL: baseURL,
  timeout: 1200000,
});

const resource = "/api/eneosCar/v2";

let IPAuthResult = null;

export default {
  //---認証無し------------------------------------------------------
  async GetIPString() {
    const response = await this.API_Get("/get/ip");
    return response;
  },
  //
  async GetIP(force) {
    if (typeof force == "undefined" && IPAuthResult != null) {
      return IPAuthResult;
    }

    IPAuthResult = false;
    try {
      const result = await this.API_Get("/api/eneosCar/checkip");
      if (result.message == "fixIP") {
        IPAuthResult = true;
        await store.commit("setIPauth", true);
      } else {
        IPAuthResult = false;
        await store.commit("setIPauth", false);
      }
      return IPAuthResult;
    } catch (e) {
      console.log("GetIP 検証に失敗", e);
      IPAuthResult = false;
      await store.commit("setIPauth", false);
      return false;
    }
  },
  //---------------------------------------------------------
  async getDivision(code) {
    try {
      if (typeof code == "string") {
        const result = await this.API_Get(
          resource + "/resource/division/get/" + code
        );
        return result.data;
      } else {
        const result = await this.API_Get(
          resource + "/resource/division/index"
        );
        return result.data;
      }
    } catch (e) {
      console.log("getDivision:error", e);
      return [];
    }
  },
  async getDivisionAll() {
    const result = await this.API_Get(resource + "/resource/division/All");
    return result.data;
  },
  async getSection() {
    const result = await this.API_Get(resource + "/resource/section");
    return result.data;
  },
  async getSectionCode(code) {
    const result = await this.API_Get(
      resource + "/resource/section/get/" + code
    );
    return result.data;
  },
  async getPositionAll() {
    const result = await this.API_Get(resource + "/resource/position/All");
    return result.data;
  },
  async getDepartment() {
    try {
      const result = await this.API_Get(
        resource + "/resource/department/index"
      );
      return result.data;
    } catch (e) {
      console.log("getDepartment:error", e);
      return [];
    }
  },
  async getDepartmentOnDivision(code) {
    try {
      if (code != "") {
        code = "/" + code;
      }
      const result = await this.API_Get(
        resource + "/resource/department/onDivision/index" + code
      );
      return result.data;
    } catch (e) {
      console.log("error", e);
      return [];
    }
  },
  async getDepartmentRankList() {
    const result = await this.API_Get(
      resource + "/resource/department/departmentRank"
    );
    return result.data;
  },
  //自身のアカウント階層を取得
  async getDepartmentRank() {
    const result = await this.API_Get(
      resource + "/resource/department/get/rank"
    );
    return result.message;
  },
  ///
  async getOrganizationChart() {
    const result = await this.API_Get(
      resource + "/resource/organization/chart"
    );
    //
    if (typeof result == "undefined") {
      return;
    }
    return result.data;
  },
  async getOrganizationChartALL() {
    const result = await this.API_Get(
      resource + "/resource/organization/chart/all"
    );
    if (typeof result == "undefined") {
      console.log("getOrganizationChartALL", result);
      return;
    }
    //
    return result.data;
  },
  async GetUserName(data) {
    const result = await this.API_Post(data, resource + "/resource/user/name");
    return result.data;
  },
  async getUserCodeToNameAll(data) {
    const result = await this.API_Post(
      data,
      resource + "/resource/user/name/all"
    );
    return result.data.data;
  },
  async getDepartmentAll() {
    const result = await this.API_Get(resource + "/resource/department/All");
    return result.data;
  },
  async getMyCar() {
    const result = await this.API_Get(resource + "/resource/myCar/index");
    return result.data;
  },
  async getCompanyCar() {
    const result = await this.API_Get(resource + "/resource/companyCar/index");
    return result.data;
  },
  async getBusinessUseCar() {
    const result = await this.API_Get(
      resource + "/resource/businessUseCar/index"
    );
    return result.data;
  },
  async GetLastRunValue() {
    const result = await this.API_Get(resource + "/resource/carApproval/last");
    return result.data;
  },
  async searchCompanyCar(data) {
    const result = await this.API_Post(
      data,
      resource + "/resource/myCar/approval/index"
    );
    return result.data;
  },
  async searchMyCar(data) {
    const result = await this.API_Post(
      data,
      resource + "/resource/companyCar/approval/index"
    );
    return result.data;
  },
  //アルバイト一覧
  async tempUserIndex(data) {
    const result = await this.API_Post(data, resource + "/resource/user/index");
    return result.data.data;
  },
  //アルバイト追加
  async tempUserRegister(data) {
    const result = await this.API_Post(
      data,
      resource + "/resource/user/register"
    );
    return result.data;
  },
  async getNextApprover(data) {
    const result = await this.API_Post(
      data,
      resource + "/resource/approver/next"
    );

    return [
      result.data.code,
      result.data.position,
      result.data.next,
      result.data.route,
    ];
  },
  async UserDataCheck(data) {
    const result = await this.API_Post(
      data,
      resource + "/resource/userList/check"
    );
    return result.data.data;
  },
  async UserConsistencyCheck(data) {
    const result = await this.API_Post(
      data,
      resource + "/resource/userList/consistencyCheck"
    );
    return result.data.data;
  },
  async UserDataEnsureConsistency(data) {
    const result = await this.API_Post(
      data,
      resource + "/resource/userList/ensureConsistency"
    );
    return result.data.data;
  },
  async UserDataUpdate(data) {
    const result = await this.API_Post(
      data,
      resource + "/resource/userList/update"
    );
    return result.data;
  },
  async getApprovalMyCar(data) {
    const result = await this.API_Post(
      data,
      resource + "/resource/approVal/myCar/index"
    );
    return result.data;
  },

  async CheckApprovalFlow() {
    const result = await this.API_Get(
      resource + "/resource/approVal/flow/check"
    );
    return result.data;
  },
  async CheckApprovalFlowOnly(data) {
    const result = await this.API_Post(
      data,
      resource + "/resource/approVal/flow/check/only"
    );
    return result.data;
  },
  //ユーザ情報取り込みv2
  async importUserDataCheck(data) {
    const result = await this.API_Post(
      data,
      resource + "/resource/import/user/check"
    );
    return result.data;
  },
  async importUserData(data) {
    const result = await this.API_Post(
      data,
      resource + "/resource/import/user"
    );
    return result.data;
  },
  //---------------------------------------------------
  async API_Get(URL) {
    Repository.interceptors.request.use(function (config) {
      config.headers.Authorization = `Bearer ${store.state.accessToken}`;
      return config;
    });
    const { data } = await Repository.get(URL).catch(function (error) {
      if (error.isAxiosError) {
        if (error.code === "ECONNABORTED") {
          alert("エラーコード[E0002]セッション切断"); //APIへのリクエスト失敗
          return false;
        }
        alert("エラーコード[E0001]"); //APIへのリクエスト失敗
        return false;
      }
      const errorMessage = error.response.data || error.message;
      console.log("Get:", errorMessage);
    });
    return data;
  },
  async API_Post(data, URL) {
    let result = await Repository.post(URL, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${store.state.accessToken}`,
      },
      timeout: 600000,
    }).catch(function (error) {
      if (error.isAxiosError) {
        if (error.code === "ECONNABORTED") {
          alert("エラーコード[E0003]セッション切断"); //APIへのリクエスト失敗
          return false;
        }
        alert("エラーコード[E0004]"); //APIへのリクエスト失敗
        return false;
      }
      const errorMessage = error.response.data || error.message;
      console.log("post:", errorMessage);
      return false;
    });
    return result;
  },
  async FileUpload(URL, Data) {
    //api用の認証headerが含まれてしまうのでaxiosを使用
    await axios
      .put(URL, Data, {
        headers: {
          "Content-Type": "image/jpeg",
          //"Content-type": 'multipart/form-data',
        },
      })
      .then(() => {
        return true;
      })
      .catch((e) => {
        const errorMessage = e.response.data || e.message;
        console.log("FileUpload:", errorMessage);
        throw e;
      });
  },
  //ファイルダウンロード
  async FileDownload(URL, fileType) {
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": fileType,
        //"Access-Control-Allow-Origin": "http://localhost:8003/",
      },
      responseType: "arraybuffer",
      data: {},
    });
    return response;
  },
};
