import { downLoadCSV, getValue } from "./csvExport.js";
import store from "@/store/vuex.js";

export function MyCar(reports, name) {
  let header =
    "社員コード,氏名,事務所・事業所,部署,従業員区分,四輪車・バイク,使用用途,車両番号,備考欄,運転免許証有効期限,運転免許証(表面),確認済,車検満了日,車検証(写)・自賠責(写),確認済,自動車保険満了日,自動車保険証券(写),確認済,標準交付証明書(写し),確認済,同意する,同意日時,申請,修正申請,削除申請,ステータス,";
  let csv = "";
  reports.map((report) => {
    let line = "";
    line += getValue(report.Recodes.FID7989) + ","; //社員コード
    line += getValue(report.Recodes.FID7990) + ","; //氏名
    if (line == "") {
      line += store.state.organization[getValue(report.Recodes.FID7991)] + ","; //事務所・事業所
      line += store.state.organization[getValue(report.Recodes.FID7992)] + ","; //部署
    } else {
      line += getValue(report.Recodes.FID7991) + ","; //事務所・事業所
      line += getValue(report.Recodes.FID7992) + ","; //部署
    }

    line += getValue(report.Recodes.FID7993) + ","; //従業員区分
    line += getValue(report.Recodes.FID8269) + ","; //四輪車・バイク
    line += getValue(report.Recodes.FID7995) + ","; //使用用途
    line += getValue(report.Recodes.FID7996) + ","; //車両番号
    line += getValue(report.Recodes.FID8334) + ","; //備考欄
    line += getValue(report.Recodes.FID8323) + ","; //運転免許証有効期限
    line += getValue(report.Recodes.FID8001) + ","; //運転免許証(表面)
    line += getValue(report.Recodes.FID8313) + ","; //確認済
    line += getValue(report.Recodes.FID8324) + ","; //車検満了日
    line += getValue(report.Recodes.FID8002) + ","; //車検証(写)・自賠責(写)
    line += getValue(report.Recodes.FID8314) + ","; //確認済
    line += getValue(report.Recodes.FID8325) + ","; //自動車保険満了日
    line += getValue(report.Recodes.FID8003) + ","; //自動車保険証券(写)
    line += getValue(report.Recodes.FID8315) + ","; //確認済
    line += getValue(report.Recodes.FID8327) + ","; //標準交付証明書(写し)
    line += getValue(report.Recodes.FID8329) + ","; //確認済
    line += getValue(report.Recodes.FID8330) + ","; //同意する
    line += getValue(report.Recodes.FID9207) + ","; //同意日時
    line += getValue(report.Recodes.FID8004) + ","; //申請
    line += getValue(report.Recodes.FID11970) + ","; //修正申請
    line += getValue(report.Recodes.FID11971) + ","; //削除申請
    line += getValue(report.Recodes.FID8504) + ","; //ステータス
    line += "\n";
    csv += line;
  });
  let file = "\ufeff" + header + "\n" + csv;
  //
  downLoadCSV(file, name);
}
