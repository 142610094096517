<template>
  <div class="body">
    <p align="center" class="title">ユーザ一覧</p>
    <v-row class="ml-2 mr-2">
      <v-col align="left">
        <v-btn @click="create" large>新規作成 </v-btn>
      </v-col>
      <v-col align="right" class="button">
        <v-btn @click.once="back" large>戻る</v-btn>
      </v-col>
    </v-row>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-form ref="form" v-model="valid">
        <v-row class="ml-2 mr-2">
          <v-col cols="">
            <v-data-table
              :headers="headers"
              :items="reports"
              :item-key="reports.ID"
              :page="
                $store.state.pageNum == undefined ? 1 : $store.state.pageNum
              "
              @update:page="updatePageNum"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              :header-props="{
                'sort-by-text': '並び順',
              }"
              :footer-props="{
                'items-per-page-text': '行/ページ:',
              }"
              no-data-text="データが存在しません"
              :items-per-page="displayResults"
              @update:sort-by="updateSortBy"
              @update:sort-desc="updateSortDesc"
              :custom-sort="customSort"
            >
              <template v-slot:item.Recodes.FID7767.value="{ item }">
                {{ $store.state.organization[item.Recodes.FID7767.value] }}
              </template>
              <template v-slot:item.Recodes.FID7776.value="{ item }">
                {{ $store.state.organization[item.Recodes.FID7776.value] }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
const eneosCar = RepositoryFactory.get("eneosCar");
export default {
  name: "ReportIndex",
  components: {},
  data() {
    return {
      valid: true,
      initEscape: false,
      isLoading: false,
      fullPage: false,
      displayResults: 15, //5,10,15のいずれか
      height: 100,
      width: 250,
      path: "",
      sortBy: null,
      sortDesc: null,
      //
      PageID: 1036,
      reports: [],
      rules: {
        required: (value) => !!value || "必須項目です",
        requiredArray: (value) => (value.length > 0 ? true : "必須項目です"),
        fullWidth: (value) => {
          return /^[^ -~｡-ﾟ]*$/.test(value) || "全角のみ入力可能です";
        },
      },
      word: "",
    };
  },
  async created() {
    await this.getInit();
  },
  methods: {
    async getInit() {
      //タイトルをデフォルトに戻す
      if (typeof process.env.VUE_APP_BASE_TITLE != "undefined") {
        document.title = process.env.VUE_APP_BASE_TITLE;
      }
      //初期処理
      this.isLoading = true;
      //所属部署の情報取得
      if (Object.keys(this.$store.state.organization).length < 2) {
        await store.commit("setDepartmentAll");
      }
      //ユーザ情報の一覧取得
      try {
        const reports = await eneosCar.tempUserIndex();
        if (reports != null) {
          this.reports = reports;
        }
      } catch (e) {
        console.log("e", e);
      }
      //
      this.isLoading = false;
    },
    getKeyWord(data) {
      if (data.PageID == this.PageID) {
        data.search.forEach((data) => {
          console.log(data);
        });
      }
    },
    getSearchWord() {
      let data = {
        PageID: this.PageID,
        orderBy: "",
        search: [],
      };
      return data;
    },
    async search() {
      if (this.initEscape == false) {
        this.initEscape = true;
      } else {
        if (this.$refs.form.validate() == false) {
          return;
        }
      }
      //初期処理
      this.isLoading = true;
      //データ追加
      let data = await this.getSearchWord();
      //api
      const reports = await api.ReportSearch(data);
      if (reports != null) {
        this.reports = reports;
        store.commit("setreports", reports);
      }
      store.commit("setkeyWord", data);
      this.isLoading = false;
    },

    create() {
      this.$router.push({ path: "/add/tempUser" });
    },
    move(data) {
      this.isLoading = true;
      this.$router.push({ path: "/user/setting/" + data.Recodes.FID0.name });
      this.isLoading = false;
    },
    back() {
      store.commit("clearkeyWord");
      store.commit("clearreports");
      store.commit("clearPageNum");
      store.commit("clearTableSort");
      //
      history.back();
    },
    updatePageNum(value) {
      store.commit("setPageNum", value);
    },
    updateSortBy(value) {
      store.commit("setTableSortBy", value);
    },
    updateSortDesc(value) {
      store.commit("setTableSortDesc", value);
    },
    customSort(items, index, isDesc) {
      //デフォルト
      if (index.length == 0 || items.length == 0) {
        return items;
      }
      const regex = /[^0-9]/g;
      const order = isDesc[0] ? 1 : -1;
      const pos = parseInt(index[0].replace(regex, ""));
      items.sort((a, b) => {
        //アイコンソートは特殊処理
        if (index == "target") {
          return 0;
        }
        //
        return this.sort(a, b, order, pos);
      });
      //
      return items;
    },
    sort(a, b, order, pos) {
      if (a.Value[pos] == b.Value[pos]) return 0;
      if (a.Value[pos] === "") return 1;
      if (b.Value[pos] === "") return -1;
      if (!isNaN(a.Value[pos]) && !isNaN(b.Value[pos])) {
        return Number(a.Value[pos]) == Number(b.Value[pos])
          ? 0
          : Number(a.Value[pos]) > Number(b.Value[pos])
          ? -1 * order
          : order;
      }
      return a.Value[pos].localeCompare(b.Value[pos], "jp") * -1 * order;
    },
    //
  },
  computed: {
    headers() {
      return [
        {
          text: "従業員番号",
          value: "Recodes.FID0.name",
          align: "start",
          width: "15%",
          sortable: false,
        },
        {
          text: "氏名",
          value: "Recodes.FID7762.value",
          align: "start",
          sortable: false,
        },
        {
          text: "事業部",
          value: "Recodes.FID7767.value",
          sortable: true,
          filterable: false,
        },
        {
          text: "部署",
          value: "Recodes.FID7776.value",
          sortable: true,
          filterable: false,
        },
        {
          text: "従業員区分",
          value: "Recodes.FID7777.value",
          sortable: true,
          filterable: false,
        },
      ];
    },
  },
};
</script>
