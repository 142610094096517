<template>
  <div class="body">
    <p align="center" class="title">マイカー承認ルートチェック</p>
    <v-row>
      <v-col align="right" class="button">
        <v-btn @click.once="back">戻る</v-btn>
      </v-col>
    </v-row>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-form ref="form" v-model="valid">
        <v-row class="ml-2 mr-1 mt-2">
          <v-col class="mb-3">
            <v-autocomplete
              label="部署・店舗"
              v-model="code"
              no-item-text="データが見つかりません"
              :items="codeBox"
              item-text="label"
              item-value="value"
              background-color="white"
              outlined
            >
            </v-autocomplete>
          </v-col>
          <v-col class="mb-3">
            <v-autocomplete
              label="役職"
              v-model="position"
              no-item-text="データが見つかりません"
              :items="positionBox"
              background-color="white"
              outlined
            >
            </v-autocomplete>
          </v-col>
          <v-col class="mb-3">
            <v-btn @click="onlyCheck" color="primary" large>
              個別ユーザ承認ルートチェック
            </v-btn>
          </v-col>
          <v-col align="right" cols="5"> </v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row class="ml-2 mr-1 mt-2">
          <v-col align="right">
            <v-btn @click="check" color="primary" large>
              全ユーザ承認ルートチェック
            </v-btn>
          </v-col>
          <v-col align="right" cols="5"> </v-col>
        </v-row>
        <v-row class="ml-2 mr-1 mt-2">
          <v-col cols="">
            <v-data-table
              :headers="headers"
              :items="reports"
              :item-key="reports.name"
              :page="
                $store.state.pageNum == undefined ? 1 : $store.state.pageNum
              "
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              :header-props="{
                'sort-by-text': '並び順',
              }"
              :footer-props="{
                'items-per-page-text': '行/ページ:',
              }"
              no-data-text="データが存在しません"
              :items-per-page="displayResults"
              @update:page="updatePageNum"
              @update:sort-by="updateSortBy"
              @update:sort-desc="updateSortDesc"
              :custom-sort="customSort"
            >
              <template v-slot:item.deportment="{ item }">
                {{ $store.state.organization[item.deportment] }}
              </template>
              <template v-slot:item.message="{ item }">
                {{ convertMessage(item.message) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const eneosCar = RepositoryFactory.get("eneosCar");

export default {
  name: "ReportIndex",
  components: {},
  data() {
    return {
      valid: true,
      initEscape: false,
      isLoading: false,
      fullPage: false,
      displayResults: 15, //5,10,15のいずれか
      height: 100,
      width: 250,
      path: "",
      sortBy: null,
      sortDesc: null,
      //
      reports: [
        {
          name: "ユーザ名",
          division: "",
          deportment: "",
          message: "承認者が見つかりませんでした",
        },
      ],
      //
      rules: {
        required: (value) => !!value || "必須項目です",
        requiredArray: (value) => (value.length > 0 ? true : "必須項目です"),
        fullWidth: (value) => {
          return /^[^ -~｡-ﾟ]*$/.test(value) || "全角のみ入力可能です";
        },
      },
      //
      code: "1010311100",
      codeBox: [],
      position: "スタッフ",
      positionBox: [],
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {
    headers() {
      return [
        { text: "申請者", value: "name", sortable: false },
        { text: "申請者の部署", value: "deportment", sortable: false },
        { text: "申請者の役職", value: "position", sortable: false },
        { text: "優先度", value: "priority", sortable: false },
        { text: "メッセージ", value: "message", sortable: false },
      ];
    },
  },
  methods: {
    async getInit() {
      //初期処理
      this.isLoading = true;
      await store.commit("setDepartmentAll");
      //

      //部署のセレクトボックスを作成
      const [rankList, departments] = await Promise.all([
        eneosCar.getPositionAll(),
        eneosCar.getDepartmentAll(),
      ]);
      this.positionBox = rankList;
      departments.map((data) => {
        let obj = { value: data.value, label: data.label };
        this.codeBox.push(obj);
      });
      //
      this.isLoading = false;
    },
    back() {
      history.back();
    },
    updatePageNum(value) {
      store.commit("setPageNum", value);
    },
    updateSortBy(value) {
      store.commit("setTableSortBy", value);
    },
    updateSortDesc(value) {
      store.commit("setTableSortDesc", value);
    },
    customSort(items, index, isDesc) {
      //デフォルト
      if (index.length == 0 || items.length == 0) {
        return items;
      }
      const regex = /[^0-9]/g;
      const order = isDesc[0] ? 1 : -1;
      const pos = parseInt(index[0].replace(regex, ""));
      items.sort((a, b) => {
        //アイコンソートは特殊処理
        if (index == "target") {
          // //アイコンの特殊ソート処理は実装途中
          return 0;
        }
        //
        return this.sort(a, b, order, pos);
      });
      //
      return items;
    },
    sort(a, b, order, pos) {
      if (
        typeof a.Recodes["FID" + pos] == "undefined" ||
        typeof b.Recodes["FID" + pos] == "undefined"
      )
        return -1;
      if (a.Recodes["FID" + pos].value == b.Recodes["FID" + pos].value)
        return 0;
      if (a.Recodes["FID" + pos].value === "") return 1;
      if (b.Recodes["FID" + pos].value === "") return -1;
      if (
        !isNaN(a.Recodes["FID" + pos].value) &&
        !isNaN(b.Recodes["FID" + pos].value)
      ) {
        return Number(a.Recodes["FID" + pos].value) ==
          Number(b.Recodes["FID" + pos].value)
          ? 0
          : Number(a.Recodes["FID" + pos].value) >
            Number(b.Recodes["FID" + pos].value)
          ? -1 * order
          : order;
      }
      return (
        a.Recodes["FID" + pos].value.localeCompare(
          b.Recodes["FID" + pos].value,
          "jp"
        ) *
        -1 *
        order
      );
    },
    dateSort(dateArr) {
      const date1 = new Date(dateArr[0].replace(/-/g, "/"));
      const date2 = new Date(dateArr[1].replace(/-/g, "/"));
      if (date2 - date1 > 0) {
        return dateArr;
      } else {
        return [dateArr[1], dateArr[0]];
      }
    },
    async check() {
      this.isLoading = true;
      try {
        const response = await eneosCar.CheckApprovalFlow();
        console.log("response", response);
        if (response != null && response.length > 0) {
          this.reports = response;
        }
      } catch (e) {
        console.log("e", e);
      }
      this.isLoading = false;
    },
    //承認ルートチェックで見つからなかった場合のメッセージ
    convertMessage(str) {
      let arr = str.split(" ");
      if (arr.length >= 3) {
        console.log("arr", arr.length, arr);
        let tmp = arr[0].split(":");
        if (!isNaN(tmp[1])) {
          tmp[1] = this.$store.state.organization[tmp[1]];
        }
        arr[0] = tmp.join(":");
      }
      return arr.join(" ");
    },
    async onlyCheck() {
      this.isLoading = true;
      this.reports = [];
      try {
        let req = {
          code: this.code,
          position: this.position,
        };
        const response = await eneosCar.CheckApprovalFlowOnly(req);
        console.log("response", response);
        if (response != null && response.data.length > 0) {
          this.reports = response.data;
        } else {
          this.reports.push({
            name: "個別ユーザ",
            deportment: "",
            position: this.position,
            priority: "",
            message: "正常に承認ルートが存在します",
          });
        }
      } catch (e) {
        console.log("e", e);
      }
      this.isLoading = false;
    },
  },
};
</script>
