<template>
  <div>
    <p>従業員情報取り込み:{{ step }}</p>
    <v-col align="right"><v-btn @click.once="back">戻る</v-btn></v-col>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-container cols="12">
        <!-- CSV取り込み部分   -->
        <v-form ref="form">
          <v-row>
            <v-col>
              ※新規発行したユーザのデフォルトパスワードは「******」です<br />
              ※初期パスワードの有効期限は14日です<br />
              ※新規作成時のシステム権限は「一般」になります<br />
              氏名の変更は無視されます※環境依存文字が差分判定をさせるため<br />
            </v-col>
          </v-row>
          <v-row>
            <v-file-input
              v-model="uploadfile.file"
              :rules="[rules.fileSize]"
              type="file"
              label="CSV取り込み"
              prepend-icon="mdi-image"
              ref="rfafile"
              accept=".csv"
              @change="chargeCSV"
              show-size
            >
            </v-file-input>
          </v-row>
          <v-row class="mb-2">
            <v-col cols="2">
              <v-btn
                @click="updateCheck"
                color="green white--text"
                :disabled="step != 1"
              >
                ①更新チェック
              </v-btn>
            </v-col>
            <v-col cols="8" v-if="step != 2"> </v-col>
            <v-col cols="2" v-if="step == 2">
              <v-btn
                @click="(sendReports = noChangeUsers), (checkFlags1 = true)"
                color="gray black--text"
              >
                変更なし
              </v-btn>
            </v-col>
            <v-col cols="2" v-if="step == 2">
              <v-btn
                @click="(sendReports = newUsers), (checkFlags2 = true)"
                color="primary white--text"
              >
                新規ユーザ
              </v-btn>
            </v-col>
            <v-col cols="2" v-if="step == 2">
              <v-btn
                @click="(sendReports = updateUsers), (checkFlags3 = true)"
                color="green white--text"
              >
                更新ユーザ
              </v-btn>
            </v-col>
            <v-col cols="2" v-if="step == 2">
              <v-btn
                @click="(sendReports = deleteUsers), (checkFlags4 = true)"
                color="red white--text"
              >
                削除ユーザ
              </v-btn>
            </v-col>

            <v-col cols="2">
              <v-btn
                @click="sendUserData"
                color="primary"
                :disabled="!SaveFlag"
              >
                ③一括更新
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <!-- 事業コードのエラー -->
        <v-row v-if="errorMessages.length > 0">
          <v-col cols="10">
            {{ errorMessages.length }}件のエラーが見つかりました
          </v-col>
          <v-col
            v-for="(message, i) in errorMessages"
            cols="4"
            :key="`[me-${i}]`"
          >
            {{ message }}
          </v-col>
        </v-row>
        <!-- 取り込みデータ表示部分   -->
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="header"
              :items="step == 0 || step == 1 ? importReports : sendReports"
              :item-key="sendReports.ID"
              :items-per-page="15"
              :header-props="{
                'sort-by-text': '並び順',
              }"
              :footer-props="{
                'items-per-page-text': '行/ページ:',
              }"
              no-data-text="データが存在しません"
            >
              <template v-slot:item.Recodes.FID0.role="{ item }" v-ripple>
                {{ item.Recodes.FID0.role == 1 ? "管理者" : "一般" }}
              </template>
              <template v-slot:item.Recodes.FID7767.value="{ item }">
                {{ $store.state.organization[item.Recodes.FID7767.value] }}
              </template>
              <template v-slot:item.Recodes.FID7776.value="{ item }">
                {{ $store.state.organization[item.Recodes.FID7776.value] }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row v-if="localFlag">
          <v-col cols="3">
            <v-btn @click="UserConsistencyCheck" color="primary">
              整合性チェック
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn
              @click="UserDataEnsureConsistency"
              color="red"
              :disabled="consistencyCheckReports.length == 0"
            >
              整合性チェック後削除
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>
<script>
import { RepositoryFactory } from "../../api/RepositoryFactory";
const eneosCar = RepositoryFactory.get("eneosCar");
import {
  convertHalfWidthToFullWidthKana,
  getDivisionAll,
  getDepartmentAll,
} from "../specialMethod";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      localFlag: false,
      isLoading: false,
      //CSV取り込み関連
      uploadfile: { file: {}, url: "", data: { name: "", size: 0, type: "" } },
      rules: {
        fileSize: (value) => {
          return (
            value == null ||
            value.size < 15000000 ||
            "ファイルサイズを15MB未満にしてください"
          );
        },
      },
      //ユーザ情報ページの内容
      data: {
        PageID: 1036, //
        Page_id: 1036, //送信に使っているが本来は変換する
        Report_id: null, //
        UpdateType: "update",
        KeyID: [7762, 9137],
        KeyMap: {
          FID7762: 0,
        },
        Recodes: {
          FID0: {
            value: "",
            formType: 21,
            name: "",
            mail: "",
            role: "0",
            password: "",
            account: "",
            attribute: "",
            detail: "",
          },
          FID7762: { value: "", formType: 1 },
          FID7763: { value: "", formType: 1 },
          FID7764: { value: "一般", formType: 7 },
          FID8135: { value: "", formType: 14 },
          FID7765: { value: "", formType: 14 },
          FID8748: { value: "", formType: 14 },
          FID7766: { value: "", formType: 14 },
          FID8133: { value: "", formType: 14 },
          FID8134: { value: "", formType: 14 },
          FID7767: { value: "", formType: 1 },
          FID7776: { value: "", formType: 1 },
          FID7777: { value: "", formType: 2 },
          FID9137: { value: "", formType: 1 },
          // FID9441: { value: "", formType: 14 },
        },
      },
      //送信関連
      step: 0,
      sendFlag: false,
      //取り込みデータ
      importReports: [],
      deleteUsers: [],
      newUsers: [],
      updateUsers: [],
      noChangeUsers: [],
      checkFlags1: false,
      checkFlags2: false,
      checkFlags3: false,
      checkFlags4: false,
      //
      sendReports: [],
      //
      consistencyCheckReports: [],
      header: [
        {
          text: "変更内容",
          value: "Recodes.FID0.detail",
          sortable: true,
          width: "400px",
        },
        {
          text: "社員番号(name)",
          value: "Recodes.FID0.name",
          sortable: true,
          width: "100px",
        },
        {
          text: "社員番号(value)",
          value: "Recodes.FID9137.value",
          sortable: true,
          width: "100px",
        },
        //
        {
          text: "氏名",
          value: "Recodes.FID7762.value",
          sortable: true,
          width: "130px",
        },
        {
          text: "氏名カナ",
          value: "Recodes.FID7763.value",
          sortable: true,
          width: "100px",
        },
        {
          text: "事業本部",
          value: "Recodes.FID7767.value",
          sortable: true,
          width: "130px",
        },
        {
          text: "(所属)部署",
          value: "Recodes.FID7776.value",
          sortable: true,
          width: "200px",
        },
        {
          text: "従業員区分",
          value: "Recodes.FID7777.value",
          sortable: true,
          width: "120px",
        },
        //システム権限
        {
          text: "システム権限",
          value: "Recodes.FID0.role",
          sortable: true,
          width: "125px",
        },
        {
          text: "アルバイト追加権限",
          value: "Recodes.FID9441.value",
          sortable: true,
          width: "125px",
        },
        //車両追加権限
        {
          text: "車両承認権限",
          value: "Recodes.FID7764.value",
          sortable: true,
          width: "100px",
        },
        /*
        {
          //設定項目はあるが、動作上の意味はない
          text: "代理者",
          value: "Recodes.FID8135.value",
          sortable: true,
          width: "100px",
        },
        */
        {
          text: "給油確認者",
          value: "Recodes.FID7765.value",
          sortable: true,
          width: "110px",
        },
        {
          text: "車両確認(日別)",
          value: "Recodes.FID8748.value",
          sortable: true,
          width: "135px",
        },
        {
          text: "車両確認(月別)",
          value: "Recodes.FID7766.value",
          sortable: true,
          width: "135px",
        },
        {
          text: "車両追加者",
          value: "Recodes.FID8133.value",
          sortable: true,
          width: "110px",
        },
        {
          text: "マイカー添付書類確認者",
          value: "Recodes.FID8134.value",
          sortable: true,
          width: "120px",
        },
      ],
      //
      divisions: [], //事業本部
      departments: [], //部署・店舗
      errorMessages: [],
    };
  },
  async mounted() {
    await this.getInit();
  },
  computed: {
    SaveFlag() {
      if (
        this.checkFlags1 &&
        this.checkFlags2 &&
        this.checkFlags3 &&
        this.checkFlags4
      ) {
        return true;
      }
      return false;
    },
  },

  methods: {
    back() {
      history.back();
    },
    //初期処理
    async getInit() {
      this.isLoading = true;
      //
      [this.divisions, this.departments] = await Promise.all([
        getDivisionAll(),
        getDepartmentAll(),
      ]);
      //
      let url = window.location.href.split("/");
      if (url[2] == "localhost:8128" || url[2] == "localhost:8129") {
        this.localFlag = true;
      }
      //読み込み終了
      this.isLoading = false;
    },
    //CSVの取り込み
    async chargeCSV(file) {
      this.step = 0;
      this.errorMessages = [];
      this.importReports = [];
      //
      this.deleteUsers = [];
      this.newUsers = [];
      this.updateUsers = [];
      this.noChangeUsers = [];
      this.sendReports = [];
      this.checkFlags1 = false;
      this.checkFlags2 = false;
      this.checkFlags3 = false;
      this.checkFlags4 = false;
      //
      if (file == null) return;
      this.isLoading = true;
      //取り込み処理
      try {
        //文字コード変換
        const baseData = await this.charCodeConvert(file);
        //2次元配列に分割
        const data = baseData.split("\n");
        let csvData = data.slice(1); //ヘッダーを除外
        let csvArray = [];
        //取り込み処理
        for (let i = 0; i < csvData.length; i++) {
          // 空行を除く
          let replace_str = csvData[i].replace(/\r?\n|\r|,/g, "")
          if (replace_str != "") {
            csvArray.push(this.csvSplit(csvData[i]));
          }
        }
        //report形式に変換
        this.importReports = this.convertReports(csvArray);
        this.step = 1;
      } catch (e) {
        this.step = 0;
        console.log("file", file);
        console.log(e);
      }
      this.isLoading = false;
    },
    //csvデータ変換
    convertReports(csv) {
      let data = [];
      let mainReport = JSON.parse(JSON.stringify(this.data));
      csv.forEach((c, index) => {
        //主キーチェック
        if (!this.sameKeyCheck(mainReport, c)) {
          if (index > 0) {
            data.push(mainReport);
          }
          mainReport = JSON.parse(JSON.stringify(this.data));
          //ユーザ情報基本設定
          this.setValue(mainReport, c, "FID0.name", 8); //社員番号
          this.setValue(mainReport, c, "FID9137", 8); //社員番号
          this.setValue(mainReport, c, "FID7762", 9); //氏名
          this.setValue(mainReport, c, "FID7763", 10); //氏名カナ
          this.setValue(mainReport, c, "FID7767", 0); //事業部・支社
          this.setValue(mainReport, c, "FID7776", 6); //部署
          //カタカナを全角に変換
          c[14] = convertHalfWidthToFullWidthKana(String(c[14]));
          this.setValue(mainReport, c, "FID7777", 14); //従業員区分
          //車両承認権限
          if (c.length > 37 && c[37] == "〇") {
            mainReport.Recodes.FID7764.value = "承認者";
          }
          if (c.length > 36 && c[36] == "〇") {
            mainReport.Recodes.FID7765.value = "給油確認者"; //給油確認者
          }
          if (c.length > 34 && c[34] == "〇") {
            mainReport.Recodes.FID8133.value = "車両追加者"; //車両追加者
          }
          if (c.length > 38 && c[38] == "〇") {
            mainReport.Recodes.FID8134.value = "マイカー添付書類確認者"; //マイカー添付書類確認者
          }
          if (c.length > 39 && c[39] == "〇") {
            mainReport.Recodes.FID8748.value = "車両確認者(日別)"; //車両確認者(日別)
          }
          if (c.length > 40 && c[40].includes('〇')) {
            mainReport.Recodes.FID7766.value = "車両確認者"; //車両確認者(月別)
          }
          // if (c.length > 42 && c[42].includes('〇')) {
          //   mainReport.Recodes.FID9441.value = "一時ユーザ追加"; //一時ユーザ追加
          // }
          //代理者の項目は、現在未使用
          //初期設定
          mainReport.Recodes.FID0.password = "Enegori@2023"; //デフォルトパスワード
        }
      });
      data.push(mainReport);
      //事業部コードのチェック
      let arr = [];
      data.map((repo) => {
        let flag1 = false;
        let flag2 = false;
        for (let i = 0; i < this.divisions.length; i++) {
          if (this.divisions[i].value === repo.Recodes.FID7767.value) {
            flag1 = true;
            break;
          }
        }
        for (let i = 0; i < this.departments.length; i++) {
          if (this.departments[i].value === repo.Recodes.FID7776.value) {
            flag2 = true;
            break;
          }
        }
        if (flag1 && flag2) {
          arr.push(repo);
        } else {
          if (!flag1) {
            this.errorMessages.push(
              "事業部コード[" +
                repo.Recodes.FID7767.value +
                "]が見つかりませんでした"
            );
          }
          if (!flag2) {
            this.errorMessages.push(
              "部署コード[" +
                repo.Recodes.FID7776.value +
                "]が見つかりませんでした"
            );
          }
        }
      });
      //
      return arr;
    },
    //文字コードのチェック
    charCodeConvert(file) {
      const Encoding = require("encoding-japanese");
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          //文字列をバイト列に変換
          var sjisArray = new Uint8Array(reader.result);
          // let check = Encoding.detect(sjisArray);
          // console.log("charCodeConvert", check);
          //
          let encoding = this.detectEncoding(reader.result);
          switch (encoding) {
            case "SJIS":
              var unicodeArray = Encoding.convert(sjisArray, {
                to: "UNICODE",
                from: "SJIS",
                type: "string",
              });
              resolve(unicodeArray);
              break;
            case "UTF8":
              var unicodeArray2 = Encoding.convert(sjisArray, {
                to: "UTF8",
                from: "AUTO",
                type: "string",
              });
              resolve(unicodeArray2);
              break;
            case "UNICODE":
              var unicodeArray3 = Encoding.convert(sjisArray, {
                to: "UNICODE",
                from: "AUTO",
                type: "string",
              });
              resolve(unicodeArray3);
              break;
            default:
              alert("文字コードが対応していません:" + encoding);
          }
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    },
    detectEncoding(buffer) {
      const uint8Array = new Uint8Array(buffer);
      if (
        uint8Array[0] === 0xef &&
        uint8Array[1] === 0xbb &&
        uint8Array[2] === 0xbf
      ) {
        return "UTF8";
      } else if (uint8Array[0] === 0xfe && uint8Array[1] === 0xff) {
        return "UTF16BE";
      } else if (uint8Array[0] === 0xff && uint8Array[1] === 0xfe) {
        return "UTF16LE";
      } else if (
        uint8Array[0] === 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0xfe &&
        uint8Array[3] === 0xff
      ) {
        return "UTF32BE";
      } else if (
        uint8Array[0] === 0xff &&
        uint8Array[1] === 0xfe &&
        uint8Array[2] === 0 &&
        uint8Array[3] === 0
      ) {
        return "UTF32LE";
      } else if (
        uint8Array[0] === 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0 &&
        uint8Array[3] !== 0
      ) {
        return "UTF32BE-noBOM";
      } else if (
        uint8Array[0] !== 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0 &&
        uint8Array[3] === 0
      ) {
        return "UTF32LE-noBOM";
      } else {
        return "SJIS";
      }
    },
    csvSplit(line) {
      let c = "";
      let s = new String();
      let data = new Array();
      let singleQuoteFlg = false;

      for (let i = 0; i < line.length; i++) {
        c = line.charAt(i);
        if (c == "," && !singleQuoteFlg) {
          data.push(s.toString());
          s = "";
        } else if (c == "," && singleQuoteFlg) {
          s = s + c;
        } else if (c == '"') {
          singleQuoteFlg = !singleQuoteFlg;
        } else {
          s = s + c;
        }
      }
      //最後のデータを追加
      data.push(s.toString());
      return data;
    },
    //主キーチェック
    sameKeyCheck(data, csv) {
      let flag = true;
      Object.keys(data.KeyMap).map((key) => {
        const x = key;
        const y = data.KeyMap[key];
        if (data.Recodes[x].value == "" || csv[y] == "") {
          flag = false;
        }
        if (data.Recodes[x].value != csv[y]) {
          flag = false;
        }
      });
      return flag;
    },
    setValue(report, csv, to, from, tag) {
      if (typeof tag == "undefined") {
        if (to == "FID0.name") {
          report.Recodes["FID0"].name = String(csv[from]).trim();
        } else if (typeof csv[from] != "undefined" && csv[from] != "") {
          report.Recodes[to].value = String(csv[from])
            .trim()
            .replace("　", " ");
        }
        return;
      } else {
        switch (tag) {
          case "add":
            report.Recodes[to].value += String(csv[from]).trim();
            break;
        }
      }
    },
    //更新チェック
    async updateCheck() {
      this.isLoading = true;
      try {
        const response = await eneosCar.importUserDataCheck(this.importReports);
        if (response.data.deleteUser != null) {
          this.deleteUsers = response.data.deleteUser;
        }
        if (this.deleteUsers.length > 300) {
          alert('削除ユーザが300件を超えている為、処理ができません。')
          this.step = 0;
          this.isLoading = false;
          return
        }
        if (response.data.newUser != null) {
          this.newUsers = response.data.newUser;
        }
        if (response.data.noChangeUser != null) {
          this.noChangeUsers = response.data.noChangeUser;
        }

        if (response.data.updateUser != null) {
          this.updateUsers = response.data.updateUser;
        }
        this.step = 2;
      } catch (e) {
        console.log("updateCheck", e);
        this.step = 0;
      }
      this.isLoading = false;
    },
    //削除チェック
    async deleteCheck() {
      this.isLoading = true;
      try {
        this.step = 3;
      } catch (e) {
        console.log("deleteCheck", e);
        this.step = 0;
      }
      this.isLoading = false;
    },
    //ユーザ情報取り込み
    async sendUserData() {
      this.isLoading = true;
      try {
        const response = await eneosCar.importUserData(this.importReports);
        console.log("sendUserData", response);
        alert("ユーザ情報取り込みが完了しました");
        this.back();
        //送信処理の実行
        this.step = 4;
      } catch (e) {
        console.log("sendUserData", e);
        this.step = 0;
      }
      this.isLoading = false;
    },
    //ユーザデータの整合性チェック
    async UserConsistencyCheck() {
      try {
        this.isLoading = true;
        this.consistencyCheckReports = [];
        const response = await eneosCar.UserConsistencyCheck(this.reports);
        this.consistencyCheckReports = response;
        this.isLoading = false;
      } catch (e) {
        console.log("checkUserData", e);
      }
    },
    //ユーザデータの整合性チェック後削除
    async UserDataEnsureConsistency() {
      try {
        this.isLoading = true;
        await eneosCar.UserDataEnsureConsistency(this.consistencyCheckReports);
        this.isLoading = false;
      } catch (e) {
        console.log("checkUserData", e);
      }
    },
  },
};
</script>
