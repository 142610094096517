<template>
  <v-card class="subPage" color="white">
    <div class="ma-1">
      <p>マイカー承認フロー2</p>
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form">
          <v-row>
            <v-col cols="0">
              <v-autocomplete
                label="部署・店舗"
                v-model="data.Recodes.FID8550.value"
                :rules="[rules.required, rules.containBox8550]"
                background-color="white"
                id="FID8550"
                class="selectBox8550"
                outlined
                no-data-text="データが見つかりません"
                clearable
                :items="selectBoxFID8550"
                hide-details="auto"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="0">
              <v-autocomplete
                label="役職"
                v-model="data.Recodes.FID8551.value"
                :rules="[rules.required, rules.containBox8551]"
                background-color="white"
                id="FID8551"
                class="selectBox8551"
                outlined
                no-data-text="データが見つかりません"
                clearable
                :items="selectBoxFID8551"
                hide-details="auto"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row
            v-if="
              typeof data.Recodes.FID8552.subReports != 'undefined' &&
              data.Recodes.FID8552.subReports.length > 0
            "
          >
            <v-col
              cols="12"
              v-for="(report, i) in data.Recodes.FID8552.subReports"
              :key="`[8552-${i}`"
            >
              <approvalFlow3
                label=""
                background-color="white"
                :id="'FID8552-' + i"
                class="subPage8552"
                ref="subPage8552"
                :Report="report"
                :subindex="i"
                :daleteReports.sync="data.DeleteSubReports"
                v-on:clickSubmit="saveSubPage"
                :myInit="subInit"
                FID="FID8552"
                hide-details="auto"
              >
              </approvalFlow3>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="left">
              <v-btn @click="subPageDel('FID8552')" color="red" class="ma-2"
                >削除</v-btn
              >
              <v-btn @click="subPageAdd('FID8552')" color="primary" class="ma-2"
                >追加</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-card>
</template>

<script>
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");

import approvalFlow3 from "../views/approvalFlow3";

export default {
  name: "Home",
  components: {
    approvalFlow3,
  },
  props: {
    //値
    Report: Object,
    subindex: Number,
    parentData: {
      type: Object,
      default: () => {},
      required: false,
    },
    myInit: Boolean,
    FID: String,
  },
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      data: {
        PageID: 1227,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID8550: { value: "", formType: 2, disabled: false },
          FID8551: { value: "", formType: 2, disabled: false },
          FID8552: {
            value: "PID1228",
            formType: 17,
            disabled: false,
            subReports: [],
          },
        },
        DeleteSubReports: [],
      },
      selectBoxFID8550: [
        "事業部",
        "部署",
        "支社",
        "部署(業務グループ)",
        "グループ",
        "店舗",
      ],
      selectBoxFID8551: [
        "スタッフ",
        "マネージャー",
        "グループリーダー",
        "直営事業部長",
      ],

      rules: {
        required: (value) => !!value || "必須項目です",
        containBox8550: (value) => {
          if (typeof this.selectBoxFID8550 == "undefined") {
            return true;
          }
          return this.selectBoxFID8550.includes(value) ||
            value == "" ||
            value == null
            ? true
            : "選択肢にない項目が入力されています";
        },
        containBox8551: (value) => {
          if (typeof this.selectBoxFID8551 == "undefined") {
            return true;
          }
          return this.selectBoxFID8551.includes(value) ||
            value == "" ||
            value == null
            ? true
            : "選択肢にない項目が入力されています";
        },
      },
      copyFlag: false,
      noBack: false,
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {},
  watch: {
    $route() {
      this.getInit();
    },
    data: {
      handler: async function (data) {
        //

        //
        this.$emit("clickSubmit", this.FID, this.subindex, data);
      },
      deep: true,
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        //データが存在する場合
        if (this.$route.query.copyFlag) {
          this.reportCopy();
        } else if (this.Report.ReportID != null || this.myInit) {
          //サブレポートの値代入
          this.data.ReportID = this.Report.ReportID;
          this.data.Recodes = api.OptionCopy(
            this.data.Recodes,
            this.Report.Recodes
          );
        } else {
          //初期値設定
        }
        //データ取得処理

        //init処理

        //初回データ反映
        this.$emit("clickSubmit", this.FID, this.subindex, this.data);
        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
      }
    },
    async send() {
      const result = this.escapeValidation
        ? true
        : await this.checkValidation();
      if (result) {
        console.log("SendPage1227");
      }
    },
    subPageAdd(FID, index) {
      this.isLoading = true;
      if (
        typeof this.data.Recodes[FID] == "undefined" ||
        typeof this.data.Recodes[FID].subReports == "undefined"
      ) {
        index = 0;
        this.data.Recodes[FID].subReports = [];
      }
      if (typeof index == "undefined") {
        index = this.data.Recodes[FID].subReports.length;
      }
      let max = 1;
      if (this.data.Recodes[FID].subReports.length < max) {
        this.data.Recodes[FID].subReports.splice(index, 0, {});
      }
      this.isLoading = false;
    },
    subPageDel(FID, index) {
      //this.subInit = false;
      let min = 1;
      if (typeof index == "undefined") {
        index = this.data.Recodes[FID].subReports.length - 1;
      }
      if (this.data.Recodes[FID].subReports.length > min) {
        this.data.DeleteSubReports = this.data.DeleteSubReports.concat(
          this.data.Recodes[FID].subReports.splice(index, 1)
        );
      }
      //this.subInit = true;
    },
    saveSubPage(FID, subindex, reports) {
      this.data.Recodes[FID].subReports.splice(subindex, 1, reports);
      if (reports.DeleteSubReports.length == 1) {
        this.data.DeleteSubReports = this.data.DeleteSubReports.concat(
          reports.DeleteSubReports.pop()
        );
      }
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (this.escapeValidation) {
        return true;
      }
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }
      return check;
    },
    async del() {
      await api.DelReport(this.data);
      alert("削除完了");
      this.back();
    },
  },
};
</script>
<style></style>
