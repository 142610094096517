<template>
  <div class="home">
    <p>社有車新規登録・設定</p>
    <!-- {{ data.Recodes.FID8322.value }} -->
    <v-row>
      <v-col align="right"><v-btn @click.once="back">戻る</v-btn></v-col>
    </v-row>
    <v-container cols="12">
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form" @submit.prevent>
          <v-row>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-menu
                v-model="data.Recodes.FID8099.modal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="初回登録日"
                    v-model="data.Recodes.FID8099.value"
                    :disabled="data.Recodes.FID8099.disabled || allDisabled8529"
                    :rules="[rules.required]"
                    :background-color="compClass8099"
                    id="FID8099"
                    class="inputTime8099"
                    append-icon="mdi-calendar"
                    outlined
                    v-on="on"
                    readonly
                    hide-details="auto"
                  >
                  </v-text-field> </template
                ><v-date-picker
                  v-model="data.Recodes.FID8099.value"
                  no-title
                  scrollable
                  locale="ja-jp"
                  :day-format="(date) => new Date(date).getDate()"
                  @input="data.Recodes.FID8099.modal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-menu
                v-model="data.Recodes.FID8100.modal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="最終更新日"
                    v-model="data.Recodes.FID8100.value"
                    :disabled="data.Recodes.FID8100.disabled || allDisabled8529"
                    :background-color="compClass8100"
                    id="FID8100"
                    class="inputTime8100"
                    append-icon="mdi-calendar"
                    outlined
                    v-on="on"
                    readonly
                    hide-details="auto"
                  >
                  </v-text-field> </template
                ><v-date-picker
                  v-model="data.Recodes.FID8100.value"
                  no-title
                  scrollable
                  locale="ja-jp"
                  :day-format="(date) => new Date(date).getDate()"
                  @input="data.Recodes.FID8100.modal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-text-field
                label="登録者"
                v-model="data.Recodes.FID8103.value"
                disabled
                background-color="white"
                id="FID8103"
                class="userInfo8103"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-autocomplete
                label="管理本部"
                v-model="data.Recodes.FID8321.value"
                :disabled="data.Recodes.FID8321.disabled || allDisabled8529"
                :rules="[rules.required]"
                :background-color="compClass8321"
                id="FID8321"
                class="selectBox8321"
                outlined
                no-data-text="データが見つかりません"
                clearable
                :items="selectBoxFID8321"
                item-text="label"
                item-value="value"
                hide-details="auto"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="6" sm="6" md="3" lg="3">
              <v-autocomplete
                label="管理部門"
                v-model="data.Recodes.FID9238.value"
                :disabled="data.Recodes.FID9238.disabled || allDisabled8529"
                :rules="[rules.required]"
                :background-color="compClass9238"
                id="FID9238"
                class="selectBox9238"
                outlined
                no-data-text="データが見つかりません"
                clearable
                :items="selectBoxFID9238"
                item-text="label"
                item-value="value"
                hide-details="auto"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-autocomplete
                label="部署"
                v-model="data.Recodes.FID8322.value"
                :disabled="data.Recodes.FID8322.disabled || allDisabled8529"
                :rules="[rules.required]"
                :background-color="compClass8322"
                id="FID8322"
                class="selectBox8322"
                outlined
                no-data-text="データが見つかりません"
                clearable
                :items="selectBoxFID8322"
                item-text="label"
                item-value="value"
                hide-details="auto"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="0">
              <v-card elevation="2" class="ma-0 pa-0">
                <v-row no-gutters>
                  <v-col
                    v-for="label in data.Recodes.FID8101.labels"
                    :key="label"
                    cols="12"
                    sm="12"
                    :md="data.Recodes.FID8101.labels.length > 4 ? 4 : 6"
                    :lg="data.Recodes.FID8101.labels.length > 4 ? 4 : 6"
                  >
                    <v-card class="pa-2" tile>
                      <v-checkbox
                        class="black--text ma-1"
                        v-model="FID8101json"
                        :label="label"
                        :value="label"
                        hide-details="auto"
                        :rules="[FID8101json.length > 3 ? '最大3個まで' : true]"
                      >
                        <template slot="label">
                          {{ label }}
                        </template>
                      </v-checkbox>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row
                  no-gutters
                  class="ml-2"
                  v-for="hint in hint8101"
                  :key="hint"
                >
                  {{ hint }}
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" sm="3" md="3" lg="3">
              <v-menu
                v-model="data.Recodes.FID8271.modal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="初期日・異動日"
                    v-model="data.Recodes.FID8271.value"
                    :disabled="data.Recodes.FID8271.disabled || allDisabled8529"
                    :background-color="compClass8271"
                    id="FID8271"
                    class="inputTime8271"
                    append-icon="mdi-calendar"
                    outlined
                    v-on="on"
                    readonly
                    hide-details="auto"
                  >
                  </v-text-field> </template
                ><v-date-picker
                  v-model="data.Recodes.FID8271.value"
                  no-title
                  scrollable
                  locale="ja-jp"
                  :day-format="(date) => new Date(date).getDate()"
                  @input="data.Recodes.FID8271.modal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="6" lg="6">
              <div
                label=""
                :disabled="allDisabled8529"
                background-color="white"
                id="FID8317"
                class="plainText8317 text-h5"
                hide-details="auto"
              >
                車検証情報入力
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="0">
              <v-dialog
                v-model="data.Recodes.FID8007.modal"
                :max-width="resizeWidth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-text-field
                      label="自動車番号または車両番号"
                      v-model="data.Recodes.FID8007.value"
                      :disabled="
                        data.Recodes.FID8007.disabled || allDisabled8529
                      "
                      :rules="[rules.required]"
                      :background-color="compClass8007"
                      id="FID8007"
                      class="textfield8007"
                      append-icon="mdi-car"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      hide-details="auto"
                    >
                    </v-text-field></div></template
                ><car-Number
                  v-if="data.Recodes.FID8007.modal"
                  title="自動車番号または車両番号"
                  :value="data.Recodes.FID8007.value"
                  :rules="[rules.required]"
                  v-on:ok="
                    (data.Recodes.FID8007.modal = false),
                      $set(data.Recodes.FID8007, 'value', $event)
                  "
                  type="calculator"
                ></car-Number>
              </v-dialog>
            </v-col>
            <v-col cols="0">
              <v-text-field
                label="車台番号"
                v-model="data.Recodes.FID8113.value"
                :disabled="data.Recodes.FID8113.disabled || allDisabled8529"
                :background-color="compClass8113"
                id="FID8113"
                class="textfield8113"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="0">
              <v-dialog
                v-model="data.Recodes.FID8272.modal"
                :max-width="resizeWidth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-text-field
                      label="旧車番"
                      v-model="data.Recodes.FID8272.value"
                      :disabled="
                        data.Recodes.FID8272.disabled || allDisabled8529
                      "
                      :background-color="compClass8272"
                      id="FID8272"
                      class="textfield8272"
                      append-icon="mdi-copyright"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      @click:append="clickIcon8272"
                      hide-details="auto"
                      :hint="hint8272"
                      :rules="[
                        data.Recodes.FID8101.value.includes('車番変更')
                          ? rules.required(data.Recodes.FID8272.value)
                          : true,
                      ]"
                      persistent-hint
                    >
                    </v-text-field></div></template
                ><car-Number
                  v-if="data.Recodes.FID8272.modal"
                  title="旧車番"
                  しゃ
                  :value="data.Recodes.FID8272.value"
                  :rules="[]"
                  v-on:ok="
                    (data.Recodes.FID8272.modal = false),
                      $set(data.Recodes.FID8272, 'value', $event)
                  "
                  type="calculator"
                ></car-Number>
              </v-dialog>
            </v-col>
            <v-col cols="0">
              <v-autocomplete
                label="使用区分"
                v-model="data.Recodes.FID8613.value"
                :disabled="data.Recodes.FID8613.disabled || allDisabled8529"
                :rules="[rules.required, rules.containBox8613]"
                :background-color="compClass8613"
                id="FID8613"
                class="selectBox8613"
                outlined
                no-data-text="データが見つかりません"
                clearable
                :items="selectBoxFID8613"
                hide-details="auto"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="6" lg="6">
              <div
                label=""
                :disabled="allDisabled8529"
                background-color="white"
                id="FID8102"
                class="plainText8102 text-h5"
                hide-details="auto"
              >
                利用状況確認
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-dialog
                v-model="data.Recodes.FID8318.modal"
                :max-width="resizeWidth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-text-field
                      label="初年度登録月"
                      v-model="data.Recodes.FID8318.value"
                      :disabled="
                        data.Recodes.FID8318.disabled || allDisabled8529
                      "
                      :background-color="compClass8318"
                      id="FID8318"
                      class="textfield8318"
                      append-icon="mdi-calendar-badge"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      hide-details="auto"
                    >
                    </v-text-field></div></template
                ><japanese-Calendar
                  v-if="data.Recodes.FID8318.modal"
                  title="初年度登録月"
                  :value="data.Recodes.FID8318.value"
                  :rules="[]"
                  v-on:ok="
                    (data.Recodes.FID8318.modal = false),
                      $set(data.Recodes.FID8318, 'value', $event)
                  "
                  type="calculator"
                  YMonly
                ></japanese-Calendar>
              </v-dialog>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-dialog
                v-model="data.Recodes.FID8319.modal"
                :max-width="resizeWidth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-text-field
                      label="有効期間の満了する日"
                      v-model="data.Recodes.FID8319.value"
                      :disabled="
                        data.Recodes.FID8319.disabled || allDisabled8529
                      "
                      :background-color="compClass8319"
                      id="FID8319"
                      class="textfield8319"
                      append-icon="mdi-calendar-badge"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      hide-details="auto"
                    >
                    </v-text-field></div></template
                ><japanese-Calendar
                  v-if="data.Recodes.FID8319.modal"
                  title="有効期間の満了する日"
                  :value="data.Recodes.FID8319.value"
                  :rules="[]"
                  v-on:ok="
                    (data.Recodes.FID8319.modal = false),
                      $set(data.Recodes.FID8319, 'value', $event)
                  "
                  type="calculator"
                ></japanese-Calendar>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="0">
              <v-text-field
                label="車名"
                v-model="data.Recodes.FID8111.value"
                :disabled="data.Recodes.FID8111.disabled || allDisabled8529"
                :rules="[rules.required]"
                :background-color="compClass8111"
                id="FID8111"
                class="textfield8111"
                outlined
                hide-details="auto"
                :hint="hint8111"
                persistent-hint
              >
              </v-text-field>
            </v-col>
            <v-col cols="0">
              <v-text-field
                label="車種名"
                v-model="data.Recodes.FID8112.value"
                :disabled="data.Recodes.FID8112.disabled || allDisabled8529"
                :rules="[rules.required]"
                :background-color="compClass8112"
                id="FID8112"
                class="textfield8112"
                outlined
                persistent-hint
                :hint="FID8112"
              >
              </v-text-field>
            </v-col>
            <v-col cols="0">
              <v-text-field
                label="型式"
                v-model="data.Recodes.FID8114.value"
                :disabled="data.Recodes.FID8114.disabled || allDisabled8529"
                :rules="[rules.zenkakuEiSuu]"
                :background-color="compClass8114"
                id="FID8114"
                class="textfield8114"
                outlined
                hide-details="auto"
                :hint="hint8114"
                persistent-hint
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="0">
              <v-select
                label="自動車の種別"
                v-model="data.Recodes.FID8108.value"
                :disabled="data.Recodes.FID8108.disabled || allDisabled8529"
                :rules="[rules.required]"
                :background-color="compClass8108"
                id="FID8108"
                class="selectBox8108"
                outlined
                no-data-text="データが見つかりません"
                clearable
                :items="selectBoxFID8108"
                persistent-hint
              >
              </v-select>
            </v-col>
            <v-col cols="0">
              <v-autocomplete
                label="種別・用途"
                v-model="data.Recodes.FID8109.value"
                :disabled="data.Recodes.FID8109.disabled || allDisabled8529"
                :rules="[rules.required]"
                :background-color="compClass8109"
                id="FID8109"
                class="selectBox8109"
                outlined
                no-data-text="データが見つかりません"
                clearable
                :items="selectBoxFID8109"
                hide-details="auto"
                persistent-hint
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="0">
              <v-text-field
                label="自家用・事業用の別"
                v-model="data.Recodes.FID8116.value"
                :disabled="data.Recodes.FID8116.disabled || allDisabled8529"
                :background-color="compClass8116"
                id="FID8116"
                class="textfield8116"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="0">
              <v-dialog
                v-model="data.Recodes.FID8110.modal"
                :max-width="resizeWidth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-text-field
                      label="最大積載量"
                      v-model="data.Recodes.FID8110.value"
                      :disabled="
                        data.Recodes.FID8110.disabled || allDisabled8529
                      "
                      :rules="[
                        rules.required,
                        rules.smallNum,
                        rules.customValidation8110,
                      ]"
                      :background-color="compClass8110"
                      id="FID8110"
                      class="textfield8110"
                      append-icon="mdi-calculator"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      hide-details="auto"
                      :hint="hint8110"
                      persistent-hint
                    >
                    </v-text-field></div></template
                ><input-Number
                  v-if="data.Recodes.FID8110.modal"
                  title="最大積載量"
                  :value="data.Recodes.FID8110.value"
                  :rules="[rules.required, rules.smallNum]"
                  v-on:ok="
                    (data.Recodes.FID8110.modal = false),
                      $set(data.Recodes.FID8110, 'value', $event)
                  "
                  type="calculator"
                ></input-Number>
              </v-dialog>
            </v-col>
            <v-col cols="0">
              <v-file-input
                label="車検証"
                v-model="uploadfile8333.file"
                :disabled="data.Recodes.FID8333.disabled || allDisabled8529"
                :rules="[
                  rules.pdfExtension,
                  rules.fileRequired(
                    uploadfile8333.file,
                    data.Recodes.FID8333.value
                  ),
                ]"
                :background-color="compClass8333"
                id="FID8333"
                class="fileInput8333"
                @change="changeFile8333"
                outlined
                prepend-icon="mdi-image"
                ref="rfafile"
                accept=".pdf"
                hide-details="auto"
              >
              </v-file-input>
            </v-col>
            <v-col cols="0">
              <v-btn
                label="ダウンロード"
                :disabled="data.Recodes.FID8532.disabled"
                background-color="white"
                id="FID8532"
                class="button8532 mt-2"
                @click="onBtn8532"
                color="yellow lighten-2"
                hide-details="auto"
              >
                ダウンロード</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="4" lg="4">
              <div
                label=""
                :disabled="allDisabled8529"
                background-color="white"
                id="FID8535"
                class=""
                hide-details="auto"
              ></div>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-btn
                label="新規登録"
                :disabled="data.Recodes.FID8534.disabled || allDisabled8529"
                background-color="white"
                id="FID8534"
                class="button8534"
                @click="onBtn8534"
                color="yellow lighten-2"
                block
                style="font-size: 20px; letter-spacing: 10px"
                height="50"
                hide-details="auto"
              >
                新規登録</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="4" lg="4">
              <v-text-field
                label="ステータス"
                v-model="data.Recodes.FID8505.value"
                :disabled="allDisabled8529"
                background-color="white"
                id="FID8505"
                class="textfield8505"
                outlined
                readonly
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-btn
                label="更新"
                :disabled="data.Recodes.FID9252.disabled || allDisabled8529"
                background-color="white"
                id="FID9252"
                class="button9252"
                @click="onBtn9252"
                color="yellow lighten-2"
                block
                style="font-size: 20px; letter-spacing: 10px"
                height="50"
                hide-details="auto"
              >
                更新</v-btn
              >
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-btn
                label="削除"
                :disabled="data.Recodes.FID9253.disabled || allDisabled8529"
                background-color="white"
                id="FID9253"
                class="button9253"
                @click="onBtn9253"
                color="red white--text"
                block
                style="font-size: 20px; letter-spacing: 10px"
                height="50"
                hide-details="auto"
              >
                削除</v-btn
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col align="left">
              <v-btn
                v-if="viewFID8586"
                @click="deleteModal = true"
                :disabled="
                  data.ReportID == null ||
                  disabled ||
                  data.Recodes.FID8586.disabled ||
                  allDisabled8529
                "
                color="red white--text"
                >削除
              </v-btn>
              <v-dialog v-model="deleteModal" max-width="400">
                <v-card>
                  <v-card-title class="text-h5"> 削除しますか？ </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mr-3"
                      color="green darken-1 white--text"
                      @click="deleteModal = false"
                      large
                    >
                      いいえ
                    </v-btn>
                    <v-btn color="red darken-1 white--text" @click="del" large>
                      はい
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-container>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");

import { getDivision } from "./specialMethod";
import { getCompanyCarSection } from "./specialMethod";
import { getCompanyCarDepartment } from "./specialMethod";
import carNumber from "../views/carNumberBase";
import japaneseCalendar from "../views/japaneseCalendarBase";
import inputNumber from "../views/inputNumber";

export default {
  name: "Home",
  components: {
    carNumber,
    japaneseCalendar,
    inputNumber,
  },
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      data: {
        PageID: 1158,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID8099: {
            value: new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              new Date().getHours() + 9
            )
              .toISOString()
              .match(/^\d{4}-\d{2}-\d{2}/g)
              .pop(),
            formType: 3,
            disabled: false,
            modal: false,
          },
          FID8100: {
            value: new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              new Date().getHours() + 9
            )
              .toISOString()
              .match(/^\d{4}-\d{2}-\d{2}/g)
              .pop(),
            formType: 3,
            disabled: true,
            modal: false,
          },
          FID8103: { value: "", formType: 18, disabled: false },
          FID8104: { value: "", formType: 18, disabled: false },
          FID8321: { value: "", formType: 2, disabled: false },
          FID9238: { value: "", formType: 2, disabled: false },
          FID8322: { value: "", formType: 2, disabled: false },
          FID8101: {
            value: "新規増車",
            formType: 14,
            disabled: false,
            color: "black",
            labels: [
              "新規増車",
              "減車(廃止)",
              "車検更新",
              "管理部門変更",
              "車番変更",
              "使用区分変更",
            ],
          },
          FID8271: {
            value: new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              new Date().getHours() + 9
            )
              .toISOString()
              .match(/^\d{4}-\d{2}-\d{2}/g)
              .pop(),
            formType: 3,
            disabled: false,
            modal: false,
          },
          FID8007: { value: "", formType: 1, disabled: false, modal: false },
          FID8113: { value: "", formType: 1, disabled: false },
          FID8272: { value: "", formType: 1, disabled: false, modal: false },
          FID8613: { value: "", formType: 2, disabled: false },
          FID8318: { value: "", formType: 1, disabled: false, modal: false },
          FID8319: { value: "", formType: 1, disabled: false, modal: false },
          FID8111: { value: "", formType: 1, disabled: false },
          FID8112: { value: "", formType: 1, disabled: false },
          FID8114: { value: "", formType: 1, disabled: false },
          FID8108: { value: "", formType: 2, disabled: false },
          FID8109: { value: "", formType: 2, disabled: false },
          FID8116: { value: "", formType: 1, disabled: false },
          FID8110: { value: "0", formType: 1, disabled: false, modal: false },
          FID8333: {
            value: "",
            formType: 9,
            disabled: false,
            form_id: 8333,
            url: "",
          },
          FID8532: { value: "", formType: 16, disabled: true },
          FID8534: { value: "", formType: 16, disabled: false },
          FID8257: { value: "", formType: 20, disabled: false },
          FID8505: { value: "新規申請", formType: 1, disabled: false },
          FID9252: { value: "", formType: 16, disabled: false },
          FID9253: { value: "", formType: 16, disabled: false },
          FID8586: { value: "", formType: 22, disabled: false },
          FID8533: { value: "", formType: 20, disabled: false },
        },
        DeleteSubReports: [],
      },
      FID8101json: [""],
      selectBoxFID8613: [
        "SS代車",
        "セールスカー",
        "デリバリー車",
        "レンタカー",
        "その他",
      ],
      selectBoxFID8108: ["軽", "小型", "普通", "原付"],
      selectBoxFID8109: ["乗用", "貸物", "特種"],
      uploadfile8333: {
        file: null,
        url: "",
        data: { name: "", size: 0, type: "" },
      },
      rules: {
        required: (value) => !!value || "必須項目です",
        zenkakuEiSuu: (value) => {
          return /[\x20-\x7E]/.test(value) ? "英数時は全角のみ" : true;
        },
        smallNum: (value) => {
          return /^(\d*.?\d+)*$/.test(value) || "数値のみ入力可能です。";
        },

        pdfExtension: (value) => {
          if (value == null || JSON.stringify(value) == "{}") return true;
          return value.type == "application/pdf"
            ? true
            : "対応していないファイルです";
        },
        containBox8613: (value) => {
          if (typeof this.selectBoxFID8613 == "undefined") {
            return true;
          }
          return this.selectBoxFID8613.includes(value) ||
            value == "" ||
            value == null
            ? true
            : "選択肢にない項目が入力されています";
        },
        customValidation8110: (value) => {
          if (
            typeof this.data.Recodes.FID8109 != "undefined" &&
            this.data.Recodes.FID8109.value == "普通"
          ) {
            return this.rules.required(value);
          }
          if (
            typeof this.data.Recodes.FID8109 != "undefined" &&
            this.data.Recodes.FID8109.value == "貸物"
          ) {
            return this.rules.required(value);
          }
          return true;
        },
        fileRequired: (file, value) => {
          if (file == null && value == "") return "ファイルを選択してください";
          return true;
        },
      },
      deleteModal: false,
      copyFlag: false,
      sendCopyFlag: false,
      noBack: false,
      backCount: -1,
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {
    compClass8099: function () {
      if (this.data.Recodes.FID8101.value.includes("新規増車")) {
        return "white";
      }
      return "grey";
    },
    compClass8100: function () {
      return "grey";
    },
    compClass8321: function () {
      if (
        this.data.Recodes.FID8101.value.includes("新規増車") ||
        this.data.Recodes.FID8101.value.includes("管理部門変更")
      ) {
        return "white";
      }
      return "grey";
    },
    compClass9238: function () {
      if (
        this.data.Recodes.FID8101.value.includes("新規増車") ||
        this.data.Recodes.FID8101.value.includes("管理部門変更")
      ) {
        return "white";
      }
      return "grey";
    },
    compClass8322: function () {
      if (
        this.data.Recodes.FID8101.value.includes("新規増車") ||
        this.data.Recodes.FID8101.value.includes("管理部門変更")
      ) {
        return "white";
      }
      return "grey";
    },
    hint8101: function () {
      let arr = [];
      if (this.data.Recodes.FID8101.value.includes("新規増車")) {
        arr.push("リース契約もしくは自社所有として購入した場合");
      }
      if (this.data.Recodes.FID8101.value.includes("減車")) {
        arr.push("下記3つの場合等 車両の運用をやめた場合に選択");
        arr.push("例①リース会社に返却した 例②他社に譲渡した 例③廃車にした");
      }
      if (this.data.Recodes.FID8101.value.includes("車検更新")) {
        arr.push("車検を更新した場合に選択");
        arr.push("(2023年10月1日→2025年10月1日)");
      }
      if (this.data.Recodes.FID8101.value.includes("管理部門変更")) {
        arr.push("社有車の管理拠点を変更した場合");
        arr.push("例:SS→デリバリーセンター");
      }
      if (this.data.Recodes.FID8101.value.includes("車番変更")) {
        arr.push("車両番号が変更になった場合");
        arr.push("例① 横浜581き8875→横浜581か6615)");
        arr.push("レンタカーから一般車両に変更があった場合");
        arr.push("例② 北九州501わ2422⇒北九州501も9774");
      }
      if (this.data.Recodes.FID8101.value.includes("使用区分変更")) {
        arr.push("SS代車からデリバリー車に変更になった等");
        arr.push("（管理拠点および車番に変更がない場合）");
      }
      if (arr.length == 0) {
        arr.push("変更区分を選択してください");
      }
      return arr;
    },
    compClass8271: function () {
      if (
        this.data.Recodes.FID8101.value.includes("減車") ||
        this.data.Recodes.FID8101.value.includes("新規増車") ||
        this.data.Recodes.FID8101.value.includes("車番変更") ||
        this.data.Recodes.FID8101.value.includes("使用区分変更") ||
        this.data.Recodes.FID8101.value.includes("車検更新") ||
        this.data.Recodes.FID8101.value.includes("管理部門変更")
      ) {
        return "white";
      }
      return "grey";
    },
    compClass8007: function () {
      if (
        this.FID8101json.includes("新規増車") ||
        this.FID8101json.includes("車番変更")
      ) {
        return "white";
      }
      return "grey";
    },
    resizeWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "270px";
        case "sm":
          return "500px";
        case "md":
          return "600px";
        case "lg":
          return "500px";
        case "xl":
          return "500px";
        default:
          return "500px";
      }
    },
    compClass8113: function () {
      if (this.FID8101json.includes("新規増車")) {
        return "white";
      }
      return "grey";
    },
    compClass8272: function () {
      if (
        this.FID8101json.includes("新規増車") ||
        this.FID8101json.includes("車番変更")
      ) {
        return "white";
      }
      return "grey";
    },
    hint8272: function () {
      if (this.data.Recodes.FID8101.value.includes("車番変更")) {
        return "旧車番を入力してください";
      }
      return "アイコンクリックで車番号をコピー";
    },
    compClass8613: function () {
      if (
        this.data.Recodes.FID8101.value.includes("新規増車") ||
        this.data.Recodes.FID8101.value.includes("使用区分変更")
      ) {
        return "white";
      }
      return "grey";
    },
    compClass8318: function () {
      if (this.FID8101json.includes("新規増車")) {
        return "white";
      }
      return "grey";
    },
    compClass8319: function () {
      if (
        this.FID8101json.includes("新規増車") ||
        this.FID8101json.includes("車検更新")
      ) {
        return "white";
      }
      return "grey";
    },
    compClass8111: function () {
      if (this.FID8101json.includes("新規増車")) {
        return "white";
      }
      return "grey";
    },
    hint8111: function () {
      return "例:トヨタ";
    },
    compClass8112: function () {
      if (this.FID8101json.includes("新規増車")) {
        return "white";
      }
      return "grey";
    },
    compClass8114: function () {
      if (this.FID8101json.includes("新規増車")) {
        return "white";
      }
      return "grey";
    },
    hint8114: function () {
      return "全て全角のみ";
    },
    compClass8108: function () {
      if (this.FID8101json.includes("新規増車")) {
        return "white";
      }
      return "grey";
    },
    compClass8109: function () {
      if (this.FID8101json.includes("新規増車")) {
        return "white";
      }
      return "grey";
    },
    FID8112: function () {
      return "例:プリウス";
    },
    compClass8116: function () {
      if (this.FID8101json.includes("新規増車")) {
        return "white";
      }
      return "grey";
    },
    compClass8110: function () {
      if (this.FID8101json.includes("新規増車")) {
        return "white";
      }
      return "grey";
    },
    hint8110: function () {
      return "用途「貨物」の場合のみ入力";
    },
    compClass8333: function () {
      if (
        this.data.Recodes.FID8101.value.includes("新規増車") ||
        this.data.Recodes.FID8101.value.includes("車番変更") ||
        this.data.Recodes.FID8101.value.includes("車検更新")
      ) {
        return "white";
      }
      return "grey";
    },
    showTemplate8257() {
      return true;
    },
    viewFID8586: function () {
      if (this.$store.state.role == 1) {
        return true;
      }
      return false;
    },
    showTemplate8533() {
      return true;
    },
    allDisabled8529() {
      let data = this.data;
      if (data.Recodes.FID8101.value == "") {
        return true;
      }
      return false;
    },
  },
  watch: {
    $route() {
      this.getInit();
    },
    data: {
      handler: async function (data) {
        if (data.Recodes.FID8101.value == "新規増車") {
          this.data.Recodes.FID8099.disabled = false;
        } else {
          this.data.Recodes.FID8099.disabled = true;
        }
        if (
          data.Recodes.FID8101.value.includes("新規増車") ||
          data.Recodes.FID8101.value.includes("管理部門変更")
        ) {
          this.data.Recodes.FID8321.disabled = false;
        } else {
          this.data.Recodes.FID8321.disabled = true;
        }
        if (
          data.Recodes.FID8101.value.includes("新規増車") ||
          data.Recodes.FID8101.value.includes("管理部門変更")
        ) {
          this.data.Recodes.FID9238.disabled = false;
        } else {
          this.data.Recodes.FID9238.disabled = true;
        }
        if (
          data.Recodes.FID8101.value.includes("新規増車") ||
          data.Recodes.FID8101.value.includes("管理部門変更")
        ) {
          this.data.Recodes.FID8322.disabled = false;
        } else {
          this.data.Recodes.FID8322.disabled = true;
        }
        if (
          data.Recodes.FID8101.value.includes("新規増車") ||
          data.Recodes.FID8101.value.includes("減車") ||
          data.Recodes.FID8101.value.includes("車番変更") ||
          data.Recodes.FID8101.value.includes("使用区分変更") ||
          data.Recodes.FID8101.value.includes("車検更新") ||
          data.Recodes.FID8101.value.includes("管理部門変更")
        ) {
          this.data.Recodes.FID8271.disabled = false;
        } else {
          this.data.Recodes.FID8271.disabled = true;
        }
        if (
          data.Recodes.FID8101.value.includes("新規増車") ||
          (data.Recodes.FID8101.value.includes("車番変更") &&
            data.Recodes.FID8007.value != "レンタカー")
        ) {
          this.data.Recodes.FID8007.disabled = false;
        } else {
          this.data.Recodes.FID8007.disabled = true;
        }
        if (data.Recodes.FID8101.value == "新規増車") {
          this.data.Recodes.FID8113.disabled = false;
        } else {
          this.data.Recodes.FID8113.disabled = true;
        }
        if (data.Recodes.FID8101.value.includes("車番変更")) {
          this.data.Recodes.FID8272.disabled = false;
        } else {
          this.data.Recodes.FID8272.disabled = true;
        }
        if (
          data.Recodes.FID8101.value.includes("新規増車") ||
          data.Recodes.FID8101.value.includes("使用区分変更")
        ) {
          this.data.Recodes.FID8613.disabled = false;
        } else {
          this.data.Recodes.FID8613.disabled = true;
        }
        if (
          data.Recodes.FID8101.value.includes("新規増車") ||
          data.Recodes.FID8101.value.includes("管理部門変更")
        ) {
          this.data.Recodes.FID8318.disabled = false;
        } else {
          this.data.Recodes.FID8318.disabled = true;
        }
        if (
          data.Recodes.FID8101.value.includes("新規増車") ||
          data.Recodes.FID8101.value.includes("車検更新")
        ) {
          this.data.Recodes.FID8319.disabled = false;
        } else {
          this.data.Recodes.FID8319.disabled = true;
        }
        if (data.Recodes.FID8101.value.includes("新規増車")) {
          this.data.Recodes.FID8111.disabled = false;
        } else {
          this.data.Recodes.FID8111.disabled = true;
        }
        if (data.Recodes.FID8101.value.includes("新規増車")) {
          this.data.Recodes.FID8112.disabled = false;
        } else {
          this.data.Recodes.FID8112.disabled = true;
        }
        if (data.Recodes.FID8101.value.includes("新規増車")) {
          this.data.Recodes.FID8114.disabled = false;
        } else {
          this.data.Recodes.FID8114.disabled = true;
        }
        if (data.Recodes.FID8101.value.includes("新規増車")) {
          this.data.Recodes.FID8108.disabled = false;
        } else {
          this.data.Recodes.FID8108.disabled = true;
        }
        if (data.Recodes.FID8101.value.includes("新規増車")) {
          this.data.Recodes.FID8109.disabled = false;
        } else {
          this.data.Recodes.FID8109.disabled = true;
        }
        if (data.Recodes.FID8101.value.includes("新規増車")) {
          this.data.Recodes.FID8116.disabled = false;
        } else {
          this.data.Recodes.FID8116.disabled = true;
        }
        if (data.Recodes.FID8101.value.includes("新規増車")) {
          this.data.Recodes.FID8110.disabled = false;
        } else {
          this.data.Recodes.FID8110.disabled = true;
        }
        if (
          data.Recodes.FID8101.value.includes("新規増車") ||
          data.Recodes.FID8101.value.includes("車番変更") ||
          data.Recodes.FID8101.value.includes("車検更新")
        ) {
          this.data.Recodes.FID8333.disabled = false;
        } else {
          this.data.Recodes.FID8333.disabled = true;
        }
        //マイカー添付書類
        if (
          data.Recodes.FID8333.value != "" &&
          typeof this.$store.state.params.FID8134 != "undefined" &&
          this.$store.state.params.FID8134.value == "マイカー添付書類確認者"
        ) {
          this.data.Recodes.FID8532.disabled = false;
        } else {
          this.data.Recodes.FID8532.disabled = true;
        }
        if (
          data.Recodes.FID8101.value.includes("新規増車") &&
          data.Recodes.FID8101.value != ""
        ) {
          this.data.Recodes.FID8534.disabled = false;
        } else {
          this.data.Recodes.FID8534.disabled = true;
        }
        if (
          data.Recodes.FID8101.value.includes("車検更新") ||
          data.Recodes.FID8101.value.includes("管理部門変更") ||
          data.Recodes.FID8101.value.includes("車番変更") ||
          data.Recodes.FID8101.value.includes("使用区分変更")
        ) {
          this.data.Recodes.FID9252.disabled = false;
        } else {
          this.data.Recodes.FID9252.disabled = true;
        }
        if (data.Recodes.FID8101.value.includes("減車(廃止)")) {
          this.data.Recodes.FID9253.disabled = false;
        } else {
          this.data.Recodes.FID9253.disabled = true;
        }
        if (this.$store.state.role == 1) {
          this.data.Recodes.FID8586.disabled = false;
        } else {
          this.data.Recodes.FID8586.disabled = true;
        }
      },
      deep: true,
    },

    FID8101json: function (value) {
      this.$set(
        this.data.Recodes.FID8101,
        "value",
        value.filter((item) => item !== "").join(",")
      );
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        if (this.$route.query.copyFlag) {
          this.reportCopy();
        } else if (this.$route.params.report_id != null) {
          this.data.ReportID = Number(this.$route.params.report_id);
          //データ取得
          let data = {
            PageID: this.data.PageID,
            ReportID: Number(this.data.ReportID),
          };
          const result = await api.ReportDetailV2(data);
          this.RecodeCopy(this.data, result.Recodes);
          //初期処理
          this.initFile8333();
        }
        //データ取得処理
        [this.selectBoxFID8321, this.selectBoxFID9238, this.selectBoxFID8322] =
          await Promise.all([
            getDivision(this.data),
            getCompanyCarSection(this.$store.state.params.FID7776.value),
            getCompanyCarDepartment(this.data),
          ]);

        //init処理
        this.initCheck8099();
        this.initCheck8100();
        await this.getUserInfoParams();
        this.initFID8103();
        this.initFID8104();
        this.initFID8101();
        this.initCheck8271();
        this.initCheck8534();
        this.initCheck9252();
        this.initCheck9253();

        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
        if (
          typeof response[FID] != "undefined" &&
          typeof response[FID].subReports != "undefined" &&
          response[FID].subReports != null
        ) {
          data.Recodes[FID].subReports = response[FID].subReports;
        }
      }
    },
    clickIcon8272() {
      this.data.Recodes.FID8272.value = this.data.Recodes.FID8007.value;
    },
    changeFile8333(file) {
      if (file !== undefined && file !== null) {
        this.uploadfile8333.file = file;
        this.uploadfile8333.data.size = this.uploadfile8333.file.size;
        this.uploadfile8333.data.type = this.uploadfile8333.file.type;
        this.uploadfile8333.data.form_id = 8333;
        this.data.Recodes.FID8333.file_type = this.uploadfile8333.file.type;
      }
    },
    async onBtn8532() {
      //処理を記載
      if (
        typeof this.data.Recodes.FID8333.url == "undefined" ||
        this.data.Recodes.FID8333.url == ""
      ) {
        this.data.Recodes.FID8333.url = await api.FileAccess(
          this.data.Recodes.FID8333
        );
      }
      const response = await api.FileDownload(this.data.Recodes.FID8333.url);
      let blob = new Blob([response.data]);
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = this.data.Recodes.FID8333.value;
      link.click();
    },
    async onBtn8534() {
      let check = await this.checkValidation();
      if (!check) {
        return;
      }
      //処理を記載
      this.data.Recodes.FID8534.value = "新規申請";
      this.data.Recodes.FID8534.disabled = true; //1回だけ
      this.data.Recodes.FID8505.value = this.data.Recodes.FID8534.value;
      await this.save8257(); //送信処理
    },
    getDateTime() {
      let date = new Date();
      let years = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let days = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let seconds = date.getSeconds().toString().padStart(2, "0");
      return (
        years +
        "-" +
        month +
        "-" +
        days +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    async save8257() {
      this.data.Recodes.FID8257.value = this.getDateTime();

      var duplicateCheck0 = await api.FormDetail(8007);
      duplicateCheck0 = duplicateCheck0.filter((v) => v !== "");
      const count0 = duplicateCheck0.reduce((acc, val) => {
        return val === this.data.Recodes.FID8007.value ? acc + 1 : acc;
      }, 0);
      if (
        (this.$route.params.report_id != null && count0 > 1) ||
        (this.$route.params.report_id == null && count0 > 0)
      ) {
        alert("既に同じ車両番号が入力されています");
        return;
      }
      //(N96)送信処理を追加
      if (!(await this.checkValidation())) {
        return;
      }
      this.escapeValidation = true;
      await this.send();
    },
    async onBtn9252() {
      let check = await this.checkValidation();
      if (!check) {
        return;
      }
      //処理を記載
      this.data.Recodes.FID9252.value = "更新";
      this.data.Recodes.FID9252.disabled = true; //1回だけ
      this.data.Recodes.FID8505.value = this.data.Recodes.FID9252.value;
      await this.save8257(); //送信処理
    },
    async onBtn9253() {
      let check = await this.checkValidation();
      if (!check) {
        return;
      }
      //処理を記載
      this.data.Recodes.FID9253.value = "削除";
      this.data.Recodes.FID9253.disabled = true; //1回だけ
      this.data.Recodes.FID8505.value = this.data.Recodes.FID9253.value;
      await this.save8257(); //送信処理
    },
    async save8533() {
      this.data.Recodes.FID8533.value = this.getDateTime();

      var duplicateCheck0 = await api.FormDetail(8007);
      duplicateCheck0 = duplicateCheck0.filter((v) => v !== "");
      const count0 = duplicateCheck0.reduce((acc, val) => {
        return val === this.data.Recodes.FID8007.value ? acc + 1 : acc;
      }, 0);
      if (
        (this.$route.params.report_id != null && count0 > 1) ||
        (this.$route.params.report_id == null && count0 > 0)
      ) {
        alert("既に同じ車両番号が入力されています");
        return;
      }
      await this.send();
    },
    initCheck8099() {
      if (typeof this.data.Recodes.FID8099 == "undefined") {
        this.data.Recodes.FID8099 = { value: "", formType: 3 };
      }
      if (this.data.Recodes.FID8099.value == "") {
        this.data.Recodes.FID8099.value = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date().getHours() + 9
        )
          .toISOString()
          .match(/^\d{4}-\d{2}-\d{2}/g)
          .pop();
      }
    },
    initCheck8100() {
      if (typeof this.data.Recodes.FID8100 == "undefined") {
        this.data.Recodes.FID8100 = { value: "", formType: 3 };
      }
      this.data.Recodes.FID8100.value = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        new Date().getHours() + 9
      )
        .toISOString()
        .match(/^\d{4}-\d{2}-\d{2}/g)
        .pop();
    },
    async getUserInfoParams() {
      if (
        !this.$store.state.params ||
        typeof this.$store.state.params != "object" ||
        !Object.keys(this.$store.state.params).length
      ) {
        const result = await api.EmployeeDetail();
        store.commit("setParams", result.Recodes);
      }
    },
    initFID8103() {
      if (this.$store.state.params?.FID7762?.value == undefined) {
        console.log(
          "ユーザ設定ページ追加フォーム取得の対象フォームIDが見つかりませんでした"
        );
      }
      if (this.data.Recodes.FID8103.value == "") {
        this.data.Recodes.FID8103.value =
          this.$store.state.params?.FID7762?.value || "";
      }
    },
    initFID8104() {
      if (this.data.Recodes.FID8104.value == "") {
        this.data.Recodes.FID8104.value = this.$store.state.user.username;
      }
    },
    initFID8101() {
      if (this.data.Recodes.FID8101.value != "") {
        this.FID8101json = this.data.Recodes.FID8101.value.split(",");
      }
    },
    initCheck8271() {
      if (typeof this.data.Recodes.FID8271 == "undefined") {
        this.data.Recodes.FID8271 = { value: "", formType: 3 };
      }
      if (this.data.Recodes.FID8271.value == "") {
        this.data.Recodes.FID8271.value = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date().getHours() + 9
        )
          .toISOString()
          .match(/^\d{4}-\d{2}-\d{2}/g)
          .pop();
      }
    },
    initCheck8534() {
      if (typeof this.data.Recodes.FID8534 == "undefined") {
        this.data.Recodes.FID8534 = { value: "", formType: 16 };
      }
      if (this.data.Recodes.FID8534.value != "") {
        this.data.Recodes.FID8534.disabled = true; //1回だけ
      }
    },
    initCheck9252() {
      if (typeof this.data.Recodes.FID9252 == "undefined") {
        this.data.Recodes.FID9252 = { value: "", formType: 16 };
      }
      if (this.data.Recodes.FID9252.value != "") {
        this.data.Recodes.FID9252.disabled = true; //1回だけ
      }
    },
    initCheck9253() {
      if (typeof this.data.Recodes.FID9253 == "undefined") {
        this.data.Recodes.FID9253 = { value: "", formType: 16 };
      }
      if (this.data.Recodes.FID9253.value != "") {
        this.data.Recodes.FID9253.disabled = true; //1回だけ
      }
    },
    initFile8333() {
      this.uploadfile8333.file = {};
    },
    async sendFile8333() {
      try {
        if (
          this.uploadfile8333.file !== undefined &&
          this.uploadfile8333.file !== null &&
          this.uploadfile8333.data.type != ""
        ) {
          this.data.Recodes.FID8333.value = await api.UploadFile(
            this.uploadfile8333.data,
            this.uploadfile8333.file
          );
        }
      } catch (e) {
        console.log(e);
        throw e;
      }
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }

      return check;
    },
    async send() {
      const result = this.escapeValidation
        ? true
        : await this.checkValidation();

      if (result) {
        this.isLoading = true;
        await this.sendFile8333();

        const save = await api.SendReport(this.data);
        if (save == false || save.result == "Fail") {
          alert("登録失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }

        if (this.sendCopyFlag) {
          this.sendCopy();
          return;
        }
        alert("登録完了");
        if (!this.noBack) {
          this.back();
        } else {
          this.$set(this.$route.params, "report_id", save.reportID);
          this.$set(this.data, "ReportID", save.reportID);
          await this.getInit();
          this.isLoading = false;
          this.noBack = false;
        }
      }
    },
    back() {
      store.commit("clearreports");
      if (this.copyFlag) {
        history.go(this.backCount);
      } else {
        history.back();
      }
    },
    async del() {
      this.isLoading = true;
      await api.DelReport(this.data);
      alert("削除完了");
      this.back();
    },
  },
};
</script>
<style></style>
