<template>
  <div class="menu">
    <v-list>
      <v-list-group
        v-for="item in menuItems"
        :key="item.title"
        v-model="item.active"
        :prepend-icon="item.icon"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title v-text="item.title"></v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          v-for="subItem in item.items"
          :key="subItem.text"
          :to="subItem.to"
        >
          <v-list-item-content>
            <v-list-item-title v-text="subItem.text"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "MainMenu",
  data: () => ({
    menuItems: [],
  }),
};
</script>