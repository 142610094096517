var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{attrs:{"height":_vm.resizeHeight,"width":_vm.resizeWidth}},[_c('v-card-actions',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-2",attrs:{"justify":"center","align-content":"center"}},[_c('v-subheader',{staticClass:"main-title"},[_vm._v("車両番号入力")])],1),_c('v-form',{ref:"form",staticClass:"ma-0 pa-0",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{staticClass:"ma-0 pa-0"},[_c('v-col',{attrs:{"cols":"8","lg":"4"}},[_c('v-subheader',{class:'mb-4 settings ' + _vm.$vuetify.breakpoint.name == 'lg'
                  ? 'text-h7'
                  : 'text-h8'},[_vm._v(" 地名区分 ")])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"4","lg":"6"}},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-combobox',_vm._g(_vm._b({attrs:{"label":"地名","type":"text","outlined":"","background-color":"gray","items":_vm.placeBox,"no-data-text":"該当なし","rules":[_vm.number_rules.input_rules],"hide-details":"true"},model:{value:(_vm.place),callback:function ($$v) {_vm.place=$$v},expression:"place"}},'v-combobox',attrs,false),on))]}}])},[_c('span',[_vm._v("選択肢に無い地区も入力できます")])])],1),(_vm.$vuetify.breakpoint.name == 'lg')?_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-8 ml-2"},'v-icon',attrs,false),on),[_vm._v("mdi-help ")])]}}],null,false,3040618987)},[_c('span',[_vm._v("選択肢に無い地区も入力できます")])]):_vm._e()],1),_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"8","lg":"4"}},[_c('v-subheader',{class:'mb-4 settings ' + _vm.$vuetify.breakpoint.name == 'lg'
                  ? 'text-h7'
                  : 'text-h8'},[_vm._v(" 分類番号 ")])],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"4","lg":"6"}},[_c('v-text-field',{attrs:{"label":"分類番号","type":"text","outlined":"","background-color":"gray","persistent-hint":false,"rules":[_vm.number_rules.input_rules, _vm.number_rules.hankakuEiSuu],"hide-details":"true"},model:{value:(_vm.Class),callback:function ($$v) {_vm.Class=$$v},expression:"Class"}})],1),(_vm.$vuetify.breakpoint.name == 'lg')?_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mb-8 ml-2"},'v-icon',attrs,false),on),[_vm._v("mdi-help ")])]}}],null,false,3040618987)},[_c('span',[_vm._v("半角入力です")])]):_vm._e()],1),(
            _vm.windowsType == 'xl' || _vm.windowsType == 'lg' || _vm.windowsType == 'md'
          )?_c('v-row',{staticClass:"ma-0"},[_c('v-col',{attrs:{"cols":"6","lg":"4"}},[_c('v-subheader',{class:'mb-4 settings ' + _vm.$vuetify.breakpoint.name == 'lg'
                  ? 'text-h7'
                  : 'text-h8'},[_vm._v(" 車両番号 ")])],1),_c('v-col',{attrs:{"cols":"6","lg":"8"}},[_c('v-row',[_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"0","lg":"3"}},[_c('v-text-field',{attrs:{"label":_vm.char == '' ? 'あ' : 'ひらがな',"type":"text","outlined":"","background-color":"gray","rules":[_vm.number_rules.input_rules, _vm.number_rules.hiragana],"hide-details":"true"},model:{value:(_vm.char),callback:function ($$v) {_vm.char=$$v},expression:"char"}})],1),_c('v-col',{staticClass:"pa-0 ml-6",attrs:{"cols":"5","lg":"5"}},[_c('v-text-field',{attrs:{"label":_vm.designNum1 == '' ? '1234' : '4桁',"type":"number","outlined":"","background-color":"gray","rules":[_vm.number_rules.input_rules, _vm.number_rules.number]},model:{value:(_vm.designNum1),callback:function ($$v) {_vm.designNum1=$$v},expression:"designNum1"}})],1)],1)],1)],1):_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-subheader',{class:'mb-4 settings ' + _vm.$vuetify.breakpoint.name == 'lg'
                  ? 'text-h7'
                  : 'text-h8'},[_vm._v(" 車体番号 ")])],1),_c('v-col',{staticClass:"ma-0 pa-0",attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"label":"ひらがな","type":"text","outlined":"","background-color":"gray","rules":[_vm.number_rules.input_rules, _vm.number_rules.hiragana],"hide-details":"true"},model:{value:(_vm.char),callback:function ($$v) {_vm.char=$$v},expression:"char"}})],1),_c('v-col',{staticClass:"pa-0 ml-2",attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"label":"12","type":"number","outlined":"","background-color":"gray","rules":[_vm.number_rules.input_rules, _vm.number_rules.number],"hide-details":"true"},model:{value:(_vm.designNum1),callback:function ($$v) {_vm.designNum1=$$v},expression:"designNum1"}})],1),_c('v-col',{staticClass:"ml-6 pa-0",attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"label":"34","type":"number","outlined":"","background-color":"gray","rules":[_vm.number_rules.input_rules, _vm.number_rules.number],"hide-details":"true"},model:{value:(_vm.designNum2),callback:function ($$v) {_vm.designNum2=$$v},expression:"designNum2"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"mb-1 mt-3 red--text settings",style:(_vm.cancelStyle),attrs:{"height":_vm.buttonHeihgt,"width":_vm.buttonWidth,"color":"blue-grey lighten-5"},on:{"click":_vm.sub2}},[_vm._v(" リセット ")]),_c('v-btn',{staticClass:"mb-1 mt-3 ml-7 white--text settings",style:(_vm.submitStyle),attrs:{"height":_vm.buttonHeihgt,"width":_vm.buttonWidth,"color":_vm.color},on:{"click":_vm.submit}},[_vm._v(" 確定 ")])],1),(_vm.error_message)?_c('v-row',{staticClass:"error_message ma-0",attrs:{"justify":"center"}},[_vm._v(" "+_vm._s(_vm.error_message)+" ")]):_vm._e()],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }