import Vue from "vue";
import App from "./App.vue";
import router from "./router/routerUse";
import store from "./store/vuex";
import axios from "axios";
import VueAxios from "vue-axios";
import vuetify from "./plugins/vuetify";
import Loading from "vue-loading-overlay"; //ローディング
import "@mdi/font/css/materialdesignicons.css"; // アイコン系
//---------------------------------------------------------------------------
//vueにこれを入れるだけで既存のユーザプールを使える
import { Amplify } from "aws-amplify";
import {
  applyPolyfills,
  defineCustomElements,
} from "@aws-amplify/ui-components/loader";

Amplify.configure({
  aws_cognito_region: "ap-northeast-1",
  aws_user_pools_id: process.env.VUE_APP_POOL_ID,
  aws_user_pools_web_client_id: process.env.VUE_APP_CLIENT,
  //
  aws_mandatory_sign_in: "enable",
  aws_user_pools_mfa_type: "OFF",
  //
});

//言語変換
import * as AmplifyModules from "aws-amplify";
import cognito_ja from "./cognito-ja";
let languageDict = cognito_ja;
AmplifyModules.I18n.putVocabularies(languageDict);

applyPolyfills().then(() => {
  defineCustomElements(window);
});
Vue.config.ignoredElements = [/amplify-\w*/];
//---------------------------------------------------------------------------
Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.component("loading", Loading);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
