<template>
  <div class="home">
    <p>組織図1</p>
    <v-row>
      <v-col align="right"><v-btn @click.once="back">戻る</v-btn></v-col>
    </v-row>
    <v-container cols="12">
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form" @submit.prevent>
          <v-row>
            <v-col cols="0">
              <v-text-field
                label="事業所名"
                v-model="data.Recodes.FID7977.value"
                :rules="[rules.required]"
                background-color="white"
                id="FID7977"
                class="textfield7977"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="0">
              <v-text-field
                label="事業所コード"
                v-model="data.Recodes.FID7978.value"
                :rules="[rules.required]"
                background-color="white"
                id="FID7978"
                class="textfield7978"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row
            v-if="
              typeof data.Recodes.FID7986.subReports != 'undefined' &&
              data.Recodes.FID7986.subReports.length > 0
            "
          >
            <v-col
              cols="12"
              v-for="(report, i) in data.Recodes.FID7986.subReports"
              :key="`[7986-${i}`"
            >
              <carapprovalSub1
                label=""
                background-color="white"
                :id="'FID7986-' + i"
                class="subPage7986"
                ref="subPage7986"
                :Report="report"
                :subindex="i"
                :daleteReports.sync="data.DeleteSubReports"
                v-on:clickSubmit="saveSubPage"
                :myInit="subInit"
                FID="FID7986"
                hide-details="auto"
              >
              </carapprovalSub1>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="left">
              <v-btn @click="subPageDel('FID7986')" color="red" class="ma-2"
                >削除</v-btn
              >
              <v-btn @click="subPageAdd('FID7986')" color="primary" class="ma-2"
                >追加</v-btn
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col align="left">
              <v-btn
                @click="deleteModal = true"
                :disabled="data.ReportID == null || disabled"
                color="red white--text"
                >削除
              </v-btn>
              <v-dialog v-model="deleteModal" max-width="400">
                <v-card>
                  <v-card-title class="text-h5"> 削除しますか？ </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mr-3"
                      color="green darken-1 white--text"
                      @click="deleteModal = false"
                      large
                    >
                      いいえ
                    </v-btn>
                    <v-btn color="red darken-1 white--text" @click="del" large>
                      はい
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col align="right">
              <v-btn @click="send" color="primary">送信</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-container>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");

import carapprovalSub1 from "../views/carapprovalSub1";

export default {
  name: "Home",
  components: {
    carapprovalSub1,
  },
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      data: {
        PageID: 1151,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID7977: { value: "", formType: 1, disabled: false },
          FID7978: { value: "", formType: 1, disabled: false },
          FID7986: {
            value: "PID1152",
            formType: 17,
            disabled: false,
            subReports: [],
          },
        },
        DeleteSubReports: [],
      },

      rules: {
        required: (value) => !!value || "必須項目です",
      },
      deleteModal: false,
      copyFlag: false,
      sendCopyFlag: false,
      noBack: false,
      backCount: -1,
    };
  },
  async created() {
    await this.getInit();
  },

  computed: {},
  watch: {
    $route() {
      this.getInit();
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        if (this.$route.query.copyFlag) {
          this.reportCopy();
        } else if (this.$route.params.report_id != null) {
          this.data.ReportID = Number(this.$route.params.report_id);
          //データ取得
          let data = {
            PageID: this.data.PageID,
            ReportID: Number(this.data.ReportID),
          };
          const result = await api.ReportDetailV2(data);
          this.RecodeCopy(this.data, result.Recodes);
          //初期処理
        }
        //データ取得処理

        //init処理

        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
        if (
          typeof response[FID] != "undefined" &&
          typeof response[FID].subReports != "undefined" &&
          response[FID].subReports != null
        ) {
          data.Recodes[FID].subReports = response[FID].subReports;
        }
      }
    },
    subPageAdd(FID, index) {
      this.isLoading = true;
      if (
        typeof this.data.Recodes[FID] == "undefined" ||
        typeof this.data.Recodes[FID].subReports == "undefined"
      ) {
        index = 0;
        this.data.Recodes[FID].subReports = [];
      }
      if (typeof index == "undefined") {
        index = this.data.Recodes[FID].subReports.length;
      }
      let max = 20;
      if (this.data.Recodes[FID].subReports.length < max) {
        this.data.Recodes[FID].subReports.splice(index, 0, {});
      }
      this.isLoading = false;
    },
    subPageDel(FID, index) {
      //this.subInit = false;
      let min = 0;
      if (typeof index == "undefined") {
        index = this.data.Recodes[FID].subReports.length - 1;
      }
      if (this.data.Recodes[FID].subReports.length > min) {
        this.data.DeleteSubReports = this.data.DeleteSubReports.concat(
          this.data.Recodes[FID].subReports.splice(index, 1)
        );
      }
      //this.subInit = true;
    },
    saveSubPage(FID, subindex, reports) {
      this.data.Recodes[FID].subReports.splice(subindex, 1, reports);
      if (reports.DeleteSubReports.length == 1) {
        this.data.DeleteSubReports = this.data.DeleteSubReports.concat(
          reports.DeleteSubReports.pop()
        );
      }
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }
      if (typeof this.$refs.subPage7986 != "undefined") {
        for (let i = 0; i < this.$refs.subPage7986.length; i++) {
          let tmp = await this.$refs.subPage7986[i].checkValidation();
          if (tmp == false) {
            check = false;
          }
        }
      }
      return check;
    },
    async send() {
      const result = this.escapeValidation
        ? true
        : await this.checkValidation();

      if (result) {
        this.isLoading = true;

        const save = await api.SendReport(this.data);
        if (save == false || save.result == "Fail") {
          alert("登録失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }
        console.log("SendPage1151");

        if (this.sendCopyFlag) {
          this.sendCopy();
          return;
        }
        alert("登録完了");
        if (!this.noBack) {
          this.back();
        } else {
          this.$set(this.$route.params, "report_id", save.reportID);
          this.$set(this.data, "ReportID", save.reportID);
          await this.getInit();
          this.isLoading = false;
          this.noBack = false;
        }
      }
    },
    back() {
      store.commit("clearreports");
      if (this.copyFlag) {
        history.go(this.backCount);
      } else {
        history.back();
      }
    },
    async del() {
      this.isLoading = true;
      await api.DelReport(this.data);
      alert("削除完了");
      this.back();
    },
  },
};
</script>
<style></style>
