<template>
  <v-card class="subPage" color="white">
    <div class="ma-1">
      <p>酒気帯び運転確認:業務後</p>
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="12" md="3" lg="3">
              <div
                label=""
                :disabled="allDisabled8539"
                background-color="white"
                id="FID8041"
                class="plainText8041 mt-4 text-body-1"
                hide-details="auto"
              >
                確認方法
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="9" lg="9">
              <div class="pa-1">
                <v-radio-group
                  label=""
                  v-model="data.Recodes.FID8042.value"
                  :disabled="allDisabled8539"
                  :rules="[rules.SendValidation8049No0]"
                  background-color="white"
                  id="FID8042"
                  class="radioGroup8042 pt-2 pl-3 pb-4 ma-0"
                  style="border-radius: 4px; border-color: #9f9f9f"
                  row
                  hide-details="auto"
                >
                  <v-radio
                    label="対面・検知器"
                    value="対面・検知器"
                    class="mt-1"
                  ></v-radio>
                  <v-radio
                    label="コールセンター"
                    value="コールセンター"
                    class="mt-1"
                  ></v-radio>
                  <v-radio
                    label="2台目以上のため不要"
                    value="2台目以上のため不要"
                    class="mt-1"
                  ></v-radio>
                  <v-radio
                    v-if="false"
                    label="対面その他"
                    value="対面その他"
                    class="mt-1"
                  ></v-radio>
                </v-radio-group>
              </div>
              {{
                data.Recodes.FID8042.value == "コールセンター"
                  ? "03-5418-8986へ電話をかけてください"
                  : ""
              }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="3" lg="3">
              <div
                label=""
                :disabled="allDisabled8539"
                background-color="white"
                id="FID8048"
                class="plainText8048 mt-4 text-body-1"
                hide-details="auto"
              >
                確認者氏名
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="3">
              <v-text-field
                label="確認者氏名"
                v-model="data.Recodes.FID8049.value"
                :disabled="
                  allDisabled8539 ||
                  data.Recodes.FID8042.value == 'コールセンター'
                "
                :rules="[rules.customValidation8049]"
                :background-color="
                  data.Recodes.FID8042.value == 'コールセンター'
                    ? 'blue-grey lighten-2'
                    : 'white'
                "
                id="FID8049"
                class="textfield8049"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="5" sm="5" md="3" lg="3">
              <div
                label=""
                :disabled="allDisabled8539"
                background-color="white"
                id="FID8388"
                class="plainText8388 mt-4 text-body-1"
                hide-details="auto"
              >
                事前チェック
              </div>
            </v-col>
            <v-col cols="7" sm="7" md="3" lg="3">
              <v-dialog
                v-model="data.Recodes.FID8389.modalDate"
                :max-width="resizeWidth"
              >
                <v-date-picker
                  v-model="data.Recodes.FID8389.date"
                  no-title
                  scrollable
                  locale="ja-jp"
                  :day-format="(date) => new Date(date).getDate()"
                  @input="data.Recodes.FID8389.modalDate = false"
                >
                </v-date-picker>
              </v-dialog>
              <v-text-field
                label="確認時間(手動)"
                :disabled="allDisabled8539"
                background-color="white"
                id="FID8389"
                class="textfield8389"
                outlined
                :value="DateTimeFID8389"
                readonly
                append-icon="mdi-clock-time-eight"
                @click="data.Recodes.FID8389.modalTime = true"
                @click:append="data.Recodes.FID8389.modalTime = true"
                prepend-icon="mdi-calendar"
                @click:prepend="data.Recodes.FID8389.modalDate = true"
                hide-details="auto"
              >
              </v-text-field>
              <v-dialog
                v-model="data.Recodes.FID8389.modalTime"
                :max-width="resizeWidth"
              >
                <input-Time
                  v-if="data.Recodes.FID8389.modalTime"
                  title="時刻選択"
                  v-model="data.Recodes.FID8389.time"
                  :rules="[]"
                  :minuteInterval="1"
                  v-on:ok="
                    (data.Recodes.FID8389.modalTime = false),
                      $set(data.Recodes.FID8389, 'time', $event)
                  "
                  type="calculator"
                ></input-Time>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="3" lg="3">
              <div
                label=""
                :disabled="allDisabled8539"
                background-color="white"
                id="FID8043"
                class="plainText8043 mt-4 text-body-1"
                hide-details="auto"
              >
                酒気帯び
              </div>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-btn
                label="無"
                :disabled="data.Recodes.FID8254.disabled || allDisabled8539"
                background-color="white"
                id="FID8254"
                class="button8254"
                @click="onBtn8254"
                color="blue white--text"
                block
                style="font-size: 20px; letter-spacing: 10px"
                height="50"
                hide-details="auto"
              >
                <v-icon v-if="data.Recodes.FID8254.value != ''">
                  mdi-check-bold
                </v-icon>
                無
              </v-btn>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-btn
                label="有"
                :disabled="data.Recodes.FID8044.disabled || allDisabled8539"
                background-color="white"
                id="FID8044"
                class="button8044"
                @click="vtnModal8044 = true"
                color="red white--text"
                block
                style="font-size: 20px; letter-spacing: 10px"
                height="50"
                hide-details="auto"
              >
                <v-icon v-if="data.Recodes.FID8044.value != ''">
                  mdi-check-bold
                </v-icon>
                有
              </v-btn>
              <v-dialog v-model="vtnModal8044" max-width="400">
                <v-card>
                  <v-card-title class="text-h5">
                    酒気帯び有で間違いありませんか？
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mr-3"
                      color="green darken-1 white--text"
                      @click="vtnModal8044 = false"
                      large
                    >
                      いいえ
                    </v-btn>
                    <v-btn
                      color="red darken-1 white--text"
                      @click="onBtn8044(), (vtnModal8044 = false)"
                      large
                    >
                      はい
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="3">
              <v-text-field
                label="確認時間"
                v-model="data.Recodes.FID8045.value"
                :disabled="data.Recodes.FID8045.disabled || allDisabled8539"
                :rules="[rules.required]"
                background-color="white"
                id="FID8045"
                class="textfield8045"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="0">
              <div
                label=""
                :disabled="allDisabled8539"
                background-color="white"
                id="FID8387"
                class=""
                hide-details="auto"
              ></div>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-card>
</template>

<script>
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");

import inputTime from "../views/inputTime";

export default {
  name: "Home",
  components: {
    inputTime,
  },
  props: {
    //値
    Report: Object,
    subindex: Number,
    parentData: {
      type: Object,
      default: () => {},
      required: false,
    },
    myInit: Boolean,
    FID: String,
    allDisabled8539: { type: Boolean },
  },
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      data: {
        PageID: 1163,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID8042: { value: "", formType: 7, disabled: false },
          FID8049: { value: "", formType: 1, disabled: false },
          FID8389: {
            value: "",
            formType: 1,
            disabled: false,
            date: "",
            time: "",
            modalDate: false,
            modalTime: false,
          },
          FID8254: { value: "", formType: 16, disabled: false },
          FID8044: { value: "", formType: 16, disabled: false },
          FID8045: { value: "", formType: 1, disabled: true },
        },
        DeleteSubReports: [],
      },
      validationFlag8049: true,
      vtnModal8044: false,

      rules: {
        required: (value) => !!value || "必須項目です",
        SendValidation8049No0: (value) => {
          if (!this.validationFlag8049) {
            return true;
          }
          if (value != "対面その他") {
            this.validationFlag8049 = false;
            return "対面その他のみ";
          }
          return true;
        },
        customValidation8049: (value) => {
          if (
            typeof this.data.Recodes.FID8042 != "undefined" &&
            this.data.Recodes.FID8042.value == "対面その他"
          ) {
            return this.rules.required(value);
          }
          return true;
        },
      },
      copyFlag: false,
      noBack: false,
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {
    resizeWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "270px";
        case "sm":
          return "500px";
        case "md":
          return "600px";
        case "lg":
          return "500px";
        case "xl":
          return "500px";
        default:
          return "500px";
      }
    },
    DateTimeFID8389() {
      let date = this.data.Recodes.FID8389.date;
      let time = this.data.Recodes.FID8389.time;

      if (date != "" || time != "") {
        if (date == "") {
          date = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            new Date().getHours() + 9
          )
            .toISOString()
            .match(/^\d{4}-\d{2}-\d{2}/g)
            .pop();
        } else if (time == "") {
          const japanStandardTime = new Date().toLocaleString({
            timeZone: "Asia/Tokyo",
          });
          time =
            new Date(japanStandardTime).getHours() +
            ":" +
            new Date(japanStandardTime).getMinutes();
        }
      }
      this.$set(this.data.Recodes.FID8389, "value", date + " " + time);
      return date + " " + time;
    },
  },
  watch: {
    $route() {
      this.getInit();
    },
    data: {
      handler: async function (data) {
        //
        if (
          data.Recodes.FID8042.value == "" ||
          data.Recodes.FID8254.value != ""
        ) {
          this.data.Recodes.FID8254.disabled = true;
        } else {
          this.data.Recodes.FID8254.disabled = false;
        }
        if (
          data.Recodes.FID8042.value == "" ||
          data.Recodes.FID8254.value != ""
        ) {
          this.data.Recodes.FID8044.disabled = true;
        } else {
          this.data.Recodes.FID8044.disabled = false;
        }

        //
        this.$emit("clickSubmit", this.FID, this.subindex, data);
      },
      deep: true,
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        //データが存在する場合
        if (this.$route.query.copyFlag) {
          this.reportCopy();
        } else if (this.Report.ReportID != null || this.myInit) {
          //サブレポートの値代入
          this.data.ReportID = this.Report.ReportID;
          this.data.Recodes = api.OptionCopy(
            this.data.Recodes,
            this.Report.Recodes
          );
        } else {
          //初期値設定
        }
        //データ取得処理

        //init処理
        this.initCheck8389();
        this.initCheck8254();
        this.initCheck8044();

        //初回データ反映
        this.$emit("clickSubmit", this.FID, this.subindex, this.data);
        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
      }
    },
    send() {},
    async onBtn8254() {
      //処理を記載
      const date = this.getDateTime();
      this.data.Recodes.FID8254.value = date; //tag:日付+時間
      this.data.Recodes.FID8254.disabled = true; //1回だけ
      this.data.Recodes.FID8045.value = this.data.Recodes.FID8254.value;
      const val = this.data.Recodes.FID8254.value.split(" ");
      this.data.Recodes.FID8045.date = val[0];
      this.data.Recodes.FID8045.time = val[1];
      if (typeof this.data.Recodes.FID8254 == "undefined") {
        this.data.Recodes.FID8254 = { value: "", formType: 16 };
      }
      this.data.Recodes.FID8044.disabled = true;
      this.$emit("save8538", this.subindex, this.data);
    },
    getDateTime() {
      let date = new Date();
      let years = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let days = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let seconds = date.getSeconds().toString().padStart(2, "0");
      return (
        years +
        "-" +
        month +
        "-" +
        days +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    async onBtn8044() {
      //処理を記載
      const date = this.getDateTime();
      this.data.Recodes.FID8044.value = date; //tag:日付+時間
      this.data.Recodes.FID8045.value = this.data.Recodes.FID8044.value;
      const val = this.data.Recodes.FID8044.value.split(" ");
      this.data.Recodes.FID8045.date = val[0];
      this.data.Recodes.FID8045.time = val[1];
      if (typeof this.data.Recodes.FID8044 == "undefined") {
        this.data.Recodes.FID8044 = { value: "", formType: 16 };
      }
      this.data.Recodes.FID8254.disabled = true;
      this.$emit("save8538", this.subindex, this.data);
    },
    initCheck8389() {
      if (typeof this.data.Recodes.FID8389 == "undefined") {
        this.data.Recodes.FID8389 = { value: "", formType: 1 };
      }
      if (this.data.Recodes.FID8389.value != "") {
        //tag:日付+時間
        const val = this.data.Recodes.FID8389.value.split(" ");
        this.data.Recodes.FID8389.date = val[0];
        this.data.Recodes.FID8389.time = val[1];
      }
    },
    initCheck8254() {
      if (typeof this.data.Recodes.FID8254 == "undefined") {
        this.data.Recodes.FID8254 = { value: "", formType: 16 };
      }
      if (this.data.Recodes.FID8254.value != "") {
        this.data.Recodes.FID8254.disabled = true; //1回だけ
      }
      if (this.data.Recodes.FID8044.value != "") {
        if (typeof this.data.Recodes.FID8044 == "undefined") {
          this.data.Recodes.FID8044 = { value: "", formType: 16 };
        }
        this.data.Recodes.FID8254.disabled = true;
      }
    },
    initCheck8044() {
      if (this.data.Recodes.FID8254.value != "") {
        if (typeof this.data.Recodes.FID8254 == "undefined") {
          this.data.Recodes.FID8254 = { value: "", formType: 16 };
        }
        this.data.Recodes.FID8044.disabled = true;
      }
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (this.escapeValidation) {
        return true;
      }
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }
      return check;
    },
    /*
    async send() {
      console.log("subReport Send");
       //ファイルアップロード
      //
    },
    */
    async del() {
      await api.DelReport(this.data);
      alert("削除完了");
      this.back();
    },
  },
};
</script>
<style></style>
