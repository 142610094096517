<template>
  <v-card class="subPage" color="white">
    <div class="ma-1">
      <p></p>
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form">
          <v-row>
            <v-col cols="0">
              <v-text-field
                label=" 承認/棄却"
                v-model="data.Recodes.FID8578.value"
                :disabled="data.Recodes.FID8578.disabled"
                background-color="white"
                id="FID8578"
                class="textfield8578"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="0">
              <v-text-field
                label="承認日時"
                v-model="data.Recodes.FID8488.value"
                :disabled="data.Recodes.FID8488.disabled"
                background-color="white"
                id="FID8488"
                class="textfield8488"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="0">
              <v-text-field
                label="承認者名"
                v-model="data.Recodes.FID8498.value"
                :disabled="data.Recodes.FID8498.disabled"
                background-color="white"
                id="FID8498"
                class="textfield8498"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="0">
              <v-text-field
                label="承認者(社員番号)"
                v-model="data.Recodes.FID8489.value"
                :disabled="data.Recodes.FID8489.disabled"
                background-color="white"
                id="FID8489"
                class="textfield8489"
                outlined
                readonly
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-card>
</template>

<script>
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");

export default {
  name: "Home",
  components: {},
  props: {
    //値
    Report: Object,
    subindex: Number,
    parentData: {
      type: Object,
      default: () => {},
      required: false,
    },
    myInit: Boolean,
    FID: String,
  },
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      data: {
        PageID: 1213,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID8578: { value: "", formType: 1, disabled: true },
          FID8488: { value: "", formType: 1, disabled: true },
          FID8498: { value: "", formType: 1, disabled: true },
          FID8489: { value: "", formType: 1, disabled: true },
        },
        DeleteSubReports: [],
      },

      rules: {},
      copyFlag: false,
      noBack: false,
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {},
  watch: {
    $route() {
      this.getInit();
    },
    data: {
      handler: async function (data) {
        //

        //
        this.$emit("clickSubmit", this.FID, this.subindex, data);
      },
      deep: true,
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        //データが存在する場合
        if (this.$route.query.copyFlag) {
          this.reportCopy();
        } else if (this.Report.ReportID != null || this.myInit) {
          //サブレポートの値代入
          this.data.ReportID = this.Report.ReportID;
          this.data.Recodes = api.OptionCopy(
            this.data.Recodes,
            this.Report.Recodes
          );
        } else {
          //初期値設定
        }
        //データ取得処理

        //init処理

        //初回データ反映
        this.$emit("clickSubmit", this.FID, this.subindex, this.data);
        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
      }
    },
    async send() {
      const result = this.escapeValidation
        ? true
        : await this.checkValidation();
      if (result) {
        console.log("SendPage1213");
      }
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (this.escapeValidation) {
        return true;
      }
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }
      return check;
    },
    async del() {
      await api.DelReport(this.data);
      alert("削除完了");
      this.back();
    },
  },
};
</script>
<style></style>
