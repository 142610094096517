import Vue from "vue";
import Vuex from "vuex";

import { RepositoryFactory } from "../api/RepositoryFactory";
const eneosCar = RepositoryFactory.get("eneosCar");

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    auth: false,
    user: null,
    credential: "",
    cre: [],
    name: "",
    accessToken: "",
    role: 0,
    params: [],
    backPath: "/",
    backPath2: "/",
    //
    keyWord: {},
    reports: {},
    pageNum: 1,
    //
    tableSort: {
      sortBy: null,
      sortDesc: null,
    },
    //追加部分
    IPauth: false,
    CarApproval: false,
    EmailVerification: false,
    organizationInit: false,
    organization: {
      code: "name",
    },
    carApprovalMove: true,
  },
  mutations: {
    async setDepartmentAll(state) {
      if (state.role == 1) {
        const response = await eneosCar.getDepartmentAll();
        response.map((data) => {
          state.organization[data.value] = data.label;
        });
      } else {
        //事業部の取得
        const div = await eneosCar.getDivision();
        await div.map(async (d) => {
          state.organization[d.value] = d.label;
        });
        //
        let response = await eneosCar.getOrganizationChart();
        state.organization[response.value] = response.label;
        for (let i = 0; i < response.group.length; i++) {
          let value = response.group[i].value;
          let label = response.group[i].label;
          state.organization[value] = label;
          //
          if (response.group[i].shop != null) {
            for (let j = 0; j < response.group[i].shop.length; j++) {
              let shop = response.group[i].shop[j];
              let value2 = shop.value;
              let label2 = shop.label;
              state.organization[value2] = label2;
            }
          }
        }
      }
      return;
    },
    async getDepartmentName(state, code) {
      if (typeof code == "undefined" || code == null || code == "") {
        return "";
      }
      if (typeof state.organization[code] != "undefined") {
        return state.organization[code];
      }
      let response = await eneosCar.getDepartmentOnDivision(code);
      if (response.length == 1) {
        state.organization[code] = response[0].label;
      } else {
        state.organization[code] = "エラー";
      }
      return "未取得";
    },
    setIPauth(state, bool) {
      state.IPauth = bool;
    },
    setEmailVerification(state, bool) {
      state.EmailVerification = bool;
    },
    setCarApproval(state, bool) {
      state.CarApproval = bool;
    },
    setAuth(state, auth) {
      state.auth = auth;
    },
    setRole(state, role) {
      state.role = Number(role);
    },
    setParams(state, params) {
      state.params = params;
    },
    setUser(state, user) {
      state.user = user;
      if (user) {
        state.name = user.username;
      } else {
        state.name = null;
      }
    },
    setBackpath(state, path) {
      state.backPath = path;
    },
    setBackpath2(state, path) {
      state.backPath2 = path;
    },
    setCredential(state, id) {
      state.credential = id;
    },
    setAccessToken(state, data) {
      state.accessToken = data;
    },
    setPageNum(state, pageNum) {
      state.pageNum = pageNum;
    },
    setkeyWord(state, obj) {
      state.keyWord = obj;
    },
    setTableSort(state, obj) {
      state.tableSort.sortBy = obj.sortBy;
      state.tableSort.sortDesc = obj.sortDesc;
    },
    setTableSortBy(state, obj) {
      state.tableSort.sortBy = obj;
    },
    setTableSortDesc(state, obj) {
      state.tableSort.sortDesc = obj;
    },
    setreports(state, obj) {
      state.reports = obj;
    },
    setCarApprovalMove(state, bool) {
      state.carApprovalMove = bool;
    },
    clearkeyWord(state) {
      state.keyWord = {};
    },
    clearreports(state) {
      state.reports = {};
    },
    clearPageNum(state) {
      state.pageNum = 1;
    },
    clearTableSort(state) {
      state.tableSort.sortBy = null;
      state.tableSort.sortDesc = null;
    },
    logOut(state) {
      state.IPauth = false;
      state.CarApproval = false;
      state.setEmailVerification = false;
      state.auth = false;
      state.role = 0;
      state.name = null;
      state.credential = null;
      state.cre = null;
      state.params = [];
      state.accessToken = null;
      state.keyWord = {};
      state.pageNum = 1;
      state.tableSort.sortBy = null;
      state.tableSort.sortDesc = null;
      state.carApprovalMove = true;
    },
  },
});
