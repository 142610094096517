<template>
  <div class="home">
    <p>社有車一括取り込みページ</p>
    <v-col align="right"><v-btn @click.once="back">戻る</v-btn></v-col>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-container cols="12">
        <v-form ref="form">
          <v-row>
            <v-col cols="2">
              <v-checkbox
                v-model="header_flag"
                label="ヘッダー有"
                disabled
              ></v-checkbox>
            </v-col>
            <v-col cols="3">
              <v-checkbox
                v-model="data.UpdateType"
                label="データの上書き許可"
                vale
                :disabled="uploadfile.file != null"
                true-value="update"
                false-value="saveOldValue"
              ></v-checkbox>
            </v-col>
            <v-col cols="5" class="mt-5">
              自動車番号,管理部門,部署が同じなら同一車両とみなします
            </v-col>
          </v-row>
          <v-row>
            <v-file-input
              v-model="uploadfile.file"
              type="file"
              label="エクセル取り込み(xlsx)"
              prepend-icon="mdi-image"
              ref="rfafile"
              accept=".xlsx,.xlsm"
              @change="chargeExcel"
              :rules="[rules.fileSize]"
              show-size
            ></v-file-input
          ></v-row>
          <v-row>
            <v-col align="left">
              <v-btn @click="downloadSampleExcel" color="green white--text">
                取り込み用サンプルエクセル
              </v-btn>
            </v-col>
            <v-col align="right">
              <v-btn @click="send" color="primary" :disabled="!sendFlag"
                >送信</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="">
              <v-data-table
                :headers="headers"
                :items="reports"
                :item-key="reports.ID"
                :header-props="{
                  'sort-by-text': '並び順',
                }"
                :footer-props="{
                  'items-per-page-text': '行/ページ:',
                }"
                no-data-text="データが存在しません"
                :items-per-page="-1"
              >
                <template v-slot:item.Recodes.FID8321.value="{ item }">
                  {{ $store.state.organization[item.Recodes.FID8321.value] }}
                </template>
                <template v-slot:item.Recodes.FID9238.value="{ item }">
                  {{ $store.state.organization[item.Recodes.FID9238.value] }}
                </template>
                <template v-slot:item.Recodes.FID8322.value="{ item }">
                  {{ $store.state.organization[item.Recodes.FID8322.value] }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>

    <!-- <loading :active.sync="isLoading" :is-full-page="fullPage"></loading> -->
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
const localDownload = RepositoryFactory.get("localDownload");
import XLSX from "xlsx";
import { importCompanyCar } from "./specialMethod";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      isLoading: false,
      fullPage: false,
      headerText: ",", //`$$headerText`,
      valid: true,
      data: {
        PageID: 1158, //
        Page_id: 1158, //送信に使っているが本来は変換する
        Report_id: null, //
        UpdateType: "update",
        //KeyID: [8007, 8321, 8322],
        KeyID: [8007, 8322],
        KeyMap: {
          FID8007: 0,
          FID8321: 1,
          FID8322: 2,
        },
        Recodes: {
          FID8099: { value: "", formType: 3 },
          FID8100: { value: "", formType: 3 },
          FID8103: { value: "", formType: 18 },
          FID8104: { value: "", formType: 18 },
          FID8321: { value: "", formType: 2 }, //管理部門
          FID9238: { value: "", formType: 2 }, //管理部門
          FID8322: { value: "", formType: 2 }, //部署
          FID8101: { value: "新規増車", formType: 14 },
          FID8271: { value: "", formType: 3 },
          FID8007: { value: "", formType: 1 },
          FID8113: { value: "", formType: 1 },
          FID8272: { value: "", formType: 1 },
          FID8613: { value: "", formType: 2 },
          FID8318: { value: "", formType: 1 },
          FID8319: { value: "", formType: 1 },
          FID8111: { value: "", formType: 1 },
          FID8112: { value: "", formType: 1 },
          FID8114: { value: "", formType: 1 },
          FID8108: { value: "", formType: 2 },
          FID8109: { value: "", formType: 2 },
          FID8116: { value: "", formType: 1 },
          FID8110: { value: "0", formType: 1 },
          FID8333: { value: "", formType: 9 },
          FID8532: { value: "", formType: 16 },
          FID8534: { value: "", formType: 16 },
          FID8257: { value: "", formType: 20 },
          FID8505: { value: "新規申請", formType: 1 },
          FID8586: { value: "", formType: 22 },
          FID8533: { value: "", formType: 20 },
        },
      },

      //
      sendFlag: false,
      uploadfile: { file: {}, url: "", data: { name: "", size: 0, type: "" } },
      header_flag: true,
      reports: [],
      rules: {
        fileSize: (value) => {
          return (
            value == null ||
            value.size < 15000000 ||
            "ファイルサイズを15MB未満にしてください"
          );
        },
      },
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {
    headers() {
      return [
        { text: "初回登録日", value: "Recodes.FID8099.value", width: "120px" },
        { text: "最終更新日", value: "Recodes.FID8100.value", width: "120px" },
        { text: "管理本部", value: "Recodes.FID8321.value", width: "120px" },
        { text: "管理部門", value: "Recodes.FID9238.value", width: "120px" },
        { text: "部署", value: "Recodes.FID8322.value", width: "120px" },
        { text: "車両番号", value: "Recodes.FID8007.value", width: "120px" },
        { text: "車体番号", value: "Recodes.FID8113.value", width: "120px" },
        { text: "使用区分", value: "Recodes.FID8613.value", width: "120px" },
        {
          text: "種別",
          value: "Recodes.FID8108.value",
          width: "80px",
        },
        {
          text: "用途",
          value: "Recodes.FID8109.value",
          width: "80px",
        },
        { text: "最大積載量", value: "Recodes.FID8110.value", width: "120px" },
        { text: "車台番号", value: "Recodes.FID8113.value", width: "120px" },
        { text: "車名", value: "Recodes.FID8111.value", width: "100px" },
        { text: "車種名", value: "Recodes.FID8112.value", width: "120px" },

        {
          text: "初年度登録月",
          value: "Recodes.FID8318.value",
          width: "120px",
        },
        {
          text: "有効期限の満了する日",
          value: "Recodes.FID8319.value",
          width: "120px",
        },
      ];
    },
  },
  methods: {
    async getInit() {
      //初期処理
      this.isLoading = true;
      //読み込み終了
      this.isLoading = false;
    },
    /*-----------------------------*/
    //文字コードのチェック
    charCodeConvert(file) {
      const Encoding = require("encoding-japanese");
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          //文字列をバイト列に変換
          var sjisArray = new Uint8Array(reader.result);
          const encoding = this.detectEncoding(reader.result);
          switch (encoding) {
            case "SJIS":
              var unicodeArray = Encoding.convert(sjisArray, {
                to: "UNICODE",
                from: "SJIS",
                type: "string",
              });
              resolve(unicodeArray);
              break;
            case "UTF8":
              var unicodeArray2 = Encoding.convert(sjisArray, {
                to: "UNICODE",
                from: "AUTO",
                type: "string",
              });
              resolve(unicodeArray2);
              break;
            default:
              alert("文字コードが対応していません:" + encoding);
          }
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    },
    detectEncoding(buffer) {
      const uint8Array = new Uint8Array(buffer);
      if (
        uint8Array[0] === 0xef &&
        uint8Array[1] === 0xbb &&
        uint8Array[2] === 0xbf
      ) {
        return "UTF8";
      } else if (uint8Array[0] === 0xfe && uint8Array[1] === 0xff) {
        return "UTF16BE";
      } else if (uint8Array[0] === 0xff && uint8Array[1] === 0xfe) {
        return "UTF16LE";
      } else if (
        uint8Array[0] === 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0xfe &&
        uint8Array[3] === 0xff
      ) {
        return "UTF32BE";
      } else if (
        uint8Array[0] === 0xff &&
        uint8Array[1] === 0xfe &&
        uint8Array[2] === 0 &&
        uint8Array[3] === 0
      ) {
        return "UTF32LE";
      } else if (
        uint8Array[0] === 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0 &&
        uint8Array[3] !== 0
      ) {
        return "UTF32BE-noBOM";
      } else if (
        uint8Array[0] !== 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0 &&
        uint8Array[3] === 0
      ) {
        return "UTF32LE-noBOM";
      } else {
        return "SJIS";
      }
    },
    /*ダブルクォーテーション内のカンマは分割しない*/
    csvSplit(line) {
      let c = "";
      let s = new String();
      let data = new Array();
      let singleQuoteFlg = false;

      for (let i = 0; i < line.length; i++) {
        c = line.charAt(i);
        if (c == "," && !singleQuoteFlg) {
          data.push(s.toString());
          s = "";
        } else if (c == "," && singleQuoteFlg) {
          s = s + c;
        } else if (c == '"') {
          singleQuoteFlg = !singleQuoteFlg;
        } else {
          s = s + c;
        }
      }
      //最後のデータを追加
      data.push(s.toString());
      return data;
    },
    //現在のレポートとCSVの主キーが同じか確認する
    sameKeyCheck(data, csv) {
      let flag = true;
      Object.keys(data.KeyMap).map((key) => {
        const x = key;
        const y = data.KeyMap[key];
        if (data.Recodes[x].value == "" || csv[y] == "") {
          flag = false;
        }
        if (data.Recodes[x].value != csv[y]) {
          flag = false;
        }
      });
      return flag;
    },
    setValue(report, csv, to, from, tag) {
      if (typeof tag == "undefined") {
        if (typeof csv[from] != "undefined" && csv[from] != "") {
          report.Recodes[to].value = String(csv[from]).trim();
        }
        return;
      } else {
        switch (tag) {
          case "add":
            report.Recodes[to].value += String(csv[from]).trim();
            break;
        }
      }
    },
    async chargeExcel(event) {
      this.isLoading = true;
      try {
        const file = event;
        this.isLoading = true;
        if (file) {
          const reader = new FileReader();
          reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]]; //シート番号
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            //先頭削除
            jsonData.shift();
            //データ処理
            await store.commit("setDepartmentAll");
            this.reports = await importCompanyCar(this.data, jsonData);
            this.sendFlag = true;
            //バリデーションチェック
            this.isLoading = false;
            await this.$nextTick();
            await this.checkValidation();
          };
          reader.readAsArrayBuffer(file);
        }
        this.isLoading = false;
      } catch (e) {
        this.reports = [];
        this.isLoading = false;
        console.log("chargeExcel", e);
      }
    },
    async send() {
      const isCorrectValue = this.checkValidation();
      if (isCorrectValue == false) return;
      if (this.reports.length >= 10000) {
        alert("取り込み件数が上限10000件を超えています");
        return;
      }
      this.isLoading = true;
      const result = await api.ImportCSV(this.reports);
      if (result == undefined || result.result != "Success") {
        alert("データが登録できませんでした。");
      } else {
        alert("登録完了");
      }

      this.isLoading = false;
      history.back();
    },
    checkValidation: function () {
      let form = this.$refs.form;
      let check = form.validate();

      return check;
    },
    async downloadSampleExcel() {
      await localDownload.companyCarExcel();
    },
    back() {
      history.back();
    },
  },
};
</script>
