//import getip from './useGetIP_API'   //アクセスするAPI
import seisei_back from "./useGoapiV2";
import eneosCar from "./eneosCar";
import localDownload from "./localDownload";

const repositories = {
  //ここにリポジトリのリストを格納
  seisei_back: seisei_back,
  eneosCar: eneosCar,
  localDownload: localDownload,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};

export default RepositoryFactory;
