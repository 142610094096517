<template>
  <v-app class="base">
    <!-- ヘッダー-->
    <v-app-bar app :color="headerColor" clipped-left dark>
      <v-container fluid :class="`d-flex justify-space-between`">
        <div></div>
        <!-- スマホ画面--->
        <div
          text-align="left"
          class="white--text d-flex d-sm-none d-md-none"
          style="font-size: 10.5pt"
        >
          {{ title }} <br />カカナイ{{ version }}
        </div>
        <!-- タブレット画面--->
        <div
          text-align="left"
          class="white--text d-none d-sm-flex d-md-none"
          style="font-size: 14pt"
        >
          {{ title }}<br />{{ version }}
        </div>
        <!-- ブラウザ画面 -->
        <div
          text-align="left"
          class="white--text d-none d-md-flex d-md-none"
          style="font-size: 18pt"
        >
          {{ title }}{{ version }}
        </div>
        <!-- スマホ画面--->
        <div
          v-if="$store.state.auth"
          @click="drawer = false"
          class="white--text d-flex d-sm-none d-md-none"
          style="font-size: 10.5pt"
        >
          <div></div>
          <v-btn @click="signOut" v-if="signOutView">サインアウト</v-btn>
        </div>
        <!-- タブレット画面--->
        <div
          v-if="$store.state.auth"
          @click="drawer = false"
          class="white--text d-none d-sm-flex d-md-none"
          style="font-size: 14pt"
        >
          <div></div>
          <v-btn @click="signOut" v-if="signOutView">サインアウト</v-btn>
        </div>
        <!-- ブラウザ画面--->
        <div
          v-if="$store.state.auth"
          @click="drawer = false"
          class="white--text d-none d-md-flex d-md-none"
          style="font-size: 18pt"
        >
          <div></div>
          <v-btn @click="signOut" v-if="signOutView">サインアウト</v-btn>
        </div>
        <div v-else>
          <v-btn
            @click="menteClose"
            v-if="maintenance && pass == 'eneos'"
            color="orange"
            >メンテナンス解除</v-btn
          >
        </div>
      </v-container>
    </v-app-bar>
    <!-- ボディ -->
    <div>
      <v-main class="home2" v-if="!maintenance">
        <!-- <v-navigation-drawer app clipped v-model="drawer"> -->
        <MainMenu />
        <!-- </v-navigation-drawer> -->
        <v-container fluid>
          <router-view />
          <!-- 更新画面 -->
          <v-dialog
            v-if="isChanged"
            v-model="isChanged"
            max-width="400"
            max-height="600"
          >
            <reload-Dialog
              ref="reloadDialog"
              v-on:click-Submit="modalClose"
            ></reload-Dialog>
          </v-dialog>
        </v-container>
      </v-main>
      <v-main class="home2" v-else>
        <maintenancePage> </maintenancePage>
      </v-main>
    </div>
    <div>
      <!--フッター
      <v-footer class="base"> aaaa</v-footer>
      -->
    </div>
  </v-app>
</template>

<script>
import store from "./store/vuex.js";
import MainMenu from "./components/MainMenu";
import reloadDialog from "./components/Reload/reloadDialog";
import maintenancePage from "./views/maintenance/maintenance";
import { Auth } from "aws-amplify";
export default {
  name: "App",
  components: {
    MainMenu,
    reloadDialog,
    maintenancePage,
  },
  data: () => ({
    title: "EF新車両管理システム",
    version: "",
    drawer: false,
    isChanged: false,
    maintenanceFlag: false,
    headerColor: "#FFA500",
    //
    pass: "",
  }),
  mounted() {
    if (!this.$store.state.auth) {
      this.drawer = false;
    }
    this.init();
    document.addEventListener("keydown", this.onKeyDown);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
  computed: {
    signOutView() {
      //ログインコンポーネントか未ログインの時は表示しない
      if (this.$route.path == "/login" || !this.$store.state.auth) {
        return false;
      }
      //
      return true;
    },
    maintenance() {
      if (this.maintenanceFlag && !this.$store.state.auth) {
        return true;
      }
      return false;
    },
  },
  methods: {
    changeCarApproval() {
      store.commit("setCarApproval", !this.$store.state.CarApproval);
    },
    init() {
      let url = window.location.href.split("/");
      if (process.env.NODE_ENV == "development") {
        this.title += " (開発)";
        this.headerColor = "#FF6347";
        if (
          process.env.VUE_APP_SEISEI_URL == "https://dev-api.e-car-control.com"
        ) {
          this.title += " -トライアル向き";
        }
      }
      //
      switch (url[2]) {
        case "demo.service.kakanai.com": //デモ環境
          this.title += " (デモ)";
          this.headerColor = "#f5deb3";
          this.maintenance = false; //メンテナンスモード
          break;
        case "dev.e-car-control.com": //トライアル環境
          this.title += " (trial)";
          this.maintenanceFlag = true; //メンテナンスモード
          break;
        case "e-car-control.com": //本番環境
          this.headerColor = "#FF4F02";
          break;
        case "localhost:8128": //ローカル開発環境
        case "localhost:8129": //ローカル開発環境
          this.maintenanceFlag = false; //メンテナンスモード
      }
      //
      this.version =
        typeof process.env.VUE_APP_VERSION != "undefined"
          ? process.env.VUE_APP_VERSION
          : this.version;
    },
    async signOut() {
      try {
        await Auth.signOut();
        this.$store.commit("logOut");
        this.$router.push("/login");
        this.drawer = false;
      } catch (error) {
        console.log(error);
      }
    },
    //SPAの更新確認
    fetchServer() {
      fetch(this.normalizedUrl)
        .then((res) => {
          if (res.status !== 200) {
            throw Error("Cannot access server.");
          }
          return res.text();
        })
        .then((html) => {
          const hash = this.createHash(html);
          this.judgeHash(hash);
        })
        .catch((err) => console.log(err));
    },
    createHash(str) {
      const len = str.length;
      let hash = 0;
      if (len === 0) return hash;
      let i;
      for (i = 0; i < len; i++) {
        hash = (hash << 5) - hash + str.charCodeAt(i);
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    },
    judgeHash(hash) {
      if (!this.previousHash) {
        this.previousHash = hash;
        return;
      }
      if (this.previousHash !== hash) {
        //this.isChanged = true;
      }
    },
    modalClose() {
      this.isChanged = false;
    },
    menteClose() {
      this.maintenanceFlag = false;
      document.removeEventListener("keydown", this.onKeyDown);
    },
    onKeyDown(event) {
      //let v = this.returnData.value;
      switch (event.keyCode) {
        case 13:
          this.pass = "";
          break;
        case 69:
          this.pass += "e";
          break;
        case 78:
          this.pass += "n";
          break;
        case 79:
          this.pass += "o";
          break;
        case 83:
          this.pass += "s";
          break;
      }
    },
  },
};
</script>

<style>
@import "../css/styles.css";
</style>
