import Vue from "vue";
import VueRouter from "vue-router";

import goTo from "vuetify/lib/services/goto";
import store from "../store/vuex.js";
// Amplify読み込み
//AccessTokenの取得

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    //バリデーション失敗時の自動スクロール
    let scrollTo = 0;
    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }
    return goTo(scrollTo);
  },
  routes: [
    {
      path: "/login",
      name: "Login",
      component: require("../views/Auth/Login.vue").default,
    },
    {
      path: "/",
      name: "メニュー",
      component: require("../views/Home.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/user/setting/:report_id",
      name: "ユーザ情報設定",
      component: require("../views/userSetting.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/user/index",
      name: "ユーザ情報一覧",
      component: require("../views/userIndex.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/setting",
      name: "設定",
      component: require("../views/setting.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/carapproval/index",
      name: "組織図一覧",
      component: require("../views/carapprovalIndex.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/carapproval/create",
      name: "組織図1",
      component: require("../views/carapprovalCreate.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/carapproval/create/:report_id",
      name: "組織図1_update",
      component: require("../views/carapprovalCreate.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/mycar/index",
      name: "マイカー一覧",
      component: require("../views/mycarIndex.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/mycar/create",
      name: "マイカー新規登録・設定",
      component: require("../views/mycarCreate.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/mycar/create/:report_id",
      name: "マイカー新規登録・設定_update",
      component: require("../views/mycarCreate.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/companycar/index",
      name: "社有車一覧",
      component: require("../views/companycarIndex.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/companycar/create",
      name: "社有車新規登録・設定",
      component: require("../views/companycarCreate.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/companycar/create/:report_id",
      name: "社有車新規登録・設定_update",
      component: require("../views/companycarCreate.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/applicationform/index",
      name: "車両利用申請一覧",
      component: require("../views/applicationformIndex.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/applicationform/create",
      name: "車両利用申請",
      component: require("../views/applicationformCreate.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/applicationform/create/:report_id",
      name: "車両利用申請_update",
      component: require("../views/applicationformCreate.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/batchreport/setting",
      name: "組織図(参照)",
      component: require("../views/batchreportSetting.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/mycar/approval",
      name: "マイカー承認一覧",
      component: require("../views/mycarApproval.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/car/approval",
      name: "社有車承認申請一覧",
      component: require("../views/carApproval.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/oathPage",
      name: "誓約書",
      component: require("../views/oathPage.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/approvalflow/index",
      name: "マイカー承認フロー",
      component: require("../views/approvalflowIndex.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/approval/flow1",
      name: "マイカー承認フロー1",
      component: require("../views/approvalFlow1.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/approval/flow1/:report_id",
      name: "マイカー承認フロー1_update",
      component: require("../views/approvalFlow1.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/car/inspection",
      name: "車両利用申請点検",
      component: require("../views/carInspection.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/car/index",
      name: "業務利用車一覧",
      component: require("../views/carIndex.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/import/userData",
      name: "従業員情報取り込み",
      component: require("../views/importUserdata.vue").default,
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next) => {
        if (store.state.role === 1) {
          next();
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: "/import/organization",
      name: "組織図取り込み",
      component: require("../views/importOrganization.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/companycar/indexall",
      name: "社有車一覧(全車両)",
      component: require("../views/companycarIndexall.vue").default,
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next) => {
        if (store.state.role === 1) {
          next();
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: "/mycar/indexall",
      name: "マイカー一覧(全車両)",
      component: require("../views/mycarIndexall.vue").default,
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next) => {
        if (store.state.role === 1) {
          next();
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: "/import/companyCar",
      name: "社有車一括取り込みページ",
      component: require("../views/importCompanycar.vue").default,
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next) => {
        if (store.state.role === 1) {
          next();
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: "/applicationform/indexall",
      name: "車両利用申請(全件)",
      component: require("../views/applicationformIndexall.vue").default,
      meta: { requiresAuth: true },
      beforeEnter: (to, from, next) => {
        if (store.state.role === 1) {
          next();
        } else {
          next({ path: from.path });
        }
      },
    },
    {
      path: "/add/tempUser",
      name: "アルバイト追加",
      component: require("../views/userTempAdd.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/index/tempUser",
      name: "従業員・アルバイト一覧",
      component: require("../views/userTempIndex.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/approvalflow/check",
      name: "マイカー承認ルートチェック",
      component: require("../views/approvalflowCheck.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/mycar/documentCheckIndex",
      name: "マイカー書類チェック用一覧画面",
      component: require("../views/mycarDocumentcheckindex.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/import/mycar",
      name: "マイカー情報取り込み",
      component: require("../views/importMycar.vue").default,
      meta: { requiresAuth: true },
    },
    {
      path: "/import/userDataImport",
      name: "従業員データ取り込み",
      component: require("../views/userDataImport/userDataImport.vue").default,
      meta: { requiresAuth: true },
    },
  ],
});

export default router;
