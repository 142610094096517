<template>
  <div class="body">
    <p align="center" class="title">業務利用車一覧</p>
    <v-row>
      <v-col align="right" class="button">
        <v-btn @click.once="back">戻る</v-btn>
      </v-col>
    </v-row>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-form ref="form" v-model="valid">
        <v-row class="ml-4 mr-1"> <v-col cols=""> 社有車 </v-col> </v-row>
        <v-row class="ml-4 mr-1">
          <v-col cols="6" sm="6" md="2" lg="2">
            <v-text-field
              label="事業本部"
              :rules="[rules.required]"
              v-model="keyword1"
              outlined
              no-data-text="データが見つかりません"
              clearable
              hide-details="auto"
              disabled
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="6" md="2" lg="2">
            <v-text-field
              label="部署名"
              :rules="[rules.required]"
              v-model="keyword2"
              outlined
              no-data-text="データが見つかりません"
              disabled
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="6" md="3" lg="3">
            <v-autocomplete
              label="グループ"
              :rules="[]"
              v-model="keyword3"
              :items="selectBox3"
              id="FID8322"
              class="selectBox8322"
              outlined
              no-data-text="データが見つかりません"
              clearable
              item-text="label"
              item-value="value"
              hide-details="auto"
              @change="setSelectBox4"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="6" sm="6" md="3" lg="3">
            <v-autocomplete
              label="店舗"
              v-model="keyword4"
              :items="selectBox4"
              id="FID8322"
              class="selectBox8322"
              outlined
              no-data-text="データが見つかりません"
              clearable
              item-text="label"
              item-value="value"
              hide-details="auto"
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="2">
            <v-btn x-large color="primary white--text" @click="search">
              検索
            </v-btn>
          </v-col>
        </v-row>

        <v-row class="ml-2 mr-1">
          <v-col cols="">
            <v-data-table
              :headers="headers"
              :items="reports"
              :item-key="reports.ID"
              :page="
                $store.state.pageNum == undefined ? 1 : $store.state.pageNum
              "
              @update:page="updatePageNum"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              @click:row="move"
              :header-props="{
                'sort-by-text': '並び順',
              }"
              :footer-props="{
                'items-per-page-text': '行/ページ:',
              }"
              no-data-text="データが存在しません"
              :items-per-page="displayResults"
              @update:sort-by="updateSortBy"
              @update:sort-desc="updateSortDesc"
              :custom-sort="customSort"
            >
              <template v-slot:item.Recodes.FID8322.value="{ item }">
                {{ $store.state.organization[item.Recodes.FID8322.value] }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row class="ml-4 mr-1">
          <v-col cols=""> マイカー(業務利用車のみ) </v-col>
        </v-row>
        <v-row class="ml-2 mr-1">
          <v-col cols="">
            <v-data-table
              :headers="headers2"
              :items="reports2"
              :item-key="reports2.ID"
              @click:row="move2"
              :header-props="{
                'sort-by-text': '並び順',
              }"
              :footer-props="{
                'items-per-page-text': '行/ページ:',
              }"
              no-data-text="データが存在しません"
              :items-per-page="-1"
              @update:sort-by="updateSortBy"
              @update:sort-desc="updateSortDesc"
              :custom-sort="customSort"
            >
              <template v-slot:item.Recodes.FID7992.value="{ item }">
                {{ $store.state.organization[item.Recodes.FID7992.value] }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
const eneosCar = RepositoryFactory.get("eneosCar");

export default {
  name: "ReportIndex",
  components: {},
  data() {
    return {
      valid: true,
      initEscape: false,
      isLoading: false,
      fullPage: false,
      displayResults: -1, //5,10,15のいずれか
      height: 100,
      width: 250,
      path: "",
      sortBy: null,
      sortDesc: null,
      //
      PageID: 1158,
      reports: [],
      reports2: [],
      //
      busyoFlag: "",
      groupFlag: true,
      keyword1: "",
      keyword2: "",
      keyword1Code: "",
      keyword2Code: "",
      keyword3: "",
      keyword4: "",
      selectBox3: [],
      selectBox4: [],
      //
      rank2onlyFlag: false,
      //
      rules: {
        required: (value) => !!value || "必須項目です",
        requiredArray: (value) => (value.length > 0 ? true : "必須項目です"),
        fullWidth: (value) => {
          return /^[^ -~｡-ﾟ]*$/.test(value) || "全角のみ入力可能です";
        },
      },
    };
  },
  async created() {
    await this.getInit();
  },
  methods: {
    async getInit() {
      //タイトルをデフォルトに戻す
      if (typeof process.env.VUE_APP_BASE_TITLE != "undefined") {
        document.title = process.env.VUE_APP_BASE_TITLE;
      }
      //初期処理
      this.isLoading = true;
      this.getKeyWord(this.$store.state.keyWord);
      this.path = this.$route.path;
      //検索項目の取得
      const res1 = await eneosCar.getDivision(store.state.params.FID7767.value);
      this.keyword1 = res1[0].label;
      this.keyword1Code = res1[0].value;
      //
      let flag = false;
      let response = await eneosCar.getOrganizationChart();
      if (Object.keys(this.$store.state.organization).length < 2) {
        await store.commit("setDepartmentAll");
      }
      this.keyword2 = response.label;
      this.keyword2Code = response.value;
      this.selectBox3 = response.group;
      //対象を探す
      for (let i = 0; i < response.group.length; i++) {
        if (flag) {
          break;
        }
        if (response.group[i].value == store.state.params.FID7776.value) {
          this.keyword3 = response.group[i].value;
          if (response.group[i].shop != null) {
            this.selectBox4 = response.group[i].shop;
          }
          flag = true;
          break;
        }
        if (response.group[i].shop != null) {
          this.selectBox4 = response.group[i].shop;
          for (let j = 0; j < this.selectBox4.length; j++) {
            if (this.selectBox4[j].value == store.state.params.FID7776.value) {
              this.keyword3 = response.group[i].value;
              this.keyword4 = this.selectBox4[j].value;
              flag = true;
              break;
            }
          }
        }
      }
      //○○直営事業部の場合の処理
      if (this.keyword3 == "" && this.keyword4 == "") {
        this.rank2onlyFlag = true;
      }

      //データ取得
      if (JSON.stringify(this.$store.state.reports) != "{}") {
        this.reports = this.$store.state.reports;
      } else {
        let profile;
        [, profile] = await Promise.all([this.search(), api.GetRole()]);
        store.commit("setRole", profile.Role);
      }
      //
      if (this.$store.state.tableSort.sortBy != null) {
        this.sortBy = this.$store.state.tableSort.sortBy;
        this.sortDesc = this.$store.state.tableSort.sortDesc;
      }
      this.reports = this.reports.reverse();

      this.isLoading = false;
    },
    setSelectBox4() {
      //対象を探す
      if (this.keyword3 == null) {
        this.selectBox4 = [];
        return;
      }
      for (let i = 0; i < this.selectBox3.length; i++) {
        if (this.selectBox3[i].value == this.keyword3) {
          this.selectBox4 = this.selectBox3[i].shop;
          this.keyword4 = "";
        }
      }
    },
    getKeyWord(data) {
      if (data.PageID == this.PageID) {
        data.search.forEach((data) => {
          console.log(data);
        });
      }
    },
    //社有車の検索
    async getSearchWord() {
      let data = {
        PageID: this.PageID,
        orderBy: "",
        search: [],
      };
      data.search.push({
        form_id: 8321,
        value: this.keyword1Code,
        option: "match",
      });
      data.search.push({
        form_id: 9238,
        value: this.keyword2Code,
        option: "match",
      });

      //車両情報の取得
      if (this.keyword4 != "" && this.keyword4 != null) {
        data.search.push({
          form_id: 8322,
          value: this.keyword4,
          option: "match",
        });
        this.rank2onlyFlag = false;
      } else if (this.keyword3 != "" && this.keyword3 != null) {
        data.search.push({
          form_id: 8322,
          value: this.keyword3,
          option: "match",
        });
        this.rank2onlyFlag = false;
      }
      if (this.rank2onlyFlag) {
        data.search.push({
          form_id: 8322,
          value: this.keyword2,
          option: "match",
        });
      }
      return data;
    },
    async search() {
      if (this.initEscape == false) {
        this.initEscape = true;
        this.isLoading = false;
        await this.$nextTick();
      }
      const ck = await this.checkValidation();
      if (!ck) {
        return;
      }
      //初期処理
      this.isLoading = true;
      let data = await this.getSearchWord();
      //社有車の一覧を取得
      const res1 = await api.ReportSearch(data);
      if (res1 != null) {
        this.reports = res1;
      }
      //マイカーの一覧を取得
      const res2 = await eneosCar.getBusinessUseCar();
      if (res2 != null) {
        this.reports2 = res2;
      }
      this.isLoading = false;
    },
    SetVal(type, val) {
      let recode = { fromType: type, value: val, disabled: false };
      return recode;
    },
    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }

      return check;
    },
    create() {
      this.isLoading = true;
      this.$router.push({ path: "/mycar/create" });

      this.isLoading = false;
    },
    //社有車の遷移
    move(data) {
      this.isLoading = true;
      if (
        typeof data.Recodes.FID8007 != "undefined" &&
        data.Recodes.FID8007.value != null
      ) {
        localStorage.setItem("kakanaiValueFID7996", data.Recodes.FID8007.value); // 車両番号FID8007
        localStorage.setItem("kakanaiValueFID8014", data.Recodes.FID8613.value); // 区分
        if (!this.setKakanaiValueFID9572()) {
          return;
        }

        this.$router.push({ path: "/applicationform/index" });
        store.commit("clearreports");
      } else {
        console.log("data", data);
      }
      this.isLoading = false;
    },
    //マイカーの遷移
    move2(data) {
      this.isLoading = true;
      if (
        typeof data.Recodes.FID7996 != "undefined" &&
        data.Recodes.FID7996.value != null
      ) {
        localStorage.setItem("kakanaiValueFID7996", data.Recodes.FID7996.value);
        if (!this.setKakanaiValueFID9572()) {
          return;
        }
        this.$router.push({ path: "/applicationform/index" });
        store.commit("clearreports");
      } else {
        console.log("data", data);
      }
      this.isLoading = false;
    },
    setKakanaiValueFID9572() {
      let value = "";
      if (this.keyword4 != "" && this.keyword4 != null) {
        value = this.keyword4;
      } else if (this.keyword3 != "" && this.keyword3 != null) {
        value = this.keyword3;
      } else if (this.keyword2 != "" && this.keyword2 != null) {
        value = this.keyword2;
      }
      if (value == "") {
        alert("パラメータが設定されていません");
        return false;
      }
      //適切な業務グループの情報を設定
      localStorage.setItem("kakanaiValueFID7776", value);
      localStorage.setItem("kakanaiValueFID9572", value);
      return true;
    },
    back() {
      store.commit("clearkeyWord");
      store.commit("clearreports");
      store.commit("clearPageNum");
      store.commit("clearTableSort");

      //
      if (this.$route.path == this.path) {
        history.back();
      } else {
        alert("読み込みエラー:ホームに戻ります");
        this.$router.push("/", () => {});
      }
      //history.back();
    },
    getValue(v) {
      if (typeof v == "undefined") {
        return "";
      }
      return v.value;
    },
    downloadCSV(csv, filename) {
      //CSV出力部分
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },
    updatePageNum(value) {
      store.commit("setPageNum", value);
    },
    updateSortBy(value) {
      store.commit("setTableSortBy", value);
    },
    updateSortDesc(value) {
      store.commit("setTableSortDesc", value);
    },
    customSort(items, index, isDesc) {
      //デフォルト
      if (index.length == 0 || items.length == 0) {
        return items;
      }
      const regex = /[^0-9]/g;
      const order = isDesc[0] ? 1 : -1;
      const pos = parseInt(index[0].replace(regex, ""));
      items.sort((a, b) => {
        //アイコンソートは特殊処理
        if (index == "target") {
          return 0;
        }
        //
        return this.sort(a, b, order, pos);
      });
      //
      return items;
    },
    sort(a, b, order, pos) {
      if (
        typeof a.Recodes["FID" + pos] == "undefined" ||
        typeof b.Recodes["FID" + pos] == "undefined"
      )
        return -1;
      if (a.Recodes["FID" + pos].value == b.Recodes["FID" + pos].value)
        return 0;
      if (a.Recodes["FID" + pos].value === "") return 1;
      if (b.Recodes["FID" + pos].value === "") return -1;
      if (
        !isNaN(a.Recodes["FID" + pos].value) &&
        !isNaN(b.Recodes["FID" + pos].value)
      ) {
        return Number(a.Recodes["FID" + pos].value) ==
          Number(b.Recodes["FID" + pos].value)
          ? 0
          : Number(a.Recodes["FID" + pos].value) >
            Number(b.Recodes["FID" + pos].value)
          ? -1 * order
          : order;
      }
      return (
        a.Recodes["FID" + pos].value.localeCompare(
          b.Recodes["FID" + pos].value,
          "jp"
        ) *
        -1 *
        order
      );
    },
    dateSort(dateArr) {
      const date1 = new Date(dateArr[0].replace(/-/g, "/"));
      const date2 = new Date(dateArr[1].replace(/-/g, "/"));
      if (date2 - date1 > 0) {
        return dateArr;
      } else {
        return [dateArr[1], dateArr[0]];
      }
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "車両番号",
          value: "Recodes.FID8007.value",
          align: "start",
          sortable: true,
        },
        { text: "部署", value: "Recodes.FID8322.value", sortable: true },
        { text: "車種名", value: "Recodes.FID8112.value", sortable: true },
        //{ text: "使用区分", value: "Recodes.FID8613.value", sortable: true },
      ];
    },
    headers2() {
      return [
        {
          text: "車両番号",
          value: "Recodes.FID7996.value",
          align: "start",
          sortable: true,
        },
        { text: "部署", value: "Recodes.FID7992.value", sortable: true },
      ];
    },
  },
};
</script>
