<template>
  <div class="home">
    <p>ユーザ情報設定{{ data.ReportID }}</p>
    <v-row>
      <v-col align="right"><v-btn @click.once="back">戻る</v-btn></v-col>
    </v-row>
    <v-container cols="12">
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form">
          <!--  ----------------------------->
          <v-row v-if="false">
            <v-col cols="4" v-for="(value, key) in data.Recodes" :key="key">
              {{ key }}:{{ value }}
            </v-col>
          </v-row>
          <!--  ----------------------------->
          <v-row>
            <v-col cols="4">
              <v-text-field
                v-model="data.Recodes.FID0.name"
                :rules="[rules.required]"
                label="社員番号"
                type="text"
                outlined
                :disabled="!createFlag"
                :background-color="createFlag ? 'white' : 'gray'"
                clearable
                v-bind:autocomplete="'off'"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4" v-if="false">
              <v-text-field
                v-model="data.Recodes.FID0.mail"
                label="メールアドレス"
                type="text"
                :disabled="!createFlag"
                :rules="[rules.mail]"
                outlined
                background-color="white"
                clearable
              >
              </v-text-field>
            </v-col>
            <v-col v-if="createFlag" cols="4">
              <v-text-field
                v-model="data.Recodes.FID0.password"
                label="初期パスワード"
                :type="passwordShow ? 'text' : 'password'"
                :rules="[rules.required, rules.password]"
                :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="passwordShow = !passwordShow"
                outlined
                background-color="white"
                hint="12文字以上 | 数字 英大文字・小文字 記号いずれか3種類以上"
                persistent-hint
                clearable
                v-bind:autocomplete="'new-password'"
              >
              </v-text-field>
            </v-col>
            <v-col v-else-if="userNameLogin" cols="4">
              <v-text-field
                v-model="data.Recodes.FID0.password"
                label="パスワードリセット"
                :type="passwordShow ? 'text' : 'password'"
                :rules="[
                  data.Recodes.FID0.password == '' ||
                  data.Recodes.FID0.password == null
                    ? true
                    : rules.password,
                ]"
                :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="passwordShow = !passwordShow"
                outlined
                background-color="white"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4" sm="4" md="4" lg="4">
              <v-text-field
                label="氏名"
                v-model="data.Recodes.FID7762.value"
                :rules="[rules.required]"
                id="FID7762"
                class="textfield7762"
                outlined
                background-color="white"
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="4" sm="4" md="4" lg="4">
              <v-text-field
                label="氏名カナ"
                v-model="data.Recodes.FID7763.value"
                :rules="[rules.required]"
                id="FID7763"
                class="textfield7763"
                outlined
                background-color="white"
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-divider class="ma-3"></v-divider>
          <v-row>
            <v-col cols="3">
              <div
                label=""
                id="FID7848"
                class="text-h5 mt-4"
                hide-details="auto"
              >
                所属情報
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3">
              <v-autocomplete
                label="事業本部"
                v-model="keyword1"
                :rules="[rules.required]"
                outlined
                background-color="white"
                hide-details="auto"
                :items="selectBox1"
                item-text="label"
                item-value="value"
                no-data-text="データが見つかりません"
                @change="
                  (keyword2 = ''), (data.Recodes.FID7767.value = keyword1)
                "
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                label="部署名"
                v-model="keyword2"
                :rules="[rules.required]"
                outlined
                background-color="white"
                hide-details="auto"
                :items="
                  keyword1 == null || keyword1 == ''
                    ? []
                    : selectBox2List[keyword1]
                "
                item-text="label"
                item-value="value"
                no-data-text="データが見つかりません"
                @change="
                  (keyword3 = ''), (data.Recodes.FID7776.value = keyword2)
                "
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                label="グループ"
                :rules="[]"
                v-model="keyword3"
                :items="
                  keyword2 == null || keyword2 == ''
                    ? []
                    : selectBox3List[keyword2]
                "
                id="FID8322"
                class="selectBox8322"
                outlined
                background-color="white"
                no-data-text="データが見つかりません"
                clearable
                item-text="label"
                item-value="value"
                hide-details="auto"
                @change="
                  (keyword4 = ''), (data.Recodes.FID7776.value = keyword3)
                "
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="3">
              <v-autocomplete
                label="店舗"
                v-model="keyword4"
                :items="
                  keyword3 == null || keyword3 == ''
                    ? []
                    : selectBox4List[keyword3]
                "
                id="FID8322"
                class="selectBox8322"
                outlined
                background-color="white"
                no-data-text="データが見つかりません"
                clearable
                item-text="label"
                item-value="value"
                hide-details="auto"
                @change="data.Recodes.FID7776.value = keyword4"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-autocomplete
                label="(所属)事業本部"
                v-model="data.Recodes.FID7767.value"
                :rules="[rules.required]"
                id="FID7767"
                class="textfield7767"
                outlined
                background-color="white"
                hide-details="auto"
                :items="selectBoxFID7767"
                item-text="label"
                item-value="value"
                no-data-text="データが見つかりません"
                disabled
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-text-field
                label="(所属)部署"
                :value="$store.state.organization[data.Recodes.FID7776.value]"
                :rules="[rules.required]"
                id="FID7776"
                class="textfield7776"
                outlined
                background-color="white"
                hide-details="auto"
                no-data-text="事業部を選んでください"
                disabled
              >
              </v-text-field>
            </v-col>
            <v-col cols="4" sm="4" md="3" lg="3">
              <v-select
                label="従業員区分"
                v-model="data.Recodes.FID7777.value"
                :rules="[rules.required]"
                id="FID7777"
                class="selectBox7777"
                outlined
                background-color="white"
                no-data-text="データが見つかりません"
                clearable
                :items="selectBoxFID7777"
                hide-details="auto"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-divider class="ma-3"></v-divider>
          <v-row>
            <v-col cols="4" sm="4" md="4" lg="4">
              <div
                label=""
                id="FID7848"
                class="plainText7848 text-h5 mt-4"
                hide-details="auto"
              >
                システム権限
              </div>
            </v-col>
            <v-col cols="4">
              <v-card elevation="2" class="mt-0 mr-0 ml-0 mb-4 pa-0">
                <v-radio-group
                  v-model="data.Recodes.FID0.role"
                  :rules="[rules.required]"
                  id="FID7764"
                  class="radio-groupradioGroup7764 pt-2 pl-3 ma-0"
                  style="border-radius: 4px; border-color: #9f9f9f"
                  background-color="white"
                  row
                  hide-details="auto"
                >
                  <v-radio label="一般" value="0" class="mt-3 mb-6"></v-radio>
                  <v-radio
                    label="グループ管理者"
                    value="2"
                    class="mt-3 mb-6"
                    disabled
                  ></v-radio>
                  <v-radio label="管理者" value="1" class="mt-3 mb-6"></v-radio>
                </v-radio-group>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card elevation="2" class="ma-0 pa-0">
                <v-row no-gutters>
                  <v-col
                    v-for="label in data.Recodes.FID9441.labels"
                    :key="label"
                    cols="12"
                    sm="12"
                    :md="12 / data.Recodes.FID9441.labels.length"
                    :lg="12 / data.Recodes.FID9441.labels.length"
                  >
                    <v-card outlined tile>
                      <v-checkbox
                        class="black--text"
                        v-model="FID9441json"
                        :label="label"
                        :value="label"
                        hint="アルバイト追加権限"
                      >
                        <template slot="label">
                          {{ label }}
                        </template>
                      </v-checkbox>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="4" sm="4" md="4" lg="4">
              <div
                label=""
                id="FID7848"
                class="plainText7848 text-h5 mt-2"
                hide-details="auto"
              >
                車両申請権限
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-card class="mt-0 mr-0 ml-0 mb-4 pa-0">
                <v-radio-group
                  v-model="data.Recodes.FID7764.value"
                  :rules="[rules.required]"
                  id="FID7764"
                  class="radio-groupradioGroup7764 pt-2 pl-3 mt-0 mb-3"
                  style="border-radius: 4px; border-color: #9f9f9f"
                  background-color="white"
                  row
                  hide-details="auto"
                >
                  <v-radio
                    label="一般"
                    value="一般"
                    class="mt-3 mb-6"
                  ></v-radio>
                  <v-radio
                    label="途中承認者"
                    value="途中承認者"
                    class="mt-3 mb-6"
                  ></v-radio>
                  <v-radio
                    label="承認者"
                    value="承認者"
                    class="mt-3 mb-6"
                  ></v-radio>
                </v-radio-group>
              </v-card>
            </v-col>
            <v-col cols="2" sm="2" md="3" lg="3">
              <v-card elevation="2" class="ma-0 pa-0">
                <v-row no-gutters>
                  <v-col
                    v-for="label in data.Recodes.FID8135.labels"
                    :key="label"
                    cols="12"
                    sm="12"
                    :md="12 / data.Recodes.FID8135.labels.length"
                    :lg="12 / data.Recodes.FID8135.labels.length"
                  >
                    <v-card outlined tile>
                      <v-checkbox
                        class="black--text"
                        v-model="FID8135json"
                        :label="label"
                        :value="label"
                      >
                        <template slot="label">
                          {{ label }}
                        </template>
                      </v-checkbox>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3">
              <v-card elevation="2" class="ma-0 pa-0">
                <v-row no-gutters>
                  <v-col
                    v-for="label in data.Recodes.FID7765.labels"
                    :key="label"
                    cols="12"
                    sm="12"
                    :md="12 / data.Recodes.FID7765.labels.length"
                    :lg="12 / data.Recodes.FID7765.labels.length"
                  >
                    <v-card outlined tile>
                      <v-checkbox
                        class="black--text"
                        v-model="FID7765json"
                        :label="label"
                        :value="label"
                      >
                        <template slot="label">
                          {{ label }}
                        </template>
                      </v-checkbox>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">
              <v-card class="ma-0 pa-0">
                <v-row no-gutters>
                  <v-col
                    v-for="label in data.Recodes.FID8748.labels"
                    :key="label"
                    cols="12"
                    sm="12"
                    :md="12 / data.Recodes.FID8748.labels.length"
                    :lg="12 / data.Recodes.FID8748.labels.length"
                  >
                    <v-card outlined tile>
                      <v-checkbox
                        class="black--text"
                        v-model="FID8748json"
                        :label="label"
                        :value="label"
                      >
                        <template slot="label">
                          {{ label }}
                        </template>
                      </v-checkbox>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
              (運転記録簿の日時承認)
            </v-col>
            <v-col cols="2">
              <v-card class="ma-0 pa-0">
                <v-row no-gutters>
                  <v-col
                    v-for="label in data.Recodes.FID7766.labels"
                    :key="label"
                    cols="12"
                    sm="12"
                    :md="12 / data.Recodes.FID7766.labels.length"
                    :lg="12 / data.Recodes.FID7766.labels.length"
                  >
                    <v-card outlined tile>
                      <v-checkbox
                        class="black--text"
                        v-model="FID7766json"
                        :label="label"
                        :value="label"
                      >
                        <template slot="label">
                          {{ label }}
                        </template>
                      </v-checkbox>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
              (運転記録簿の月次承認)
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3">
              <!-- 車両追加者---->
              <v-card elevation="2" class="ma-0 pa-0">
                <v-row no-gutters>
                  <v-col
                    v-for="label in data.Recodes.FID8133.labels"
                    :key="label"
                    cols="12"
                    sm="12"
                    :md="12 / data.Recodes.FID8133.labels.length"
                    :lg="12 / data.Recodes.FID8133.labels.length"
                  >
                    <v-card outlined tile>
                      <v-checkbox
                        class="black--text"
                        v-model="FID8133json"
                        :label="label"
                        :value="label"
                      >
                        <template slot="label">
                          {{ label }}
                        </template>
                      </v-checkbox>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="3" sm="3" md="3" lg="3">
              <v-card elevation="2" class="ma-0 pa-0">
                <v-row no-gutters>
                  <!-- マイカー添付書類確認者---->
                  <v-col
                    v-for="label in data.Recodes.FID8134.labels"
                    :key="label"
                    cols="12"
                    sm="12"
                    :md="12 / data.Recodes.FID8134.labels.length"
                    :lg="12 / data.Recodes.FID8134.labels.length"
                  >
                    <v-card outlined tile>
                      <v-checkbox
                        class="black--text"
                        v-model="FID8134json"
                        :label="label"
                        :value="label"
                      >
                        <template slot="label">
                          {{ label }}
                        </template>
                      </v-checkbox>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="left">
              <v-btn
                @click="deleteModal = true"
                :disabled="data.ReportID == null || disabled"
                color="red white--text"
                >削除</v-btn
              >
              <v-dialog v-model="deleteModal" max-width="400">
                <v-card>
                  <v-card-title class="text-h5"> 削除しますか？ </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mr-3"
                      color="green darken-1 white--text"
                      @click="deleteModal = false"
                      large
                    >
                      いいえ
                    </v-btn>
                    <v-btn color="red darken-1 white--text" @click="del" large>
                      はい
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col align="right">
              <v-btn @click="send" color="primary">送信</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </v-container>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
import { getDivisionAll, getDepartmentAll } from "./specialMethod";
const eneosCar = RepositoryFactory.get("eneosCar");
export default {
  name: "Home",
  components: {},
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      data: {
        PageID: 1036,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID0: {
            value: "",
            formType: 21,
            name: "",
            mail: "eneos_car@demo.service.kakanai.com",
            role: "0",
            password: "",
            account: "",
            attribute: "",
          },
          FID7762: { value: "", formType: 1, disabled: false },
          FID7763: { value: "", formType: 1, disabled: false },
          FID7764: { value: "一般", formType: 7, disabled: false },
          FID9441: {
            value: "",
            formType: 14,
            disabled: false,
            color: "black",
            labels: ["一時ユーザ追加"],
          },
          FID7765: {
            value: "",
            formType: 14,
            disabled: false,
            color: "black",
            labels: ["給油確認者"],
          },
          FID7766: {
            value: "",
            formType: 14,
            disabled: false,
            color: "black",
            labels: ["車両確認者"],
          },
          FID8748: {
            value: "",
            formType: 14,
            disabled: false,
            color: "black",
            labels: ["車両確認者(日別)"],
          },
          FID8133: {
            value: "",
            formType: 14,
            disabled: false,
            color: "black",
            labels: ["車両追加者"],
          },
          FID8134: {
            value: "",
            formType: 14,
            disabled: false,
            color: "black",
            labels: ["マイカー添付書類確認者"],
          },
          FID8135: {
            value: "",
            formType: 14,
            disabled: false,
            color: "black",
            labels: ["代理者"],
          },
          FID7767: { value: "", formType: 1, disabled: false },
          FID7776: { value: "", formType: 1, disabled: false },
          FID7777: { value: "", formType: 2, disabled: false },
          FID9137: { value: "", formType: 1, disabled: false },
        },
        DeleteSubReports: [],
      },
      FID7765json: [""],
      FID7766json: [""],
      FID8748json: [""],
      FID8133json: [""],
      FID8134json: [""],
      FID8135json: [""],
      FID9441json: [""],
      selectBoxFID7767: [], //事業部・支社
      selectBoxFID7776: [], //部署
      selectBoxFID7777: [], //従業員区分
      rules: {
        required: (value) => !!value || "必須項目です",
        mail: (v) => /.+@.+\..+/.test(v) || "メールアドレスの形式が不正です",
        password: (password) => {
          if (password.length < 12) {
            return "パスワードの長さは12文字以上です";
          }
          if (password.includes("＠")) {
            return "全角「＠」が入っています";
          }
          const digitPattern = /\d/;
          const upperCasePattern = /[A-Z]/;
          const lowerCasePattern = /[a-z]/;
          const symbolPattern = /[\W_]/; // \Wは非単語文字（記号など）、_はアンダースコアを表す
          // 各パターンに一致するかをカウント
          let count = 0;
          if (digitPattern.test(password)) count++;
          if (upperCasePattern.test(password)) count++;
          if (lowerCasePattern.test(password)) count++;
          if (symbolPattern.test(password)) count++;
          if (count < 3) {
            return "数字 英大文字・小文字 記号いずれか3種類以上";
          } else {
            return true;
          }
        },
        containBox7776: (value) => {
          if (typeof this.selectBoxFID7776 == "undefined") {
            return true;
          }
          let flag = false;
          this.selectBoxFID7776.map((val) => {
            if (val.value == value) {
              flag = true;
            }
          });
          return flag ? true : "選択肢にない項目が入力されています";
        },
      },
      authChange: true,
      createFlag: false,
      userNameLogin: true,
      copyFlag: false,
      deleteModal: false,
      sendModal: false,
      backCount: -1,
      message: "",
      passwordShow: false,
      //
      keyword1: "",
      keyword2: "",
      keyword3: "",
      keyword4: "",
      selectBox1: [],
      selectBox2List: {
        key: [],
      },
      selectBox3List: {
        key: [],
      },
      selectBox4List: {
        key: [],
      },
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {},
  watch: {
    $route() {
      this.getInit();
    },

    FID7765json: function (value) {
      this.$set(
        this.data.Recodes.FID7765,
        "value",
        value.filter((item) => item !== "").join(",")
      );
    },
    FID7766json: function (value) {
      this.$set(
        this.data.Recodes.FID7766,
        "value",
        value.filter((item) => item !== "").join(",")
      );
    },
    FID8133json: function (value) {
      this.$set(
        this.data.Recodes.FID8133,
        "value",
        value.filter((item) => item !== "").join(",")
      );
    },
    FID8134json: function (value) {
      this.$set(
        this.data.Recodes.FID8134,
        "value",
        value.filter((item) => item !== "").join(",")
      );
    },
    FID9441json: function (value) {
      this.$set(
        this.data.Recodes.FID9441,
        "value",
        value.filter((item) => item !== "").join(",")
      );
    },
    FID8135json: function (value) {
      this.$set(
        this.data.Recodes.FID8135,
        "value",
        value.filter((item) => item !== "").join(",")
      );
    },
    FID8748json: function (value) {
      this.$set(
        this.data.Recodes.FID8748,
        "value",
        value.filter((item) => item !== "").join(",")
      );
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        const path = this.$route.path.split("/");
        this.createFlag = path[path.length - 1] == "create" ? true : false;

        if (!this.createFlag) {
          //ユーザ情報の取得
          let data = {
            name: this.$route.params.report_id,
            PageID: this.data.PageID,
          };
          //
          [this.data.Recodes, this.data.ReportID] = await api.UserDetail(
            data,
            this.data.Recodes
          );
          //初期処理
          console.log("ユーザ情報の取得");
        }
        //自身が管理者で自身のデータは変更できない内容にする
        if (
          typeof this.$store.state.params.FID0 != "undefined" &&
          !this.createFlag &&
          this.data.Recodes.FID0.role == "1" &&
          this.data.Recodes.FID0.name == this.$store.state.params.FID0.name
        ) {
          this.authChange = false;
          this.message = "管理者自身の権限は変更できません";
        }
        [
          this.selectBoxFID7767,
          this.selectBoxFID7776,
          ,
          this.selectBoxFID7777,
        ] = await Promise.all([
          getDivisionAll(),
          getDepartmentAll(),
          this.initKeyWord(),
          eneosCar.getPositionAll(),
        ]);
        //データ取得処理
        this.initFID7765();
        this.initFID7766();
        this.initFID8133();
        this.initFID8134();
        this.initFID9441();
        this.initFID8135();
        this.initFID8748();
        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    async initKeyWord() {
      let data = {
        PageID: 1151,
        orderBy: "",
        search: [],
      };
      const reports = await api.ReportSearch(data);
      let res2 = await eneosCar.getOrganizationChart();
      //
      if (typeof this.data.Recodes.FID7767 != "undefined") {
        this.keyword1 = this.data.Recodes.FID7767.value;
      }
      if (typeof res2 == "undefined") {
        res2 = await eneosCar.getOrganizationChartALL();
      }
      reports.map((repo1) => {
        this.selectBox1.push({
          label: repo1.Recodes.FID7977.value,
          value: repo1.Recodes.FID7978.value,
        });
        //
        this.selectBox2List[repo1.Recodes.FID7978.value] = [];
        if (repo1.Recodes.FID7986.subReports != null) {
          repo1.Recodes.FID7986.subReports.map((repo2) => {
            //
            this.selectBox2List[repo1.Recodes.FID7978.value].push({
              label: repo2.Recodes.FID7980.value,
              value: repo2.Recodes.FID7981.value,
            });
            this.selectBox3List[repo2.Recodes.FID7981.value] = [];
            if (repo2.Recodes.FID7987.subReports != null) {
              repo2.Recodes.FID7987.subReports.map((repo3) => {
                if (
                  this.data.Recodes.FID7776.value == repo3.Recodes.FID7983.value
                ) {
                  this.keyword2 = repo2.Recodes.FID7981.value;
                  this.keyword3 = repo3.Recodes.FID7983.value;
                }
                this.selectBox3List[repo2.Recodes.FID7981.value].push({
                  label: repo3.Recodes.FID7982.value,
                  value: repo3.Recodes.FID7983.value,
                });
                this.selectBox4List[repo3.Recodes.FID7983.value] = [];
                if (repo3.Recodes.FID7988.subReports != null) {
                  repo3.Recodes.FID7988.subReports.map((repo4) => {
                    if (
                      this.data.Recodes.FID7776.value ==
                      repo4.Recodes.FID7985.value
                    ) {
                      this.keyword2 = repo2.Recodes.FID7981.value;
                      this.keyword3 = repo3.Recodes.FID7983.value;
                      this.keyword4 = repo4.Recodes.FID7985.value;
                    }
                    this.selectBox4List[repo3.Recodes.FID7983.value].push({
                      label: repo4.Recodes.FID7984.value,
                      value: repo4.Recodes.FID7985.value,
                    });
                  });
                }
              });
            }
          });
        }
      });
      //

      //
      console.log("initKeyWord:end");
    },
    initFID7765() {
      if (this.data.Recodes.FID7765.value != "") {
        this.FID7765json = this.data.Recodes.FID7765.value.split(",");
      }
    },
    initFID7766() {
      if (this.data.Recodes.FID7766.value != "") {
        this.FID7766json = this.data.Recodes.FID7766.value.split(",");
      }
    },
    initFID8748() {
      if (this.data.Recodes.FID8748.value != "") {
        this.FID8748json = this.data.Recodes.FID8748.value.split(",");
      }
    },
    initFID8133() {
      if (this.data.Recodes.FID8133.value != "") {
        this.FID8133json = this.data.Recodes.FID8133.value.split(",");
      }
    },
    initFID8134() {
      if (this.data.Recodes.FID8134.value != "") {
        this.FID8134json = this.data.Recodes.FID8134.value.split(",");
      }
    },
    initFID9441() {
      if (this.data.Recodes.FID9441.value != "") {
        this.FID9441json = this.data.Recodes.FID9441.value.split(",");
      }
    },
    initFID8135() {
      if (this.data.Recodes.FID8135.value != "") {
        this.FID8135json = this.data.Recodes.FID8135.value.split(",");
      }
    },
    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }

      return check;
    },
    async send() {
      const result = await this.checkValidation();
      if (result) {
        this.isLoading = true;
        this.data.Recodes.FID9137.value = this.data.Recodes.FID0.name;
        switch (this.data.Recodes.FID0.role) {
          case "0":
            this.data.Recodes.FID0.attribute = "general";
            break;
          case "1":
            this.data.Recodes.FID0.attribute = "admin";
            break;
        }
        try {
          let response;
          if (this.createFlag) {
            //
            response = await api.CreateEmployee(this.data);
          } else {
            response = await api.UpdateEmployee(this.data);
          }
          if (response.status == "sucsess") {
            if (this.$route.params.report_id == "create") {
              alert("新規登録完了");
            } else {
              alert("更新完了");
            }
          } else {
            console.log(response.errors);
            alert("登録できません。");
          }
          this.isLoading = false;
          history.back();
        } catch (e) {
          console.log("send", e);
          this.isLoading = false;
          history.back();
        }
      }
    },
    back() {
      store.commit("clearreports");
      if (this.copyFlag) {
        history.go(this.backCount);
      } else {
        history.back();
      }
    },
    async del() {
      const response = await api.DeleteEmployee(this.data);
      if (response.status == "sucsess") {
        console.log("削除完了");
      } else {
        alert("ユーザ削除エラー:" + response.errors);
      }
      history.back();
    },
  },
};
</script>
<style></style>
