<template>
  <div class="home">
    <p>マイカー情報取り込み</p>
    <v-col align="right"><v-btn @click.once="back">戻る</v-btn></v-col>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-container cols="12">
        <v-form ref="form">
          <v-row>
            <v-checkbox
              v-model="header_flag"
              label="ヘッダー有"
            ></v-checkbox> </v-row
          ><v-row
            ><v-file-input
              v-model="uploadfile.file"
              type="file"
              label="エクセル取り込み(xlsx)"
              prepend-icon="mdi-image"
              ref="rfafile"
              accept=".xlsx,.xlsm"
              @change="chargeExcel"
              :rules="[rules.fileSize]"
              show-size
            ></v-file-input
          ></v-row>
          <v-row>
            <v-col align="left">
              <v-btn @click="downloadSampleExcel" color="green white--text">
                取り込み用サンプルエクセル
              </v-btn>
            </v-col>
            <v-col align="right">
              <v-btn @click="send" color="primary" :disabled="!sendFlag"
                >送信</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="">
              <v-data-table
                :headers="headers"
                :items="reports"
                :item-key="reports.ID"
                :header-props="{
                  'sort-by-text': '並び順',
                }"
                :footer-props="{
                  'items-per-page-text': '行/ページ:',
                }"
                no-data-text="データが存在しません"
              >
                <template v-slot:item.Recodes.FID7991.value="{ item }">
                  {{ $store.state.organization[item.Recodes.FID7991.value] }}:{{
                    item.Recodes.FID7991.value
                  }}
                </template>
                <template v-slot:item.Recodes.FID7992.value="{ item }">
                  {{ $store.state.organization[item.Recodes.FID7992.value] }}:{{
                    item.Recodes.FID7992.value
                  }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
    </div>

    <!-- <loading :active.sync="isLoading" :is-full-page="fullPage"></loading> -->
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
const localDownload = RepositoryFactory.get("localDownload");
import XLSX from "xlsx";
import {
  convertHalfWidthToFullWidthKana,
  convertExcelDate,
} from "./specialMethod";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      isLoading: false,
      fullPage: false,
      headerText: ",", //`$$headerText`,
      valid: true,
      data: {
        PageID: 1156, //
        Page_id: 1156, //送信に使っているが本来は変換する
        Report_id: null, //
        UpdateType: "update",
        KeyID: [7996],
        KeyMap: {
          FID7996: 0,
        },
        Recodes: {
          FID7989: { value: "", formType: 18 },
          FID7990: { value: "", formType: 18 },
          FID7991: { value: "", formType: 18 },
          FID7992: { value: "", formType: 18 },
          FID7993: { value: "", formType: 18 },
          FID8269: { value: "四輪車", formType: 2 },
          FID7995: { value: "通勤車", formType: 2 },
          FID7996: { value: "", formType: 1 },
          FID8334: { value: "", formType: 1 },
          FID8323: { value: "", formType: 3 },
          FID8001: { value: "", formType: 9 },
          FID8009: { value: "", formType: 10 },
          FID8313: { value: "", formType: 16 },
          FID8324: { value: "", formType: 3 },
          FID8002: { value: "", formType: 9 },
          FID8010: { value: "", formType: 10 },
          FID8314: { value: "", formType: 16 },
          FID8325: { value: "", formType: 3 },
          FID8003: { value: "", formType: 9 },
          FID8011: { value: "", formType: 10 },
          FID8315: { value: "", formType: 16 },
          FID8327: { value: "", formType: 9 },
          FID8328: { value: "", formType: 10 },
          FID8329: { value: "", formType: 16 },
          FID8330: { value: "", formType: 16 },
          FID9207: { value: "", formType: 1 },
          FID8004: { value: "", formType: 16 },
          FID8504: { value: "承認済", formType: 1 },
          FID8494: { value: "1214", formType: 17, subReports: [] },
          FID8503: { value: "", formType: 20 },
          FID8585: { value: "", formType: 22 },
          FID8747: { value: "", formType: 20 },
          FID9575: { value: "", formType: 20 },
        },
      },
      subReport1214: {
        PageID: 1214, //
        Page_id: 1214, //送信に使っているが本来は変換する
        Report_id: null, //
        UpdateType: "saveOldValue",
        KeyID: [8496, 8541],
        KeyMap: {},
        Recodes: {
          FID8496: { value: "申請前", formType: 2 },
          FID8567: { value: "", formType: 16 },
          FID8568: { value: "", formType: 16 },
          FID8497: { value: "", formType: 1 },
          FID8541: { value: "", formType: 2 },
          FID8542: { value: "", formType: 1 },
          FID8562: { value: "", formType: 1 },
          FID8491: { value: "1213", formType: 17, subReports: [] },
          FID8502: { value: "", formType: 20 },
        },
      },

      //
      sendFlag: false,
      uploadfile: { file: {}, url: "", data: { name: "", size: 0, type: "" } },
      header_flag: true,
      reports: [],
      rules: {
        fileSize: (value) => {
          return (
            value == null ||
            value.size < 15000000 ||
            "ファイルサイズを15MB未満にしてください"
          );
        },
      },
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {
    headers() {
      return [
        { text: "車両番号", value: "Recodes.FID7996.value", sortable: true },
        { text: "社員コード", value: "Recodes.FID7989.value", sortable: true },
        { text: "氏名", value: "Recodes.FID7990.value", sortable: true },
        {
          text: "事務所・事業所",
          value: "Recodes.FID7991.value",
          sortable: true,
        },
        { text: "部署", value: "Recodes.FID7992.value", sortable: true },
        { text: "従業員区分", value: "Recodes.FID7993.value", sortable: true },
        {
          text: "四輪車・バイク",
          value: "Recodes.FID8269.value",
          sortable: true,
        },
        { text: "使用用途", value: "Recodes.FID7995.value", sortable: true },
        //{ text: "備考欄", value: "Recodes.FID8334.value", sortable: true },
        {
          text: "運転免許証有効期限",
          value: "Recodes.FID8323.value",
          sortable: true,
        },
        { text: "車検満了日", value: "Recodes.FID8324.value", sortable: true },
        {
          text: "自動車保険満了日",
          value: "Recodes.FID8325.value",
          sortable: true,
        },
      ];
    },
  },
  methods: {
    async getInit() {
      //初期処理
      this.isLoading = true;
      //
      await store.commit("setDepartmentAll");
      //読み込み終了
      this.isLoading = false;
    },
    /*-----------------------------*/
    //文字コードのチェック
    charCodeConvert(file) {
      const Encoding = require("encoding-japanese");
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          //文字列をバイト列に変換
          var sjisArray = new Uint8Array(reader.result);
          const encoding = this.detectEncoding(reader.result);
          switch (encoding) {
            case "SJIS":
              var unicodeArray = Encoding.convert(sjisArray, {
                to: "UNICODE",
                from: "SJIS",
                type: "string",
              });
              resolve(unicodeArray);
              break;
            case "UTF8":
              var unicodeArray2 = Encoding.convert(sjisArray, {
                to: "UNICODE",
                from: "AUTO",
                type: "string",
              });
              resolve(unicodeArray2);
              break;
            default:
              alert("文字コードが対応していません:" + encoding);
          }
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    },
    detectEncoding(buffer) {
      const uint8Array = new Uint8Array(buffer);
      if (
        uint8Array[0] === 0xef &&
        uint8Array[1] === 0xbb &&
        uint8Array[2] === 0xbf
      ) {
        return "UTF8";
      } else if (uint8Array[0] === 0xfe && uint8Array[1] === 0xff) {
        return "UTF16BE";
      } else if (uint8Array[0] === 0xff && uint8Array[1] === 0xfe) {
        return "UTF16LE";
      } else if (
        uint8Array[0] === 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0xfe &&
        uint8Array[3] === 0xff
      ) {
        return "UTF32BE";
      } else if (
        uint8Array[0] === 0xff &&
        uint8Array[1] === 0xfe &&
        uint8Array[2] === 0 &&
        uint8Array[3] === 0
      ) {
        return "UTF32LE";
      } else if (
        uint8Array[0] === 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0 &&
        uint8Array[3] !== 0
      ) {
        return "UTF32BE-noBOM";
      } else if (
        uint8Array[0] !== 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0 &&
        uint8Array[3] === 0
      ) {
        return "UTF32LE-noBOM";
      } else {
        return "SJIS";
      }
    },
    /*ダブルクォーテーション内のカンマは分割しない*/
    csvSplit(line) {
      let c = "";
      let s = new String();
      let data = new Array();
      let singleQuoteFlg = false;

      for (let i = 0; i < line.length; i++) {
        c = line.charAt(i);
        if (c == "," && !singleQuoteFlg) {
          data.push(s.toString());
          s = "";
        } else if (c == "," && singleQuoteFlg) {
          s = s + c;
        } else if (c == '"') {
          singleQuoteFlg = !singleQuoteFlg;
        } else {
          s = s + c;
        }
      }
      //最後のデータを追加
      data.push(s.toString());
      return data;
    },
    //現在のレポートとCSVの主キーが同じか確認する
    sameKeyCheck(data, csv) {
      let flag = true;
      Object.keys(data.KeyMap).map((key) => {
        const x = key;
        const y = data.KeyMap[key];
        if (data.Recodes[x].value == "" || csv[y] == "") {
          flag = false;
        }
        if (data.Recodes[x].value != csv[y]) {
          flag = false;
        }
      });
      return flag;
    },
    setValue(report, csv, to, from, tag) {
      if (typeof tag == "undefined") {
        if (typeof csv[from] != "undefined" && csv[from] != "") {
          report.Recodes[to].value = String(csv[from]).trim();
        }
        return;
      } else {
        switch (tag) {
          case "add":
            report.Recodes[to].value += String(csv[from]).trim();
            break;
        }
      }
    },
    async chargeExcel(event) {
      this.isLoading = true;
      try {
        const file = event;
        this.isLoading = false;
        this.reports = [];
        if (file) {
          const reader = new FileReader();
          reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]]; //シート番号
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            //先頭削除
            jsonData.shift();
            //データ処理
            this.reports = this.convertMyCarReports(jsonData);
            this.sendFlag = true;
            //バリデーションチェック
            this.isLoading = false;
            await this.$nextTick();
            await this.checkValidation();
          };
          reader.readAsArrayBuffer(file);
        }
        this.isLoading = false;
      } catch (e) {
        this.reports = [];
        console.log("chargeExcel", e);
      }
    },
    convertMyCarReports(csv) {
      let data = [];
      let mainReport = JSON.parse(JSON.stringify(this.data));
      //
      let today = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        new Date().getHours() + 9
      )
        .toISOString()
        .match(/^\d{4}-\d{2}-\d{2}/g)
        .pop();
      //1行ごとに読み込む
      csv.forEach((c, index) => {
        if (c.length > 9 && c[0] != null && c[0] != "") {
          //主キーチェック
          if (index > 0) {
            data.push(mainReport);
          }
          console.log("convertExcelDate:", c);
          mainReport = JSON.parse(JSON.stringify(this.data));

          mainReport.Recodes.FID7996.value = this.toHalfWidth(c[0]); //車両番号
          this.setValue(mainReport, c, "FID7989", 1); //社員コード
          this.setValue(mainReport, c, "FID7990", 2); //氏名
          this.setValue(mainReport, c, "FID7991", 4); //事業本部コード
          this.setValue(mainReport, c, "FID7992", 6); //部署コード
          //
          c[7] = convertHalfWidthToFullWidthKana(String(c[7]));
          this.setValue(mainReport, c, "FID7993", 7); //従業員区分

          //四輪車・バイク 
          if (c[8] != "バイク") {
            mainReport.Recodes.FID8269.value = "四輪車";
          } else {
            mainReport.Recodes.FID8269.value = "バイク";
          }

          //使用用途
          if (c[9] == "通勤" || c[9] == "通勤車") {
            mainReport.Recodes.FID7995.value = "通勤車";
          } else {
            //c[9] == "通勤/業務"
            mainReport.Recodes.FID7995.value = "通勤車/業務使用車";
          }
          this.setValue(mainReport, c, "FID8334", 10); //備考欄
          mainReport.Recodes.FID8323.value = String(convertExcelDate(c[11])); //運転免許証有効期限
          mainReport.Recodes.FID8324.value = String(convertExcelDate(c[12])); //車検満了日
          mainReport.Recodes.FID8325.value = String(convertExcelDate(c[13])); //自動車保険満了日
          //承認日時
          mainReport.Recodes.FID8313.value = this.getDateTime();
          mainReport.Recodes.FID8314.value = this.getDateTime();
          mainReport.Recodes.FID8315.value = today;
          //
          mainReport.Recodes.FID8504.value = "承認済";
        }
        //サブレポート宣言処理
        let subReport1214 = JSON.parse(JSON.stringify(this.subReport1214));
        subReport1214.Recodes.FID8496.value = "承認済";
        subReport1214.Recodes.FID8497.value = "システムによる承認";
        subReport1214.Recodes.FID8541.value = "システムによる承認";
        subReport1214.Recodes.FID8542.value = "システムによる承認";
        subReport1214.Recodes.FID8562.value = "システムによる承認";
        //サブレポートの追加処理
        mainReport.Recodes.FID8494.subReports.push(subReport1214);
      });
      data.push(mainReport);
      //
      return data;
    },

    async send() {
      const isCorrectValue = this.checkValidation();
      if (isCorrectValue == false) return;
      if (this.reports.length >= 10000) {
        alert("取り込み件数が上限10000件を超えています");
        return;
      }
      this.isLoading = true;
      const result = await api.ImportCSV(this.reports);
      if (result == undefined || result.result != "Success") {
        alert("データが登録できませんでした。");
      } else {
        alert("登録完了");
      }

      this.isLoading = false;
      history.back();
    },
    checkValidation: function () {
      let form = this.$refs.form;
      let check = form.validate();

      return check;
    },
    back() {
      history.back();
    },
    async downloadSampleExcel() {
      await localDownload.myCarExcel();
    },
    getDateTime() {
      let date = new Date();
      let years = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let days = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let seconds = date.getSeconds().toString().padStart(2, "0");
      return (
        years +
        "-" +
        month +
        "-" +
        days +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    toHalfWidth(str) {
      // 全角英数字を半角に変換
      str = str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
      });
      return str;
    },
  },
};
</script>
