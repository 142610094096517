<template>
  <v-card :height="resizeHeight" :width="resizeWidth">
    <v-card-actions class="">
      <v-container>
        <div class="text-center">
          <v-form ref="form" v-model="valid">
            <v-row>
              <v-col>
                <v-text-field
                  :value="hour + ':' + minute"
                  :label="title"
                  :rules="rules"
                  style="color: red"
                  type="text"
                  disabled
                  outlined
                  class="custom-label-color text-h5"
                  background-color="rgb(224, 224, 224)"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <!-- ------>
            <v-row justify="center">
              <v-col cols="6">
                <v-virtual-scroll
                  :items="hours"
                  :item-height="itemHeight"
                  :height="scrollSizeH"
                  :width="scrollSizeW"
                >
                  <template v-slot:default="{ item }">
                    <v-btn
                      :height="buttonHeihgt"
                      :width="buttonWidth"
                      :color="getVtnColor(hour, item)"
                      class="mr-1 mb-1 white--text"
                      :style="style"
                      @click="setHour(item)"
                      ref="hours"
                    >
                      {{ item }}
                    </v-btn>
                  </template>
                </v-virtual-scroll>
              </v-col>
              <v-col cols="6">
                <v-virtual-scroll
                  :items="minutes"
                  :item-height="itemHeight"
                  :height="scrollSizeH"
                  :width="scrollSizeW"
                >
                  <template v-slot:default="{ item }">
                    <v-btn
                      :height="buttonHeihgt"
                      :width="buttonWidth"
                      :color="getVtnColor(minute, item)"
                      class="mr-1 mb-1 white--text"
                      :style="style"
                      @click="setMinutes(item)"
                      ref="minutes"
                    >
                      {{ item }}
                    </v-btn>
                  </template>
                </v-virtual-scroll>
              </v-col>
            </v-row>
            <!-- ------>
            <v-row justify="center">
              <v-btn
                :height="buttonHeihgt"
                :width="buttonWidth"
                :color="color"
                class="mr-1 mb-1 white--text"
                :style="style"
                @click="inputNow"
              >
                現在時刻
              </v-btn>
              <v-btn
                :height="buttonHeihgt"
                :width="buttonWidth"
                :color="color"
                class="mr-1 mb-1 white--text"
                :style="style"
                @click="submit"
              >
                OK
              </v-btn>
            </v-row>
          </v-form>
        </div>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  components: {},
  props: {
    value: String,
    title: String,
    rules: Array,
    initValue: String,
    minuteInterval: Number,
    submitClose: { type: Boolean, default: false },
  },
  data() {
    return {
      returnData: {
        value: this.value,
      },
      look: false,
      vtnColor: "blue-grey",
      vtnSelectColor: "blue-grey darken-4",
      color: "blue-grey",
      style: "border-radius: 0px;font-size:20px",
      //
      itemHeight: 64,
      scrollSizeH: 300,
      scrollSizeW: 250,
      //
      valid: true,
      hours: [...Array(24)].map((_, i) => i),
      minutes: [...Array(60)].map((_, i) => {
        return i;
      }),
      hour: "00",
      minute: "00",
    };
  },
  async mounted() {
    await this.init();
    document.addEventListener("keydown", this.onKeyDown);
    if (this.minuteInterval != 0) {
      this.minutes = this.minutes.filter(
        (arr) => arr % this.minuteInterval == 0
      );
    }
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.onKeyDown);
  },
  computed: {
    resizeHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "480px";
        case "sm":
          return "580px";
        case "md":
          return "580px";
        case "lg":
          return "580px";
        case "xl":
          return "580px";
        default:
          return "580px";
      }
    },
    resizeWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "270px";
        case "sm":
          return "500px";
        case "md":
          return "600px";
        case "lg":
          return "500px";
        case "xl":
          return "500px";
        default:
          return "500px";
      }
    },
    buttonHeihgt() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "44px";
        case "sm":
          return "60px";
        case "md":
          return "55px";
        case "lg":
          return "60px";
        case "xl":
          return "60px";
        default:
          return "44px";
      }
    },
    buttonWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "80px";
        case "sm":
          return "180px";
        case "md":
          return "180px";
        case "lg":
          return "180px";
        case "xl":
          return "180px";
        default:
          return "70px";
      }
    },
  },

  methods: {
    onKeyDown(event) {
      //let v = this.returnData.value;
      switch (event.keyCode) {
        case 13:
          this.submit();
          break;
        default:
          console.log("athoerkey", typeof event.keyCode, event.keyCode);
      }
    },
    async init() {
      let tmp = this.value.split(":");
      //
      if (this.value == null || tmp.length < 2) {
        this.returnData.value = "00:00";
      } else {
        this.hour = tmp[0];
        this.minute = tmp[1];
        this.returnData.value = this.value;
      }
      await this.VtnScroll();
    },
    submit() {
      if (this.$refs.form.validate()) {
        this.returnData.value = this.hour + ":" + this.minute;
        this.$emit("ok", this.returnData.value);
      }
    },
    setHour(value) {
      if (value < 10) {
        this.hour = "0" + String(value);
      } else {
        this.hour = value;
      }
    },
    setMinutes(value) {
      if (value < 10) {
        this.minute = "0" + String(value);
      } else {
        this.minute = value;
      }
    },
    async inputNow() {
      const japanStandardTime = new Date().toLocaleString({
        timeZone: "Asia/Tokyo",
      });
      this.setHour((this.hour = new Date(japanStandardTime).getHours()));
      this.setMinutes(new Date(japanStandardTime).getMinutes());
      await this.VtnScroll();
      if (this.submitClose) {
        await this.submit();
      }
    },
    getVtnColor(target, value) {
      if (Number(target) == Number(value)) {
        return this.vtnSelectColor;
      }
      return this.vtnColor;
    },
    async VtnScroll() {
      this.$nextTick(async () => {
        const virtual = document.getElementsByClassName("v-virtual-scroll");
        //
        if (virtual == null || virtual.length < 1) {
          return;
        }
        const hDiv = virtual[0];
        const mDiv = virtual[1];
        const _sleep = (ms) =>
          new Promise((resolve) => setTimeout(resolve, ms));
        await _sleep(150); //DOMがマウントされないので待機
        this.$set(hDiv, "scrollTop", (Number(this.hour) - 2) * this.itemHeight);
        this.$set(
          mDiv,
          "scrollTop",
          (Number(this.minute) - 2) * this.itemHeight
        );
      });
    },
  },
};
</script>
<style lang="css" scoped>
.custom-label-color input {
  color: black !important;
}
</style>
