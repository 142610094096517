import { downLoadCSV, getValue } from "./csvExport.js";
import store from "@/store/vuex.js";
export function companyCar(reports, name) {
  let header =
    "初回登録日,最終更新日,登録者,社員番号,管理本部,管理部門,部署,登録事由,初期日・異動日,自動車番号または車両番号,車台番号,旧車番,使用区分,初年度登録月,有効期間の満了する日,車名,車種名,型式,自動車の種別,種別・用途,自家用・事業用の別,最大積載量,車検証,ダウンロード,新規申請,ステータス,更新,削除,";
  let csv = "";
  reports.map((report) => {
    let line = "";
    line += getValue(report.Recodes.FID8099) + ","; //初回登録日
    line += getValue(report.Recodes.FID8100) + ","; //最終更新日
    line += getValue(report.Recodes.FID8103) + ","; //登録者
    line += getValue(report.Recodes.FID8104) + ","; //社員番号
    if (line == "") {
      line += store.state.organization[getValue(report.Recodes.FID8321)] + ","; //管理本部
      line += store.state.organization[getValue(report.Recodes.FID9238)] + ","; //管理部門
      line += store.state.organization[getValue(report.Recodes.FID8322)] + ","; //部署
    } else {
      line += getValue(report.Recodes.FID8321) + ","; //管理本部
      line += getValue(report.Recodes.FID9238) + ","; //管理部門
      line += getValue(report.Recodes.FID8322) + ","; //部署
    }
    line += getValue(report.Recodes.FID8101) + ","; //登録事由
    line += getValue(report.Recodes.FID8271) + ","; //初期日・異動日
    line += getValue(report.Recodes.FID8007) + ","; //自動車番号または車両番号
    line += getValue(report.Recodes.FID8113) + ","; //車台番号
    line += getValue(report.Recodes.FID8272) + ","; //旧車番
    line += getValue(report.Recodes.FID8613) + ","; //使用区分
    line += getValue(report.Recodes.FID8318) + ","; //初年度登録月
    line += getValue(report.Recodes.FID8319) + ","; //有効期間の満了する日
    line += getValue(report.Recodes.FID8111) + ","; //車名
    line += getValue(report.Recodes.FID8112) + ","; //車種名
    line += getValue(report.Recodes.FID8114) + ","; //型式
    line += getValue(report.Recodes.FID8108) + ","; //自動車の種別
    line += getValue(report.Recodes.FID8109) + ","; //種別・用途
    line += getValue(report.Recodes.FID8116) + ","; //自家用・事業用の別
    line += getValue(report.Recodes.FID8110) + ","; //最大積載量
    line += getValue(report.Recodes.FID8333) + ","; //車検証
    line += getValue(report.Recodes.FID8532) + ","; //ダウンロード
    line += getValue(report.Recodes.FID8534) + ","; //新規申請
    line += getValue(report.Recodes.FID8505) + ","; //ステータス
    line += getValue(report.Recodes.FID9252) + ","; //更新
    line += getValue(report.Recodes.FID9253) + ","; //削除
    line += "\n";
    csv += line;
  });
  let file = "\ufeff" + header + "\n" + csv;
  //
  downLoadCSV(file, name);
}
