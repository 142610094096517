/*
    マイカー添付書類確認
    マイカー一覧
    社有車一覧
    社有車一覧(全件)
*/

export function downLoadCSV(file, name) {
  //CSV出力部分
  let blob = new Blob([file], { type: "text/csv" });
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = name + ".csv";
  link.click();
  console.log("downLoadCSV完了");
}

export function getValue(v) {
  if (typeof v == "undefined") {
    return "";
  }
  return `"${v.value}"`;
}
