<template>
  <div class="body">
    <p align="center" class="title">マイカー一覧(全車両)</p>
    <v-row>
      <v-col align="left" class="button"
        ><v-btn @click="create">新規作成</v-btn></v-col
      >

      <v-col align="right" class="button">
        <v-btn
          @click="makeCSVDownload"
          class="mr-5"
          color="orange white--text"
          :disabled="isLoading"
        >
          CSV出力
        </v-btn>

        <v-btn @click.once="back">戻る</v-btn>
      </v-col>
    </v-row>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-form ref="form" v-model="valid">
        <v-row class="ml-2 mr-1">
          <v-col cols="0">
            <v-autocomplete
              label="事業部"
              v-model="keyword9200"
              background-color="white"
              id="FID9200"
              class="searchForm selectBox9200"
              no-data-text="データが見つかりません"
              :items="selectBoxFID9200"
              item-text="label"
              item-value="value"
              outlined
              clearable
              hide-details="auto"
            >
            </v-autocomplete> </v-col
          ><v-col cols="0">
            <v-autocomplete
              label="部署"
              v-model="keyword9198"
              background-color="white"
              id="FID9198"
              class="searchForm selectBox9198"
              no-data-text="データが見つかりません"
              :items="selectBoxFID9198"
              item-text="label"
              item-value="value"
              outlined
              clearable
              hide-details="auto"
            >
            </v-autocomplete> </v-col
          ><v-col cols="0">
            <v-text-field
              label="車両番号"
              v-model="keyword9199"
              background-color="white"
              id="FID9199"
              class="searchForm"
              outlined
              clearable
              hide-details="auto"
            >
            </v-text-field> </v-col
          ><v-col cols="0"
            ><v-btn x-large @click="search()" color="primary white--text"
              >検索</v-btn
            ></v-col
          >
        </v-row>
        <v-row class="ml-2 mr-1"> </v-row>
        <v-row class="ml-2 mr-1">
          <v-col cols="">
            <v-data-table
              class="reportIndex"
              :headers="headers"
              :items="reports"
              :item-key="reports.ID"
              :page="
                $store.state.pageNum == undefined ? 1 : $store.state.pageNum
              "
              @update:page="updatePageNum"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              @click:row="move"
              :header-props="{
                'sort-by-text': '並び順',
              }"
              :footer-props="{
                'items-per-page-text': '行/ページ:',
              }"
              no-data-text="データが存在しません"
              :items-per-page="displayResults"
              @update:sort-by="updateSortBy"
              @update:sort-desc="updateSortDesc"
              :custom-sort="customSort"
            >
              <template v-slot:item.Target="{ item }">
                <v-chip
                  v-if="getLabel(item) != ''"
                  :color="getIconColor(item)"
                  class="white--text"
                >
                  {{ getLabel(item) }}
                </v-chip></template
              ><template v-slot:item.Recodes.FID7991.value="{ item }">
                {{ $store.state.organization[item.Recodes.FID7791.value] }}
              </template>
              <template v-slot:item.Recodes.FID7992.value="{ item }">
                {{ $store.state.organization[item.Recodes.FID7992.value] }}
              </template>
              <template v-slot:item.Recodes.FID8323.value="{ item }">
                <td :class="itemRowBackground(item.Recodes.FID8323.value)">{{item.Recodes.FID8323.value}}</td>
              </template>
              <template v-slot:item.Recodes.FID8324.value="{ item }">
                <td :class="itemRowBackground(item.Recodes.FID8324.value)">{{item.Recodes.FID8324.value}}</td>
              </template>
              <template v-slot:item.Recodes.FID8325.value="{ item }">
                <td :class="itemRowBackground(item.Recodes.FID8325.value)">{{item.Recodes.FID8325.value}}</td>
              </template>
              <template v-slot:item.link9197="{ item }">
                <v-btn @click.stop="link9197(item)" color="indigo white--text"
                  >車両利用申請</v-btn
                >
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
import { getDivisionAll } from "./specialMethod";
import { getDepartmentAll } from "./specialMethod";
import { MyCar } from "./csvExport/myCar";

export default {
  name: "ReportIndex",
  components: {},
  data() {
    return {
      valid: true,
      initEscape: false,
      isLoading: false,
      fullPage: false,
      displayResults: 15, //5,10,15のいずれか
      height: 100,
      width: 250,
      path: "",
      sortBy: null,
      sortDesc: null,
      //
      PageID: 1156,
      reports: [],
      rules: {
        required: (value) => !!value || "必須項目です",
        requiredArray: (value) => (value.length > 0 ? true : "必須項目です"),
        fullWidth: (value) => {
          return /^[^ -~｡-ﾟ]*$/.test(value) || "全角のみ入力可能です";
        },
      },
      selectBoxFID9200: [],
      keyword9200: "",
      selectBoxFID9198: [],
      keyword9198: "",
      keyword9199: "",
    };
  },
  async created() {
    await this.getInit();
  },
  methods: {
    async getInit() {
      //タイトルをデフォルトに戻す
      if (typeof process.env.VUE_APP_BASE_TITLE != "undefined") {
        document.title = process.env.VUE_APP_BASE_TITLE;
      }
      //初期処理
      this.isLoading = true;
      this.getKeyWord(this.$store.state.keyWord);
      this.path = this.$route.path;
      //データ取得
      if (JSON.stringify(this.$store.state.reports) != "{}") {
        this.reports = this.$store.state.reports;
      } else {
        let profile;
        [, profile] = await Promise.all([this.search(), api.GetRole()]);
        store.commit("setRole", profile.Role);
      }
      if (this.$store.state.tableSort.sortBy != null) {
        this.sortBy = this.$store.state.tableSort.sortBy;
        this.sortDesc = this.$store.state.tableSort.sortDesc;
      }
      this.reports = this.reports.reverse();
      this.selectBoxFID9200 = await getDivisionAll();
      this.selectBoxFID9198 = await getDepartmentAll();

      this.isLoading = false;
    },
    getLabel(report) {
      if (
        typeof report.Recodes.FID8504 != "undefined" &&
        (report.Recodes.FID8504.value == "" ||
          report.Recodes.FID8504.value == null)
      ) {
        return "";
      }
      if (
        typeof report.Recodes.FID8504 != "undefined" &&
        report.Recodes.FID8504.value == "申請前"
      ) {
        return "一時保存";
      }
      if (
        typeof report.Recodes.FID8504 != "undefined" &&
        report.Recodes.FID8504.value == "承認済"
      ) {
        return "承認済";
      }
      if (
        typeof report.Recodes.FID8504 != "undefined" &&
        report.Recodes.FID8504.value == "棄却"
      ) {
        return "棄却";
      }
      if (
        typeof report.Recodes.FID8504 != "undefined" &&
        report.Recodes.FID8504.value != "" &&
        report.Recodes.FID8504.value != null
      ) {
        return "申請中";
      }
      return "";
    },
    getIconColor(report) {
      if (
        typeof report.Recodes.FID8504 != "undefined" &&
        (report.Recodes.FID8504.value == "" ||
          report.Recodes.FID8504.value == null)
      ) {
        return "white";
      }
      if (
        typeof report.Recodes.FID8504 != "undefined" &&
        report.Recodes.FID8504.value == "申請前"
      ) {
        return "pink";
      }
      if (
        typeof report.Recodes.FID8504 != "undefined" &&
        report.Recodes.FID8504.value == "承認済"
      ) {
        return "grey";
      }
      if (
        typeof report.Recodes.FID8504 != "undefined" &&
        report.Recodes.FID8504.value == "棄却"
      ) {
        return "blue lighten-4";
      }
      if (
        typeof report.Recodes.FID8504 != "undefined" &&
        report.Recodes.FID8504.value != "" &&
        report.Recodes.FID8504.value != null
      ) {
        return "primary";
      }
      return "";
    },
    link9197(item) {
      this.isLoading = true;
      this.$router.push({ path: "/applicationform/index" });
      this.isLoading = false;
      localStorage.setItem("kakanaiValueFID7996", item.Recodes.FID7996.value);
      store.commit("clearreports");
    },
    getKeyWord(data) {
      if (data.PageID == this.PageID) {
        data.search.forEach((data) => {
          if (data.form_id == 7991) {
            this.keyword9200 = data.value;
          }
          if (data.form_id == 7992) {
            this.keyword9198 = data.value;
          }
          if (data.form_id == 7996) {
            this.keyword9199 = data.value;
          }
        });
      }
    },
    getSearchWord() {
      let data = {
        PageID: this.PageID,
        orderBy: "",
        search: [],
      };

      if (this.keyword9200 != "") {
        data.search.push({ form_id: 7991, value: this.keyword9200 });
      }
      if (this.keyword9198 != "") {
        data.search.push({ form_id: 7992, value: this.keyword9198 });
      }
      if (this.keyword9199 != "") {
        data.search.push({ form_id: 7996, value: this.keyword9199 });
      }

      return data;
    },
    async search() {
      if (this.initEscape == false) {
        this.initEscape = true;
        this.isLoading = false;
        await this.$nextTick();
      }
      const ck = await this.checkValidation();
      if (!ck && !this.isLoading) {
        return;
      }
      //初期処理
      this.isLoading = true;
      //データ追加
      let data = await this.getSearchWord();
      //api
      const reports = await api.ReportSearch(data);
      //SearchTag
      if (reports != null) {
        this.reports = reports;
        store.commit("setreports", reports);
      }
      store.commit("setkeyWord", data);
      this.isLoading = false;
    },

    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }

      return check;
    },
    create() {
      this.isLoading = true;
      this.$router.push({ path: "/mycar/create" });

      this.isLoading = false;
    },
    move(data) {
      this.isLoading = true;
      this.$router.push({ path: "/mycar/create/" + data.ReportID });

      this.isLoading = false;
    },
    back() {
      store.commit("clearkeyWord");
      store.commit("clearreports");
      store.commit("clearPageNum");
      store.commit("clearTableSort");
      store.commit("clearkeyWord");

      //
      if (this.$route.path == this.path) {
        history.back();
      } else {
        alert("読み込みエラー:ホームに戻ります");
        this.$router.push("/", () => {});
      }
      //history.back();
    },
    getValue(v) {
      if (typeof v == "undefined") {
        return "";
      }
      return v.value;
    },
    downloadCSV(csv, filename) {
      //CSV出力部分
      let blob = new Blob([csv], { type: "text/csv" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },
    updatePageNum(value) {
      store.commit("setPageNum", value);
    },
    updateSortBy(value) {
      store.commit("setTableSortBy", value);
    },
    updateSortDesc(value) {
      store.commit("setTableSortDesc", value);
    },
    customSort(items, index, isDesc) {
      //デフォルト
      if (index.length == 0 || items.length == 0) {
        return items;
      }
      const regex = /[^0-9]/g;
      const order = isDesc[0] ? 1 : -1;
      const pos = parseInt(index[0].replace(regex, ""));
      items.sort((a, b) => {
        //アイコンソートは特殊処理
        if (index == "target") {
          // //アイコンの特殊ソート処理は実装途中
          return 0;
        }
        //
        return this.sort(a, b, order, pos);
      });
      //
      return items;
    },
    sort(a, b, order, pos) {
      if (
        typeof a.Recodes["FID" + pos] == "undefined" ||
        typeof b.Recodes["FID" + pos] == "undefined"
      )
        return -1;
      if (a.Recodes["FID" + pos].value == b.Recodes["FID" + pos].value)
        return 0;
      if (a.Recodes["FID" + pos].value === "") return 1;
      if (b.Recodes["FID" + pos].value === "") return -1;
      if (
        !isNaN(a.Recodes["FID" + pos].value) &&
        !isNaN(b.Recodes["FID" + pos].value)
      ) {
        return Number(a.Recodes["FID" + pos].value) ==
          Number(b.Recodes["FID" + pos].value)
          ? 0
          : Number(a.Recodes["FID" + pos].value) >
            Number(b.Recodes["FID" + pos].value)
          ? -1 * order
          : order;
      }
      return (
        a.Recodes["FID" + pos].value.localeCompare(
          b.Recodes["FID" + pos].value,
          "jp"
        ) *
        -1 *
        order
      );
    },
    dateSort(dateArr) {
      const date1 = new Date(dateArr[0].replace(/-/g, "/"));
      const date2 = new Date(dateArr[1].replace(/-/g, "/"));
      if (date2 - date1 > 0) {
        return dateArr;
      } else {
        return [dateArr[1], dateArr[0]];
      }
    },
    async makeCSVDownload() {
      let name = "マイカー一覧(全車両)";
      await store.commit("setDepartmentAll");
      MyCar(this.reports, name);
    },
    // 有効期限3か月前よりアラート表示をする
    itemRowBackground(dateValue) {
      // 各期限日
      let targetDate =  new Date(dateValue)
      // 今日の3か月後を取得
      let date3MonthAfterFromNow = new Date().setMonth(new Date().getMonth() + 3);
      // 今日
      let now = new Date();
      
      if (now > targetDate) {
        // 今日が期限を過ぎているか
        return 'red-alert'
      } else if (targetDate <= date3MonthAfterFromNow) {
        // 対象の日が今日より３か月後以内かどうか
        return 'yellow-alert'
      }
      return ""
    },
  },
  computed: {
    headers() {
      return [
        { text: "承認", value: "Target", align: "start", sortable: false },
        { text: "車両番号", value: "Recodes.FID7996.value", sortable: true },
        { text: "部署", value: "Recodes.FID7992.value", sortable: true },
        { text: "氏名", value: "Recodes.FID7990.value", sortable: true },
        { text: "使用用途", value: "Recodes.FID7995.value", sortable: true },
        { text: "備考欄", value: "Recodes.FID8334.value", sortable: true },
        {
          text: "運転免許証有効期限",
          value: "Recodes.FID8323.value",
          sortable: true,
        },
        {
          text: "自賠責満了日",
          value: "Recodes.FID8324.value",
          sortable: true,
        },
        {
          text: "自動車保険満了日",
          value: "Recodes.FID8325.value",
          sortable: true,
        },
        { text: "車両利用申請", value: "link9197", sortable: false },
      ];
    },
  },
};
</script>
<style>
.red-alert {
  background-color: #FF7D7D !important;
}
.yellow-alert {
  background-color: #FFFF00 !important;
}
</style>
