<template>
  <div class="home">
    <p>組織図取り込み</p>
    <v-col align="right"><v-btn @click.once="back">戻る</v-btn></v-col>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-container cols="12">
        <v-form ref="form">
          <v-row>
            <v-col align="left">
              <v-btn @click="generateRentalCar" color="blue white--text">
                全部署のレンタカーを作成
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="checkOrganization" color="blue white--text">
                部署コードの重複チェック
              </v-btn>
            </v-col>
            <v-col align="left"> 部署の重複:{{ errors }} </v-col>
          </v-row>
          <v-row>
            注意1:取り込んだファイルに含まれていない部署・店舗は削除されます<br />
            注意2:部署コードを基準に変更されます。<br />
            注意3:E列【事業本部】の配下にない「監査室以下」の情報は取り込まれません。<br />
          </v-row>
          <v-row class="mt-7">
            <v-col cols="8">
              <v-file-input
                v-model="uploadfile.file"
                type="file"
                label="エクセル取り込み(xlsx)"
                prepend-icon="mdi-image"
                ref="rfafile"
                accept=".xlsx,.xlsm"
                @change="chargeExcel"
                :rules="[rules.fileSize]"
                show-size
                outline
              ></v-file-input>
            </v-col>
          </v-row>
          <v-row class="mt-7">
            <v-col align="left">
              <v-btn @click="downloadSampleExcel" color="green white--text">
                取り込み用サンプルエクセル
              </v-btn>
            </v-col>
            <v-col align="right">
              <v-btn @click="send" color="primary" :disabled="!sendFlag" large>
                送信
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-container>
      <v-row>
        <v-col cols="">
          <v-data-table
            :headers="headers"
            :items="reports"
            :item-key="reports.ID"
            :page="$store.state.pageNum == undefined ? 1 : $store.state.pageNum"
            :sort-by="sortBy"
            :sort-desc="sortDesc"
            :header-props="{
              'sort-by-text': '並び順',
            }"
            :footer-props="{
              'items-per-page-text': '行/ページ:',
            }"
            no-data-text="データが存在しません"
            :items-per-page="displayResults"
            @update:page="updatePageNum"
            @update:sort-by="updateSortBy"
            @update:sort-desc="updateSortDesc"
            :custom-sort="customSort"
          >
            <template v-slot:no-results>
              <span>データが存在しません</span>
            </template>

            <!-- ---->
            <template v-slot:item="{ item, index }" v-ripple>
              <template
                v-if="
                  typeof item.Recodes.FID7986?.subReports != 'undefined' &&
                  item.Recodes.FID7986.subReports != null &&
                  item.Recodes.FID7986.subReports.length > 0
                "
              >
                <template v-for="(sub0, i0) in item.Recodes.FID7986.subReports">
                  <template
                    v-if="
                      typeof sub0.Recodes.FID7987?.subReports != 'undefined' &&
                      sub0.Recodes.FID7987.subReports != null &&
                      sub0.Recodes.FID7987.subReports.length > 0
                    "
                  >
                    <template
                      v-for="(sub1, i1) in sub0.Recodes.FID7987.subReports"
                    >
                      <template
                        v-if="
                          typeof sub1.Recodes.FID7988?.subReports !=
                            'undefined' &&
                          sub1.Recodes.FID7988.subReports != null &&
                          sub1.Recodes.FID7988.subReports.length > 0
                        "
                      >
                        <template
                          v-for="(sub2, i2) in sub1.Recodes.FID7988.subReports"
                        >
                          <tr
                            :class="index % 2 == 0 ? 'trWhite' : 'trBlue'"
                            :key="
                              'sub-' + index + '-' + i0 + '-' + i1 + '-' + i2
                            "
                          >
                            <td
                              v-if="
                                typeof item.Recodes.FID7977 != 'undefined' &&
                                i2 == 0 &&
                                i1 == 0 &&
                                i0 == 0
                              "
                            >
                              <v-text-field
                                label="事業所名"
                                v-model="item.Recodes.FID7977.value"
                                :disabled="
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  allDisabled ||
                                  item.DeleteFlag
                                "
                                id="FID7977"
                                class="textfield7977 mt-2 mb-1"
                                outlined
                                hide-details="auto"
                              >
                              </v-text-field>
                            </td>
                            <td v-else></td>
                            <td
                              v-if="
                                typeof item.Recodes.FID7978 != 'undefined' &&
                                i2 == 0 &&
                                i1 == 0 &&
                                i0 == 0
                              "
                            >
                              <v-text-field
                                label="事業所コード"
                                v-model="item.Recodes.FID7978.value"
                                :disabled="
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  allDisabled ||
                                  item.DeleteFlag
                                "
                                id="FID7978"
                                class="textfield7978 mt-2 mb-1"
                                outlined
                                hide-details="auto"
                              >
                              </v-text-field>
                            </td>
                            <td v-else></td>
                            <td
                              v-if="
                                typeof sub0.Recodes.FID7980 != 'undefined' &&
                                i2 == 0 &&
                                i1 == 0
                              "
                            >
                              <v-text-field
                                label="部署名"
                                v-model="sub0.Recodes.FID7980.value"
                                :disabled="
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag ||
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag ||
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag
                                "
                                id="FID7980"
                                class="textfield7980 mt-2 mb-1"
                                outlined
                                hide-details="auto"
                              >
                              </v-text-field>
                            </td>
                            <td v-else></td>
                            <td
                              v-if="
                                typeof sub0.Recodes.FID7981 != 'undefined' &&
                                i2 == 0 &&
                                i1 == 0
                              "
                            >
                              <v-text-field
                                label="部署コード"
                                v-model="sub0.Recodes.FID7981.value"
                                :disabled="
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag ||
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag ||
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag
                                "
                                id="FID7981"
                                class="textfield7981 mt-2 mb-1"
                                outlined
                                hide-details="auto"
                              >
                              </v-text-field>
                            </td>
                            <td v-else></td>

                            <td
                              v-if="
                                typeof sub1.Recodes.FID7982 != 'undefined' &&
                                i2 == 0
                              "
                            >
                              <v-text-field
                                label="部門名"
                                v-model="sub1.Recodes.FID7982.value"
                                :disabled="
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag ||
                                  sub1.DeleteFlag ||
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag ||
                                  sub1.DeleteFlag
                                "
                                id="FID7982"
                                class="textfield7982 mt-2 mb-1"
                                outlined
                                hide-details="auto"
                              >
                              </v-text-field>
                            </td>
                            <td v-else></td>
                            <td
                              v-if="
                                typeof sub1.Recodes.FID7983 != 'undefined' &&
                                i2 == 0
                              "
                            >
                              <v-text-field
                                label="部署コード"
                                v-model="sub1.Recodes.FID7983.value"
                                :disabled="
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag ||
                                  sub1.DeleteFlag ||
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag ||
                                  sub1.DeleteFlag
                                "
                                id="FID7983"
                                class="textfield7983 mt-2 mb-1"
                                outlined
                                hide-details="auto"
                              >
                              </v-text-field>
                            </td>
                            <td v-else></td>
                            <td
                              v-if="typeof sub2.Recodes.FID7984 != 'undefined'"
                            >
                              <v-text-field
                                label="部署名"
                                v-model="sub2.Recodes.FID7984.value"
                                :disabled="
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag ||
                                  sub1.DeleteFlag ||
                                  sub2.DeleteFlag
                                "
                                id="FID7984"
                                class="textfield7984 mt-2 mb-1"
                                outlined
                                hide-details="auto"
                              >
                              </v-text-field>
                            </td>
                            <td v-else></td>
                            <td
                              v-if="typeof sub2.Recodes.FID7985 != 'undefined'"
                            >
                              <v-text-field
                                label="部署コード"
                                v-model="sub2.Recodes.FID7985.value"
                                :disabled="
                                  allDisabled ||
                                  item.DeleteFlag ||
                                  sub0.DeleteFlag ||
                                  sub1.DeleteFlag ||
                                  sub2.DeleteFlag
                                "
                                id="FID7985"
                                class="textfield7985 mt-2 mb-1"
                                outlined
                                hide-details="auto"
                              >
                              </v-text-field>
                            </td>
                            <td v-else></td>
                          </tr> </template
                      ></template>
                      <template v-else
                        ><tr
                          :class="index % 2 == 0 ? 'trWhite' : 'trBlue'"
                          :key="'sub-' + index + '-' + i0 + '-' + i1"
                        >
                          <td
                            v-if="
                              typeof item.Recodes.FID7977 != 'undefined' &&
                              i1 == 0 &&
                              i0 == 0
                            "
                          >
                            <v-text-field
                              label="事業所名"
                              v-model="item.Recodes.FID7977.value"
                              :disabled="
                                allDisabled ||
                                item.DeleteFlag ||
                                allDisabled ||
                                item.DeleteFlag ||
                                allDisabled ||
                                item.DeleteFlag
                              "
                              id="FID7977"
                              class="textfield7977 mt-2 mb-1"
                              outlined
                              hide-details="auto"
                            >
                            </v-text-field>
                          </td>
                          <td v-else></td>
                          <td
                            v-if="
                              typeof item.Recodes.FID7978 != 'undefined' &&
                              i1 == 0 &&
                              i0 == 0
                            "
                          >
                            <v-text-field
                              label="事業所コード"
                              v-model="item.Recodes.FID7978.value"
                              :disabled="
                                allDisabled ||
                                item.DeleteFlag ||
                                allDisabled ||
                                item.DeleteFlag ||
                                allDisabled ||
                                item.DeleteFlag
                              "
                              id="FID7978"
                              class="textfield7978 mt-2 mb-1"
                              outlined
                              hide-details="auto"
                            >
                            </v-text-field>
                          </td>
                          <td v-else></td>
                          <td
                            v-if="
                              typeof sub0.Recodes.FID7980 != 'undefined' &&
                              i1 == 0
                            "
                          >
                            <v-text-field
                              label="部署名"
                              v-model="sub0.Recodes.FID7980.value"
                              :disabled="
                                allDisabled ||
                                item.DeleteFlag ||
                                sub0.DeleteFlag ||
                                allDisabled ||
                                item.DeleteFlag ||
                                sub0.DeleteFlag
                              "
                              id="FID7980"
                              class="textfield7980 mt-2 mb-1"
                              outlined
                              hide-details="auto"
                            >
                            </v-text-field>
                          </td>
                          <td v-else></td>
                          <td
                            v-if="
                              typeof sub0.Recodes.FID7981 != 'undefined' &&
                              i1 == 0
                            "
                          >
                            <v-text-field
                              label="部署コード"
                              v-model="sub0.Recodes.FID7981.value"
                              :disabled="
                                allDisabled ||
                                item.DeleteFlag ||
                                sub0.DeleteFlag ||
                                allDisabled ||
                                item.DeleteFlag ||
                                sub0.DeleteFlag
                              "
                              id="FID7981"
                              class="textfield7981 mt-2 mb-1"
                              outlined
                              hide-details="auto"
                            >
                            </v-text-field>
                          </td>
                          <td v-else></td>
                          <td v-if="typeof sub1.Recodes.FID7982 != 'undefined'">
                            <v-text-field
                              label="部門名"
                              v-model="sub1.Recodes.FID7982.value"
                              :disabled="
                                allDisabled ||
                                item.DeleteFlag ||
                                sub0.DeleteFlag ||
                                sub1.DeleteFlag
                              "
                              id="FID7982"
                              class="textfield7982 mt-2 mb-1"
                              outlined
                              hide-details="auto"
                            >
                            </v-text-field>
                          </td>
                          <td v-else></td>
                          <td v-if="typeof sub1.Recodes.FID7983 != 'undefined'">
                            <v-text-field
                              label="部署コード"
                              v-model="sub1.Recodes.FID7983.value"
                              :disabled="
                                allDisabled ||
                                item.DeleteFlag ||
                                sub0.DeleteFlag ||
                                sub1.DeleteFlag
                              "
                              id="FID7983"
                              class="textfield7983 mt-2 mb-1"
                              outlined
                              hide-details="auto"
                            >
                            </v-text-field>
                          </td>
                          <td v-else></td>
                          <td></td>
                          <td></td>
                        </tr>
                      </template> </template
                  ></template>
                  <template v-else
                    ><tr
                      :class="index % 2 == 0 ? 'trWhite' : 'trBlue'"
                      :key="'sub-' + index + '-' + i0"
                    >
                      <td
                        v-if="
                          typeof item.Recodes.FID7977 != 'undefined' && i0 == 0
                        "
                      >
                        <v-text-field
                          label="事業所名"
                          v-model="item.Recodes.FID7977.value"
                          :disabled="
                            allDisabled ||
                            item.DeleteFlag ||
                            allDisabled ||
                            item.DeleteFlag
                          "
                          id="FID7977"
                          class="textfield7977 mt-2 mb-1"
                          outlined
                          hide-details="auto"
                        >
                        </v-text-field>
                      </td>
                      <td v-else></td>
                      <td
                        v-if="
                          typeof item.Recodes.FID7978 != 'undefined' && i0 == 0
                        "
                      >
                        <v-text-field
                          label="事業所コード"
                          v-model="item.Recodes.FID7978.value"
                          :disabled="
                            allDisabled ||
                            item.DeleteFlag ||
                            allDisabled ||
                            item.DeleteFlag
                          "
                          id="FID7978"
                          class="textfield7978 mt-2 mb-1"
                          outlined
                          hide-details="auto"
                        >
                        </v-text-field>
                      </td>
                      <td v-else></td>
                      <td v-if="typeof sub0.Recodes.FID7980 != 'undefined'">
                        <v-text-field
                          label="部署名"
                          v-model="sub0.Recodes.FID7980.value"
                          :disabled="
                            allDisabled || item.DeleteFlag || sub0.DeleteFlag
                          "
                          id="FID7980"
                          class="textfield7980 mt-2 mb-1"
                          outlined
                          hide-details="auto"
                        >
                        </v-text-field>
                      </td>
                      <td v-else></td>
                      <td v-if="typeof sub0.Recodes.FID7981 != 'undefined'">
                        <v-text-field
                          label="部署コード"
                          v-model="sub0.Recodes.FID7981.value"
                          :disabled="
                            allDisabled || item.DeleteFlag || sub0.DeleteFlag
                          "
                          id="FID7981"
                          class="textfield7981 mt-2 mb-1"
                          outlined
                          hide-details="auto"
                        >
                        </v-text-field>
                      </td>
                      <td v-else></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </template> </template
              ></template>
              <template v-else>
                <tr :class="index % 2 == 0 ? 'trWhite' : 'trBlue'">
                  <td v-if="typeof item.Recodes.FID7977 != 'undefined'">
                    <v-text-field
                      label="事業所名"
                      v-model="item.Recodes.FID7977.value"
                      :disabled="allDisabled || item.DeleteFlag"
                      id="FID7977"
                      class="textfield7977 mt-2 mb-1"
                      outlined
                      hide-details="auto"
                    >
                    </v-text-field>
                  </td>
                  <td v-else></td>
                  <td v-if="typeof item.Recodes.FID7978 != 'undefined'">
                    <v-text-field
                      label="事業所コード"
                      v-model="item.Recodes.FID7978.value"
                      :disabled="allDisabled || item.DeleteFlag"
                      id="FID7978"
                      class="textfield7978 mt-2 mb-1"
                      outlined
                      hide-details="auto"
                    >
                    </v-text-field>
                  </td>
                  <td v-else></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </template>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
import XLSX from "xlsx";
const localDownload = RepositoryFactory.get("localDownload");
import { SetCar } from "./specialMethod";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      isLoading: false,
      fullPage: false,
      headerText: ",", //`$$headerText`,
      valid: true,
      data: {
        PageID: 1151, //
        Page_id: 1151, //送信に使っているが本来は変換する
        Report_id: null, //
        UpdateType: "update",
        KeyID: [7978],
        KeyMap: {
          FID7977: 0,
        },
        Recodes: {
          FID7977: { value: "", formType: 1 },
          FID7978: { value: "", formType: 1 },
          FID7986: { value: "1152", formType: 17, subReports: [] },
        },
      },
      subReport1152: {
        PageID: 1152, //
        Page_id: 1152, //送信に使っているが本来は変換する
        Report_id: null, //
        UpdateType: "overWrite",
        KeyID: [7981],
        KeyMap: {
          FID7980: 0,
        },
        Recodes: {
          FID7980: { value: "", formType: 1 },
          FID7981: { value: "", formType: 1 },
          FID7987: { value: "1153", formType: 17, subReports: [] },
        },
      },
      subReport1153: {
        PageID: 1153, //
        Page_id: 1153, //送信に使っているが本来は変換する
        Report_id: null, //
        UpdateType: "overWrite",
        KeyID: [7983],
        KeyMap: {
          FID7982: 0,
        },
        Recodes: {
          FID7982: { value: "", formType: 1 },
          FID7983: { value: "", formType: 1 },
          FID7988: { value: "1154", formType: 17, subReports: [] },
        },
      },
      subReport1154: {
        PageID: 1154, //
        Page_id: 1154, //送信に使っているが本来は変換する
        Report_id: null, //
        UpdateType: "overWrite",
        KeyID: [7985],
        KeyMap: {
          FID7984: 0,
        },
        Recodes: {
          FID7984: { value: "", formType: 1 },
          FID7985: { value: "", formType: 1 },
        },
      },
      //
      RentalCar: {
        PageID: 1158, //
        Page_id: 1158, //送信に使っているが本来は変換する
        Report_id: null, //
        UpdateType: "update",
        KeyID: [8007, 8322],
        KeyMap: {
          FID8007: 0,
          FID8321: 1,
          FID8322: 2,
        },
        Recodes: {
          FID8099: { value: "", formType: 3 },
          FID8100: { value: "", formType: 3 },
          FID8103: { value: "", formType: 18 },
          FID8104: { value: "", formType: 18 },
          FID8321: { value: "", formType: 2 }, //管理部門
          FID9238: { value: "", formType: 2 }, //管理部門
          FID8322: { value: "", formType: 2 }, //部署
          FID8101: { value: "新規増車", formType: 14 },
          FID8271: { value: "", formType: 3 },
          FID8007: { value: "", formType: 1 },
          FID8113: { value: "", formType: 1 },
          FID8272: { value: "", formType: 1 },
          FID8613: { value: "", formType: 2 },
          FID8318: { value: "", formType: 1 },
          FID8319: { value: "", formType: 1 },
          FID8111: { value: "", formType: 1 },
          FID8112: { value: "", formType: 1 },
          FID8114: { value: "", formType: 1 },
          FID8108: { value: "", formType: 2 },
          FID8109: { value: "", formType: 2 },
          FID8116: { value: "", formType: 1 },
          FID8110: { value: "0", formType: 1 },
          FID8333: { value: "", formType: 9 },
          FID8532: { value: "", formType: 16 },
          FID8534: { value: "", formType: 16 },
          FID8257: { value: "", formType: 20 },
          FID8505: { value: "新規申請", formType: 1 },
          FID8586: { value: "", formType: 22 },
          FID8533: { value: "", formType: 20 },
        },
      },

      //
      sendFlag: false,
      uploadfile: { file: {}, url: "", data: { name: "", size: 0, type: "" } },
      header_flag: true,
      reports: [],
      rules: {
        fileSize: (value) => {
          return (
            value == null ||
            value.size < 15000000 ||
            "ファイルサイズを15MB未満にしてください"
          );
        },
      },
      sortBy: null,
      sortDesc: null,
      displayResults: -1,
      allDisabled: true,
      //
      codes: [],
      errors: [],
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {
    headers() {
      return [
        {
          text: "事業所名",
          value: "Recodes.FID7977.value",
          align: "start",
          sortable: true,
          width: "200px",
        },
        {
          text: "事業所コード",
          value: "Recodes.FID7978.value",
          sortable: true,
          width: "160px",
        },
        {
          text: "部署名",
          value: "Recodes.FID7980.value",
          sortable: true,
          width: "200px",
        },
        {
          text: "部署コード",
          value: "Recodes.FID7981.value",
          sortable: true,
          width: "160px",
        },
        {
          text: "部門名",
          value: "Recodes.FID7982.value",
          sortable: true,
          width: "200px",
        },
        {
          text: "部署コード",
          value: "Recodes.FID7983.value",
          sortable: true,
          width: "160px",
        },
        {
          text: "店舗名",
          value: "Recodes.FID7984.value",
          sortable: true,
          width: "250px",
        },
        {
          text: "部署コード",
          value: "Recodes.FID7985.value",
          sortable: true,
          width: "160px",
        },
      ];
    },
  },
  methods: {
    async getInit() {
      //初期処理
      this.isLoading = true;
      //読み込み終了
      this.isLoading = false;
    },
    updatePageNum(value) {
      store.commit("setPageNum", value);
    },
    updateSortBy(value) {
      store.commit("setTableSortBy", value);
    },
    updateSortDesc(value) {
      store.commit("setTableSortDesc", value);
    },
    customSort(items, index, isDesc) {
      //デフォルト
      if (index.length == 0 || items.length == 0) {
        return items;
      }
      const regex = /[^0-9]/g;
      const order = isDesc[0] ? 1 : -1;
      const pos = parseInt(index[0].replace(regex, ""));
      items.sort((a, b) => {
        //アイコンソートは特殊処理
        if (index == "target") {
          return 0;
        }
        //
        return this.sort(a, b, order, pos);
      });
      //
      return items;
    },
    sort(a, b, order, pos) {
      if (
        typeof a.Recodes["FID" + pos] == "undefined" ||
        typeof b.Recodes["FID" + pos] == "undefined"
      )
        return -1;
      if (a.Recodes["FID" + pos].value == b.Recodes["FID" + pos].value)
        return 0;
      if (a.Recodes["FID" + pos].value === "") return 1;
      if (b.Recodes["FID" + pos].value === "") return -1;
      if (
        !isNaN(a.Recodes["FID" + pos].value) &&
        !isNaN(b.Recodes["FID" + pos].value)
      ) {
        return Number(a.Recodes["FID" + pos].value) ==
          Number(b.Recodes["FID" + pos].value)
          ? 0
          : Number(a.Recodes["FID" + pos].value) >
            Number(b.Recodes["FID" + pos].value)
          ? -1 * order
          : order;
      }
    },
    /*-----------------------------*/
    //文字コードのチェック
    charCodeConvert(file) {
      const Encoding = require("encoding-japanese");
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          //文字列をバイト列に変換
          var sjisArray = new Uint8Array(reader.result);
          const encoding = this.detectEncoding(reader.result);
          switch (encoding) {
            case "SJIS":
              var unicodeArray = Encoding.convert(sjisArray, {
                to: "UNICODE",
                from: "SJIS",
                type: "string",
              });
              resolve(unicodeArray);
              break;
            case "UTF8":
              var unicodeArray2 = Encoding.convert(sjisArray, {
                to: "UNICODE",
                from: "AUTO",
                type: "string",
              });
              resolve(unicodeArray2);
              break;
            default:
              alert("文字コードが対応していません:" + encoding);
          }
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    },
    detectEncoding(buffer) {
      const uint8Array = new Uint8Array(buffer);
      if (
        uint8Array[0] === 0xef &&
        uint8Array[1] === 0xbb &&
        uint8Array[2] === 0xbf
      ) {
        return "UTF8";
      } else if (uint8Array[0] === 0xfe && uint8Array[1] === 0xff) {
        return "UTF16BE";
      } else if (uint8Array[0] === 0xff && uint8Array[1] === 0xfe) {
        return "UTF16LE";
      } else if (
        uint8Array[0] === 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0xfe &&
        uint8Array[3] === 0xff
      ) {
        return "UTF32BE";
      } else if (
        uint8Array[0] === 0xff &&
        uint8Array[1] === 0xfe &&
        uint8Array[2] === 0 &&
        uint8Array[3] === 0
      ) {
        return "UTF32LE";
      } else if (
        uint8Array[0] === 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0 &&
        uint8Array[3] !== 0
      ) {
        return "UTF32BE-noBOM";
      } else if (
        uint8Array[0] !== 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0 &&
        uint8Array[3] === 0
      ) {
        return "UTF32LE-noBOM";
      } else {
        return "SJIS";
      }
    },
    /*ダブルクォーテーション内のカンマは分割しない*/
    csvSplit(line) {
      let c = "";
      let s = new String();
      let data = new Array();
      let singleQuoteFlg = false;

      for (let i = 0; i < line.length; i++) {
        c = line.charAt(i);
        if (c == "," && !singleQuoteFlg) {
          data.push(s.toString());
          s = "";
        } else if (c == "," && singleQuoteFlg) {
          s = s + c;
        } else if (c == '"') {
          singleQuoteFlg = !singleQuoteFlg;
        } else {
          s = s + c;
        }
      }
      //最後のデータを追加
      data.push(s.toString());
      return data;
    },
    //現在のレポートとCSVの主キーが同じか確認する
    sameKeyCheck(data, csv) {
      let flag = true;
      Object.keys(data.KeyMap).map((key) => {
        const x = key;
        const y = data.KeyMap[key];
        if (data.Recodes[x].value == "" || csv[y] == "") {
          flag = false;
        }
        if (data.Recodes[x].value != csv[y]) {
          flag = false;
        }
      });
      return flag;
    },
    setValue(report, csv, to, from, tag) {
      if (typeof tag == "undefined") {
        if (typeof csv[from] != "undefined" && csv[from] != "") {
          report.Recodes[to].value = String(csv[from]).trim();
        }
        return;
      } else {
        switch (tag) {
          case "add":
            report.Recodes[to].value += String(csv[from]).trim();
            break;
        }
      }
    },
    async chargeExcel(event) {
      this.isLoading = true;
      try {
        const file = event;
        if (file) {
          const reader = new FileReader();
          reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]]; //シート番号
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            //先頭削除
            jsonData.shift();
            //データ処理
            this.reports = this.convertReports(jsonData);
            this.sendFlag = true;
            //バリデーションチェック
            this.isLoading = false;
            await this.$nextTick();
            await this.checkValidation();
          };
          reader.readAsArrayBuffer(file);
        }
        this.isLoading = false;
      } catch (e) {
        console.log("chargeExcel", e);
      }
    },
    convertReports(csv) {
      let data = [];
      let mainReport = JSON.parse(JSON.stringify(this.data));
      let subReport1152 = JSON.parse(JSON.stringify(this.subReport1152));
      let subReport1153 = JSON.parse(JSON.stringify(this.subReport1153));
      let subReport1154 = JSON.parse(JSON.stringify(this.subReport1154));
      //
      csv.forEach((c) => {
        //取り込みロジックは完全に独立して作成
        if (c.length > 1) {
          //改レポート判定
          if (
            typeof c[4] == "string" &&
            c[4] != "" &&
            typeof c[5] == "number"
          ) {
            //監査室のスキップ
            if (mainReport.Recodes.FID7977.value != "") {
              data.push(mainReport);
            }
            mainReport = JSON.parse(JSON.stringify(this.data));
            subReport1152 = JSON.parse(JSON.stringify(this.subReport1152));
            subReport1153 = JSON.parse(JSON.stringify(this.subReport1153));
            subReport1154 = JSON.parse(JSON.stringify(this.subReport1154));
          }
          //マス単位での処理
          c.map((value, x) => {
            if (value != null && value != "") {
              switch (x) {
                //事業部
                case 4:
                  mainReport.Recodes.FID7977.value = value;
                  break;
                case 5:
                  mainReport.Recodes.FID7978.value = value;
                  break;
                //部署
                case 8:
                  subReport1152 = JSON.parse(
                    JSON.stringify(this.subReport1152)
                  );
                  subReport1152.Recodes.FID7980.value = value;
                  break;
                case 9:
                  subReport1152.Recodes.FID7981.value = value;
                  mainReport.Recodes.FID7986.subReports.push(subReport1152);
                  break;
                //部門
                case 12:
                  subReport1153 = JSON.parse(
                    JSON.stringify(this.subReport1153)
                  );
                  subReport1153.Recodes.FID7982.value = value;
                  break;
                case 13:
                  subReport1153.Recodes.FID7983.value = value;
                  subReport1152.Recodes.FID7987.subReports.push(subReport1153);
                  break;
                //店舗
                case 16:
                  subReport1154 = JSON.parse(
                    JSON.stringify(this.subReport1154)
                  );
                  subReport1154.Recodes.FID7984.value = value;
                  break;
                case 17:
                  subReport1154.Recodes.FID7985.value = value;
                  subReport1153.Recodes.FID7988.subReports.push(subReport1154);
                  break;
              }
            }
          });
        }
      });
      data.push(mainReport);
      //
      return data;
    },

    async send() {
      const isCorrectValue = this.checkValidation();
      if (isCorrectValue == false) return;
      if (this.reports.length >= 10000) {
        alert("取り込み件数が上限10000件を超えています");
        return;
      }
      this.isLoading = true;
      const result = await api.ImportCSV(this.reports);
      if (result == undefined || result.result != "Success") {
        alert("データが登録できませんでした。");
      } else {
        alert("登録完了");
      }

      this.isLoading = false;
      history.back();
    },
    checkValidation: function () {
      let form = this.$refs.form;
      let check = form.validate();

      return check;
    },
    async downloadSampleExcel() {
      await localDownload.organizationExcel();
    },
    //レンタカーを作成
    async generateRentalCar() {
      this.isLoading = true;
      //全部署を取得
      let OrganizationReports = await this.getOrganizationReports();
      //レンタカーのデータを生成
      let RentalCars = this.addOrganizationRentaCar(OrganizationReports);
      //
      const result = await api.ImportCSV(RentalCars);
      if (result == undefined || result.result != "Success") {
        alert("データが登録できませんでした。");
      } else {
        alert("登録完了");
      }
      this.isLoading = false;
    },
    //全部署を取得
    async getOrganizationReports() {
      let data = {
        PageID: 1151,
        orderBy: "",
        search: [],
      };
      let res = api.ReportSearch(data);
      return res;
    },
    addOrganizationRentaCar(organizationReports) {
      //
      let today = new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        new Date().getHours() + 9
      )
        .toISOString()
        .match(/^\d{4}-\d{2}-\d{2}/g)
        .pop();
      //
      let RentalCars = [];
      //
      organizationReports.map((repo) => {
        let Division = repo.Recodes.FID7978.value;
        if (
          repo.Recodes.FID7986.subReports == undefined ||
          repo.Recodes.FID7986.subReports == null
        ) {
          return;
        }
        repo.Recodes.FID7986.subReports.map((sub1) => {
          let Section = sub1.Recodes.FID7981.value;
          RentalCars.push(
            SetCar(this.RentalCar, today, Division, Section, Section)
          );
          if (
            sub1.Recodes.FID7987.subReports == undefined ||
            sub1.Recodes.FID7987.subReports == null
          ) {
            return;
          }

          sub1.Recodes.FID7987.subReports.map((sub2) => {
            RentalCars.push(
              SetCar(
                this.RentalCar,
                today,
                Division,
                Section,
                sub2.Recodes.FID7983.value
              )
            );
            if (
              sub2.Recodes.FID7988.subReports == undefined ||
              sub2.Recodes.FID7988.subReports == null
            ) {
              return;
            }
            sub2.Recodes.FID7988.subReports.map((sub3) => {
              RentalCars.push(
                SetCar(
                  this.RentalCar,
                  today,
                  Division,
                  Section,
                  sub3.Recodes.FID7985.value
                )
              );
            });
          });
        });
      });
      //
      return RentalCars;
    },
    async checkOrganization() {
      let data = {
        PageID: 1151,
        orderBy: "",
        search: [],
      };
      this.codes = [];
      this.errors = [];
      const reports = await api.ReportSearch(data);
      //第1階層
      reports.map((repo) => {
        this.duoCheck(repo.Recodes.FID7977.value, repo.Recodes.FID7978.value);
        //第2階層
        if (
          typeof repo.Recodes.FID7986.subReports != "undefined" &&
          repo.Recodes.FID7986.subReports != null
        ) {
          repo.Recodes.FID7986.subReports.map((sub1) => {
            this.duoCheck(
              sub1.Recodes.FID7980.value,
              sub1.Recodes.FID7981.value
            );
            //第3階層
            if (
              typeof sub1.Recodes.FID7987.subReports != "undefined" &&
              sub1.Recodes.FID7987.subReports != null
            ) {
              sub1.Recodes.FID7987.subReports.map((sub2) => {
                this.duoCheck(
                  sub2.Recodes.FID7982.value,
                  sub2.Recodes.FID7983.value
                );
                //第4階層
                if (
                  typeof sub2.Recodes.FID7988.subReports != "undefined" &&
                  sub2.Recodes.FID7988.subReports != null
                ) {
                  sub2.Recodes.FID7988.subReports.map((sub3) => {
                    this.duoCheck(
                      sub3.Recodes.FID7984.value,
                      sub3.Recodes.FID7985.value
                    );
                  });
                }
              });
            }
          });
        }
      });
    },
    duoCheck(label, code) {
      if (!this.codes.includes(code)) {
        this.codes.push(code);
      } else {
        this.errors.push(label);
      }
    },
    back() {
      history.back();
    },
  },
};
</script>
