import axios from "axios";

//
export default {
  async companyCarExcel() {
    const baseURL = process.env.VUE_APP_BASE_URL;
    const filePath = "/downloads/companyCarImportSample.xlsx";
    const downloadUrl = baseURL + "/" + process.env.VUE_APP_NAME + filePath;
    //
    const response = await axios.get(downloadUrl, {
      responseType: "arraybuffer",
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "companyCarImportSample.xlsx");
    document.body.appendChild(link);
    link.click();
    return;
  },
  async myCarExcel() {
    const baseURL = process.env.VUE_APP_BASE_URL;
    const filePath = "/downloads/myCarImportSample.xlsx";
    const downloadUrl = baseURL + "/" + process.env.VUE_APP_NAME + filePath;
    //
    const response = await axios.get(downloadUrl, {
      responseType: "arraybuffer",
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "myCarImportSample.xlsx");
    document.body.appendChild(link);
    link.click();
    return;
  },
  async organizationExcel() {
    const baseURL = process.env.VUE_APP_BASE_URL;
    const filePath = "/downloads/organizationSample.xlsx";
    const downloadUrl = baseURL + "/" + process.env.VUE_APP_NAME + filePath;
    //
    const response = await axios.get(downloadUrl, {
      responseType: "arraybuffer",
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "organizationSample.xlsx");
    document.body.appendChild(link);
    link.click();
    return;
  },
};
