<template>
  <div class="body">
    <p align="center" class="title">ユーザ情報一覧</p>
    <v-row>
      <v-col align="left" class="button"
        ><v-btn @click="create">新規作成</v-btn></v-col
      >

      <v-col align="right" class="button">
        <v-btn @click.once="back">戻る</v-btn>
      </v-col>
    </v-row>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-form ref="form" v-model="valid">
        <v-row>
          <v-col cols="">
            <v-data-table
              :headers="headers"
              :items="reports"
              :item-key="reports.ID"
              :page="
                $store.state.pageNum == undefined ? 1 : $store.state.pageNum
              "
              @update:page="updatePageNum"
              :sort-by="sortBy"
              :sort-desc="sortDesc"
              @click:row="move"
              :header-props="{
                'sort-by-text': '並び順',
              }"
              :footer-props="{
                'items-per-page-text': '行/ページ:',
              }"
              no-data-text="データが存在しません"
              :items-per-page="displayResults"
              @update:sort-by="updateSortBy"
              @update:sort-desc="updateSortDesc"
              :custom-sort="customSort"
              :search="word"
            >
              <template v-slot:top>
                <v-text-field
                  v-model="word"
                  label="検索"
                  class="mx-4"
                ></v-text-field>
              </template>
              <template v-slot:item.Recodes.FID7767.value="{ item }">
                {{
                  typeof item.Recodes.FID7767 != "undefined"
                    ? $store.state.organization[item.Recodes.FID7767.value]
                    : ""
                }}
              </template>
              <template v-slot:item.Recodes.FID7776.value="{ item }">
                {{
                  typeof item.Recodes.FID7776 != "undefined"
                    ? $store.state.organization[item.Recodes.FID7776.value]
                    : ""
                }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="downloadCSV" class="mr-5" color="orange white--text">
              CSV出力
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");

export default {
  name: "ReportIndex",
  components: {},
  data() {
    return {
      valid: true,
      initEscape: false,
      isLoading: false,
      fullPage: false,
      displayResults: 15, //5,10,15のいずれか
      height: 100,
      width: 250,
      path: "",
      sortBy: null,
      sortDesc: null,
      //
      PageID: 1036,
      reports: [],
      rules: {
        required: (value) => !!value || "必須項目です",
        requiredArray: (value) => (value.length > 0 ? true : "必須項目です"),
        fullWidth: (value) => {
          return /^[^ -~｡-ﾟ]*$/.test(value) || "全角のみ入力可能です";
        },
      },
      word: "",
    };
  },
  async created() {
    await this.getInit();
  },
  methods: {
    async getInit() {
      //タイトルをデフォルトに戻す
      if (typeof process.env.VUE_APP_BASE_TITLE != "undefined") {
        document.title = process.env.VUE_APP_BASE_TITLE;
      }
      //初期処理
      this.isLoading = true;
      //
      await store.commit("setDepartmentAll");
      //ユーザ情報の一覧取得
      try {
        const reports = await api.UserIndex(this.PageID);
        this.reports = reports;
      } catch (e) {
        console.log("e", e);
      }
      //
      this.isLoading = false;
    },
    getKeyWord(data) {
      if (data.PageID == this.PageID) {
        data.search.forEach((data) => {
          console.log(data);
        });
      }
    },
    getSearchWord() {
      let data = {
        PageID: this.PageID,
        orderBy: "",
        search: [],
      };
      return data;
    },
    async search() {
      if (this.initEscape == false) {
        this.initEscape = true;
      } else {
        if (this.$refs.form.validate() == false) {
          return;
        }
      }
      //初期処理
      this.isLoading = true;
      //データ追加
      let data = await this.getSearchWord();
      //api
      const reports = await api.ReportSearch(data);
      if (reports != null) {
        this.reports = reports;
        store.commit("setreports", reports);
      }
      store.commit("setkeyWord", data);
      this.isLoading = false;
    },

    create() {
      this.$router.push({ path: "/user/setting/" + "create" });
    },
    move(data) {
      this.isLoading = true;
      this.$router.push({ path: "/user/setting/" + data.Recodes.FID0.name });
      this.isLoading = false;
    },
    back() {
      store.commit("clearkeyWord");
      store.commit("clearreports");
      store.commit("clearPageNum");
      store.commit("clearTableSort");
      //
      history.back();
    },
    updatePageNum(value) {
      store.commit("setPageNum", value);
    },
    updateSortBy(value) {
      store.commit("setTableSortBy", value);
    },
    updateSortDesc(value) {
      store.commit("setTableSortDesc", value);
    },
    customSort(items, index, isDesc) {
      //デフォルト
      if (index.length == 0 || items.length == 0) {
        return items;
      }
      const regex = /[^0-9]/g;
      const order = isDesc[0] ? 1 : -1;
      const pos = parseInt(index[0].replace(regex, ""));
      items.sort((a, b) => {
        //アイコンソートは特殊処理
        if (index == "target") {
          return 0;
        }
        //
        return this.sort(a, b, order, pos);
      });
      //
      return items;
    },
    sort(a, b, order, pos) {
      if (a.Value[pos] == b.Value[pos]) return 0;
      if (a.Value[pos] === "") return 1;
      if (b.Value[pos] === "") return -1;
      if (!isNaN(a.Value[pos]) && !isNaN(b.Value[pos])) {
        return Number(a.Value[pos]) == Number(b.Value[pos])
          ? 0
          : Number(a.Value[pos]) > Number(b.Value[pos])
          ? -1 * order
          : order;
      }
      return a.Value[pos].localeCompare(b.Value[pos], "jp") * -1 * order;
    },
    //
    downloadCSV() {
      let header =
        "現在組織コード3,現在組織正式名称3,現組拡組織コード分類ＣＤ,現組拡組織コード分類名称,現在組織コード4,現在組織正式名称4,現在組織コード,現在組織正式名称,社員コード,氏名漢字,氏名カナ,現在資格コード,現在資格正式名称,現在役職コード,現在役職正式名称,現在役職名称,入社年月日,勤続年数,任意年月日,退職金起算日,現在休退職区分,現在休職発令日,現在事由名称,現在社員休退職区分,現在社員休退職年月日,採用区分,採用区分名称,従業員区分,従業員区分名称,給与区分,給与区分名称,現在発令日,現在事由区分名称,ルート作成グループ,車両追加者,社有車一覧閲覧権限（未実装）,給油数量確認者,承認者/途中承認者,マイカー添付書類確認者,車両確認者（日別）,車両確認者（月次）";
      let csv = "\ufeff" + header + "\n";
      this.reports.map((report) => {
        if (Object.keys(report.Recodes).length >= 14) {
          let line = report.Recodes.FID7767.value + ",,,,"; //現在組織コード3,現在組織正式名称3,現組拡組織コード分類ＣＤ,現組拡組織コード分類名称,現在組織コード4,現在組織正式名称4,
          line += report.Recodes.FID7776.value + ",,";
          line += report.Recodes.FID7776.value + ",,"; //現在組織コード,現在組織正式名称,
          line += String(report.Recodes.FID0.name) + ","; //社員コード
          line += report.Recodes.FID7762.value + ","; //氏名漢字
          line += report.Recodes.FID7763.value + ","; //氏名カナ
          line += ",,,"; //
          line += report.Recodes.FID7777.value + ","; //従業員区分
          line += ",,,,,,,,,,,,,,,,,,,";
          line += this.csvRole(report.Recodes.FID8133.value); //車両追加者
          line += ","; //社有車一覧閲覧権限(未実装)
          line += this.csvRole(report.Recodes.FID7765.value); //給油数量確認者
          //承認者/途中承認者
          if (report.Recodes.FID7764.value == "一般") {
            line += ",";
          } else {
            line += "〇,";
          }
          line += this.csvRole(report.Recodes.FID8134.value); //マイカー添付書類確認者
          line += this.csvRole(report.Recodes.FID8748.value); //車両確認者(日別)
          line += this.csvRole(report.Recodes.FID7766.value); //車両確認者(月別)
          csv += line + "\n";
        }
      });
      //CSV出力部分
      const Encoding = require("encoding-japanese");
      const sjisData = Encoding.convert(csv, {
        to: "SJIS",
        from: "UNICODE",
        type: "arraybuffer",
      });

      // 先にUint16ArrayからUint8Arrayに変換する
      const uint8Array = new Uint8Array(sjisData);
      // Blobでデータを作成
      const blob = new Blob([uint8Array], {
        type: "text/csv;charset=shift-jis;",
      });
      /*
      const blob = new Blob([new TextEncoder("shift-jis").encode(csv)], {
        type: "text/csv;charset=Shift-JIS;",
      });
      */
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "社員情報(形式は未調整).csv";
      document.body.appendChild(a);
      a.click();
      // 不要になった要素を削除
      window.URL.revokeObjectURL(url);
    },
    csvRole(value) {
      if (value == "") {
        return ",";
      }
      return "〇,";
    },
  },
  computed: {
    headers() {
      return [
        {
          text: "従業員番号",
          value: "Recodes.FID0.name",
          align: "start",
          sortable: true,
        },
        //メールアドレスは、システム上は記録されていない
        //{ text: "メールアドレス", value: "Recodes.FID0.mail", sortable: false },
        {
          text: "氏名",
          value: "Recodes.FID7762.value",
          align: "start",
          sortable: true,
        },
        {
          text: "システム権限",
          value: "Recodes.FID0.auth",
          sortable: false,
          filterable: false,
        },
        {
          text: "車両権限",
          value: "Recodes.FID7764.value",
          sortable: true,
          filterable: false,
        },
        {
          text: "事業部",
          value: "Recodes.FID7767.value",
          sortable: true,
          filterable: false,
        },
        {
          text: "部署",
          value: "Recodes.FID7776.value",
          sortable: true,
          filterable: false,
        },
        {
          text: "従業員区分",
          value: "Recodes.FID7777.value",
          sortable: true,
          filterable: false,
        },
      ];
    },
  },
};
</script>
