<template>
  <v-card class="subPage" color="white">
    <div class="ma-1">
      <p>社有車利用</p>
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form">
          <v-row>
            <v-col cols="12" sm="12" md="3" lg="3">
              <v-dialog
                v-model="data.Recodes.FID8255.modal"
                :max-width="resizeWidth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-text-field
                      label="前回距離"
                      v-model="data.Recodes.FID8255.value"
                      :disabled="allDisabled8539"
                      :rules="[rules.smallNum, rules.SendValidation8031No0]"
                      background-color="white"
                      id="FID8255"
                      class="textfield8255"
                      append-icon="mdi-calculator"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      hide-details="auto"
                    >
                    </v-text-field></div></template
                ><input-Number
                  v-if="data.Recodes.FID8255.modal"
                  title="前回距離"
                  :value="data.Recodes.FID8255.value"
                  :rules="[rules.smallNum]"
                  v-on:ok="
                    (data.Recodes.FID8255.modal = false),
                      $set(data.Recodes.FID8255, 'value', $event)
                  "
                  type="calculator"
                  AC
                ></input-Number>
              </v-dialog>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="3">
              <v-btn
                label="開始"
                :disabled="data.Recodes.FID8030.disabled || allDisabled8539"
                background-color="white"
                id="FID8030"
                class="button8030"
                @click="onBtn8030"
                color="yellow lighten-2"
                block
                style="font-size: 20px; letter-spacing: 10px"
                height="50"
                hide-details="auto"
              >
                開始</v-btn
              >
            </v-col>
            <v-col cols="9" sm="9" md="4" lg="4" v-if="!startTimeRetouch">
              <v-text-field
                label="開始時間"
                v-model="data.Recodes.FID8032.value"
                :disabled="data.Recodes.FID8032.disabled || allDisabled8539"
                background-color="white"
                id="FID8032"
                class="textfield8032"
                outlined
                hide-details="auto"
                append-icon="mdi-clock-time-eight"
                prepend-icon="mdi-calendar"
              >
              </v-text-field>
            </v-col>
            <v-col cols="9" sm="9" md="4" lg="4" v-else>
              <v-text-field
                label="開始時刻"
                :disabled="allDisabled8539"
                background-color="white"
                id="FID9998"
                class="textfield9998"
                outlined
                :value="DateTimeFID9998"
                readonly
                append-icon="mdi-clock-time-eight"
                @click="data.Recodes.FID9998.modalTime = true"
                @click:append="data.Recodes.FID9998.modalTime = true"
                prepend-icon="mdi-calendar"
                @click:prepend="data.Recodes.FID9998.modalDate = true"
                :rules="[startTimeRetouch ? rules.required : true]"
                :hint="'修正前:' + data.Recodes.FID8032.value"
                persistent-hint
              >
              </v-text-field>
              <v-dialog
                v-model="data.Recodes.FID9998.modalDate"
                :max-width="resizeWidth"
              >
                <v-date-picker
                  v-model="data.Recodes.FID9998.date"
                  no-title
                  scrollable
                  locale="ja-jp"
                  :day-format="(date) => new Date(date).getDate()"
                  @input="data.Recodes.FID9998.modalDate = false"
                >
                </v-date-picker>
              </v-dialog>
              <v-dialog
                v-model="data.Recodes.FID9998.modalTime"
                :max-width="resizeWidth"
              >
                <input-Time
                  v-if="data.Recodes.FID9998.modalTime"
                  title="時刻選択"
                  v-model="data.Recodes.FID9998.time"
                  :rules="[]"
                  :minuteInterval="1"
                  v-on:ok="
                    (data.Recodes.FID9998.modalTime = false),
                      $set(data.Recodes.FID9998, 'time', $event)
                  "
                  type="calculator"
                ></input-Time>
              </v-dialog>
            </v-col>
            <v-col cols="3" sm="3" md="2" lg="2">
              <v-checkbox
                class="mt-2"
                v-model="startTimeRetouch"
                label="修正"
                :disabled="data.Recodes.FID8032.value == ''"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="3" lg="3">
              <v-dialog
                v-model="data.Recodes.FID8256.modal"
                :max-width="resizeWidth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-text-field
                      label="現在距離"
                      v-model="data.Recodes.FID8256.value"
                      :disabled="allDisabled8539"
                      :rules="[rules.smallNum, rules.SendValidation8031No2]"
                      background-color="white"
                      id="FID8256"
                      class="textfield8256"
                      append-icon="mdi-calculator"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      hide-details="auto"
                    >
                    </v-text-field></div></template
                ><input-Number
                  v-if="data.Recodes.FID8256.modal"
                  title="現在距離"
                  :value="data.Recodes.FID8256.value"
                  :rules="[rules.smallNum]"
                  v-on:ok="
                    (data.Recodes.FID8256.modal = false),
                      $set(data.Recodes.FID8256, 'value', $event)
                  "
                  type="calculator"
                  AC
                ></input-Number>
              </v-dialog>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="3">
              <div class="pa-1" v-if="usePurpose != '代車利用'">
                <v-radio-group
                  label="ETC利用"
                  v-model="data.Recodes.FID8034.value"
                  :disabled="allDisabled8539"
                  :rules="[rules.SendValidation8031No4]"
                  background-color="white"
                  id="FID8034"
                  class="radioGroup8034 pt-2 pl-3 pb-4 ma-0"
                  style="border-radius: 4px; border-color: #9f9f9f"
                  row
                  hide-details="auto"
                >
                  <v-radio label="有り" value="有り" class="mt-1"></v-radio>
                  <v-radio label="無し" value="無し" class="mt-1"></v-radio>
                </v-radio-group>
              </div>
            </v-col>
            <v-col cols="6" sm="6" md="6" lg="3">
              <v-dialog
                v-model="data.Recodes.FID8035.modal"
                :max-width="resizeWidth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-text-field
                      label="燃料(L)"
                      v-model="data.Recodes.FID8035.value"
                      :disabled="allDisabled8539"
                      :rules="[rules.smallNum, rules.SendValidation8031No6]"
                      background-color="white"
                      id="FID8035"
                      class="textfield8035"
                      append-icon="mdi-calculator"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      hide-details="auto"
                    >
                    </v-text-field></div></template
                ><input-Number
                  v-if="data.Recodes.FID8035.modal"
                  title="燃料(L)"
                  :value="data.Recodes.FID8035.value"
                  :rules="[rules.smallNum]"
                  v-on:ok="
                    (data.Recodes.FID8035.modal = false),
                      $set(data.Recodes.FID8035, 'value', $event)
                  "
                  type="calculator"
                ></input-Number>
              </v-dialog>
            </v-col>
            <v-col cols="6" sm="6" md="6" lg="3">
              <v-dialog
                v-model="data.Recodes.FID8036.modal"
                :max-width="resizeWidth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-text-field
                      label="オイル(L)"
                      v-model="data.Recodes.FID8036.value"
                      :disabled="allDisabled8539"
                      :rules="[rules.smallNum, rules.SendValidation8031No8]"
                      background-color="white"
                      id="FID8036"
                      class="textfield8036"
                      append-icon="mdi-calculator"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      hide-details="auto"
                    >
                    </v-text-field></div></template
                ><input-Number
                  v-if="data.Recodes.FID8036.modal"
                  title="オイル(L)"
                  :value="data.Recodes.FID8036.value"
                  :rules="[rules.smallNum]"
                  v-on:ok="
                    (data.Recodes.FID8036.modal = false),
                      $set(data.Recodes.FID8036, 'value', $event)
                  "
                  type="calculator"
                ></input-Number>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-dialog
                v-model="data.Recodes.FID8270.modal"
                :max-width="resizeWidth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-text-field
                      label="走行距離"
                      v-model="subtraction8270"
                      :disabled="
                        data.Recodes.FID8270.disabled || allDisabled8539
                      "
                      :rules="[rules.smallNum]"
                      background-color="white"
                      id="FID8270"
                      class="textfield8270"
                      append-icon="mdi-calculator"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      hide-details="auto"
                    >
                    </v-text-field></div></template
                ><input-Number
                  v-if="data.Recodes.FID8270.modal"
                  title="走行距離"
                  :value="data.Recodes.FID8270.value"
                  :rules="[rules.smallNum]"
                  v-on:ok="
                    (data.Recodes.FID8270.modal = false),
                      $set(data.Recodes.FID8270, 'value', $event)
                  "
                  type="calculator"
                ></input-Number>
              </v-dialog>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-text-field
                label="異常個所 及び 交換部品"
                v-model="data.Recodes.FID8037.value"
                :disabled="allDisabled8539"
                background-color="white"
                id="FID8037"
                class="textfield8037"
                outlined
                hide-details="auto"
                :hint="hint8037"
                :persistent-hint="
                  $vuetify.breakpoint.name == 'lg' ||
                  $vuetify.breakpoint.name == 'xl'
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" sm="3" md="3" lg="3">
              <div
                label=""
                :disabled="allDisabled8539"
                background-color="white"
                id="FID8604"
                class=""
                hide-details="auto"
              ></div>
            </v-col>
            <v-col cols="12" sm="12" md="3" lg="3">
              <v-btn
                label="終了"
                :disabled="data.Recodes.FID8031.disabled || allDisabled8539"
                background-color="white"
                id="FID8031"
                class="button8031"
                @click="onBtn8031"
                color="yellow lighten-2"
                block
                style="font-size: 20px; letter-spacing: 10px"
                height="50"
                hide-details="auto"
              >
                終了</v-btn
              >
            </v-col>
            <v-col cols="9" sm="9" md="4" lg="4" v-if="!endTimeRetouch">
              <v-text-field
                label="終了時間"
                v-model="data.Recodes.FID8033.value"
                :disabled="data.Recodes.FID8033.disabled || allDisabled8539"
                background-color="white"
                id="FID8033"
                class="textfield8033"
                outlined
                hide-details="auto"
                append-icon="mdi-clock-time-eight"
                prepend-icon="mdi-calendar"
              >
              </v-text-field>
            </v-col>
            <v-col cols="9" sm="9" md="4" lg="4" v-else>
              <v-text-field
                label="終了時間"
                :disabled="allDisabled8539"
                background-color="white"
                id="FID9999"
                class="textfield9999"
                outlined
                :value="DateTimeFID9999"
                readonly
                append-icon="mdi-clock-time-eight"
                @click="data.Recodes.FID9999.modalTime = true"
                @click:append="data.Recodes.FID9999.modalTime = true"
                prepend-icon="mdi-calendar"
                @click:prepend="data.Recodes.FID9999.modalDate = true"
                :rules="[endTimeRetouch ? rules.required : true]"
                :hint="'修正前:' + data.Recodes.FID8032.value"
                persistent-hint
              >
              </v-text-field>
              <v-dialog
                v-model="data.Recodes.FID9999.modalDate"
                :max-width="resizeWidth"
              >
                <v-date-picker
                  v-model="data.Recodes.FID9999.date"
                  no-title
                  scrollable
                  locale="ja-jp"
                  :day-format="(date) => new Date(date).getDate()"
                  @input="data.Recodes.FID9999.modalDate = false"
                >
                </v-date-picker>
              </v-dialog>
              <v-dialog
                v-model="data.Recodes.FID9999.modalTime"
                :max-width="resizeWidth"
              >
                <input-Time
                  v-if="data.Recodes.FID9999.modalTime"
                  title="時刻選択"
                  v-model="data.Recodes.FID9999.time"
                  :rules="[]"
                  :minuteInterval="1"
                  v-on:ok="
                    (data.Recodes.FID9999.modalTime = false),
                      $set(data.Recodes.FID9999, 'time', $event)
                  "
                  type="calculator"
                ></input-Time>
              </v-dialog>
            </v-col>
            <v-col cols="3" sm="3" md="2" lg="2">
              <v-checkbox
                :disabled="data.Recodes.FID8033.value == ''"
                class="mt-2"
                v-model="endTimeRetouch"
                label="修正"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="0" v-if="false">
              <v-text-field
                label="オイル点検"
                v-model="data.Recodes.FID8582.value"
                :disabled="data.Recodes.FID8582.disabled || allDisabled8539"
                background-color="white"
                id="FID8582"
                class="textfield8582"
                outlined
                hide-details="auto"
                :hint="hint8582"
                persistent-hint
              >
              </v-text-field>
            </v-col>
            <v-col cols="1" sm="1" md="3" lg="3"> </v-col>
            <v-col cols="8" sm="8" md="7" lg="7">
              <v-text-field
                label="修正理由を記入"
                v-model="data.Recodes.FID10000.value"
                :disabled="data.Recodes.FID10000.disabled || allDisabled8539"
                background-color="white"
                id="FID10000"
                class="textfield10000"
                outlined
                hide-details="auto"
                persistent-hint
                :rules="[
                  startTimeRetouch || endTimeRetouch ? rules.required : true,
                ]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-card>
</template>

<script>
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");

import inputNumber from "../views/inputNumber";
import { LastRunValue } from "./specialMethod";
import inputTime from "../views/inputTime";
export default {
  name: "Home",
  components: {
    inputNumber,
    inputTime,
  },
  props: {
    //値
    Report: Object,
    subindex: Number,
    parentData: {
      type: Object,
      default: () => {},
      required: false,
    },
    myInit: Boolean,
    FID: String,
    allDisabled8539: { type: Boolean },
    status: { type: String, default: "" },
    usePurpose: { type: String, default: "" },
  },
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      disabled: false,
      data: {
        PageID: 1162,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID8255: { value: "", formType: 1, disabled: false, modal: false },
          FID8030: { value: "", formType: 16, disabled: false },
          FID8032: { value: "", formType: 1, disabled: true },
          FID8256: { value: "", formType: 1, disabled: false, modal: false },
          FID8034: { value: "", formType: 7, disabled: false },
          FID8035: { value: "0", formType: 1, disabled: false, modal: false },
          FID8036: { value: "0", formType: 1, disabled: false, modal: false },
          FID8270: { value: "", formType: 1, disabled: true, modal: false },
          FID8037: { value: "", formType: 1, disabled: false },
          FID8031: { value: "", formType: 16, disabled: true },
          FID8033: { value: "", formType: 1, disabled: true },
          FID8582: { value: "", formType: 1, disabled: true },
          FID9998: {
            value: "",
            formType: 1,
            disabled: true,
            date: "",
            time: "",
            modalDate: false,
            modalTime: false,
          },
          FID9999: {
            value: "",
            formType: 1,
            disabled: false,
            date: "",
            time: "",
            modalDate: false,
            modalTime: false,
          },
          FID10000: { value: "", formType: 1, disabled: false },
        },
        DeleteSubReports: [],
      },
      validationFlag8256: true,
      validationFlag8031: false,

      rules: {
        required: (value) => !!value || "必須項目です",
        smallNum: (value) => {
          return /^(\d*.?\d+)*$/.test(value) || "数値のみ入力可能です。";
        },

        SendValidation8256No0: (value) => {
          if (!this.validationFlag8256) {
            return true;
          }
          if (value == "") {
            this.validationFlag8256 = false;
            return "入力必須";
          }
          return true;
        },
        SendValidation8031No0: (value) => {
          if (!this.validationFlag8031) {
            return true;
          }
          if (value == "") {
            this.validationFlag8031 = false;
            return "入力必須";
          }
          return true;
        },
        SendValidation8031No2: (value) => {
          if (!this.validationFlag8031) {
            return true;
          }
          if (value == "") {
            this.validationFlag8031 = false;
            return "入力必須";
          }
          return true;
        },
        SendValidation8031No4: (value) => {
          if (!this.validationFlag8031) {
            return true;
          }
          if (value == "") {
            this.validationFlag8031 = false;
            return "入力必須";
          }
          return true;
        },
        SendValidation8031No6: (value) => {
          if (!this.validationFlag8031) {
            return true;
          }
          if (value == "") {
            this.validationFlag8031 = false;
            return "入力必須";
          }
          return true;
        },
        SendValidation8031No8: (value) => {
          if (!this.validationFlag8031) {
            return true;
          }
          if (value == "") {
            this.validationFlag8031 = false;
            return "入力必須";
          }
          return true;
        },
      },
      copyFlag: false,
      noBack: false,
      startTimeRetouch: false,
      endTimeRetouch: false,
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {
    resizeWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "270px";
        case "sm":
          return "500px";
        case "md":
          return "600px";
        case "lg":
          return "500px";
        case "xl":
          return "500px";
        default:
          return "500px";
      }
    },
    subtraction8270: function () {
      let total =
        typeof this.data.Recodes.FID8256?.value == "undefined" ||
        isNaN(this.data.Recodes.FID8256.value)
          ? 0
          : Number(this.data.Recodes.FID8256.value);
      total -=
        typeof this.data.Recodes.FID8255?.value == "undefined" ||
        isNaN(this.data.Recodes.FID8255.value)
          ? 0
          : Number(this.data.Recodes.FID8255.value);

      this.total8270(total);
      return total;
    },
    hint8037: function () {
      return "日常点検の結果、異常個所及び交換部品がある場合に入力";
    },
    hint8582: function () {
      return "給油数量確認者が実地";
    },
    DateTimeFID9998() {
      let date = this.data.Recodes.FID9998.date;
      let time = this.data.Recodes.FID9998.time;

      if (date != "" || time != "") {
        if (date == "") {
          date = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            new Date().getHours() + 9
          )
            .toISOString()
            .match(/^\d{4}-\d{2}-\d{2}/g)
            .pop();
        } else if (time == "") {
          const japanStandardTime = new Date().toLocaleString({
            timeZone: "Asia/Tokyo",
          });
          time =
            new Date(japanStandardTime).getHours() +
            ":" +
            new Date(japanStandardTime).getMinutes();
        }
      }
      this.$set(this.data.Recodes.FID9998, "value", date + " " + time);
      return date + " " + time;
    },
    DateTimeFID9999() {
      let date = this.data.Recodes.FID9999.date;
      let time = this.data.Recodes.FID9999.time;

      if (date != "" || time != "") {
        if (date == "") {
          date = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            new Date().getHours() + 9
          )
            .toISOString()
            .match(/^\d{4}-\d{2}-\d{2}/g)
            .pop();
        } else if (time == "") {
          const japanStandardTime = new Date().toLocaleString({
            timeZone: "Asia/Tokyo",
          });
          time =
            new Date(japanStandardTime).getHours() +
            ":" +
            new Date(japanStandardTime).getMinutes();
        }
      }
      this.$set(this.data.Recodes.FID9999, "value", date + " " + time);
      return date + " " + time;
    },
  },
  watch: {
    $route() {
      this.getInit();
    },
    data: {
      handler: async function (data) {
        //
        if (
          data.Recodes.FID8032.value != "" &&
          data.Recodes.FID8034.value != "" &&
          data.Recodes.FID8035.value != "" &&
          data.Recodes.FID8036.value != "" &&
          data.Recodes.FID8033.value == ""
        ) {
          this.data.Recodes.FID8031.disabled = false;
        } else {
          this.data.Recodes.FID8031.disabled = true;
        }

        //
        this.$emit("clickSubmit", this.FID, this.subindex, data);
      },
      deep: true,
    },
    // startTimeRetouch: function (flag) {
    //   if (flag) {
    //     this.dateTimeReset("FID9998");
    //   }
    // },
    // endTimeRetouch: function (flag) {
    //   if (flag) {
    //     this.dateTimeReset("FID9999");
    //   }
    // },
    // dateTimeReset(key) {
    //   console.log("key", key);
    //   /*
    //   this.data.Recodes[key].date = "";
    //   this.data.Recodes[key].time = "";
    //   this.data.Recodes[key].value = "";
    //   */
    // },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        //
        //データが存在する場合
        if (this.$route.query.copyFlag) {
          this.reportCopy();
        } else if (this.Report.ReportID != null || this.myInit) {
          //サブレポートの値代入
          this.data.ReportID = this.Report.ReportID;
          this.data.Recodes = api.OptionCopy(
            this.data.Recodes,
            this.Report.Recodes
          );
        } else {
          //初期値設定
        }
        //データ取得処理

        //init処理
        await this.initValues8255();
        this.initCheck8030();
        this.initCheck8031();
        this.initCheck9998();
        this.initCheck9999();
        //初回データ反映
        this.$emit("clickSubmit", this.FID, this.subindex, this.data);
        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
      }
    },
    send() {},
    async onBtn8030() {
      //処理を記載
      const date = this.getDateTime();
      this.data.Recodes.FID8030.value = date; //tag:日付+時間
      this.data.Recodes.FID8030.disabled = true; //1回だけ
      this.data.Recodes.FID8032.value = this.data.Recodes.FID8030.value;
      const val = this.data.Recodes.FID8030.value.split(" ");
      this.data.Recodes.FID8032.date = val[0];
      this.data.Recodes.FID8032.time = val[1];
      if (typeof this.data.Recodes.FID8030 == "undefined") {
        this.data.Recodes.FID8030 = { value: "", formType: 16 };
      }
      this.data.Recodes.FID8031.disabled = false;
      this.$emit("save8292", this.subindex, this.data);
    },
    getDateTime() {
      let date = new Date();
      let years = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let days = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let seconds = date.getSeconds().toString().padStart(2, "0");
      return (
        years +
        "-" +
        month +
        "-" +
        days +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    total8270(value) {
      this.data.Recodes.FID8270.value = value;
    },
    async onBtn8031() {
      this.validationFlag8031 = true;
      const validationCheck = await this.checkValidation();
      if (!validationCheck) {
        return;
      }
      //処理を記載
      const date = this.getDateTime();
      this.data.Recodes.FID8031.value = date; //tag:日付+時間
      this.data.Recodes.FID8031.disabled = true; //1回だけ
      this.data.Recodes.FID8033.value = this.data.Recodes.FID8031.value;
      const val = this.data.Recodes.FID8031.value.split(" ");
      this.data.Recodes.FID8033.date = val[0];
      this.data.Recodes.FID8033.time = val[1];
      this.$emit("save8537", this.subindex, this.data);
    },
    async initValues8255() {
      if (this.data.Recodes.FID8255.value == "" && this.status == "利用中") {
        this.data.Recodes.FID8255.value = await LastRunValue(this.data);
        //
        if (this.usePurpose == "代車利用") {
          this.data.Recodes.FID8034.value = "無し";
        }
      }
    },
    initCheck8030() {
      if (typeof this.data.Recodes.FID8030 == "undefined") {
        this.data.Recodes.FID8030 = { value: "", formType: 16 };
      }
      if (this.data.Recodes.FID8030.value != "") {
        this.data.Recodes.FID8030.disabled = true; //1回だけ
      }
    },
    initCheck8031() {
      if (this.data.Recodes.FID8030.value != "") {
        if (typeof this.data.Recodes.FID8030 == "undefined") {
          this.data.Recodes.FID8030 = { value: "", formType: 16 };
        }
        this.data.Recodes.FID8031.disabled = false;
      }
      if (typeof this.data.Recodes.FID8031 == "undefined") {
        this.data.Recodes.FID8031 = { value: "", formType: 16 };
      }
      if (this.data.Recodes.FID8031.value != "") {
        this.data.Recodes.FID8031.disabled = true; //1回だけ
      }
    },
    initCheck9998() {
      if (typeof this.data.Recodes.FID9998 == "undefined") {
        this.data.Recodes.FID9998 = {
          value: "",
          formType: 1,
          disabled: false,
          date: "",
          time: "",
          modalDate: false,
          modalTime: false,
        };
      }
      if (this.data.Recodes.FID9998.value != "") {
        //tag:日付+時間
        const val = this.data.Recodes.FID9998.value.split(" ");
        this.data.Recodes.FID9998.date = val[0];
        this.data.Recodes.FID9998.time = val[1];
        this.startTimeRetouch = true;
      }
    },
    initCheck9999() {
      if (typeof this.data.Recodes.FID9999 == "undefined") {
        this.data.Recodes.FID9999 = {
          value: "",
          formType: 1,
          disabled: false,
          date: "",
          time: "",
          modalDate: false,
          modalTime: false,
        };
      }
      if (this.data.Recodes.FID9999.value != "") {
        //tag:日付+時間
        const val = this.data.Recodes.FID9999.value.split(" ");
        this.data.Recodes.FID9999.date = val[0];
        this.data.Recodes.FID9999.time = val[1];
        this.endTimeRetouch = true;
      }
    },
    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (this.escapeValidation) {
        return true;
      }
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }
      return check;
    },
    async del() {
      await api.DelReport(this.data);
      alert("削除完了");
      this.back();
    },
  },
};
</script>
<style></style>
