<template>
  <div class="home">
    <p>車両利用申請</p>
    <v-row>
      <v-col align="right"><v-btn @click.once="back">戻る</v-btn></v-col>
    </v-row>
    <v-container cols="12">
      <div v-if="isLoading" align="center">
        <v-progress-circular
          :size="250"
          color="primary"
          indeterminate
          align="center"
        ></v-progress-circular>
      </div>
      <div v-else>
        <v-form ref="form" @submit.prevent>
          <v-row>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-autocomplete
                label="利用目的"
                v-model="data.Recodes.FID8014.value"
                :disabled="
                  allDisabled8539 || data.Recodes.FID8021.value != '申請前'
                "
                :rules="[rules.required]"
                background-color="white"
                id="FID8014"
                class="selectBox8014"
                outlined
                no-data-text="データが見つかりません"
                clearable
                :items="selectBoxFID8014"
                hide-details="auto"
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-autocomplete
                label="ステータス"
                v-model="data.Recodes.FID8021.value"
                :disabled="data.Recodes.FID8021.disabled || allDisabled8539"
                background-color="white"
                id="FID8021"
                class="selectBox8021"
                outlined
                no-data-text="データが見つかりません"
                clearable
                :items="selectBoxFID8021"
                hide-details="auto"
              >
              </v-autocomplete>
            </v-col>
            <v-col
              v-if="(data.Recodes.FID8021.value == '業務前' ||
              data.Recodes.FID8021.value == '利用中') &&
              this.data.Recodes.FID8016.value ==
                $store.state.user.attributes.name" 
              cols="4" sm="4" md="3" lg="3"
            >
              <v-btn
                label="ステータスの強制変更"
                background-color="white"
                id="FID8616"
                class="button8616"
                @click="validateSubValue"
                color="amber"
                block
                height="50"
                hide-details="auto"
              >
                ステータスの強制変更</v-btn
              >
            <span class="hint-text">{{ hintText }}</span>
          </v-col>
          <v-dialog v-model="changeStatusModal" max-width="400">
            <v-card>
              <v-card-title class="text-h5"> ステータスを変更しますか？</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="mr-3"
                  color="green darken-1 white--text"
                  @click="changeStatusModal = false"
                  large
                >
                  いいえ
                </v-btn>
                <v-btn color="red darken-1 white--text" @click="onChange8021" large>
                  はい
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-text-field
                label="車両番号"
                v-model="data.Recodes.FID8023.value"
                disabled
                :rules="[rules.required]"
                background-color="white"
                id="FID8023"
                class="textfield8023"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="6"
              sm="6"
              md="3"
              lg="3"
              v-if="data.Recodes.FID8023.value == 'レンタカー'"
            >
              <v-dialog
                v-model="data.Recodes.FID9584.modal"
                :max-width="resizeWidth"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs">
                    <v-text-field
                      label="自動車番号または車両番号"
                      v-model="data.Recodes.FID9584.value"
                      :disabled="
                        data.Recodes.FID9584.disabled || allDisabled8539
                      "
                      :rules="[
                        data.Recodes.FID8023.value == 'レンタカー'
                          ? rules.required
                          : true,
                      ]"
                      background-color="white"
                      id="FID9584"
                      class="textfield9584"
                      append-icon="mdi-car"
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      readonly
                      hide-details="auto"
                    >
                    </v-text-field></div></template
                ><car-Number
                  v-if="data.Recodes.FID9584.modal"
                  title="自動車番号または車両番号"
                  :value="data.Recodes.FID9584.value"
                  :rules="[rules.required]"
                  v-on:ok="
                    (data.Recodes.FID9584.modal = false),
                      $set(data.Recodes.FID9584, 'value', $event)
                  "
                  type="calculator"
                ></car-Number>
              </v-dialog>
            </v-col>

            <v-col cols="6" sm="6" md="3" lg="3">
              <v-text-field
                label="日時承認"
                v-model="data.Recodes.FID8615.value"
                :disabled="data.Recodes.FID8615.disabled || allDisabled8539"
                background-color="white"
                id="FID8615"
                class="textfield8615"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-text-field
                label="月次承認"
                v-model="data.Recodes.FID8583.value"
                :disabled="data.Recodes.FID8583.disabled || allDisabled8539"
                background-color="white"
                id="FID8583"
                class="textfield8583"
                outlined
                hide-details="auto"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-menu
                v-model="data.Recodes.FID8015.modal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="記録作成日"
                    v-model="data.Recodes.FID8015.value"
                    :disabled="allDisabled8539"
                    :rules="[rules.required]"
                    background-color="white"
                    id="FID8015"
                    class="inputTime8015"
                    append-icon="mdi-calendar"
                    outlined
                    v-on="on"
                    readonly
                    hide-details="auto"
                  >
                  </v-text-field> </template
                ><v-date-picker
                  v-model="data.Recodes.FID8015.value"
                  no-title
                  scrollable
                  locale="ja-jp"
                  :day-format="(date) => new Date(date).getDate()"
                  @input="data.Recodes.FID8015.modal = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <!-- v-model="data.Recodes.FID8016.value" -->
              <v-combobox
                label="運転者"
                :value="UserName"
                :disabled="allDisabled8539"
                :rules="[rules.required]"
                background-color="white"
                id="FID8016"
                class="selectBox8016"
                outlined
                no-data-text="データが見つかりません"
                clearable
                :items="selectBoxFID8016"
                hide-details="auto"
              >
              </v-combobox>
            </v-col>
            <v-col cols="3" v-if="false">
              <v-text-field
                label="車両部署"
                v-model="data.Recodes.FID9572.value"
                :rules="[rules.required]"
                background-color="white"
                id="FID9572"
                outlined
                hide-details="auto"
              >
                <!--   -->
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-menu
                v-model="data.Recodes.FID8017.modal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="利用開始日"
                    v-model="data.Recodes.FID8017.value"
                    :disabled="allDisabled8539"
                    :rules="[rules.required]"
                    background-color="white"
                    id="FID8017"
                    class="inputTime8017"
                    append-icon="mdi-calendar"
                    outlined
                    v-on="on"
                    readonly
                    hide-details="auto"
                    :hint="hint8017"
                    persistent-hint
                  >
                  </v-text-field> </template
                ><v-date-picker
                  v-model="data.Recodes.FID8017.value"
                  no-title
                  scrollable
                  locale="ja-jp"
                  :day-format="(date) => new Date(date).getDate()"
                  @input="data.Recodes.FID8017.modal = false"
                  :max="data.Recodes.FID8018.value"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" sm="6" md="3" lg="3">
              <v-menu
                v-model="data.Recodes.FID8018.modal"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="利用終了日"
                    v-model="data.Recodes.FID8018.value"
                    :disabled="allDisabled8539"
                    :rules="[rules.required]"
                    background-color="white"
                    id="FID8018"
                    class="inputTime8018"
                    append-icon="mdi-calendar"
                    outlined
                    v-on="on"
                    readonly
                    hide-details="auto"
                    :hint="hint8018"
                    persistent-hint
                  >
                  </v-text-field> </template
                ><v-date-picker
                  v-model="data.Recodes.FID8018.value"
                  no-title
                  scrollable
                  locale="ja-jp"
                  :day-format="(date) => new Date(date).getDate()"
                  @input="data.Recodes.FID8018.modal = false"
                  :min="data.Recodes.FID8017.value"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row v-for="(v, i) in values8019" :key="`8019-${i}`">
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-text-field
                label="行き先・貸出し先"
                v-model="values8019[i]"
                :disabled="data.Recodes.FID8019.disabled"
                :rules="[rules.required]"
                background-color="white"
                id="FID8019"
                class="textfield8019"
                outlined
                hide-details="auto"
                :hint="hint8019"
                persistent-hint
              >
              </v-text-field>
            </v-col>
            <v-col cols="0">
              <v-icon
                label=""
                :disabled="allDisabled8539"
                background-color="white"
                id="FID8020"
                class="PlusButton8020"
                size="50"
                color="success"
                @click="plusBox8020()"
                hide-details="auto"
              >
                mdi-plus-box-outline</v-icon
              >
              <v-icon size="50" color="error" @click="minusBox8020(i)">
                mdi-minus-box-outline
              </v-icon>
            </v-col>
          </v-row>
          <div v-show="showTemplate8054">
            <v-row
              v-if="
                typeof data.Recodes.FID8025.subReports != 'undefined' &&
                data.Recodes.FID8025.subReports.length > 0 &&
                data.Recodes.FID8014.value == '業務利用'
              "
            >
              <v-col
                cols="12"
                v-for="(report, i) in data.Recodes.FID8025.subReports"
                :key="`[8025-${i}`"
              >
                <applicationformSub1
                  label=""
                  :disabled="allDisabled8539"
                  background-color="white"
                  :id="'FID8025-' + i"
                  class="subPage8025"
                  ref="subPage8025"
                  :Report="report"
                  :subindex="i"
                  :daleteReports.sync="data.DeleteSubReports"
                  v-on:clickSubmit="saveSubPage"
                  :myInit="subInit"
                  FID="FID8025"
                  :allDisabled8539="allDisabled8539"
                  v-on:save8536="save8536"
                  hide-details="auto"
                >
                </applicationformSub1>
              </v-col>
            </v-row>
            <v-row> </v-row>
          </div>
          <div v-show="showTemplate8055">
            <v-row
              v-if="
                typeof data.Recodes.FID8026.subReports != 'undefined' &&
                data.Recodes.FID8026.subReports.length > 0
              "
            >
              <v-col
                cols="12"
                v-for="(report, i) in data.Recodes.FID8026.subReports"
                :key="`[8026-${i}`"
              >
                <applicationformSub2
                  label=""
                  :disabled="allDisabled8539"
                  background-color="white"
                  :id="'FID8026-' + i"
                  class="subPage8026"
                  ref="subPage8026"
                  :Report="report"
                  :subindex="i"
                  :daleteReports.sync="data.DeleteSubReports"
                  v-on:clickSubmit="saveSubPage"
                  :myInit="subInit"
                  :usePurpose="data.Recodes.FID8014.value"
                  FID="FID8026"
                  :allDisabled8539="allDisabled8539"
                  :status="data.Recodes.FID8021.value"
                  v-on:save8537="save8537"
                  v-on:save8292="save8292"
                  hide-details="auto"
                >
                </applicationformSub2>
              </v-col>
            </v-row>
            <v-row> </v-row>
          </div>
          <div v-show="showTemplate8056">
            <v-row
              v-if="
                typeof data.Recodes.FID8027.subReports != 'undefined' &&
                data.Recodes.FID8027.subReports.length > 0 &&
                data.Recodes.FID8014.value == '業務利用'
              "
            >
              <v-col
                cols="12"
                v-for="(report, i) in data.Recodes.FID8027.subReports"
                :key="`[8027-${i}`"
              >
                <applicationformSub3
                  label=""
                  :disabled="data.Recodes.FID8292.disabled || allDisabled8539"
                  background-color="white"
                  :id="'FID8027-' + i"
                  class="subPage8027"
                  ref="subPage8027"
                  :Report="report"
                  :subindex="i"
                  :daleteReports.sync="data.DeleteSubReports"
                  v-on:clickSubmit="saveSubPage"
                  :myInit="subInit"
                  FID="FID8027"
                  :allDisabled8539="allDisabled8539"
                  v-on:save8538="save8538"
                  hide-details="auto"
                >
                </applicationformSub3>
              </v-col>
            </v-row>
            <v-row> </v-row>
          </div>
          <template v-if="showTemplate8617 && $store.state.IPauth">
            <v-row class="mt-4">
              <v-col cols="6" sm="6" md="3" lg="3">
                <v-btn
                  label="日別承認"
                  :disabled="
                    data.Recodes.FID8616.disabled || inputRuleFID8616()
                  "
                  background-color="white"
                  id="FID8616"
                  class="button8616"
                  @click="onBtn8616"
                  color="amber"
                  block
                  style="font-size: 20px; letter-spacing: 10px"
                  height="50"
                  hide-details="auto"
                >
                  日別承認</v-btn
                >
              </v-col>
            </v-row>
          </template>

          <v-row>
            <v-col align="left">
              <!--
                :disabled="
                  data.ReportID == null ||
                  disabled ||
                  data.Recodes.FID8597.disabled ||
                  allDisabled8539
                "
                ---->
              <v-btn
                v-if="this.$store.state.role == 1"
                @click="deleteModal = true"
                :disabled="data.ReportID == null"
                color="red white--text"
                >管理者による削除
              </v-btn>
              <v-btn
                v-else
                @click="deleteModal = true"
                :disabled="
                  data.ReportID == null ||
                  data.Recodes.FID8597.disabled ||
                  allDisabled8539
                "
                color="red white--text"
                >削除
              </v-btn>
              <v-dialog v-model="deleteModal" max-width="400">
                <v-card>
                  <v-card-title class="text-h5"> 削除しますか？ </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      class="mr-3"
                      color="green darken-1 white--text"
                      @click="deleteModal = false"
                      large
                    >
                      いいえ
                    </v-btn>
                    <v-btn color="red darken-1 white--text" @click="del" large>
                      はい
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col v-if="showTemplate8292">
              <v-btn
                @click="save8292()"
                color="light-green white--text"
                fixed
                bottom
                :style="'right:+50px;'"
              >
                {{ tempSaveLabel }}
              </v-btn>
            </v-col>
            <v-col align="right" v-if="showTemplate8291">
              <v-btn @click="save8291()" color="indigo white--text">
                利用申請
              </v-btn>
            </v-col>
          </v-row>
          <!-- 完了モーダル -->
          <v-dialog
            v-model="completeModal"
            max-width="400"
            @click:outside="closeModal"
          >
            <v-card class="justify-center">
              <v-card-title class="text-h5">
                保存完了
                <v-card-actions class="mt-2 text-subtitle-1">
                  {{ countdown + 1 }}秒後に閉じる
                </v-card-actions>
              </v-card-title>
            </v-card>
          </v-dialog>
        </v-form>
      </div>
    </v-container>
  </div>
</template>

<script>
import store from "../store/vuex.js";
import { RepositoryFactory } from "../api/RepositoryFactory";
const api = RepositoryFactory.get("seisei_back");
const eneosCar = RepositoryFactory.get("eneosCar");
import carNumber from "../views/carNumberBase";
import applicationformSub1 from "../views/applicationformSub1";
import applicationformSub2 from "../views/applicationformSub2";
import applicationformSub3 from "../views/applicationformSub3";
import { carriyou } from "./specialMethod";

export default {
  name: "Home",
  components: {
    carNumber,
    applicationformSub1,
    applicationformSub2,
    applicationformSub3,
  },
  data() {
    return {
      isLoading: false,
      escapeValidation: false,
      showChild: true,
      subInit: false,
      //disabled: false,
      data: {
        PageID: 1160,
        ReportID: null, //新規ならnull、更新ならidがある
        Recodes: {
          FID8014: { value: "", formType: 2, disabled: false },
          FID8021: { value: "申請前", formType: 2, disabled: true },
          FID9584: { value: "", formType: 1, disabled: false, modal: false },
          FID8023: { value: "", formType: 1, disabled: false },
          FID8615: { value: "", formType: 1, disabled: true },
          FID8583: { value: "", formType: 1, disabled: true },
          FID8015: {
            value: new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              new Date().getHours() + 9
            )
              .toISOString()
              .match(/^\d{4}-\d{2}-\d{2}/g)
              .pop(),
            formType: 3,
            disabled: false,
            modal: false,
          },
          FID8016: { value: "ログインユーザ", formType: 2, disabled: false },
          FID8017: {
            value: new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              new Date().getHours() + 9
            )
              .toISOString()
              .match(/^\d{4}-\d{2}-\d{2}/g)
              .pop(),
            formType: 3,
            disabled: false,
            modal: false,
          },
          FID8018: {
            value: new Date(
              new Date().getFullYear(),
              new Date().getMonth(),
              new Date().getDate(),
              new Date().getHours() + 9
            )
              .toISOString()
              .match(/^\d{4}-\d{2}-\d{2}/g)
              .pop(),
            formType: 3,
            disabled: false,
            modal: false,
          },
          FID8019: { value: "", formType: 1, disabled: false },
          FID8020: { value: "", formType: 44, disabled: false },
          FID8025: {
            value: "PID1161",
            formType: 17,
            disabled: false,
            subReports: [{}],
          },
          FID8026: {
            value: "PID1162",
            formType: 17,
            disabled: false,
            subReports: [{}],
          },
          FID8027: {
            value: "PID1163",
            formType: 17,
            disabled: false,
            subReports: [{}],
          },
          FID8292: { value: "", formType: 20, disabled: false },
          FID8291: { value: "", formType: 20, disabled: false },
          FID8536: { value: "", formType: 20, disabled: false },
          FID8537: { value: "", formType: 20, disabled: false },
          FID8538: { value: "", formType: 20, disabled: false },
          FID8618: { value: "", formType: 20, disabled: false },
          FID8616: { value: "", formType: 16, disabled: false },
          FID8597: { value: "", formType: 22, disabled: false },
          FID9572: { value: "", formType: 1, disabled: false },
        },
        DeleteSubReports: [],
      },
      selectBoxFID8014: ["業務利用", "代車利用"],
      selectBoxFID8021: [
        "申請前",
        "業務前",
        "利用中",
        "業務後",
        "提出済",
        "承認済",
      ],
      selectBoxFID8016: [this.$store.state.params.FID0.name],
      UserName: "",
      values8019: [""],
      validationFlag8291: false,
      validationFlag8536: false,
      validationFlag8537: false,
      validationFlag8538: false,
      validationFlag8618: false,

      rules: {
        required: (value) => !!value || "必須項目です",
        SendValidation8291No0: () => {
          if (!this.validationFlag8291) {
            return true;
          }
          return true;
        },
        SendValidation8291No2: (value) => {
          if (!this.validationFlag8291) {
            return true;
          }
          if (value != "") {
            this.validationFlag8291 = false;
            return "のみ";
          }
          return true;
        },
        SendValidation8536No0: () => {
          if (!this.validationFlag8536) {
            return true;
          }
          return true;
        },
        SendValidation8537No0: () => {
          if (!this.validationFlag8537) {
            return true;
          }
          return true;
        },
        SendValidation8538No0: () => {
          if (!this.validationFlag8538) {
            return true;
          }
          return true;
        },
        SendValidation8618No0: () => {
          if (!this.validationFlag8618) {
            return true;
          }
          return true;
        },
      },
      deleteModal: false,
      changeStatusModal: false,
      countdown: 1,
      completeModal: false,
      backFlag: true,
      copyFlag: false,
      sendCopyFlag: false,
      noBack: false,
      backCount: -1,
      saveObj: null,
      //
      accountRank: -1,
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {
    resizeWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return "270px";
        case "sm":
          return "500px";
        case "md":
          return "600px";
        case "lg":
          return "500px";
        case "xl":
          return "500px";
        default:
          return "500px";
      }
    },
    hint8017: function () {
      if (this.data.Recodes.FID8014.value == "業務利用") {
        return "使用予定を入力 ※「利用終了日」以前の日付を選択";
      }
      if (this.data.Recodes.FID8014.value == "代車利用") {
        return "貸出期間を入力 ※「利用終了日」以前の日付を選択";
      }
      return "";
    },
    hint8018: function () {
      return "※「利用開始日」以降の日付のみ選択可";
    },
    hint8019: function () {
      if (this.data.Recodes.FID8014.value == "業務利用") {
        return "行先や要件を入力";
      }
      if (this.data.Recodes.FID8014.value == "代車利用") {
        return "お客様名と用途を入力";
      }
      return "";
    },
    hintText: function () {
      // 条件に基づいて表示文字列を変更します
      if (this.data.Recodes.FID8021.value == "業務前") {
        return "※酒気帯び「無」押下後にステータスが「利用中」にならない場合のみ押下ください";
      } else if (this.data.Recodes.FID8021.value == "利用中") {
        return "※「終了」押下後にステータスが「業務後」にならない場合のみ押下ください";
      }
      return "";
    },
    tempSaveLabel() {
      if (this.data.Recodes.FID8021.value == "申請前") return "一時保存";
      if (
        this.data.Recodes.FID8021.value == "承認済" &&
        this.$store.state.role == 1
      ) {
        return "システム管理者による修正";
      }
      return "修正";
    },
    showTemplate8054() {
      if (
        this.data.Recodes.FID8021.value == "業務前" ||
        this.data.Recodes.FID8021.value == "提出済" ||
        this.data.Recodes.FID8021.value == "承認済"
      ) {
        return true;
      }
      return false;
    },
    showTemplate8055() {
      if (
        this.data.Recodes.FID8021.value == "利用中" ||
        this.data.Recodes.FID8021.value == "提出済" ||
        this.data.Recodes.FID8021.value == "承認済"
      ) {
        return true;
      }
      return false;
    },
    showTemplate8056() {
      if (
        this.data.Recodes.FID8021.value == "利用終了" ||
        this.data.Recodes.FID8021.value == "業務後" ||
        this.data.Recodes.FID8021.value == "提出済" ||
        this.data.Recodes.FID8021.value == "承認済"
      ) {
        return true;
      }
      return false;
    },
    showTemplate8292() {
      if (
        this.data.Recodes.FID8021.value == "承認済" &&
        this.$store.state.role == 1
      ) {
        return true;
      }
      if (
        this.data.Recodes.FID8021.value != "利用終了" &&
        this.data.Recodes.FID8021.value != "承認済"
      ) {
        return true;
      }
      return false;
    },
    showTemplate8291() {
      if (this.data.Recodes.FID8021.value == "申請前") {
        return true;
      }
      return false;
    },
    showTemplate8536() {
      return true;
    },
    showTemplate8537() {
      return true;
    },
    showTemplate8538() {
      return true;
    },
    showTemplate8618() {
      return true;
    },
    showTemplate8617() {
      if (this.data.Recodes.FID8021.value == "提出済") {
        return true;
      }
      return false;
    },
    allDisabled8539() {
      let flag = false;
      let data = this.data;
      if (
        data.Recodes.FID8021.value == "承認済" &&
        this.$store.state.role == 1
      ) {
        return false;
      }
      if (
        //data.Recodes.FID8021.value == "提出済" ||
        data.Recodes.FID8021.value == "承認済"
      ) {
        return !flag;
      }
      return flag;
    },
  },
  watch: {
    $route() {
      this.getInit();
    },
    data: {
      handler: async function (data) {
        if (
          data.Recodes.FID8021.value == "承認済"
        ) {
          this.data.Recodes.FID8019.disabled = true;
        } else {
          this.data.Recodes.FID8019.disabled = false;
        }
        if (data.Recodes.FID8021.value != "申請前") {
          this.data.Recodes.FID8027.disabled = true;
        } else {
          this.data.Recodes.FID8027.disabled = false;
        }
        if (
          this.data.Recodes.FID8021.value != "提出済" &&
          carriyou(data, this.accountRank)
        ) {
          this.data.Recodes.FID8616.disabled = true;
        } else {
          this.data.Recodes.FID8616.disabled = false;
        }
        if (data.Recodes.FID8021.value != "申請前") {
          this.data.Recodes.FID8597.disabled = true;
        } else {
          this.data.Recodes.FID8597.disabled = false;
        }
      },
      deep: true,
    },

    values8019: function (value) {
      this.$set(this.data.Recodes.FID8019, "value", value.join(","));
    },
  },
  methods: {
    async getInit() {
      try {
        //初期処理
        this.isLoading = true;
        this.noBack = false;
        this.backFlag = true;

        //
        if (this.$route.query.copyFlag) {
          this.reportCopy();
        } else if (this.$route.params.report_id != null) {
          this.data.ReportID = Number(this.$route.params.report_id);
          //データ取得
          let data = {
            PageID: this.data.PageID,
            ReportID: Number(this.data.ReportID),
          };
          const result = await api.ReportDetailV2(data);
          this.RecodeCopy(this.data, result.Recodes);
          //初期処理

          //不具合の対応
          if (
            this.data.Recodes.FID8016.value == "" ||
            this.data.Recodes.FID8016.value == "ログインユーザ"
          ) {
            this.data.Recodes.FID8016.value =
              this.$store.state.params.FID0.name;
            this.selectBoxFID8016.push(this.$store.state.params.FID0.name);
          }
        } else {
          if (
            this.selectBoxFID8016.length == 1 &&
            (this.data.Recodes.FID8016.value == "" ||
              this.data.Recodes.FID8016.value == "ログインユーザ")
          ) {
            this.data.Recodes.FID8016.value = this.selectBoxFID8016[0];
          }
        }
        //データ取得処理
        //init処理
        this.initValues8023();
        this.initCheck8014();
        this.initCheck8015();
        this.initValues8019();
        this.initSubPage8025();
        this.initSubPage8026();
        this.initSubPage8027();
        this.initCheck8616();
        this.initValues9572();
        await this.initUserName();
        await this.initAccountRank();
        //読み込み終了
        this.isLoading = false;
      } catch (e) {
        console.log(e);
        alert("読み込みに失敗しました");
        this.back();
      }
    },
    RecodeCopy(data, response) {
      for (const [FID] of Object.entries(data.Recodes)) {
        if (typeof response[FID] != "undefined") {
          data.Recodes[FID].value = response[FID].value;
        }
        if (
          typeof response[FID] != "undefined" &&
          typeof response[FID].subReports != "undefined" &&
          response[FID].subReports != null
        ) {
          data.Recodes[FID].subReports = response[FID].subReports;
        }
      }
    },
    plusBox8020() {
      let max = 20;
      if (this.values8019.length < max) {
        this.values8019.push("");
      }
    },
    minusBox8020(index) {
      let min = 1;
      if (this.values8019.length > min) {
        this.values8019.splice(index, 1);
      }
    },
    subPageAdd(FID, index) {
      this.isLoading = true;
      if (
        typeof this.data.Recodes[FID] == "undefined" ||
        typeof this.data.Recodes[FID].subReports == "undefined"
      ) {
        index = 0;
        this.data.Recodes[FID].subReports = [];
      }
      if (typeof index == "undefined") {
        index = this.data.Recodes[FID].subReports.length;
      }
      let max = 1;
      if (this.data.Recodes[FID].subReports.length < max) {
        this.data.Recodes[FID].subReports.splice(index, 0, {});
      }
      this.isLoading = false;
    },
    subPageDel(FID, index) {
      //this.subInit = false;
      let min = 1;
      if (typeof index == "undefined") {
        index = this.data.Recodes[FID].subReports.length - 1;
      }
      if (this.data.Recodes[FID].subReports.length > min) {
        this.data.DeleteSubReports = this.data.DeleteSubReports.concat(
          this.data.Recodes[FID].subReports.splice(index, 1)
        );
      }
      //this.subInit = true;
    },
    saveSubPage(FID, subindex, reports) {
      this.data.Recodes[FID].subReports.splice(subindex, 1, reports);
      if (reports.DeleteSubReports.length == 1) {
        this.data.DeleteSubReports = this.data.DeleteSubReports.concat(
          reports.DeleteSubReports.pop()
        );
      }
    },
    async validationTermsFID8025(data) {
      if (
        data.Recodes.FID8021.value == "業務前" ||
        data.Recodes.FID8021.value == "提出済"
      ) {
        return true;
      }
      return false;
    },
    async validationTermsFID8026(data) {
      if (
        data.Recodes.FID8021.value == "利用中" ||
        data.Recodes.FID8021.value == "提出済"
      ) {
        return true;
      }
      return false;
    },
    async validationTermsFID8027(data) {
      if (
        data.Recodes.FID8021.value == "利用終了" ||
        data.Recodes.FID8021.value == "提出済"
      ) {
        return true;
      }
      return false;
    },
    getDateTime() {
      let date = new Date();
      let years = date.getFullYear().toString();
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let days = date.getDate().toString().padStart(2, "0");
      let hours = date.getHours().toString().padStart(2, "0");
      let minutes = date.getMinutes().toString().padStart(2, "0");
      let seconds = date.getSeconds().toString().padStart(2, "0");
      return (
        years +
        "-" +
        month +
        "-" +
        days +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },
    async save8292() {
      if (!(await this.checkValidation())) {
        return;
      }
      this.data.Recodes.FID8292.value = this.getDateTime();
      await this.send();
    },
    async save8291() {
      if (!(await this.checkValidation())) {
        return;
      }
      this.escapeValidation = true;
      this.validationFlag8291 = true;
      if (this.data.Recodes.FID8014.value == "業務利用") {
        this.data.Recodes.FID8291.value = "業務前";
      } else if (this.data.Recodes.FID8014.value == "代車利用") {
        this.data.Recodes.FID8291.value = "利用中";
      } else {
        alert("想定外の利用目的");
        return;
      }
      this.noBack = true;
      this.data.Recodes.FID8021.value = this.data.Recodes.FID8291.value;
      await this.send();
    },
    async save8536() {
      if (!(await this.checkValidation())) {
        return;
      }
      this.escapeValidation = true;
      this.validationFlag8536 = true;
      if (this.data.Recodes.FID8025.subReports[0].Recodes.FID8253.value != "") {
        this.data.Recodes.FID8536.value = "利用中";
      } else {
        this.data.Recodes.FID8536.value = "業務前";
      }
      this.noBack = true;
      this.data.Recodes.FID8021.value = this.data.Recodes.FID8536.value;
      await this.send();
    },
    async save8537() {
      if (!(await this.checkValidation())) {
        return;
      }
      this.escapeValidation = true;
      this.validationFlag8537 = true;

      if (this.data.Recodes.FID8014.value == "業務利用") {
        this.data.Recodes.FID8537.value = "業務後";
      } else if (this.data.Recodes.FID8014.value == "代車利用") {
        this.data.Recodes.FID8537.value = "提出済";
      } else {
        alert("想定外の利用目的");
        return;
      }
      if (this.data.Recodes.FID8014.value == "代車利用") {
        //代車利用なら戻る
        this.noBack = false;
      } else {
        //業務利用なら戻らない
        this.noBack = true;
      }
      this.data.Recodes.FID8021.value = this.data.Recodes.FID8537.value;
      await this.send();
    },
    async save8538() {
      if (!(await this.checkValidation())) {
        return;
      }
      this.escapeValidation = true;
      this.validationFlag8538 = true;
      this.data.Recodes.FID8538.value = "提出済";
      if (this.data.Recodes.FID8027.subReports[0].Recodes.FID8254.value != "") {
        this.data.Recodes.FID8538.value = "提出済";
      } else {
        this.data.Recodes.FID8538.value = "業務後";
      }
      this.data.Recodes.FID8021.value = this.data.Recodes.FID8538.value;
      await this.send();
    },
    async save8618() {
      if (!(await this.checkValidation())) {
        return;
      }
      this.escapeValidation = true;
      this.validationFlag8618 = true;
      this.data.Recodes.FID8618.value = "承認済";
      this.data.Recodes.FID8021.value = this.data.Recodes.FID8618.value;
      await this.send();
    },
    async onBtn8616() {
      let check = await this.checkValidation();
      if (!check) {
        return;
      }
      //処理を記載
      const date = this.getDateTime();
      this.data.Recodes.FID8616.value = date; //tag:日付+時間
      this.data.Recodes.FID8616.disabled = true; //1回だけ
      await this.save8618(); //送信処理
    },
    inputRuleFID8616() {
      let data = this.data;
      if (!this.$store.state.IPauth) {
        return true;
      }
      //
      if (
        this.data.Recodes.FID8021.value == "提出済" &&
        carriyou(data, this.accountRank)
      ) {
        return false;
      }
      return true;
    },
    validateSubValue() {
      // subページの有無を確認
      if (this.data.Recodes.FID8021.value === '業務前') {
        // アルコール点検が行われいるか確認
        if (this.data.Recodes.FID8025.subReports[0].Recodes.FID8253.value === "") {
          alert('業務前の酒気帯び運転確認を行ってください。');
          return;
        }
      }
      if (this.data.Recodes.FID8021.value === '利用中') {
        // 終了ボタンが押されているか確認
        if (this.data.Recodes.FID8026.subReports[0].Recodes.FID8031.value === "") {
          alert('社有車利用を終了してください。');
          return;
        }
      }

      // subページが入力されていたらモーダル表示
      this.changeStatusModal = true;
    },
    async onChange8021() {
      // 値の強制変更
      if (this.data.Recodes.FID8014.value == "業務利用") {
        if (this.data.Recodes.FID8021.value === '業務前') {
          this.data.Recodes.FID8021.value = '利用中';
        } else if (this.data.Recodes.FID8021.value === '利用中') {
          this.data.Recodes.FID8021.value = '業務後';
        }
      } else if (this.data.Recodes.FID8014.value == "代車利用") {
        if (this.data.Recodes.FID8021.value === '利用中') {
          this.data.Recodes.FID8021.value = '提出済';
        }
      } else {
        alert("想定外の利用目的");
        return;
      }
      this.changeStatusModal = false;
      await this.send();
    },
    initValues8023() {
      if (
        this.data.Recodes.FID8023.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID7996") != "undefined" &&
        localStorage.getItem("kakanaiValueFID7996") != null
      ) {
        this.data.Recodes.FID8023.value = localStorage.getItem(
          "kakanaiValueFID7996"
        );
      } else if (
        this.data.Recodes.FID8023.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID8007") != "undefined" &&
        localStorage.getItem("kakanaiValueFID8007") != null
      ) {
        this.data.Recodes.FID8023.value = localStorage.getItem(
          "kakanaiValueFID8007"
        );
      }
    },
    initCheck8014() {
      if (
        this.$route.params.report_id == null &&
        this.data.Recodes.FID8014.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID8014") != "undefined" &&
        localStorage.getItem("kakanaiValueFID8014") != null
      ) {
        let val = localStorage.getItem("kakanaiValueFID8014");
        switch (val) {
          case "SS代車":
            this.data.Recodes.FID8014.value = "代車利用";
            break;
          default:
            this.data.Recodes.FID8014.value = "業務利用";
        }
      }
    },
    initCheck8015() {
      if (typeof this.data.Recodes.FID8015 == "undefined") {
        this.data.Recodes.FID8015 = { value: "", formType: 3 };
      }
      if (this.data.Recodes.FID8015.value == "") {
        this.data.Recodes.FID8015.value = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date().getDate(),
          new Date().getHours() + 9
        )
          .toISOString()
          .match(/^\d{4}-\d{2}-\d{2}/g)
          .pop();
      }
    },
    initValues8019() {
      if (this.data.Recodes.FID8019.value != "") {
        this.values8019 = this.data.Recodes.FID8019.value.split(",");
      }
    },
    initSubPage8025() {
      for (let i = this.data.Recodes.FID8025.subReports.length; i < 1; i++) {
        this.data.Recodes.FID8025.subReports.push({});
      }
    },
    initSubPage8026() {
      for (let i = this.data.Recodes.FID8026.subReports.length; i < 1; i++) {
        this.data.Recodes.FID8026.subReports.push({});
      }
    },
    initSubPage8027() {
      for (let i = this.data.Recodes.FID8027.subReports.length; i < 1; i++) {
        this.data.Recodes.FID8027.subReports.push({});
      }
    },
    initValues9572() {
      //車両所属部署
      if (
        this.data.Recodes.FID9572.value == "" &&
        typeof localStorage.getItem("kakanaiValueFID9572") != "undefined" &&
        localStorage.getItem("kakanaiValueFID9572") != null
      ) {
        this.data.Recodes.FID9572.value = localStorage.getItem(
          "kakanaiValueFID9572"
        );
      }
    },
    async initUserName() {
      let value = this.data.Recodes.FID8016.value;
      try {
        const res = await eneosCar.GetUserName({
          id: this.data.Recodes.FID8016.value,
        });
        value = res.message;
      } catch (e) {
        console.log("initUserName:", e);
      }
      this.UserName = value;
    },
    initCheck8616() {
      if (typeof this.data.Recodes.FID8616 == "undefined") {
        this.data.Recodes.FID8616 = { value: "", formType: 16 };
      }
      if (this.data.Recodes.FID8616.value != "") {
        this.data.Recodes.FID8616.disabled = true; //1回だけ
      }
    },
    async initAccountRank() {
      const response = await eneosCar.getDepartmentRank();
      this.accountRank = Number(response);
    },
    checkValidation: async function () {
      let form = this.$refs.form;
      let check = form.validate();
      if (!check) {
        const firstError = form.inputs.find((input) => input.hasError);
        this.$vuetify.goTo(firstError, { offset: 100 });
        return check;
      }
      let flag8027 = await this.validationTermsFID8027(this.data);
      let flag8026 = await this.validationTermsFID8026(this.data);
      let flag8025 = await this.validationTermsFID8025(this.data);
      if (typeof this.$refs.subPage8025 != "undefined" && flag8025) {
        for (let i = 0; i < this.$refs.subPage8025.length; i++) {
          let tmp = await this.$refs.subPage8025[i].checkValidation();
          if (tmp == false) {
            check = false;
          }
        }
      }
      if (typeof this.$refs.subPage8026 != "undefined" && flag8026) {
        for (let i = 0; i < this.$refs.subPage8026.length; i++) {
          let tmp = await this.$refs.subPage8026[i].checkValidation();
          if (tmp == false) {
            check = false;
          }
        }
      }
      if (typeof this.$refs.subPage8027 != "undefined" && flag8027) {
        for (let i = 0; i < this.$refs.subPage8027.length; i++) {
          let tmp = await this.$refs.subPage8027[i].checkValidation();
          if (tmp == false) {
            check = false;
          }
        }
      }
      return check;
    },
    async send() {
      const result = this.escapeValidation
        ? true
        : await this.checkValidation();

      if (result) {
        this.isLoading = true;

        const save = await api.SendReport(this.data);
        if (save == false || save.result == "Fail") {
          alert("登録失敗[Error0830-1]");
          this.isLoading = false;
          return;
        }
        this.saveObj = save;
        if (this.sendCopyFlag) {
          this.sendCopy();
          return;
        }
        //モーダルを表示
        this.isLoading = false;
        this.openModal();
        //
      }
    },
    back() {
      store.commit("clearreports");
      if (this.copyFlag) {
        history.go(this.backCount);
      } else {
        history.back();
      }
    },
    async del() {
      this.isLoading = true;
      await api.DelReport(this.data);
      alert("削除完了");
      this.back();
    },
    openModal() {
      this.completeModal = true;
      this.intervalId = setInterval(this.ModalCountdown, 1000);
    },
    async ModalCountdown() {
      if (this.countdown > 0) {
        this.countdown--;
      } else {
        this.completeModal = false; //モーダルを閉じる
        //送信後処理
        this.closeModal();
      }
    },
    async closeModal() {
      //タイマーを止める
      clearInterval(this.intervalId);
      this.completeModal = false;
      //戻る処理
      if (!this.noBack) {
        this.backFlag = false;
        this.back();
      } else {
        this.$set(this.$route.params, "report_id", this.saveObj.reportID);
        this.$set(this.data, "ReportID", this.saveObj.reportID);
        await this.getInit();
      }
    },
  },
};
</script>
<style>
.hint-text {
  color: #00000099;
    font-size: 12px;
    line-height: 12px;
    display: inline-block;
    margin-top: 10px;
}
</style>
