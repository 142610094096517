<template>
  <div class="home">
    <p>従業員情報取り込み:{{ step }}</p>
    <v-col align="right"><v-btn @click.once="back">戻る</v-btn></v-col>
    <div v-if="isLoading" align="center">
      <v-progress-circular
        :size="250"
        color="primary"
        indeterminate
        align="center"
      ></v-progress-circular>
    </div>
    <div v-else>
      <v-container cols="12">
        <v-form ref="form">
          <v-row>
            <v-col cols="2">
              <v-checkbox
                v-model="header_flag"
                label="ヘッダー有"
                disabled
              ></v-checkbox>
            </v-col>
            <v-col class="mt-5">
              ※新規発行したユーザのデフォルトパスワードは「******」です<br />
              ※初期パスワードの有効期限は14日です<br />
              ※新規作成時のシステム権限は「一般」になります<br />
              氏名の変更は無視されます※環境依存文字が差分判定をさせるため<br />
            </v-col>
          </v-row>
          <v-row>
            <v-file-input
              v-model="uploadfile.file"
              type="file"
              label="CSV取り込み"
              prepend-icon="mdi-image"
              ref="rfafile"
              accept=".csv"
              @change="chargeCSV"
              :rules="[rules.fileSize]"
              show-size
            ></v-file-input
          ></v-row>
          <v-row class="mb-2">
            <v-col cols="6"> </v-col>
            <v-col cols="2">
              <v-btn
                @click="updateCheck"
                color="green white--text"
                :disabled="step != 0 || reports.length < 1"
              >
                ①更新チェック
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn
                @click="deleteCheck"
                color="red white--text"
                :disabled="step != 1"
              >
                ②削除チェック
              </v-btn>
            </v-col>
            <v-col cols="2">
              <v-btn
                @click="sendUserData"
                color="primary"
                :disabled="step != 2 || reports.length < 1"
              >
                ③一括更新
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-row v-if="errorMessages.length > 0">
          <v-col cols="10">
            {{ errorMessages.length }}件のエラーが見つかりました
          </v-col>
          <v-col
            v-for="(message, i) in errorMessages"
            cols="4"
            :key="`[me-${i}]`"
          >
            {{ message }}
          </v-col>
        </v-row>
        <v-row>
          <v-data-table
            :headers="headers"
            :items="step == 2 ? delReports : reports"
            :item-key="reports.ID"
            :header-props="{
              'sort-by-text': '並び順',
            }"
            :footer-props="{
              'items-per-page-text': '行/ページ:',
            }"
            no-data-text="データが存在しません"
            :items-per-page="15"
          >
            <template v-slot:item.Recodes.FID0.role="{ item }" v-ripple>
              {{ item.Recodes.FID0.role == 1 ? "管理者" : "一般" }}
            </template>
            <template v-slot:item.Recodes.FID7767.value="{ item }">
              {{ $store.state.organization[item.Recodes.FID7767.value] }}
            </template>
            <template v-slot:item.Recodes.FID7776.value="{ item }">
              {{ $store.state.organization[item.Recodes.FID7776.value] }}
            </template>
          </v-data-table>
        </v-row>
        <v-row v-if="localFlag">
          <v-col cols="3">
            <v-btn @click="UserConsistencyCheck" color="primary">
              整合性チェック
            </v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn
              @click="UserDataEnsureConsistency"
              color="red"
              :disabled="consistencyCheckReports.length == 0"
            >
              整合性チェック後削除
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="consistencyCheckReports.length > 0 && localFlag">
          <v-col cols="12">
            <v-data-table
              :headers="consistencyCheckReportHeader"
              :items="consistencyCheckReports"
              :item-key="reports.Account"
              :items-per-page="5"
              :header-props="{
                'sort-by-text': '並び順',
              }"
              :footer-props="{
                'items-per-page-text': '行/ページ:',
              }"
              no-data-text="データが存在しません"
            >
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { convertHalfWidthToFullWidthKana } from "./specialMethod";
import { RepositoryFactory } from "../api/RepositoryFactory";
const eneosCar = RepositoryFactory.get("eneosCar");
import XLSX from "xlsx";
import { getDivisionAll, getDepartmentAll } from "./specialMethod";

export default {
  name: "Home",
  components: {},
  data() {
    return {
      isLoading: false,
      fullPage: false,
      headerText: ",", //`$$headerText`,
      valid: true,
      step: 0,
      data: {
        PageID: 1036, //
        Page_id: 1036, //送信に使っているが本来は変換する
        Report_id: null, //
        UpdateType: "update",
        KeyID: [7762],
        KeyMap: {
          FID7762: 0,
        },
        Recodes: {
          FID0: {
            value: "",
            formType: 21,
            name: "",
            mail: "",
            role: "0",
            password: "",
            account: "",
            attribute: "",
            detail: "",
          },
          FID7762: { value: "", formType: 1 },
          FID7763: { value: "", formType: 1 },
          FID7764: { value: "一般", formType: 7 },
          FID8135: { value: "", formType: 14 },
          FID7765: { value: "", formType: 14 },
          FID8748: { value: "", formType: 14 },
          FID7766: { value: "", formType: 14 },
          FID8133: { value: "", formType: 14 },
          FID8134: { value: "", formType: 14 },
          FID7767: { value: "", formType: 1 },
          FID7776: { value: "", formType: 1 },
          FID7777: { value: "", formType: 2 },
          FID9137: { value: "", formType: 1 },
        },
      },
      //
      sendFlag: false,
      uploadfile: { file: {}, url: "", data: { name: "", size: 0, type: "" } },
      header_flag: true,
      reports: [],
      delReports: [],
      rules: {
        fileSize: (value) => {
          return (
            value == null ||
            value.size < 15000000 ||
            "ファイルサイズを15MB未満にしてください"
          );
        },
      },
      divisions: [],
      departments: [],
      //
      errorMessages: [],
      //
      localFlag: false,
      consistencyCheckReports: [],
    };
  },
  async created() {
    await this.getInit();
  },
  computed: {
    headers() {
      return [
        // {
        //   text: "ID",
        //   value: "ReportID",
        //   sortable: true,
        //   width: "10px",
        // },
        {
          text: "変更内容",
          value: "Recodes.FID0.detail",
          sortable: true,
          width: "400px",
        },
        {
          text: "社員番号(name)",
          value: "Recodes.FID0.name",
          sortable: true,
          width: "100px",
        },
        {
          text: "社員番号(value)",
          value: "Recodes.FID9137.value",
          sortable: true,
          width: "100px",
        },
        {
          text: "氏名",
          value: "Recodes.FID7762.value",
          sortable: true,
          width: "130px",
        },
        {
          text: "氏名カナ",
          value: "Recodes.FID7763.value",
          sortable: true,
          width: "100px",
        },
        {
          text: "事業部・支社",
          value: "Recodes.FID7767.value",
          sortable: true,
          width: "130px",
        },
        {
          text: "部署",
          value: "Recodes.FID7776.value",
          sortable: true,
          width: "200px",
        },
        {
          text: "従業員区分",
          value: "Recodes.FID7777.value",
          sortable: true,
          width: "120px",
        },
        {
          text: "システム権限",
          value: "Recodes.FID0.role",
          sortable: true,
          width: "125px",
        },
        {
          text: "車両権限",
          value: "Recodes.FID7764.value",
          sortable: true,
          width: "100px",
        },
        /*
        {
          text: "代理者",
          value: "Recodes.FID8135.value",
          sortable: true,
          width: "100px",
        },
        */
        {
          text: "給油確認者",
          value: "Recodes.FID7765.value",
          sortable: true,
          width: "110px",
        },
        {
          text: "車両確認(日別)",
          value: "Recodes.FID8748.value",
          sortable: true,
          width: "135px",
        },
        {
          text: "車両確認(月別)",
          value: "Recodes.FID7766.value",
          sortable: true,
          width: "135px",
        },
        {
          text: "車両追加者",
          value: "Recodes.FID8133.value",
          sortable: true,
          width: "110px",
        },
        {
          text: "マイカー添付書類",
          value: "Recodes.FID8134.value",
          sortable: true,
          width: "160px",
        },
      ];
    },
    consistencyCheckReportHeader() {
      return [
        {
          text: "name",
          value: "name",
        },
        {
          text: "アカウント",
          value: "account",
        },

        {
          text: "empFlag",
          value: "emp_flag",
        },
        {
          text: "RepFlag",
          value: "rep_flag",
        },
        {
          text: "CogFlag",
          value: "cog_flag",
        },
      ];
    },
  },
  methods: {
    async getInit() {
      //初期処理
      this.isLoading = true;
      //
      [this.divisions, this.departments] = await Promise.all([
        getDivisionAll(),
        getDepartmentAll(),
      ]);
      //
      let url = window.location.href.split("/");
      if (url[2] == "localhost:8128" || url[2] == "localhost:8129") {
        this.localFlag = true;
      }
      //読み込み終了
      this.isLoading = false;
    },
    /*-----------------------1010100000------*/
    //文字コードのチェック
    charCodeConvert(file) {
      const Encoding = require("encoding-japanese");
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          //文字列をバイト列に変換
          var sjisArray = new Uint8Array(reader.result);
          let check = Encoding.detect(sjisArray);
          console.log("check", check);
          //
          let encoding = this.detectEncoding(reader.result);
          switch (encoding) {
            case "SJIS":
              var unicodeArray = Encoding.convert(sjisArray, {
                to: "UNICODE",
                from: "SJIS",
                type: "string",
              });
              resolve(unicodeArray);
              break;
            case "UTF8":
              var unicodeArray2 = Encoding.convert(sjisArray, {
                to: "UTF8",
                from: "AUTO",
                type: "string",
              });
              resolve(unicodeArray2);
              break;
            case "UNICODE":
              var unicodeArray3 = Encoding.convert(sjisArray, {
                to: "UNICODE",
                from: "AUTO",
                type: "string",
              });
              resolve(unicodeArray3);
              break;
            default:
              alert("文字コードが対応していません:" + encoding);
          }
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    },
    detectEncoding(buffer) {
      const uint8Array = new Uint8Array(buffer);
      if (
        uint8Array[0] === 0xef &&
        uint8Array[1] === 0xbb &&
        uint8Array[2] === 0xbf
      ) {
        return "UTF8";
      } else if (uint8Array[0] === 0xfe && uint8Array[1] === 0xff) {
        return "UTF16BE";
      } else if (uint8Array[0] === 0xff && uint8Array[1] === 0xfe) {
        return "UTF16LE";
      } else if (
        uint8Array[0] === 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0xfe &&
        uint8Array[3] === 0xff
      ) {
        return "UTF32BE";
      } else if (
        uint8Array[0] === 0xff &&
        uint8Array[1] === 0xfe &&
        uint8Array[2] === 0 &&
        uint8Array[3] === 0
      ) {
        return "UTF32LE";
      } else if (
        uint8Array[0] === 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0 &&
        uint8Array[3] !== 0
      ) {
        return "UTF32BE-noBOM";
      } else if (
        uint8Array[0] !== 0 &&
        uint8Array[1] === 0 &&
        uint8Array[2] === 0 &&
        uint8Array[3] === 0
      ) {
        return "UTF32LE-noBOM";
      } else {
        return "SJIS";
      }
    },
    /*ダブルクォーテーション内のカンマは分割しない*/
    csvSplit(line) {
      let c = "";
      let s = new String();
      let data = new Array();
      let singleQuoteFlg = false;

      for (let i = 0; i < line.length; i++) {
        c = line.charAt(i);
        if (c == "," && !singleQuoteFlg) {
          data.push(s.toString());
          s = "";
        } else if (c == "," && singleQuoteFlg) {
          s = s + c;
        } else if (c == '"') {
          singleQuoteFlg = !singleQuoteFlg;
        } else {
          s = s + c;
        }
      }
      //最後のデータを追加
      data.push(s.toString());
      return data;
    },
    //現在のレポートとCSVの主キーが同じか確認する
    sameKeyCheck(data, csv) {
      let flag = true;
      Object.keys(data.KeyMap).map((key) => {
        const x = key;
        const y = data.KeyMap[key];
        if (data.Recodes[x].value == "" || csv[y] == "") {
          flag = false;
        }
        if (data.Recodes[x].value != csv[y]) {
          flag = false;
        }
      });
      return flag;
    },
    setValue(report, csv, to, from, tag) {
      if (typeof tag == "undefined") {
        if (to == "FID0.name") {
          report.Recodes["FID0"].name = String(csv[from]).trim();
        } else if (typeof csv[from] != "undefined" && csv[from] != "") {
          report.Recodes[to].value = String(csv[from])
            .trim()
            .replace("　", " ");
        }
        return;
      } else {
        switch (tag) {
          case "add":
            report.Recodes[to].value += String(csv[from]).trim();
            break;
        }
      }
    },
    async chargeExcel(event) {
      this.isLoading = true;
      this.errorMessages = [];
      try {
        const file = event;
        this.isLoading = false;
        if (file) {
          const reader = new FileReader();
          reader.onload = async (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]]; //シート番号
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
            //先頭削除
            jsonData.shift();
            //データ処理
            this.reports = this.convertReports(jsonData);
            this.sendFlag = true;
            //バリデーションチェック
            this.isLoading = false;
            await this.$nextTick();
            await this.checkValidation();
          };
          reader.readAsArrayBuffer(file);
        }
        this.isLoading = false;
      } catch (e) {
        console.log("chargeExcel", e);
      }
    },
    async chargeCSV(file) {
      //CSVの取り込み
      this.isLoading = true;
      try {
        this.step = 0;
        this.reports = [];
        this.delReports = [];
        //文字コード変換
        const data = await this.charCodeConvert(file);
        //2次元配列に分割
        const data2 = data.split("\n");
        let head = this.header_flag ? 1 : 0;
        let reports = data2.slice(head);
        let data3 = [];
        //取り込み処理
        for (let i = 0; i < reports.length; i++) {
          data3.push(this.csvSplit(reports[i]));
        }
        //report形式に変換
        this.reports = this.convertReports(data3);
        this.sendFlag = true;
        //バリデーションチェック
        this.isLoading = false;
        await this.$nextTick();
        await this.checkValidation();
      } catch (e) {
        this.sendFlag = false;
        console.log(e);
      }
      this.isLoading = false;
    },
    convertReports(csv) {
      let data = [];
      let mainReport = JSON.parse(JSON.stringify(this.data));
      //
      csv.forEach((c, index) => {
        if (c.length > 1) {
          //主キーチェック
          if (!this.sameKeyCheck(mainReport, c)) {
            if (index > 0) {
              data.push(mainReport);
            }
            mainReport = JSON.parse(JSON.stringify(this.data));
            //ユーザ情報基本設定
            this.setValue(mainReport, c, "FID0.name", 8); //社員番号
            this.setValue(mainReport, c, "FID9137", 8); //社員番号
            this.setValue(mainReport, c, "FID7762", 9); //氏名
            this.setValue(mainReport, c, "FID7763", 10); //氏名カナ
            this.setValue(mainReport, c, "FID7767", 0); //事業部・支社
            this.setValue(mainReport, c, "FID7776", 6); //部署
            //カタカナを全角に変換
            c[14] = convertHalfWidthToFullWidthKana(String(c[14]));
            this.setValue(mainReport, c, "FID7777", 14); //従業員区分
            //各権限設定

            //車両承認権限
            if (c.length > 37 && c[37] == "〇") {
              mainReport.Recodes.FID7764.value = "承認者";
            }
            if (c.length > 36 && c[36] == "〇") {
              mainReport.Recodes.FID7765.value = "給油確認者"; //給油確認者
            }
            //この項目は設定項目がない
            /*
            if (c.length > 36 && c[36] == "〇") {
              //被り
              mainReport.Recodes.FID8748.value = "日時承認"; //車両確認者(日別)
            }
            if (c.length > 36 && c[36] == "〇") {
              //被り
              mainReport.Recodes.FID7766.value = "車両責任者"; //車両確認者(月別)
            }
            */
            if (c.length > 34 && c[34] == "〇") {
              mainReport.Recodes.FID8133.value = "車両追加者"; //車両追加者
            }
            if (c.length > 38 && c[38] == "〇") {
              mainReport.Recodes.FID8134.value = "マイカー添付書類確認者"; //マイカー添付書類確認者
            }
            //代理者の項目は、現在未使用
            //初期設定
            mainReport.Recodes.FID0.password = "Enegori@2023"; //デフォルトパスワード
          }
          //サブレポート宣言処理

          //CSV読み込み時のデータ対応
          //サブレポートの追加処理
        }
      });
      data.push(mainReport);
      //
      let arr = [];
      data.map((repo) => {
        let flag1 = false;
        let flag2 = false;
        for (let i = 0; i < this.divisions.length; i++) {
          if (this.divisions[i].value === repo.Recodes.FID7767.value) {
            flag1 = true;
            break;
          }
        }
        for (let i = 0; i < this.departments.length; i++) {
          if (this.departments[i].value === repo.Recodes.FID7776.value) {
            flag2 = true;
            break;
          }
        }
        if (flag1 && flag2) {
          arr.push(repo);
        } else {
          if (!flag1) {
            this.errorMessages.push(
              "事業部コード[" +
                repo.Recodes.FID7767.value +
                "]が見つかりませんでした"
            );
          }
          if (!flag2) {
            this.errorMessages.push(
              "部署コード[" +
                repo.Recodes.FID7776.value +
                "]が見つかりませんでした"
            );
          }
        }
      });
      //
      return arr;
    },
    validationCheck(report) {
      function hasValue(arr, targetValue) {
        for (const item of arr) {
          if (item.value === targetValue) {
            return true;
          }
        }
        return false;
      }
      //
      if (report.Recodes.FID7767.value == "") {
        return [false, "事業部コードが含まれていないユーザが存在ます"];
      }
      if (report.Recodes.FID7776.value == "") {
        return [false, "部署コードが含まれていないユーザが存在ます"];
      }
      //

      if (!hasValue(this.divisions, report.Recodes.FID7767.value)) {
        return [
          false,
          "事業部コード[" +
            report.Recodes.FID7767.value +
            "]が見つかりませんでした",
        ];
      }
      if (!hasValue(this.departments, report.Recodes.FID7776.value)) {
        return [
          false,
          "部署コード[" +
            report.Recodes.FID7776.value +
            "]が見つかりませんでした",
        ];
      }
      return [true, ""];
    },
    checkValidation: function () {
      let form = this.$refs.form;
      let check = form.validate();

      return check;
    },
    async updateCheck() {
      const isCorrectValue = this.checkValidation();
      if (isCorrectValue == false) return;
      if (this.reports.length >= 10000) {
        alert("取り込み件数が上限10000件を超えています");
        return;
      }
      this.isLoading = true;
      //バリデーションチェック
      let flag = true;
      this.errorMessages = [];
      this.reports.map((repo) => {
        let [chk, message] = this.validationCheck(repo);
        if (!chk) {
          flag = false;
        }
        if (message != "" && !this.errorMessages.includes(message)) {
          this.errorMessages.push(message);
        }
      });
      if (!flag) {
        this.isLoading = false;
        return;
      }
      //まず変更部分のチェック
      try {
        const response = await eneosCar.UserDataCheck(this.reports);
        //
        this.reports = [];
        //変更データの抽出
        response.map((repo) => {
          if (
            repo.Recodes.FID0.detail != "氏名の変更" &&
            repo.Recodes.FID0.name != "0001" && //削除対象から除外
            repo.Recodes.FID0.name != "0002" &&
            repo.Recodes.FID0.name != "0003" &&
            repo.Recodes.FID0.name != "0004"
          ) {
            //氏名の変更は無視
            this.reports.push(repo);
          }
        });
        //削除用ユーザの抽出
        response.map((repo) => {
          if (
            repo.Recodes.FID0.detail == "ユーザ削除" &&
            repo.Recodes.FID0.name != "0001" && //削除対象から除外
            repo.Recodes.FID0.name != "0002" &&
            repo.Recodes.FID0.name != "0003" &&
            repo.Recodes.FID0.name != "0004"
          ) {
            this.delReports.push(repo); //[こっちは表示だけ]
          }
        });
        this.step = 1;
      } catch (e) {
        alert("変更チェックができませんでした。");
        this.isLoading = false;
      }
      this.isLoading = false;
      //history.back();
    },
    async deleteCheck() {
      this.isLoading = true;
      try {
        this.step = 2;
      } catch (e) {
        console.log("deleteCheck", e);
      }
      this.isLoading = false;
    },
    async sendUserData() {
      this.isLoading = true;
      try {
        const response = await eneosCar.UserDataUpdate(this.reports);
        console.log("result", response);
        this.step = 0;
        alert("ユーザ情報 更新");
      } catch (e) {
        alert("ユーザ情報の更新に失敗しました");
      }
      this.isLoading = false;
      this.reports = [];
      history.back();
    },
    //ユーザデータの整合性チェック
    async UserConsistencyCheck() {
      try {
        this.isLoading = true;
        this.consistencyCheckReports = [];
        const response = await eneosCar.UserConsistencyCheck(this.reports);
        this.consistencyCheckReports = response;
        this.isLoading = false;
      } catch (e) {
        console.log("checkUserData", e);
      }
    },
    //ユーザデータの整合性チェック後削除
    async UserDataEnsureConsistency() {
      try {
        this.isLoading = true;
        await eneosCar.UserDataEnsureConsistency(this.consistencyCheckReports);
        this.isLoading = false;
      } catch (e) {
        console.log("checkUserData", e);
      }
    },

    //
    back() {
      history.back();
    },
  },
};
</script>
