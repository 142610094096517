import Repository from "./go_api";
import store from "../store/vuex";
import axios from "axios";

const resource = "/api/emp/v1";
const resourceV2 = "/api/customer/v2";

/* version 1.5:apiはv1を利用しリクエスト・レスポンス形式のみv2としている */
export default {
  getID(str) {
    var exp = str.match(/[0-9]+/);
    return Number(exp);
  },
  OptionCopy(base, data) {
    for (const [formID, d] of Object.entries(data)) {
      if (typeof base[formID] != "undefined") {
        base[formID].value = d.value;
      }
      if (typeof d.subReports != "undefined") {
        base[formID].subReports = d.subReports;
      }
    }
    return base;
  },
  convertReportDetail(Record) {
    let Recodes = {};
    //
    Record.map((r) => {
      if (typeof r.Form_ID == "undefined" && typeof r.form_id != "undefined") {
        r.Form_ID = r.form_id;
        r.Value = r.value;
      }
      Recodes["FID" + r.Form_ID] = {
        value: r.Value,
        formType: r.form_type,
      };
      if (r.form_type == 17) {
        Recodes["FID" + r.Form_ID].subReports = [];
        if (
          typeof r.Reports != "undefined" &&
          r.Reports != null &&
          r.Reports.length > 0
        ) {
          r.Reports.map((rr) => {
            let sub = {
              PageID: rr.Page_id,
              ReportID: rr.Report_id,
              Recodes: [],
              DeleteSubReports: [],
            };
            sub.Recodes = this.convertReportDetail(rr.Records);
            Recodes["FID" + r.Form_ID].subReports.push(sub);
          });
        }
      }
    });
    //
    return Recodes;
  },
  convertSendReport(from) {
    //
    let to = {
      Page_id: from.PageID,
      Report_id: from.ReportID,
      Records: [], //Recodes: [],
      DeleteSubReports: [],
    };
    //csv取り込み対応設定
    if (typeof from.UpdateType != "undefined") {
      to.UpdateType = from.UpdateType;
      to.KeyID = from.KeyID;
    }
    //
    if (
      typeof from.DeleteSubReports != "undefined" &&
      from.DeleteSubReports.length > 0
    ) {
      from.DeleteSubReports.map((del) => {
        to.DeleteSubReports = to.DeleteSubReports.concat(
          this.convertSendReport(del)
        );
      });
    }
    //
    for (const [formID, Record] of Object.entries(from.Recodes)) {
      let data = {
        form_id: this.getID(formID),
        form_type: Record.formType,
        value: Record.value,
      };
      if (Record.formType == 17) {
        data.value = data.value.replace("PID", "");
        data.sub_reports = [];
        if (
          typeof Record.subReports != "undefined" &&
          Record.subReports != null
        ) {
          Record.subReports.map((sub) => {
            if (sub != null && JSON.stringify(sub) != "{}") {
              data.sub_reports.push(this.convertSendReport(sub));
            }
          });
        }
      }
      if (Record.formType == 23 && typeof Record.option != "undefined") {
        data.option = Record.option;
      }
      to.Records.push(data);
    }
    return to;
  },
  convertReportIndex(r) {
    let subReports = null;
    if (r.SubValue != null && r.SubValue.length > 0) {
      subReports = this.convertReportSearch(r.SubValue);
    }
    return {
      formType: r.FormType,
      value: r.Value,
      modal: false,
      subReports: subReports,
    };
  },
  convertReportSearch(from) {
    let to = [];
    from.map((f) => {
      let Recodes = {};
      f.Recodes.map((r) => {
        Recodes["FID" + r.FormID] = this.convertReportIndex(r);
      });
      let data = {
        ReportID: f.Report_ID,
        Recodes: Recodes,
      };
      to.push(data);
    });
    return to;
  },

  convertReqGetMaster(from) {
    let to = {
      pageID: from.PageID,
      orderBy: "",
      search: [],
      option: "master",
    };
    from.search.map((search) => {
      to.search.push({
        form_id: search.FormID,
        value: search.value,
        option: search.option,
      });
    });
    return to;
  },
  convertResGetMaster(from) {
    if (
      typeof from.message != "undefined" &&
      from.message == "not match master"
    ) {
      return {};
    }
    let master = {
      //PageID: this.PageID,
      //ReportID: Number(item.ReportID),
      Recodes: {},
    };
    from.map((r) => {
      if (r.Reports == null) {
        master.Recodes["FID" + r.Form_ID] = {
          value: r.Value,
          formType: r.form_type,
          sub_reports: null,
        };
      } else {
        //サブページ対応
        master.Recodes["FID" + r.Form_ID] = {
          value: r.Value,
          formType: r.form_type,
          subReports: this.convertResGetMasterSub(r.Reports),
        };
      }
    });

    return master;
  },
  convertResGetMasterSub(reports) {
    let result = [];
    for (let i = 0; i < reports.length; i++) {
      let Recodes = {};
      reports[i].Records.map((r) => {
        if (r.sub_reports == null) {
          Recodes["FID" + r.form_id] = {
            value: r.value,
            formType: r.form_type,
            sub_reports: null,
          };
        } else {
          Recodes["FID" + r.form_id] = {
            value: r.value,
            formType: r.form_type,
            subReports: this.convertResGetMasterSub(r.sub_reports),
          };
        }
      });

      result.push({
        //reportID: 0,
        pageID: reports[i].Page_id,
        Recodes: Recodes,
      });
    }

    return result;
  },
  //---------------------------------------------------------------
  //Reportの一覧取得
  async getReportIndex(pageID) {
    const result = await this.API_Get(resource + "/report/index/" + pageID);
    return result;
  },
  //Reportの一覧を検索する(旧ReportSearchDetail)
  async ReportSearch(data) {
    const result = await this.API_Post(
      data,
      resource + "/report/detail/search"
    );
    return this.convertReportSearch(result.data);
  },
  //Reportの詳細取得※変換対象
  async ReportDetail(data) {
    data.page_id = data.PageID;
    const result = await this.API_Post(data, resource + "/report/index");
    return this.convertReportDetail(result.data);
  },
  async ReportDetailV2(data) {
    const result = await this.API_Post(data, resourceV2 + "/report/get");
    return result.data.data;
  },
  async ReportSearchV2(data) {
    const result = await this.API_Post(data, resourceV2 + "/reports/index");
    return result.data;
  },
  //Reportの詳細取得
  async GetMaster(data) {
    const result = await this.API_Post(
      this.convertReqGetMaster(data),
      resource + "/report/master"
    );
    return this.convertResGetMaster(result.data);
  },
  async GetMasterV2(data) {
    const result = await this.API_Post(data, resourceV2 + "/reports/master");
    return result.data;
  },
  //Reportの詳細登録※変換対象
  async SendReport(body) {
    let data = this.convertSendReport(body);
    const result = await this.API_Post(data, resource + "/report/reg");
    return result.data;
  },
  //Reportの一括承認
  async UpdateAnyReport(data) {
    const result = await this.API_Post(data, resource + "/report/update/any");
    return result.data;
  },
  //Formの一覧取得
  async FormDetail(FormID) {
    //resource + "/form/" + FormID + "?params[]=limit:100&params[]=order:new" //制限を加える場合
    const result = await this.API_Get(resource + "/form/" + FormID);
    return result;
  },
  async FormList(FormID1, FormID2, FormID3) {
    if (FormID3 == null) {
      const result = await this.API_Get(
        resource + "/form/" + FormID1 + "?ID2=" + FormID2
      );
      return result;
    } else {
      const result = await this.API_Get(
        resource + "/form/" + FormID1 + "?ID2=" + FormID2 + "&ID3=" + FormID3
      );
      return result;
    }
  },
  //Formを検索条件で絞りこんで一覧として取得
  async FormSearch(data) {
    const result = await this.API_Post(data, resource + "/form/search");
    return result.data.selectBox;
  },
  async SendAnyReport(data) {
    const result = await this.API_Post(data, resource + "/report/reg/any");
    return result;
  },
  async DelReport(body) {
    let data = this.convertSendReport(body);
    await this.API_Post(data, resource + "/report/del");
    return true;
  },
  async UploadFile(data, file) {
    try {
      //1.APIからPreURLの取得
      const response = await this.API_Post(data, resource + "/upload");
      //2.URLとDataをS3にアップロード
      await this.FileUpload(response.data.url, file);
      return response.data.name;
    } catch (e) {
      console.log("UploadFile");
      throw e;
    }
  },
  //ファイルアクセス用URLの取得
  async FileAccess(data) {
    //1.APIからアクセス用PreURLの取得
    const response = await this.API_Post(data, resource + "/accessURL");
    return response.data.url;
  },
  //ユーザ権限の取得
  async GetRole() {
    const result = await this.API_Get(resource + "/employee");
    return result;
  },
  //ユーザ情報の一覧取得
  async UserIndex(data) {
    const result = await this.API_Get(resourceV2 + "/employee/index/" + data);
    return result.data;
  },
  //
  async UserList(data) {
    const result = await this.API_Get(resource + "/employee/index/" + data);
    let list = [];
    result.data.forEach((report) => {
      list.push(report.Value[0]);
    });
    return list;
  },
  //Home画面用(そのうちuserSettingでも使用する)
  async EmployeeDetail() {
    try {
      const result = await this.API_Get(resourceV2 + "/employee/detail");
      return result.data;
    } catch (e) {
      console.log("e", e);
    }
  },
  //ユーザ情報の詳細取得(サブレポート非対応)
  async UserDetail(data, base) {
    try {
      data.Page_id = data.PageID;
      const result = await this.API_Post(data, resource + "/employee/detail");
      const response = result.data;
      //変換
      const to = {
        FID0: {
          formType: 21,
          account: response.account,
          name: response.name,
          mail: response.mail,
          role: response.role,
          password: "",
        },
      };
      //--------------------------------------------------------------
      for (const [FID, d] of Object.entries(base)) {
        if (FID != "FID0") {
          to[FID] = d;
        }
      }
      response.data.map((data) => {
        if (data.Form_ID != 0) {
          //データ格納処理
          if (typeof to["FID" + data.Form_ID] != "undefined") {
            to["FID" + data.Form_ID].value = data.Value;
          }
        }
      });
      //--------------------------------------------------------------
      return [to, response.report_id];
    } catch (e) {
      console.log("e", e);
    }
  },
  convertUserSetting(data) {
    let d = this.convertSendReport(data);
    d.Report_id = data.ReportID;
    d.Page_id = data.PageID;
    d.Records[0].value = data.Recodes.FID0.account;
    d.Records[0].name = data.Recodes.FID0.name;
    d.Records[0].mail = data.Recodes.FID0.mail;
    d.Records[0].role = data.Recodes.FID0.role;
    d.Records[0].attribute = data.Recodes.FID0.attribute;
    d.Records[0].password = data.Recodes.FID0.password;
    console.log("convertUserSetting", d);
    return d;
  },
  //ユーザ情報の登録
  async CreateEmployee(data) {
    const result = await this.API_Post(
      this.convertUserSetting(data),
      resource + "/employee/create"
    );
    return result.data;
  },
  //ユーザ情報の更新
  async UpdateEmployee(data) {
    const result = await this.API_Post(
      this.convertUserSetting(data),
      resource + "/employee/update"
    );
    return result.data;
  },
  //ユーザ情報の削除
  async DeleteEmployee(data) {
    const result = await this.API_Post(
      this.convertUserSetting(data),
      resource + "/employee/delete"
    );
    return result.data;
  },
  //メールの送信
  async SendMail(data) {
    const result = await this.API_Post(data, resource + "/mail/send");
    return result.data;
  },
  //通知の送信
  async SendNotification(data) {
    const result = await this.API_Post(data, resourceV2 + "/notification/chat");
    return result.data;
  },
  //特定レポートの削除
  async RemoveReports(deleteReports) {
    const result = await this.API_Post(
      deleteReports,
      resource + "/report/remove"
    );
    return result.data;
  },
  //2段階認証の登録
  async SendSecondAuth(data) {
    const result = await this.API_Post(
      data,
      resource + "/employee/auth/setting"
    );
    return result.data;
  },
  //Reportの一括登録(1)CSV取り込み形式 ※v1を使用
  async ImportCSV(reports) {
    let data = [];
    reports.map((repo) => {
      let d = this.convertSendReport(repo);
      d.UpdateType = repo.UpdateType;
      d.KeyID = repo.KeyID;
      data.push(d);
    });
    const result = await this.API_Post(data, resource + "/report/import");
    return result.data;
  },
  //Reportの一括登録(2)ReportIndex形式
  async RegReports(data) {
    const result = await this.API_Post(data, resourceV2 + "/reports/reg");
    return result.data;
  },
  //Reportの一括削除
  async DelReports(data) {
    const result = await this.API_Post(data, resourceV2 + "/reports/del");
    return result.data;
  },
  //---------------------------------------------------
  async API_Get(URL) {
    Repository.interceptors.request.use(function (config) {
      config.headers.Authorization = `Bearer ${store.state.accessToken}`;
      return config;
    });
    const { data } = await Repository.get(URL).catch(function (error) {
      if (error.isAxiosError) {
        if (error.code === "ECONNABORTED") {
          alert("エラーコード[0830-1]セッション切断"); //APIへのリクエスト失敗
          return false;
        }
        alert("エラーコード[0830]"); //APIへのリクエスト失敗
        return false;
      }
      const errorMessage = error.response.data || error.message;
      console.log("Get:", errorMessage);
    });
    return data;
  },
  async API_Post(data, URL) {
    let result = await Repository.post(URL, data, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${store.state.accessToken}`,
      },
    }).catch(function (error) {
      if (error.isAxiosError) {
        if (error.code === "ECONNABORTED") {
          alert("エラーコード[0830-1]セッション切断"); //APIへのリクエスト失敗
          return false;
        }
        alert("エラーコード[0830]"); //APIへのリクエスト失敗
        return false;
      }
      const errorMessage = error.response.data || error.message;
      console.log("post:", errorMessage);
      return false;
    });
    return result;
  },
  async FileUpload(URL, Data) {
    //api用の認証headerが含まれてしまうのでaxiosを使用
    await axios
      .put(URL, Data, {
        headers: {
          "Content-Type": "image/jpeg",
          //"Content-type": 'multipart/form-data',
        },
      })
      .then(() => {
        return true;
      })
      .catch((e) => {
        const errorMessage = e.response.data || e.message;
        console.log("FileUpload:", errorMessage);
        throw e;
      });
  },
  //ファイルダウンロード
  async FileDownload(URL, fileType) {
    const response = await axios.get(URL, {
      headers: {
        "Content-Type": fileType,
        //"Access-Control-Allow-Origin": "http://localhost:8003/",
      },
      responseType: "arraybuffer",
      data: {},
    });
    return response;
  },
  //個別認証の実施
  async SecondCertification(data) {
    const response = await Repository.post("/kakanai/login", data, {}).catch(
      function (error) {
        console.log("SecondCertification", error);
        return false;
      }
    );
    //
    if (response.data.result) {
      return true;
    } else {
      return response.data.message;
    }
  },
};
